import { PortalLayout, PortalPageTitle } from '@/components/Portal';
import { FeedsListing } from '@/components/Portal/Feeds';
import React from 'react';

export default function Newsroom() {

  return (
    <PortalLayout>
      <PortalPageTitle
        heading={'Current Headlines'}
        description="Browse external news headlines and stay up to date"
      />

      <div className="container">
        <FeedsListing />
      </div>
    </PortalLayout>
  );
}
