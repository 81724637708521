import axios from 'axios';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { MEMBER_INSTITUTIONS } from '../constants';
import {
  requestMembersInstitutionsSuccess,
  requestMembersInstitutionsFailed,
  requestDivisionsBanksSuccess,
  requestDivisionsBanksFailed,
  requestBranchesNearMeSuccess,
  requestBranchesNearMeFailed,
  requestAllOffersSuccess,
  requestAllOffersFailed
} from '../actions';

import { getUserToken } from '@/store/selectors';
import { BASE_URL, API } from '@/config';

export function* requestMembersInstitutions(action) {
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestMembersInstitutionsSuccess(response));
  } catch (err) {
    yield put(requestMembersInstitutionsFailed(err));
  }
}

export function* requestDivisionsBanks(action) {
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action?.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestDivisionsBanksSuccess(response));
  } catch (err) {
    yield put(requestDivisionsBanksFailed(err));
  }
}

export function* requestBranchesNearMe(action) {
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        lat: action.payload?.lat,
        long: action.payload?.long,
        per_page: action.payload?.per_page,
        page: action.payload?.page,
        inner_radius: 0,
        outer_radius: action.payload?.radius
      }
    });

  try {
    const response = yield call(request);
    yield put(requestBranchesNearMeSuccess(response));
  } catch (err) {
    yield put(requestBranchesNearMeFailed(err));
  }
}

export function* requestAllOffers(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(requestAllOffersSuccess(response));
  } catch (err) {
    yield put(requestAllOffersFailed(err));
  }
}

export function* watchRequestMembersInstitutions() {
  yield takeLatest(
    MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS,
    requestMembersInstitutions
  );
}

export function* watchRequestDivisionsBanks() {
  yield takeLatest(MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS, requestDivisionsBanks);
}

export function* watchRequestBranchesNearMe() {
  yield takeLatest(MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME, requestBranchesNearMe);
}

export function* watchRequestAllOffers() {
  yield takeLatest(MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS, requestAllOffers);
}

const sagas = [
  fork(watchRequestMembersInstitutions),
  fork(watchRequestDivisionsBanks),
  fork(watchRequestBranchesNearMe),
  fork(watchRequestAllOffers)
];

export default sagas;
