import { CREATE_ACCOUNT_FORM } from '../constants';

const initialState = {
  data: {
    firstName: '',
    lastName: '',
    tel: '',
    email: '',
    companyId: '',
    department: '',
    positionTitle: '',
    subscribeWeeklyBulletins: 0,
    subscribeAustralianMutuals: 0,
    agreeToTerms: 0
  }
};

const createAccountFormReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_ACCOUNT_FORM.STEP_1:
      return {
        ...state,
        data: {
          ...state.data,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          tel: action.payload.tel,
          subscribeWeeklyBulletins: action.payload.subscribeWeeklyBulletins,
          subscribeAustralianMutuals: action.payload.subscribeAustralianMutuals
        }
      };

    case CREATE_ACCOUNT_FORM.STEP_2:
      return {
        ...state,
        data: {
          ...state.data,
          companyId: action.payload.companyId,
          department: action.payload.department,
          positionTitle: action.payload.positionTitle
        }
      };

    case CREATE_ACCOUNT_FORM.STEP_3:
      return {
        ...state,
        data: {
          ...state.data,
          email: action.payload.email,
          agreeToTerms: action.payload.agreeToTerms
        }
      };

    case CREATE_ACCOUNT_FORM.CLEAR_FORM:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default createAccountFormReducer;
