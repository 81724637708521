import React from 'react';
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
// Store
import { useDispatch, useSelector } from 'react-redux';
import { checkToken, loginUser } from '@/store/actions';
import { getUserToken, getUserError } from '@/store/selectors/user';

// Components
import { Button, EmailField, PasswordField, Checkbox } from '@/components';
import { ErrorTags } from '@/components/Portal';
import { iconArrowThin } from '@/images/icons';
import OnboardingFormTransition from './OnboardingFormTransition';
import { PORTAL_PATHS } from '@/config';

export function SignInForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  // get the query from url
  let searchParams = new URLSearchParams(search);
  const backUrlParam = searchParams.get('backUrl');

  const userToken = useSelector(getUserToken);
  const error = useSelector(getUserError);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onSubmitForm = (data) => {
    const { email, password } = data;

    dispatch(
      loginUser({
        email,
        password
      })
    );
  };

  React.useEffect(() => {
    if (!isEmpty(userToken)) {
      dispatch(checkToken());
    }
  }, [userToken]);
  React.useEffect(() => {
    if (!isEmpty(userToken)) {
      if (backUrlParam) {
        history.push(backUrlParam);
      } else {
        history.push(PORTAL_PATHS.home);
      }
    }
  }, [userToken]);

  return (
    <OnboardingFormTransition formPath={PORTAL_PATHS.onboarding.signIn}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmitForm)} noValidate>
          <h4 className="form-title">Sign in to your account</h4>

          <EmailField control={form.control} />

          <PasswordField control={form.control} />

          <div className="assist-actions">
            <div className="row no-margin">
              <div className="col-xs-6 no-pad">
                <Checkbox id="checkbox-remember-me" name="rememberMe">
                  Remember me
                </Checkbox>
              </div>

              <div className="col-xs-6 no-pad end-xs">
                <Link
                  className="forgot-password-btn"
                  to={PORTAL_PATHS.onboarding.forgotPassword}
                >
                  Forgot your password?
                </Link>
              </div>
            </div>
          </div>
          {error && error.message == 'The given data was invalid.' && (
            <>
              <div className="row errors">
                <ErrorTags errors={error.errors} />
              </div>
              <br />
            </>
          )}
          <Button variant={'btn-fill btn-full-width'} icon={iconArrowThin} type="submit">
            Sign In
          </Button>
        </form>
      </FormProvider>
    </OnboardingFormTransition>
  );
}

export default React.memo(SignInForm);
