import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { iconArrowThin } from '@/images/icons';
import { logoCobaBig } from '@/images/logos';
import transparent_half_logo from '@/images/transparent_half_logo.png';
import business_meeting from '@/images/business_meeting.jpg';
import MiniLogo from '../MiniLogo';

function IndustryAdvocate() {
  const handleScroll = () => {
    const position = window.pageYOffset;
    const targetIndustryAdvocate = document.querySelector('#industry-advocate');
    const parallaxObjects = document.querySelectorAll('.parallax-object');

    const enableParallax =
      targetIndustryAdvocate.offsetTop - position <=
        targetIndustryAdvocate.clientHeight / 1.5 &&
      targetIndustryAdvocate.offsetTop - position >=
        -targetIndustryAdvocate.clientHeight / 3.5;

    let index = 0;
    let length = parallaxObjects.length;

    for (index; index < length; index++) {
      let pos =
        enableParallax &&
        (targetIndustryAdvocate.offsetTop - position) *
          parallaxObjects[index].dataset.rate;

      enableParallax &&
        (parallaxObjects[index].style.transform = `translate3d(0px, ${pos}px, 0px)`);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <section id="industry-advocate" data-testid="industry-advocate">
      <div className="content-wrapper">
        <div className="content">
          <div className="heading">
            <MiniLogo />
            <div>
              <h2>
                We are the <u>industry advocate</u> for <br />
                Australia’s customer owned banking sector
              </h2>
              <p>
                Australians want a banking institution they can trust to put them first.
                Customer owned banking is the alternative where customer interests are not
                in conflict with shareholder interests.
              </p>
              <Link to="/about-coba/overview-and-history" className="btn btn-primary">
                More about us
                <span className="icon">{iconArrowThin}</span>
              </Link>
            </div>
          </div>

          <div className="parallax-image">
            <div className="image parallax-object" data-rate="-0.1">
              <img className="bg-img" src={business_meeting} alt="business meeting" />
              <img
                className="transparent-logo"
                src={transparent_half_logo}
                alt="transparent Coba half logo"
              />
            </div>
            <div className="logo parallax-object" data-rate="0.25">
              {logoCobaBig}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IndustryAdvocate;
