import { RATE_EXPERIENCE } from '../constants';

const initialState = {
  isLoading: false,
  ratingData: [],
  successful: false,
  failed: false
};

const rateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RATE_EXPERIENCE.RATE_EXPERIENCE:
      return { ...state, isLoading: true, currentPage: action.payload };

    case RATE_EXPERIENCE.SUCCESSFUL_RATE_EXPERIENCE:
      return {
        ...state,
        isLoading: false,
        ratingData: action.payload,
        successful: true
      };

    case RATE_EXPERIENCE.FAILED_RATE_EXPERIENCE:
      return {
        ...state,
        isLoading: false,
        ratingData: action.payload,
        failed: true
      };
  }

  return state;
};

export default rateReducer;
