import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { isEmpty, clone } from 'lodash';
import { TableBlock } from '@/components';
import Layout from '@/components/Layout';
import VideoEmbed from '@/components/VideoEmbed';
import SEO from '@/components/SEO';
import {
  ArticleAccordion,
  ArticleFindABranch,
  ArticleBanner,
  ArticleBreadcrumbs,
  ArticleSidebar,
  ArticleMember,
  ArticleCallout,
  ArticleFooter
} from '@/components/ArticlePage';
import { getArticle } from '@/store/selectors/articles';
import { requestArticleBySlug } from '@/store/actions';

const stripBrackets = (str) => str.replace('[', '').replace(']', '');

const searchAndReplaceVideo = (content) => {
  let contentCopy = clone(content);
  const squareBrackets = RegExp(/\[(.*?)\]/gi);
  const potentialVideos = contentCopy.match(squareBrackets);

  if (potentialVideos) {
    potentialVideos.forEach((video) => {
      const videoUrl = stripBrackets(video);
      const videoComponent = renderToString(<VideoEmbed video_url={videoUrl} />);
      contentCopy = contentCopy.replace(video, videoComponent);
    });
  }

  return contentCopy;
};

const stripHtml = (html) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const ArticlePage = () => {
  const location = useLocation();
  const page = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const { articleTitle } = useParams();
  const article = useSelector(getArticle);

  const actions = bindActionCreators({ requestArticleBySlug }, useDispatch());
  const [articleContent, setArticleContent] = React.useState('');
  const table = !!article?.content_table ? JSON.parse(article.content_table) : [];

  React.useEffect(() => {
    const slug = page === 'media-tool-kit' ? page : articleTitle;

    slug &&
      actions.requestArticleBySlug({
        slug: slug
      });
  }, [page]);

  React.useEffect(() => {
    if (!isEmpty(article) && article?.content) {
      setArticleContent(searchAndReplaceVideo(article.content));
    }
  }, [article]);

  if (!article) {
    return <Redirect to="/404" />;
  }

  return (
    <Layout>
      <article id="article-page" data-testid="article-page">
        <SEO
          title={`COBA article | ${stripHtml(article.heading)}`}
          article={true}
          author={article.author}
          keywords="article, blog post, media release, report, policy submission, campaign outcome, key issues, members, about us, overview and history, customer owned banking association, coba, Australia"
        />

        <ArticleBanner />

        <div className="content-wrapper">
          <div className="article-content-wrapper">
            <ArticleSidebar />

            <div className="article-content">
              <ArticleBreadcrumbs />

              {!!articleContent && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: articleContent
                  }}
                />
              )}

              {!isEmpty(table) && (
                <div className="content-section">
                  {table.map((content) => (
                    <TableBlock key={content.key} data={content.attributes.table} />
                  ))}
                </div>
              )}

              <ArticleAccordion />

              {!isEmpty(article) && !isEmpty(article.institution) && (
                <ArticleMember memberCalloutData={article.institution} />
              )}

              {!isEmpty(article) && !isEmpty(article.key_issue_callout) && (
                <ArticleCallout calloutData={article.key_issue_callout} />
              )}
            </div>
          </div>
        </div>

        <ArticleFindABranch />

        <ArticleFooter />
      </article>
    </Layout>
  );
};

export default ArticlePage;
