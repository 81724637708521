import React from 'react';

export default function RadioButton({ text, id, name, onChange, value }) {
  return (
    <div className="radio-button-wrapper">
      <input
        id={id}
        className="radio-button"
        type="radio"
        name={name}
        onChange={onChange}
        checked={id === value}
      />
      <label className="radio-button-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
}
