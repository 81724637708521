import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';

export const validationRegex = {
  optionalProtocolUrl:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  httpUrl:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  // *NOTE: matches urls like www.site.com or site.com
  noProtocolUrl: /^(?!https?)(www\.)?.*$/,
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  nonEmptyWhiteSpaceString: /.*\S.*/,
  ausPhoneNumber: /(\(+61\)|\+61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){6,10}/,
  // * NOTE: accounts for postcodes 0200 – 0299, 0800-0999 and 1000 – 9999
  ausPostcode: /^(0[289][0-9]{2})|([123456789][0-9]{3})$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)?(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
  atLeastOneSpecialChar: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
};

export function useRegisterField(validation) {
  const { register } = useFormContext();
  return !isEmpty(validation) ? register({ ...validation }) : register;
}

export function fieldHasError(fieldNames, errors) {
  const errorFieldNames = Object.keys(errors);
  return errorFieldNames.some((errorFieldName) => fieldNames.includes(errorFieldName))
    ? 'error'
    : '';
}

export function getChangedFormData(dirtyFields, formData, requiredFields = {}) {
  return !isEmpty(formData) || !!dirtyFields?.length
    ? Object.keys(dirtyFields).reduce(
        (a, c) => (formData?.[c] ? { ...a, [c]: formData?.[c] } : a),
        requiredFields
      )
    : requiredFields;
}
