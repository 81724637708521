import axios from 'axios';
import { BASE_URL, API } from '@/config';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { STAFF } from '../constants';
import { 
  requestStaffDirectorySuccess, 
  requestStaffDirectoryFailed,
} from '../actions';
import { getUserToken } from '@/store/selectors';

export function* getStaffDirectory(action) {
  const token = yield select(getUserToken);
  
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
    });

  try {
    const response = yield call(request);
    yield put(requestStaffDirectorySuccess(response));
  } catch (err) {
    yield put(requestStaffDirectoryFailed(err));
  }
}


export function* watchGetStaffDirectory() {
  yield takeLatest(STAFF.REQUEST_STAFF_LIST, getStaffDirectory);
}

const sagas = [
  fork(watchGetStaffDirectory)
];

export default sagas;