import PropTypes from 'prop-types';
import React from 'react';

Badge.propTypes = {
  type: PropTypes.string,
  state: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleOnClick: PropTypes.func
};

export default function Badge({ type, state, children, handleOnClick }) {
  return (
    <div
      className={`badge ${type} ${state}`}
      onClick={handleOnClick}
      role="button"
    >
      <div className="layout">
        {children}
      </div>
    </div>
  );

}
