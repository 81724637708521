import axios from 'axios';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { EVENTS } from '../constants';
import {
  requestEventFailed,
  requestEventSuccess,
  requestEventsPastYearsFailed,
  requestEventsPastYearsSuccess,
  searchEventsSuccess,
  searchEventsFailed
} from '../actions';
import { BASE_URL, API } from '@/config';
import { getUserToken, selectEventSearchQueries } from '@/store/selectors';

export function* getEvent(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}/${action.payload.id}`
    });

  try {
    const response = yield call(request);
    yield put(requestEventSuccess(response));
  } catch (err) {
    yield put(requestEventFailed(err));
  }
}

export function* getEventsPastYears(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(requestEventsPastYearsSuccess(response));
  } catch (err) {
    yield put(requestEventsPastYearsFailed(err));
  }
}

export function* searchEventsByMonth(action) {
  const token = yield select(getUserToken);
  const queries = yield select(selectEventSearchQueries);

  let searchYear;
  let d1;
  let d2;
  if (queries['month'] && queries['month'] !== '13') {
    d1 = new Date(2022, new Date().getMonth()); // current month
    d2 = new Date(2022, queries['month'] - 1); // searched month

    if (Date.parse(d1) > Date.parse(d2)) {
      searchYear = new Date().getFullYear() + 1;
    } else {
      searchYear = new Date().getFullYear();
    }
  } else {
    searchYear = undefined;
  }

  if (queries['month'] === '13') {
    queries['month'] = undefined;
  }

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...queries,
        ...{ year: action.payload.year ?? (searchYear || null) },
        ...{ search: action.payload.search || null }
      }
    });

  try {
    const response = yield call(request);

    yield put(searchEventsSuccess(response));
  } catch (err) {
    yield put(searchEventsFailed(err));
  }
}

export function* watchGetEvent() {
  yield takeLatest(EVENTS.REQUEST_EVENT, getEvent);
}

export function* watchGetEventsPastYears() {
  yield takeLatest(EVENTS.REQUEST_PAST_YEARS, getEventsPastYears);
}

export function* watchSearchEventsByMonth() {
  yield takeLatest(EVENTS.SEARCH_EVENTS, searchEventsByMonth);
}

const sagas = [
  fork(watchGetEvent),
  fork(watchGetEventsPastYears),
  fork(watchSearchEventsByMonth)
];

export default sagas;
