import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PORTAL_PATHS } from '@/config';
import { PortalPageTitle, PortalLayout, CMSPage } from '@/components/Portal';
import { TrainingMenu, Cart, Modules } from '@/components/Portal/Training';
import { useSelector } from 'react-redux';
import { selectStaticPage } from '@/selectors/page';

export default function Training() {
  const pageTitleHeading = 'Training';
  const { description } = useSelector(selectStaticPage);

  return (
    <PortalLayout>
      <PortalPageTitle
        heading={pageTitleHeading}
        description={description || 'Browse and download available training modules'}
      />
      <TrainingMenu mobile />

      <div className="container v-pad">
        <div className="row">
          <TrainingMenu desktop />

          <div className="col-xs-12 col-md-8">
            <Switch>
              <Route component={Cart} exact path={`${PORTAL_PATHS.TRAINING}/my/cart`} />
              <Route component={Modules} path={`${PORTAL_PATHS.TRAINING}/my/`} />
              <Route
                component={CMSPage}
                exact
                path={`${PORTAL_PATHS.TRAINING}/support/:slug`}
              />
              <Route
                component={Modules}
                exact
                path={`${PORTAL_PATHS.TRAINING}/:category/modules`}
              />
              <Route component={CMSPage} exact path={`${PORTAL_PATHS.TRAINING}/:slug`} />
            </Switch>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
