import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router';
import useMedia from 'use-media';
// Store
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { requestNewsArticlesFirstPage } from '@/store/actions';
import { selectNewsArticles, selectIsArticlesLoading } from '@/store/selectors';
// Components
import { Searchbar } from '@/components/Portal/Common';

Search.propTypes = {
	mobile: PropTypes.bool,
	desktop: PropTypes.bool,
}

export default function Search({mobile, desktop}) {
	const isMobile = useMedia('(max-width: 1023px)');

  const { search } = useLocation();
  const query = new URLSearchParams(search).get('query');
  const actions = bindActionCreators( 
    { requestNewsArticlesFirstPage }, 
    useDispatch()
  );
  const isLoading = useSelector(selectIsArticlesLoading);
  const totalSearchResults = useSelector(selectNewsArticles, shallowEqual)?.total || 0; 
 
  if ( (!isMobile && mobile) || (isMobile && desktop)) {
		return null;
	}

  const searchbarActions = {
    request: {
      dispatch: actions.requestNewsArticlesFirstPage,
      searchParamKey: 'search',
      payload: {}
    },
    clear: {
      dispatch: actions.requestNewsArticlesFirstPage,
      payload: {
        search: ''
      }
    }
  };
  
  return (
    <div className="portal-page-search">
      <Searchbar 
        searchFieldName='newsroom-search'
        defaultValue={query || ''}
        totalSearchResults={totalSearchResults}
        actions={searchbarActions}
        isSearching={isLoading}
      />
    </div>
  );
}
