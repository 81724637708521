import { createSelector } from 'reselect';
import { get, has } from 'lodash';

const getStaffDirectoryState = (state) => state.staffReducer;

const selectStaffDirectory = createSelector(getStaffDirectoryState, (staffDirectory) => {
  const sortedMember = {};
  for (const department in staffDirectory.structured) {
    const members = staffDirectory.structured[department];
    sortedMember[department] = members.sort((a, b) => a.id - b.id);
  }
  return sortedMember;
});
export const selectStaffDirectoryIsLoading = createSelector(
  getStaffDirectoryState,
  (staffDirectory) => staffDirectory.isLoading
);

export const selectStaffDirectoryErrors = createSelector(
  getStaffDirectoryState,
  (staffDirectory) => staffDirectory.errors
);

export const selectDepartmentsNames = createSelector(
  selectStaffDirectory,
  (directories) => ['all', ...Object.keys(directories)]
);

export const selectDepartmentStaff = (departmentName) =>
  createSelector(selectStaffDirectory, (directories) => {
    let results = {};

    if (departmentName === 'all') {
      return directories;
    } else if (has(directories, departmentName)) {
      results[departmentName] = get(directories, departmentName);
    }

    return results;
  });
