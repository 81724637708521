import React from 'react';
import { Link } from 'react-router-dom';
// import { PortalHeaderContext } from '@/context';

export default function PortalNavSubMenu({ content_blocks }) {
  // const { selectedMenuData } = React.useContext(PortalHeaderContext);

  // if (!selectedMenuData?.length) {
  //   return null;
  // }

  return (
    <div className="portal-nav-sub-menu">
      <div className="content">
        <div className="details">
          <ul>
            {!!content_blocks?.length &&
              content_blocks.map((data) => {
                return (
                  <li key={data.attributes.heading}>
                    {data.attributes.is_external ? (
                      <a href={data.attributes.page} target="_blank" rel="noreferrer">
                        {data.attributes.heading}
                      </a>
                    ) : (
                      <Link to={`${data.attributes.page}`}>
                        {data.attributes.heading}
                      </Link>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}
