import { STAFF } from '../constants'

const requestStaffDirectory = (payload) => ({
	type: STAFF.REQUEST_STAFF_LIST,
	payload
})

const requestStaffDirectorySuccess = (payload) => ({
	type: STAFF.REQUEST_STAFF_LIST_SUCCESS,
	payload
})

const requestStaffDirectoryFailed = (payload) => ({
	type: STAFF.REQUEST_STAFF_LIST_FAILED,
	payload
})

export { 
	requestStaffDirectory, 
	requestStaffDirectorySuccess, 
	requestStaffDirectoryFailed
}