import React from 'react';

/**
 * @typedef {Object} Option
 * @property {string} label - The label for the option.
 * @property {string} value - The value for the option.
 */

/**
 * @typedef {Object} Props
 * @property {Option[]} options - The array of options.
 * @property {function} onRate - The function to be called when a rating is selected.
 */

/**
 * RatingRadio component.
 * @param {Props} props - The props for the component.
 */
function RatingRadio(props) {
  const { options, onRate } = props;

  const handleRate = (/** @type {string} */ optionValue) => {
    console.log('aaaa', optionValue);
    onRate(optionValue);
  };

  return (
    <div className="flex flex-col w-full">
      {options.map((option, index) => (
        <label
          key={index}
          className="flex items-start justify-start w-full"
          onClick={() => handleRate(option.value)}
        >
          <input className="w-fit" type="radio" name="rating" value={option.value} />
          <div className="p-2 grow shrink basis-0 text-neutral-500 text-base font-['Gotham Narrow'] leading-normal">
            {option.label}
          </div>
        </label>
      ))}
    </div>
  );
}

export default RatingRadio;
