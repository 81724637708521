import React from 'react';
import { isEmpty } from 'lodash';
import useDimensions from 'react-use-dimensions';

export default function Slides({
                                   SlideItem,
                                   items,
                                   itemsPerView = 2,
                                   sliderPosition,
                                   setSlideWidth,
                                   itemsGap = 30,activeSlideIndex
                               }) {
    const [ref, slideSize] = useDimensions();
    React.useEffect(() => {
        if (items?.length) {
            setSlideWidth((slideSize.width + itemsGap) * itemsPerView);
        }
    }, [items, slideSize.width]);
    return (
        <div
            className="slide-container"
            style={{
                gridTemplateColumns: `repeat(${itemsPerView}, 1fr)`,
                transform: `translateX(${sliderPosition}px)`
            }}
        >
            {!isEmpty(items) &&
            !!SlideItem &&
            items.map((item, index) => {
                return (
                    <div
                        ref={index === 0 ? ref : null}
                        className={`slide ${
                            index === activeSlideIndex
                                ? ' active-slide'
                                : index < activeSlideIndex
                                    ? ' hide-slide'
                                    : ''
                        }`}
                        key={index}
                    >
                        <SlideItem {...item} />
                    </div>
                );
            })}
        </div>
    );
}
