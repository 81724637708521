import axios from 'axios';
import { BASE_URL, API, PER_PAGE } from '@/config';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { JOBS } from '../constants';
import {
  requestJobDetailsSuccess,
  requestJobDetailsFailed,
  submitJobListingSuccess,
  submitJobListingFailed,
  requestJobVacanciesFirstPageSuccess,
  requestJobVacanciesFirstPageFailed,
  requestJobVacanciesNextPageSuccess,
  requestJobVacanciesNextPageFailed
} from '../actions';
import { getUserToken, selectJobQueries } from '@/store/selectors';

export function* submitJobListing(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(submitJobListingSuccess(response));
  } catch (err) {
    yield put(submitJobListingFailed(err));
  }
}

export function* getJobVancanciesFirstPage(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestJobVacanciesFirstPageSuccess(response));
  } catch (err) {
    yield put(requestJobVacanciesFirstPageFailed(err));
  }
}

export function* getJobDetails(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}/${action.payload.id}`
    });

  try {
    const response = yield call(request);
    yield put(requestJobDetailsSuccess(response));
  } catch (err) {
    yield put(requestJobDetailsFailed(err));
  }
}

export function* getJobVancanciesNextPage(action) {
  const token = yield select(getUserToken);
  const queries = yield select(selectJobQueries);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...queries,
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestJobVacanciesNextPageSuccess(response));
  } catch (err) {
    yield put(requestJobVacanciesNextPageFailed(err));
  }
}

export function* watchGetJobDetails() {
  yield takeLatest(JOBS.REQUEST_JOB_DETAILS, getJobDetails);
}

export function* watchsubmitJobListing() {
  yield takeLatest(JOBS.SUBMIT_JOB_LISTING, submitJobListing);
}

export function* watchGetJobVacanciesFirstPage() {
  yield takeLatest(JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE, getJobVancanciesFirstPage);
}

export function* watchGetJobVacanciesNextPage() {
  yield takeLatest(JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE, getJobVancanciesNextPage);
}

const sagas = [
  fork(watchGetJobDetails),
  fork(watchsubmitJobListing),
  fork(watchGetJobVacanciesFirstPage),
  fork(watchGetJobVacanciesNextPage)
];

export default sagas;
