import React from 'react';
import QuickLinks from './QuickLinks';
import PortalBannerText from './PortalBannerText';

export default function PortalQuickLinksBanner({
  heading,
  subheading,
  text,
}) {
  return (
    <div className="portal-quick-links-banner">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-5">
          <PortalBannerText heading={heading} subheading={subheading} text={text} />
        </div>

        <div className="col-xs-12 col-sm-9 col-md-6 col-lg-5">
          <QuickLinks />
        </div>
      </div>
    </div>
  );
}
