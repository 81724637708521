import { CEO } from '../constants'

// CEO
const requestCEONewsArticleLatest = (payload) => ({
	type: CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST,
	payload
})
const requestCEONewsArticleLatestSuccess = (payload) => ({
	type: CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST_SUCCESS,
	payload
})
const requestCEONewsArticleLatestFailed = (payload) => ({
	type: CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST_FAILED,
	payload
})

const requestCEONewsArticle = (payload) => ({
	type: CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG,
	payload
})
const requestCEONewsArticleSuccess = (payload) => ({
	type: CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_SUCCESS,
	payload
})
const requestCEONewsArticleFailed = (payload) => ({
	type: CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_FAILED,
	payload
})

export {   
    requestCEONewsArticleLatest,
    requestCEONewsArticleLatestSuccess,
    requestCEONewsArticleLatestFailed,
		requestCEONewsArticle,
		requestCEONewsArticleSuccess,
		requestCEONewsArticleFailed
}