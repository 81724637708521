import { PortalLayout, PortalQuickLinksBanner } from '@/components/Portal';
import {
  CEOdesk,
  CurrentHeadlines,
  Events,
  LatestBulletins,
  SeekingFeedback
} from '@/components/Portal/Home';
import {
  requestFeedsByPage,
  requestNewsArticlesFirstPage,
  searchEvents
} from '@/store/actions';
import React from 'react';
import { useDispatch } from 'react-redux';

export default function PortalHome() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(requestNewsArticlesFirstPage());
    dispatch(searchEvents({ only_upcoming_event: true }));
    dispatch(requestFeedsByPage());
  }, []);

  return (
    <PortalLayout>
      <div className="portal-section container">
        <PortalQuickLinksBanner
          heading="COBA <br /> Member Portal"
          subheading="Welcome to the"
          text="Our primary channel for information sharing & communicating with our members"
        />
      </div>

      <div className="portal-section">
        <CEOdesk />
      </div>

      <div className="portal-section container carousels">
        <LatestBulletins />
        <SeekingFeedback />
        <Events />
        <CurrentHeadlines />
      </div>
    </PortalLayout>
  );
}
