import React from 'react';

export default function ContactFooterCircle() {
  return (
    <svg
      className="footer-circle"
      width="388"
      height="775"
      viewBox="0 0 388 775"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M388 0C173.967 0 0 173.98 0 388.027C0 601.02 173.967 775 388 775L388 592.585C275.185 592.585 183.457 500.85 183.457 388.027C183.457 275.204 275.185 183.469 388 183.469V0Z"
        fill="#22B7C4"
      />
    </svg>
  );
}
