import React from 'react';
import { useController } from 'react-hook-form';
import { iconTick } from '@/images/icons';

export default function Checkbox({
  control,
  name,
  children,
  id,
  defaultChecked,
  onChangeChecked,
  validation
}) {
  const {
    field: { ref, onChange, name: fieldName, value = false, ...inputProps }
  } = useController({
    name,
    control,
    rules: validation,
    defaultValue: defaultChecked
  });

  const onChangeCheckBox = (e) => {
    onChangeChecked && onChangeChecked(e);
    onChange(e);
  };

  return (
    <div className="checkbox-wrapper">
      <input
        type="checkbox"
        className="checkbox"
        id={id}
        ref={ref}
        name={fieldName}
        checked={value}
        value={value}
        onChange={onChangeCheckBox}
        {...inputProps}
      />
      <label className="checkbox-label" htmlFor={id}>
        {children}
      </label>
      <span className="icon">{iconTick}</span>
    </div>
  );
}
