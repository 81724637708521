import { PORTAL_PATHS } from '@/config';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateAccountEmailVerified from './CreateAccountEmailVerified';
import CreateAccountFormStep1 from './CreateAccountFormStep1';
import CreateAccountFormStep2 from './CreateAccountFormStep2';
import CreateAccountFormStep3 from './CreateAccountFormStep3';
import CreateAccountFormSubmitted from './CreateAccountFormSubmitted';

export default function CreateAccount() {
  return (
    <>
      <Switch location={location} key={location.pathname}>
        <Route exact path={PORTAL_PATHS.onboarding.createAccount.index}>
          <CreateAccountFormStep1 />
        </Route>

        <Route path={PORTAL_PATHS.onboarding.createAccount.step(1)}>
          <CreateAccountFormStep1 />
        </Route>

        <Route path={PORTAL_PATHS.onboarding.createAccount.step(2)}>
          <CreateAccountFormStep2 />
        </Route>

        <Route path={PORTAL_PATHS.onboarding.createAccount.step(3)}>
          <CreateAccountFormStep3 />
        </Route>

        <Route path={PORTAL_PATHS.onboarding.createAccount.accountCreated}>
          <CreateAccountFormSubmitted />
        </Route>

        <Route path={PORTAL_PATHS.onboarding.createAccount.emailVerified}>
          <CreateAccountEmailVerified />
        </Route>

      </Switch>
    </>
  );
}
