import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ArticleFooterHeading from './ArticleFooterHeading';
import ArticleFooterArticles from './ArticleFooterArticles';
import { articleCategoriesMap } from '@/pages/Articles/constants';
import { bindActionCreators } from 'redux';
import { requestFooterArticles } from '@/store/actions';
import {
  getIsLatestArticles,
  getIsRelatedArticles,
  getArticleSortedFooterArticles
} from '@/store/selectors/articles';

const ArticleFooter = () => {
  const { category } = useParams();
  const slug = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const actions = bindActionCreators({ requestFooterArticles }, useDispatch());
  const isLatestArticles = useSelector(getIsLatestArticles);
  const isRelatedArticles = useSelector(getIsRelatedArticles);
  const footerArticles = useSelector(getArticleSortedFooterArticles);

  React.useEffect(() => {
    if (!!isLatestArticles && !isRelatedArticles) {
      actions.requestFooterArticles({
        categoryId: articleCategoriesMap[category].id
      });
    }

    if (!isLatestArticles && !!isRelatedArticles) {
      actions.requestFooterArticles({
        categoryId: articleCategoriesMap[category].id
      });
    }
  }, [isRelatedArticles, isLatestArticles]);

  if (!footerArticles?.length) {
    return null;
  }

  return (
    <section className="article-footer">
      <div className="latest-articles">
        <div className="blue-background" />
        <div className="content-wrapper">
          <div className="blue-background" />
          {category !== 'how-it-works' &&
            category !== 'about-coba' &&
            category !== 'policy-submissions' &&
            category !== 'campaign-outcomes' &&
            slug !== 'media-tool-kit' && (
              <>
                <ArticleFooterHeading />
                <ArticleFooterArticles />
              </>
            )}
        </div>
      </div>
    </section>
  );
};

export default ArticleFooter;
