import PropTypes from 'prop-types';
import React from 'react';
import { iconArrowThin } from '@/images/icons';
import { isEmpty, first } from 'lodash';
import mini_logo_grey from '@/images/mini_logo_grey.png';
import DivisionListCardContact from './DivisionListCardContact';

const DivisionCard = ({
  name,
  logo_data,
  url,
  blurb,
  offers,
  phone,
  address,
  address2,
  city,
  state,
  postcode,
  distance,
  latitude,
  longitude
}) => {
  const EXTRACT_URL_WITHOUT_PROTOCOL_REGEX =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/;
  const urlLinkWithoutProtocol = first(!!url ? url.match(EXTRACT_URL_WITHOUT_PROTOCOL_REGEX) : []);
  const urlLink = !!urlLinkWithoutProtocol ? `https://${urlLinkWithoutProtocol}` : url;
  const hasContactDetails = !!state && !!postcode && !!city;

  return (
    <div className="division">
      <div className="header">
        {!!logo_data?.url && <img src={logo_data.url} alt={name} />}
      </div>

      <div className="description">
        <h5>{name}</h5>

        <div className="blurb" dangerouslySetInnerHTML={{ __html: blurb }} />

        <a href={urlLink} rel="noopener noreferrer" target="_blank">
          Visit website <span className="icon">{iconArrowThin}</span>
        </a>
      </div>

      <div className="footer">
        {!isEmpty(offers) && (
          <div className="offers">
            <div className="footer-heading">
              <img src={mini_logo_grey} alt="Coba mini logo" />
              <h5>What we offer</h5>
            </div>

            <ul>
              {offers.map((offer, index) => {
                return (
                  <li key={index}>
                    <span>{offer.name}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {hasContactDetails && (
          <DivisionListCardContact
            phone={phone}
            address={address}
            address2={address2}
            city={city}
            distance={distance}
            latitude={latitude}
            longitude={longitude}
          />
        )}
      </div>
    </div>
  );
};

DivisionCard.propTypes = {
  division: PropTypes.object
};

export default DivisionCard;
