import React from 'react';

const MediaReleaseHeroSection = ({ categoryName }) => {
  return (
    <section className="hero-section">
      <div className="content-wrapper">
        <div className="breadcrumbs">
          <p>Home / {categoryName}</p>
        </div>
        <h1>{categoryName}</h1>
      </div>
    </section>
  );
};

export default MediaReleaseHeroSection;
