import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { getUser, getUserDivisionOptions } from '@/store/selectors';
import { requestUserDivisions } from '@/store/actions';
import { Dropdown, Button } from '@/components';
import { iconPlus, iconArrowThinLeft } from '@/images/icons';
import UpdateExistingDivision from './UpdateExistingDivision';
import { isEmpty } from 'lodash';
import AddNewDivision from './AddNewDivision';

export default function UpdateDivision() {
  const actions = bindActionCreators({ requestUserDivisions }, useDispatch());

  const user = useSelector(getUser);
  const userDivisionOptions = useSelector(getUserDivisionOptions);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      divisionId: ''
    }
  });

  const watchDivisionId = form.watch('divisionId');

  const [showAddNewDivision, setShowAddNewDivision] = React.useState(false);

  const onClickGoBackButton = () => {
    form.reset();
    setShowAddNewDivision(false);
  };

  const onClickAddNewDivision = () => {
    setShowAddNewDivision(true);
  };

  const onClickDivisionDropdownOption = (ev) => {
    const selectedOfferId = ev.target.dataset.id;
    const targetName = ev.target.textContent;
    const dropdownOfferId = ev.target.dataset.dropdownId;

    form.setValue(
      dropdownOfferId,
      { id: selectedOfferId, value: targetName },
      { shouldDirty: true, shouldValidate: true }
    );
  };

  React.useEffect(() => {
    actions.requestUserDivisions({ institution_id: user.institution_id });
  }, []);

  return (
    <div className="form my-account update-division-details">
      <div className="row start-md">
        <div className="form-header col-xs-12 col-md-10">
          {(!isEmpty(watchDivisionId) || showAddNewDivision) && (
            <>
              <Button
                variant={'btn btn-text btn-go-back'}
                iconLeft={iconArrowThinLeft}
                onClick={onClickGoBackButton}
              >
                Go Back
              </Button>

              <br />
              <br />
            </>
          )}

          <div className="form-title H2">Update division details</div>
          <div className="form-description">
            Please use the form below to tell us about any changes to your existing member
            brand divisions or to create new brand divisions. Update existing divisions by
            selecting them from the dropdown, or add a new division below. COBA will
            review these changes before approving, or contact you for more information.
          </div>
        </div>

        <div className="col-xs-12 col-md-10">
          <FormProvider {...form}>
            <form noValidate>
              {!!userDivisionOptions?.length && !showAddNewDivision && (
                <fieldset className="fieldset">
                  <legend className="legend H3">Update existing division</legend>

                  <Dropdown
                    id="division-id"
                    name="divisionId"
                    control={form.control}
                    options={userDivisionOptions}
                    placeholder="Select a division"
                    labelFor="divisionId"
                    labelText="Select Existing Division"
                    onClickDropdownOption={onClickDivisionDropdownOption}
                  />

                  {!userDivisionOptions?.length && <p>No divisions found.</p>}
                </fieldset>
              )}
            </form>
          </FormProvider>

          {!isEmpty(watchDivisionId) && (
            <UpdateExistingDivision selectedDivisionOption={watchDivisionId} />
          )}

          {isEmpty(watchDivisionId) && !showAddNewDivision && (
            <Button
              variant={'btn btn-fill'}
              icon={iconPlus}
              onClick={onClickAddNewDivision}
            >
              Add New Division
            </Button>
          )}

          {showAddNewDivision && (
            <AddNewDivision selectedDivisionOption={watchDivisionId} />
          )}
        </div>
      </div>
    </div>
  );
}
