import React from 'react';
import { ContactCard } from '@/components/Portal/Card';

export default function Submited() {
  return (
    <div className="content-layout">
      <div className='content-section'>
        <h2>Your submission is awaiting approval</h2>
        <p className="description">
          You will receive a confirmation email when your submission has been approved and is live.
        </p>
      </div>

      <div className='content-section'>
        <p className="bold">Queries relating to this service should be directed to Elanor Ashton</p>
        <ContactCard
          name='Elanor Ashton'
          title='Administration Assistant'
          email='eashton@coba.asn.au'
          phone='+61 02 8035 8451'
        />
      </div>        
    </div>
  );
}
