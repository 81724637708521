import { getUser } from '@/selectors';
import { trackAnalyticEvent } from '@/store/actions/analyticEventsActions';
import { useCallback } from 'react';
import usePrevLocation from './usePrevLocation';

import { getUserDeviceName } from '@/selectors/user';
import isEmpty from 'lodash/isEmpty';


const { useDispatch, useSelector } = require('react-redux');

/**
 * 
 * @param {string} pathname 
 */
const getPageUrlByPathname = (pathname) => {
  if (!pathname) return undefined;
  return `${window.location.origin}/${pathname != '/' ? pathname : ''}`;
}

const useAnalyticEventsTracking = () => {
  const { previousLocation } = usePrevLocation();

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userDeviceName = useSelector(getUserDeviceName);

  const trackEvent = useCallback(
    /**
     * @param {Object} params
     * @param {'page_view' | 'download' | 'search'} params.type
     * @param {Object | undefined} params.properties
     */
    (params) => {
      if (isEmpty(user)) return;

      const { type: eventType, properties: eventProperties } = params;
      const url = window.location.href.replace(/\/+$/, '');
      const referrer = document.referrer || getPageUrlByPathname(previousLocation?.pathname);

      dispatch(trackAnalyticEvent({ url, referrer: referrer || null, deviceName: userDeviceName, eventType, eventProperties }))
    }, [previousLocation, user, userDeviceName])

  return trackEvent
}

export default useAnalyticEventsTracking;