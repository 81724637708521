import React from 'react';
import { useController } from 'react-hook-form';
import FormField from './FormField';

export default function TextField({
  control,
  defaultValue,
  id,
  labelFor,
  labelText,
  name,
  placeholder,
  onClick,
  onChangeField,
  onFocus,
  onKeyUp,
  onBlurField,
  isDisabled,
  type = 'text',
  validation = {}
}) {
  const {
    field: { ref, onChange, onBlur, name: fieldName, value = '' },
    formState: { errors = {} }
  } = useController({
    name,
    control,
    rules: validation,
    defaultValue: defaultValue
  });

  const onChangeTextField = (e) => {
    onChangeField && onChangeField(e);
    onChange(e);
  };

  const onBlurTextField = (e) => {
    onBlurField && onBlurField(e);
    onBlur(e);
  };

  return (
    <FormField labelFor={labelFor} labelText={labelText} name={name} errors={errors}>
      <input
        id={id}
        ref={ref}
        className="text-input"
        type={type}
        onClick={onClick}
        onChange={onChangeTextField}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onBlur={onBlurTextField}
        placeholder={placeholder}
        name={fieldName}
        value={value}
        disabled={isDisabled}
      />
    </FormField>
  );
}
