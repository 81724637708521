import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// Store
import { useDispatch, useSelector } from 'react-redux';
import { updateAccountPassword } from '@/store/actions';
import { getUser } from '@/store/selectors';
// Components
import { Button, Recaptcha, PasswordField } from '@/components';
import { iconArrowThin, iconTick } from '@/images/icons';
import { validationRegex } from '@/utils/forms';

export default function LoginDetails() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(true);
  const [minLettersHintOK, setMinLettersHintOK] = React.useState(false);
  const [capitalLetterHintOK, setCapitalLetterHintOK] = React.useState(false);
  const [specialCharHintOK, setSpecialCharHintOK] = React.useState(false);
  const [isUserBeingUpdated, setIsUserBeingUpdated] = useState(false);
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [captchaToken, setCaptchaToken] = React.useState(undefined);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'all'
  });

  const { isDirty, isValid } = form.formState;

  const watchNewPassword = form.watch('newPassword');
  const watchConfirmPassword = form.watch('confirmPassword');

  const minLettersHintClasses = minLettersHintOK
    ? 'password-hint min-length valid'
    : 'password-hint min-length';

  const capitalLetterHintClasses = capitalLetterHintOK
    ? 'password-hint capital-letter valid'
    : 'password-hint capital-letter';

  const specialCharHintClasses = specialCharHintOK
    ? 'password-hint special-char valid'
    : 'password-hint special-char';

  const onSubmitForm = (data) => {
    setIsUserBeingUpdated(true);

    dispatch(
      updateAccountPassword({
        current_password: data.currentPassword,
        password: data.newPassword,
        password_confirmation: data.confirmPassword
      })
    );
  };

  useEffect(() => {
    if (isUserBeingUpdated) {
      setIsUserUpdated(!isUserUpdated);
      setIsUserBeingUpdated(!isUserBeingUpdated);
    }
  }, [user]);

  const onVerifyCaptcha = (token) => {
    form.setValue('recaptcha', token);
    setCaptchaToken(token);
  };

  const onChangeNewPassword = () => {
    const newPasswordError = form?.errors?.newPassword;

    if (!!newPasswordError) {
      form.trigger('newPassword');
    }
  };

  const onChangeConfirmPassword = () => {
    const confirmPasswordError = form?.errors?.confirmPassword;
    const newPasswordError = form?.errors?.newPassword;
    const newPasswordMatchError =
      newPasswordError?.message === 'Password fields do not match.';

    if (!!confirmPasswordError) {
      form.trigger('newPassword');
      form.trigger('confirmPassword');
    }

    if (
      newPasswordMatchError &&
      !confirmPasswordError &&
      watchNewPassword === watchConfirmPassword
    ) {
      form.clearErrors('newPassword');
      form.clearErrors('confirmPassword');
    }
  };

  const passwordValidation = (value) => {
    const hasUppercase = !!value && value !== value.toLowerCase();
    const hasSpecialChar = validationRegex.atLeastOneSpecialChar.test(value);

    if (!value) {
      return 'This field is required.';
    }

    if (value.length < 6) {
      setMinLettersHintOK(false);
      return 'This field must have at least 6 characters.';
    }

    if (value.length >= 6) {
      setMinLettersHintOK(true);
    }

    if (!hasUppercase) {
      return 'This field must have at least 1 capital letter.';
    }

    if (hasUppercase) {
      setCapitalLetterHintOK(true);
    }

    if (!hasSpecialChar) {
      return 'This field must have at least 1 special character.';
    }

    if (hasSpecialChar) {
      setSpecialCharHintOK(true);
    }

    if (
      !!watchNewPassword &&
      !!watchConfirmPassword &&
      watchNewPassword !== watchConfirmPassword
    ) {
      return 'Password fields do not match.';
    }

    return true;
  };

  React.useEffect(() => {
    form.register('recaptcha', {
      required: {
        value: true,
        message: 'Please verify that you are not a robot.'
      }
    });
  }, []);

  React.useEffect(() => {
    if (isDirty) {
      setIsSubmitDisabled(isValid);
    }
  }, [isDirty]);

  React.useEffect(() => {
    if (!!captchaToken) {
      form.trigger('recaptcha');
    }
  }, [captchaToken]);

  return (
    <div className="form my-account password">
      <div className="row start-md">
        <div className="col-xs-12 col-md-10">
          <h2 className="form-title H2">Update password</h2>
        </div>

        <div className="col-xs-12 col-md-6">
          <FormProvider {...form}>
            <form noValidate>
              <PasswordField
                labelFor="current-password"
                labelText="Current Password"
                placeholder="Password"
                name="currentPassword"
                validation={{
                  required: 'This field is required.'
                }}
              />

              <PasswordField
                labelFor="new-password"
                labelText="New Password"
                placeholder="New Password"
                name="newPassword"
                onChangeField={onChangeNewPassword}
                validation={{
                  validate: passwordValidation
                }}
              />

              <PasswordField
                labelFor="verify-password"
                labelText="Verify Password"
                placeholder="Verify Password"
                name="confirmPassword"
                onChangeField={onChangeConfirmPassword}
                validation={{
                  validate: passwordValidation
                }}
              />

              <div className="password-hints">
                <p>Your password needs to include</p>

                <div className={minLettersHintClasses}>
                  <span className="icon icon-tick">{iconTick}</span>
                  <p>At least 6 letters</p>
                </div>

                <div className={capitalLetterHintClasses}>
                  <span className="icon icon-tick">{iconTick}</span>
                  <p>At least 1 capital letter</p>
                </div>

                <div className={specialCharHintClasses}>
                  <span className="icon icon-tick">{iconTick}</span>
                  <p>At least 1 special character</p>
                </div>
              </div>

              <br />

              <Recaptcha
                onVerifyCaptcha={onVerifyCaptcha}
                errorMsg={form?.errors?.captchaToken?.message}
              />

              <br />

              <Button
                variant={'btn-fill'}
                icon={iconArrowThin}
                isDisabled={isSubmitDisabled}
                type="submit"
                onClick={form.handleSubmit(onSubmitForm)}
              >
                Save Changes
              </Button>

              <br />
              <br />

              <div className="form-hint">
                <strong>Note: </strong>Changes will take effect next time you login
              </div>

              <div className={`form-success ${isUserUpdated ? 'display' : 'hidden'}`}>
                <span className="icon">{iconTick}</span>
                Your updates have been saved
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
