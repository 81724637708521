import PropTypes from 'prop-types';
import React from 'react'
import useMedia from 'use-media';
import PortalPageMenuModal from './PortalPageMenuModal';

PortalPageMenuFilter.propTypes = {
  mobileToggleName: PropTypes.string,
	mobile: PropTypes.bool,
	desktop: PropTypes.bool,
	children: PropTypes.node
}

export default function PortalPageMenuFilter({
  mobileToggleName, 
  mobile=false,
  desktop=false, 
  children
}) {
	const isMobile = useMedia('(max-width: 1023px)');

	// Render for Mobile or Desktop
	if ( (!isMobile && mobile) || (isMobile && desktop)) {
		return null;
	} 

  if (isMobile) {
    return (
      <PortalPageMenuModal
        toggleLabel={mobileToggleName}
        toggleVariant="filter"
      > 
        <div className="portal-page-menu filter col-xs-12 col-md-4">
          {children}
        </div>
      </PortalPageMenuModal>
    );
  } else {

    return (
      <div className="portal-page-menu filter col-xs-12 col-md-4">
        <aside className="portal-page-menu__content">
          {children}
        </aside>
      </div>
    );
  }

}