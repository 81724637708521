import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { iconArrowThin } from '@/images/icons';

function ErrorPage() {
  return (
    <Layout>
      <SEO title="404 Error | Customer Owned Banking Association | Australia" />
      <div id="error-page">
        <div className="content-wrapper">
          <h1>Page not found</h1>
          <h4>404 error</h4>
          <p>Unfortunately, the page you are looking for could not be found.</p>
          <Link to="/" className="btn btn-primary">
            Return to home <span className="icon">{iconArrowThin}</span>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default ErrorPage;
