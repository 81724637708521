import React from 'react';
import FormField from './FormField';
import { useController } from 'react-hook-form';

export default function Dropdown({
  id,
  options,
  placeholder = 'Select ...',
  onClickDropdownOption,
  labelFor,
  labelText,
  name,
  control,
  validation,
  defaultValue = ''
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const onClickDropdown = () => {
    setIsOpen(!isOpen);
  };

  const {
    field: { ref, onChange, ...inputProps },
    formState: { errors = {} }
  } = useController({
    name,
    control,
    defaultValue,
    rules: { ...validation }
  });

  const selectedOption = inputProps?.value;

  return (
    <FormField labelFor={labelFor} labelText={labelText} name={name} errors={errors}>
      <div className={isOpen ? 'dropdown active' : 'dropdown'} onClick={onClickDropdown}>
        <div
          className={
            !selectedOption?.value ? 'dropdown-control' : 'dropdown-control selected'
          }
        >
          {!selectedOption?.value ? placeholder : selectedOption?.value}
        </div>

        <div className="dropdown-options">
          {!!options?.length &&
            options.map((item) => (
              <div
                onClick={onClickDropdownOption}
                className="dropdown-option"
                key={`option-${item.id}`}
                data-id={item.id}
                data-dropdown-id={name}
              >
                {item.name}
              </div>
            ))}
        </div>

        <input
          onChange={onChange}
          id={id}
          ref={ref}
          type="text"
          name={name}
          hidden
          {...inputProps}
        />
      </div>
    </FormField>
  );
}
