import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useMedia from 'use-media';
// State
import { requestEvent } from '@/store/actions';
import { selectEventById, selectEvents } from '@/store/selectors';
// Components
import { PortalLayout, PortalPageTitle, Loading } from '@/components/Portal';
import {
  ActionCard,
  EventCard,
  EventScheduleCard,
  WhoShouldAttendCard
} from '@/components/Portal/Card';
import { EventDetailsMenu } from '@/components/Portal/Events';

/**
 * Converts JSON Stings into objects
 *
 * @param {object} event
 * @returns
 */
const transform = (event) => {
  return !event
    ? event
    : {
        ...event,
        key_contacts: JSON.parse(event?.key_contacts),
        schedule: JSON.parse(event?.schedule),
        survey: JSON.parse(event?.survey),
        who_should_attend: JSON.parse(event?.who_should_attend)
      };
};

const Heading = ({ heading }) => {
  return !heading ? null : <h3>{heading}</h3>;
};

const Description = ({ description }) => {
  return !description ? null : (
    <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
  );
};

const SectionHeader = ({ event }) => {
  return (
    <div className="content-section">
      <h2>{event.heading}</h2>
      <Description description={event?.description} />
    </div>
  );
};

const SectionSecondary = ({ event }) => {
  return !event.second_heading && !event?.second_description ? null : (
    <div className="content-section">
      <Heading heading={event?.second_heading} />
      <Description description={event?.second_description} />
    </div>
  );
};

const SectionWhoShouldAttend = ({ event }) => {
  return !event.who_should_attend ? null : (
    <div className="content-section">
      <WhoShouldAttendCard
        who={event.who_should_attend.map((who) => who.attributes?.title)}
      />
    </div>
  );
};

const SectionSchedule = ({ event }) => {
  return !event.schedule
    ? null
    : event.schedule.map(({ key, attributes }) => {
        return (
          <div key={key} className="content-section">
            <Heading heading={attributes.schedule_section_title} />
            {attributes.schedule_item.map(({ key, attributes }) => (
              <EventScheduleCard
                key={key}
                title={attributes?.schedule_title}
                company_name={attributes?.schedule_company_name}
                name={attributes?.schedule_name}
                startDateTime={attributes?.schedule_start_date}
                endDateTime={attributes?.schedule_end_date}
                location={attributes?.location}
              />
            ))}
          </div>
        );
      });
};

const SectionAgenda = ({ event }) => {
  return !event.agenda_description && isEmpty(event.agenda_data) ? null : (
    <div className="content-section">
      <h3>Agenda</h3>
      {event.agenda_description && (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: event.agenda_description }}
        />
      )}
      {event.agenda_data.map(({ agenda_title, agenda_file }, index) => {
        return (
          <ActionCard
            key={index}
            action="download"
            title={agenda_title}
            link={agenda_file?.url}
            value={agenda_file?.size}
          />
        );
      })}
    </div>
  );
};

const SectionSurvey = ({ event }) => {
  return !event.survey_description && isEmpty(event.survey) ? null : (
    <div className="content-section">
      <h3>Survey</h3>
      {event.survey_description && (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: event.survey_description }}
        />
      )}
      {event.survey.map(({ key, attributes }) => {
        return (
          <ActionCard
            key={key}
            action="survey"
            title={attributes?.agenda_title}
            link={attributes?.survey_url}
          />
        );
      })}
    </div>
  );
};

const SectionPresentations = ({ event }) => {
  return !event.presentations_description && isEmpty(event.presentations_data) ? null : (
    <div className="content-section">
      <h3>Presentations</h3>
      {event.presentations_description && (
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: event.presentations_description }}
        />
      )}
      {event.presentations_data.map(
        ({ presentation_title, presenter, presentation_file }, index) => {
          return (
            <ActionCard
              key={index}
              action="download"
              title={presentation_title}
              subTitle={presenter}
              link={presentation_file?.url}
              value={presentation_file?.size}
            />
          );
        }
      )}
    </div>
  );
};

const DisplayEventCard = ({ event, size }) => {
  return (
    <EventCard
      key={event?.id}
      id={event?.id}
      size={size}
      heading={event?.heading}
      start_date={event?.start_date}
      end_date={event?.end_date}
      location={event?.location}
      register={event?.register_url}
    />
  );
};

const EventSection = ({ event }) => {
  const isMobile = useMedia('(max-width: 1023px)');

  if (event.status === 'past') {
    return (
      <>
        {isMobile && <DisplayEventCard event={event} />}
        <SectionHeader event={event} />
        <SectionSecondary event={event} />
        <SectionAgenda event={event} />
        <SectionSurvey event={event} />
        <SectionPresentations event={event} />
      </>
    );
  }

  // 'inProgress' or 'upcomming'
  return (
    <>
      {isMobile && <DisplayEventCard event={event} />}
      <SectionHeader event={event} />
      <SectionWhoShouldAttend event={event} />
      <SectionSecondary event={event} />
      <SectionSchedule event={event} />
      <DisplayEventCard event={event} />
    </>
  );
};

export default function EventDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const eventID = params.id;
  const { loading } = useSelector(selectEvents);
  const event = transform(useSelector(selectEventById(eventID)));

  const [pageLoading, setPageLoading] = useState(true);
  const [pageHeading, setPageHeading] = useState('');

  React.useEffect(() => {
    if (!isEmpty(event)) {
      setPageHeading(event.heading);
    }
  }, [event]);

  React.useEffect(() => {
    setPageLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!event && !isEmpty(eventID)) {
      dispatch(requestEvent({ id: eventID }));
    }
  }, [eventID]);

  return (
    <PortalLayout>
      <PortalPageTitle heading={pageHeading} secondary />
      <EventDetailsMenu event={event} mobile />

      <div className="container">
        <div className="row">
          <EventDetailsMenu event={event} desktop />

          <div className="col-xs-12 col-sm-8">
            <div className="content-layout">
              {pageLoading == true && <Loading />}
              {!!event && <EventSection event={event} />}
            </div>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
