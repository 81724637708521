import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useMedia from 'use-media';
import { requestJobDetails } from '@/store/actions';
import { selectVacancy } from '@/store/selectors';
import { PortalLayout, PortalPageTitle, ContentSection } from '@/components/Portal';
import { VacancyDetailsMenu, DisplayVacancyCard } from '@/components/Portal/Vacancies';


const Logo = ({vacancy}) => {
	return <ContentSection>
		<div className='institution-logo'>
			<img src={vacancy?.institution?.logo_data?.url} alt={vacancy?.institution?.name} />
		</div>
	</ContentSection>
}

const Card = ({vacancy}) => {
	return <ContentSection>
		<DisplayVacancyCard vacancy={vacancy}/>
	</ContentSection>
}

const AboutTheRole = ({vacancy}) => {
	return !vacancy?.description
	? null
	: <ContentSection 
		heading='About The Role'
		description={vacancy?.description}
	/>
}

const Responsibilities = ({vacancy}) => {
	return !vacancy?.roles_and_responsibilities
	? null
	: <ContentSection 
		heading='Roles and Responsibilities'
		description={vacancy.roles_and_responsibilities}
	/>
}

const AboutYou = ({vacancy}) => {
	return !vacancy?.about_you
	? null
	: <ContentSection 
		heading='About You'
		description={vacancy.about_you}
	/>
}

const AboutUs = ({vacancy}) => {
	return !vacancy?.about_us
	? null
	: <ContentSection 
		heading='About Us'
		description={vacancy.about_us}
	/>
}

const Apply = ({vacancy}) => {
	return <ContentSection 
		heading='How to apply'
	>
		<DisplayVacancyCard vacancy={vacancy}/>
	</ContentSection>
}


export default function VacancyDetails() {
  const params = useParams();
	const isMobile = useMedia('(max-width: 1023px)');
	const dispatch = useDispatch();
  const vacancyID = params.id

	const vacancy = useSelector(selectVacancy(params.id));

  React.useEffect(() => {
    !vacancy && !!vacancyID && dispatch(requestJobDetails({ id: vacancyID }));
  }, [vacancyID]);

  return (
    <PortalLayout>
      <PortalPageTitle heading={vacancy?.title || ''} secondary />
      <VacancyDetailsMenu vacancy={vacancy} mobile/>

      <div className="container">
        <div className="row">
          <VacancyDetailsMenu vacancy={vacancy} desktop/>

          <div className="col-xs-12 col-sm-8">
            <div className="article-wrapper">
							{isMobile && <Logo vacancy={vacancy} /> }
							{isMobile && <Card vacancy={vacancy} /> }
							<AboutTheRole vacancy={vacancy} />
							<Responsibilities vacancy={vacancy} />
							<AboutYou vacancy={vacancy} />
							<AboutUs vacancy={vacancy} />
							<Apply vacancy={vacancy} />
            </div>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
