import React from 'react';
import MiniLogo from '../MiniLogo';
import {
  iconDocumentBlank,
  iconMail,
  iconPhone,
  iconLaptop,
} from '@/images/icons';
import ContactItemDetail from './ContactItemDetail';

const ContactComplaints = () => {
  return (
    <section className="contact-complaints">
      <div className="complaint-bg" />
      <div className="content-wrapper">
        <h2>
          <MiniLogo />
          Got a <u>complaint</u>?
        </h2>
        <div className="complaint-content">
          <p>
            Customers are the most important people in the credit union, building society
            and mutual bank sector and their needs and rights are our highest priority.
          </p>
          <p>
            Credit unions, building societies and mutual banks are Authorised
            Deposit-taking Institutions (ADIs) regulated under the Banking Act 1959 by the
            Australian Prudential Regulation Authority (APRA), and the Corporations Act
            2001, by the Australian Securities and Investments Commission (ASIC).
          </p>
          <p>
            Credit union, building society and mutual bank customers can carry out their
            financial transactions safe in the knowledge that all mutual ADIs are subject
            to the same rules, regulations and legal standards as the big banks.
          </p>
          <q>
            If something does go wrong, and you have a complaint about your credit union,
            mutual bank or building society, you should first raise that complaint with
            your credit union, building society or mutual bank.
          </q>
          <p>
            If you are unhappy with their response to your complaint, you can then raise
            your complaint with the Australian Financial Complaints Authority (AFCA). AFCA
            provides free, fast and binding dispute resolution to consumers and small
            businesses. COBA does not handle indivdual customer complaints or disputes.
          </p>

          <div className="contact-sub-section">
            <div className="contact-item">
              <h4>Australian Financial Complaints Authority (AFCA)</h4>

              <h5>Contact</h5>
              <ContactItemDetail 
                icon={iconLaptop}
                href="https://afca.org.au/"
              >
                afca.org.au
              </ContactItemDetail>

              <ContactItemDetail 
                icon={iconPhone}
                href="tel:1800-931-678"
              >
                1800 931 678
              </ContactItemDetail>
            </div>
          </div>

          <div className="contact-sub-section">
            <div className="contact-item">
              <h4>Customer Owned Banking Code of Practice</h4>

              <p>
                You can also report a breach of the Customer Owned Banking Code of Practice
                to the Code Compliance Committee
              </p>
                      
              <h5>Contact</h5>
              <ContactItemDetail 
                icon={iconPhone}
                href="tel:1800-931-678"
              >
                1800 931 678
                (this is a telephone service provided by AFCA)
              </ContactItemDetail>

              <ContactItemDetail icon={iconDocumentBlank}>
                PO Box 14240 Melbourne VIC 8001
              </ContactItemDetail>

              <ContactItemDetail 
                icon={iconMail}
                href="mailto:info@codecompliance.org.au"
              >info@codecompliance.org.au</ContactItemDetail>

              <ContactItemDetail 
                icon={iconLaptop}
                href="https://www.cobccc.org.au/for-consumers/resolving-complaints/"
              >https://www.cobccc.org.au/for-consumers/resolving-complaints/</ContactItemDetail>

            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default ContactComplaints;
