// import { Button } from '@/components';
import { RatingModal } from '@/components/Portal/RatingModal';
import { rateExperience } from '@/store/actions/rateActions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsrateLoading,
  getrateSubmissionFailed,
  getrateSubmissionSuccess
} from '@/store/selectors/rating';
import {} from '@/selectors';
import { selectRateInfoActive, selectRateInfoContent } from '@/selectors/rateInfo';
import { requestRateInfo } from '@/store/actions/rateInfoActions';

function RatingModalOpener() {
  const dispatch = useDispatch();

  const isLoadingRateSubmission = useSelector(getIsrateLoading);
  const isSuccessfulRateSubmission = useSelector(getrateSubmissionFailed);
  const isFailedRateSubmission = useSelector(getrateSubmissionSuccess);

  console.log({
    isLoadingRateSubmission,
    isSuccessfulRateSubmission,
    isFailedRateSubmission
  });

  const [modalIsOpen, setModalIsOpen] = useState(true);
  const isActive = useSelector(selectRateInfoActive);
  const rateInfoContent = useSelector(selectRateInfoContent);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  //   const openModal = () => {
  //     setModalIsOpen(true);
  //   };

  useEffect(() => {
    dispatch(requestRateInfo());
  }, []);

  console.log(isActive);

  return (
    <>
      {/* <Button
        variant={'btn-fill btn-full-width'}
        onClick={openModal}
        type="button"
        icon={undefined}
        iconLeft={undefined}
        isDisabled={undefined}
      >
        open modal
      </Button> */}
      {isActive && (
        <RatingModal
          options={rateInfoContent.options}
          variant={rateInfoContent.type}
          isOpen={modalIsOpen}
          onClose={() => closeModal()}
          title="How are we doing?"
          description="Leave us feedback on your satisfaction with the COBA portal. "
          onRate={(/** @type {string} */ type, /** @type {string} */ rate) => {
            dispatch(rateExperience({ type, rate }));
            closeModal();
          }}
        />
      )}
    </>
  );
}

export default RatingModalOpener;
