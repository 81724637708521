import { SUBSCRIPTION } from '../constants';

const initialState = {
  isLoading: false,
  subscriptionData: {},
  successful: false,
  failed: false
};

const subscriptionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SUBSCRIPTION.REQUEST_SUBSCRIPTION:
      return { ...state, isLoading: true, currentPage: action.payload };

    case SUBSCRIPTION.SUCCESSFUL_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        subscriptionData: action.payload,
        successful: true
      };

    case SUBSCRIPTION.FAILED_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        subscriptionData: action.payload,
        failed: true
      };
  }

  return state;
};

export default subscriptionReducer;
