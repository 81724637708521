import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useMedia from 'use-media';
import { isEmpty } from 'lodash';
import { iconArrowThin, iconArrowThinLeft, iconBarHorizontal } from '@/images/icons';
import ArticleCard from '@/components/ArticleCard';
import half_logo from '@/images/half_logo.png';
import MiniLogo from '@/components/MiniLogo';
import { getCustomerStories } from '@/store/selectors/customerStories';
import { requestCustomerStories } from '@/store/actions';

const CustomerStories = () => {
  const dispatch = useDispatch();
  const customerStories = useSelector(getCustomerStories);
  const isMobile = useMedia('(max-width: 767px)');

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const SLIDE_GAP = 30;
  const slideWidth = SLIDE_GAP + (isMobile ? 381 : 587);
  const sliderPosition = -activeSlideIndex * slideWidth;
  const [sliders, setSliders] = useState([]);
  
  const showNextCard = () => {
    let index = activeSlideIndex;
    if (activeSlideIndex + 1 < customerStories.length) {
      setTimeout(() => {
        setActiveSlideIndex(index + 1);
      }, 200);
    } else {
      index = 0;
      setTimeout(() => {
        setActiveSlideIndex(0);
      }, 200);
    }

    setSliders([...sliders, sliders[index]]);
  };
  const showPrevCard = () => {
    if (activeSlideIndex <= 0) {
      setSliders([sliders[customerStories.length - 1], ...sliders]);
      setActiveSlideIndex(customerStories.length - 1);
    } else {
      setTimeout(() => {
        setActiveSlideIndex(activeSlideIndex - 1);
      }, 200);
    }
  };
  const showSpecificCard = (index) => {
    setActiveSlideIndex(index);
  };
  useEffect(() => {
    dispatch(requestCustomerStories());
  }, []);

  useEffect(() => {
    setSliders([...customerStories]);
  }, [customerStories]);

  return (
    <section id="customer-stories" data-testid="customer-stories">
      <div className="background-half-logo">
        <img src={half_logo} alt="Coba half logo" />
      </div>

      <div className="content-wrapper">
        <header>
          <div className="heading">
            <MiniLogo />
            <h2>
              Meet our everyday <u>heroes</u>
            </h2>
          </div>
          <Link to="/news-and-resources/customer-stories" className="btn btn-secondary">
            View all customer stories <span className="icon">{iconArrowThin}</span>
          </Link>
        </header>

        <div className="carousel">
          <div className="controls-container">
            <div className="controls">
              <div className="buttons">
                <button
                  className={'btn btn-primary left btn-active'}
                  onClick={showPrevCard}
                >
                  {iconArrowThinLeft}
                </button>
                <button
                  className={'btn btn-primary right btn-active'}
                  onClick={showNextCard}
                >
                  {iconArrowThin}
                </button>
              </div>

              <div className="breadcrumbs">
                {!isEmpty(customerStories) &&
                  customerStories.map((story, index) => {
                    return (
                      <span
                        className={`icon cursor-pointer${
                          index === activeSlideIndex ? ' active-breadcrumb' : ''
                        }`}
                        key={index}
                        onClick={() => {
                          showSpecificCard(index);
                        }}
                      >
                        {iconBarHorizontal}
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            className="slide-container"
            style={{ transform: `translateX(${sliderPosition}px)` }}
          >
            {!isEmpty(sliders) &&
              sliders.map((story, index) => {
                return (
                  <div
                    className={`slide${
                      index === activeSlideIndex ? ' active-slide' : ''
                    }${index < activeSlideIndex ? ' hide-slide' : ''}`}
                    key={index}
                  >
                    <ArticleCard
                      imageId={story.feature_image}
                      articleUrl={`/news-and-resources/customer-stories/${story.slug}`}
                      size="large"
                      cardType="white"
                      date={story.created_at}
                      title={story.heading}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerStories;
