import PropTypes from 'prop-types';
import React from 'react';
import {
  AccordionItem,
  Carousel,
  CtaCard,
  PortalBannerText,
  TableBlock,
  TextSection
} from '@/components';
import {
  ActionCard,
  ContactCard,
  EventCard,
  FeedCard,
  NewsroomArticleCard,
  PageCard,
  StaffCard,
  TrainingCard,
  VacanciesCard
} from '@/components/Portal/Card';
import QuickLinkButton from '../Banner/QuickLinkButton';
import CarouselHeader from '@/components/common/Carousel/CarouselHeader';

CMSPageContent.propTypes = {
  content: PropTypes.array
};

export default function CMSPageContent({ content }) {
  return content.map(({ layout, attributes }, index) => (
    <Content key={index} layout={layout} attributes={attributes} />
  ));
}

const transformCardType = (/** @type {'feed' | 'article' | 'event'} */ apiCardType) => {
  let cardTypeComponent = <></>;
  switch (apiCardType) {
    case 'feed':
      cardTypeComponent = FeedCard;
      break;
    case 'article':
      cardTypeComponent = NewsroomArticleCard;
      break;
    case 'event':
      cardTypeComponent = EventCard;
      break;

    default:
      cardTypeComponent = <>unknown card</>;
      break;
  }

  return cardTypeComponent;
};

const Content = ({ layout, attributes, id }) => {
  switch (layout) {
    case 'h2_section':
    case 'h3_section':
      return (
        <TextSection
          key={id}
          H2Title={layout === 'h2_section' ? attributes['title'] : null}
          H3Title={layout === 'h3_section' ? attributes['title'] : null}
          content={attributes['paragraph']}
        />
      );

    case 'download_section':
      return (
        <div key={id} className="content-section">
          {attributes['downloads_buttons'].map(({ attributes }, index) => (
            <ActionCard
              key={index}
              title={attributes.download_title}
              action="download"
              value={attributes.download_file_size}
              link={attributes.download_url}
            />
          ))}
        </div>
      );

    case 'faq':
      return (
        <div key={id} className="accordion-section">
          {attributes['accordion'].map(({ attributes }, index) => (
            <AccordionItem
              key={index}
              title={attributes.title}
              content={attributes.content}
            />
          ))}
        </div>
      );

    case 'cta':
      return (
        <CtaCard
          key={id}
          title={attributes['title']}
          copy={attributes['copy']}
          buttonText={attributes['button_text']}
          buttonUrl={attributes['button_url']}
        />
      );

    case 'key_contacts':
      return (
        <ContactCard
          key={id}
          name={attributes['contact_name']}
          title={attributes['contact_title']}
          email={attributes['contact_email']}
          phone={attributes['contact_phone']}
        />
      );

    case 'table':
      return <TableBlock key={id} data={attributes['table']} />;

    case 'quick_links':
      return (
        <div key={id} className="flex flex-wrap gap-3">
          {attributes['quick_link'].map(({ attributes }, index) => (
            <QuickLinkButton
              key={index}
              text={attributes['text']}
              url={attributes['url']}
            />
          ))}
        </div>
      );

    case 'portal_banner_text':
      return (
        <PortalBannerText
          key={id}
          heading={attributes['heading']}
          subheading={attributes['sub_heading']}
          text={attributes['description']}
        />
      );

    case 'event_card':
      return (
        <EventCard
          key={id}
          id={id}
          size={attributes['size']} // small | large
          heading={attributes['heading']}
          start_date={attributes['start_date']}
          end_date={attributes['end_date']}
          location={attributes['location']}
          register={attributes['register']}
          status={attributes['status']}
        />
      );

    case 'feed_card':
      return (
        <FeedCard
          key={id}
          id={id}
          title={attributes['title']}
          author={attributes['creator']}
          publishDate={attributes['created_at']}
          publicationName={attributes['source']}
          description={attributes['description']}
          url={attributes['link']}
        />
      );

    case 'article_card':
      return (
        <NewsroomArticleCard
          key={id}
          id={id}
          news_topics={attributes['news_topics']}
          title={attributes['title']}
          author={attributes['creator']}
          publish_date={attributes['created_at']}
          feedback_date={attributes['feedback_date']}
          seeking_feedback={attributes['seeking_feedback']}
          content={attributes['content']}
          slug={attributes['slug']}
          external_link={attributes['external_link']}
        />
      );

    case 'page_card':
      return (
        <PageCard
          key={id}
          category={attributes['category']}
          description={attributes['description']}
          path={attributes['path']}
          size={attributes['size']}
          title={attributes['title']}
        />
      );

    case 'training_card':
      return (
        <TrainingCard
          key={id}
          id={attributes['id']}
          description={attributes['description']}
          pricing={attributes['current_users_training_price']}
          slug={attributes['slug']}
          purchaseStatus={attributes['purchaseStatus']}
          title={attributes['name']}
          updatedOn={attributes['course_updated']}
          readMore
          installation_instructions_data={attributes['installation_instructions_data']}
          training_downloads_data={attributes['training_downloads_data']}
        />
      );

    case 'vacancies_card':
      return (
        <VacanciesCard
          key={id}
          id={attributes['id']}
          bank={attributes?.institution?.['trading_name']}
          closes={attributes['date_end']}
          contact={attributes['key_contact_name']}
          email={attributes['key_contact_email']}
          employment={attributes['work_type']}
          location={attributes['location']}
          size={attributes['size']}
          title={attributes['title']}
          type={attributes['type']}
          url={attributes['apply_now_url']}
        />
      );

    case 'staff_card':
      return (
        <StaffCard
          key={id}
          name={attributes['full_name']}
          title={attributes['title']}
          phone={attributes['phone_number']}
          mobile={attributes['mobile_number']}
          email={attributes['email']}
          notification_message={attributes['notification_message']}
          image_text={attributes?.staff_image_data?.title}
          image_url={attributes?.staff_image_data?.url}
        />
      );

    case 'carousel_header':
      return (
        <CarouselHeader
          key={id}
          heading={attributes['title']}
          link={attributes['link']}
        />
      );

    case 'carousel':
      return (
        <Carousel
          headerTitle={attributes['title']}
          headerLink={attributes['link']}
          items={attributes['cards']}
          SlideItem={transformCardType(attributes['card_type'])}
          itemsPerView={attributes['items_per_view']}
          maxSlides={Math.ceil(attributes['cards'].length / 2)}
        />
      );

    default:
      return null;
  }
};
