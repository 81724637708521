import { PORTAL_ANNOUNCEMENT } from '../constants';

const initialState = {
    announcement: undefined,
    isActive: false,
    isLoading: false
};

const portalAnnouncementReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT: {
            return {
                ...state,
                isLoading: true
            };
        }

        case PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                announcement: action.payload.data[1].member_hello_bar_heading,
                isActive: Boolean(Number(action.payload.data[0].member_hello_bar_active))
            };
        }

        case PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT_FAILED: {
            return {
                ...state,
                isLoading: false
            };
        }
    }

    return state;
};

export default portalAnnouncementReducer;
