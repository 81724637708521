import React from 'react';
import { Link } from 'react-router-dom';

export default function PortalFooterNavCol({ navCategoryName, links }) {
  return (
    <div className="portal-footer-nav-col">
      <div className="nav-title">{navCategoryName}</div>

      {links.length &&
        links.map(({ link, name, is_external }, i) =>
          is_external ? (
            <a
              className="nav-link"
              href={link}
              target="_blank"
              key={`${name}-${i}`}
              rel="noreferrer"
            >
              {name}
            </a>
          ) : (
            <Link className="nav-link" to={link} key={`${name}-${i}`}>
              {name}
            </Link>
          )
        )}
    </div>
  );
}
