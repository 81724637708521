import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize } from 'humanize-plus';
import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import {
  HeroSection,
  ArticleCategories,
  ArticleListing,
  ArticleListingPagination
} from '@/components/ArticleIndexPage';
import { articleCategoriesMap } from '@/pages/Articles/constants';
import { requestArticlesByCategoryType } from '@/store/actions';
import {
  getArticles,
  getTotalArticles,
  getNumberOfItemsPerPage,
  getCurrentArticlesPage
} from '@/store/selectors/articles';

const ArticleIndexPage = () => {
  const dispatch = useDispatch();
  const { category } = useParams();

  const articles = useSelector(getArticles);
  const itemsPerPage = useSelector(getNumberOfItemsPerPage);
  const totalArticles = useSelector(getTotalArticles);
  const currentPage = useSelector(getCurrentArticlesPage);
  const totalPage = Math.ceil(totalArticles / (itemsPerPage || 12))

  React.useEffect(() => {
    if (category && articleCategoriesMap?.[category]) {
      dispatch(
        requestArticlesByCategoryType({
          categoryId: articleCategoriesMap[category].id
        })
      );
    }
  }, [category]);

  const currentArticlesCategory = capitalize(category.replace(/-/g, ' '));

  const onChangePagination = (pageNumber) => {
    dispatch(
      requestArticlesByCategoryType({
        categoryId: articleCategoriesMap[category].id,
        pageNumber,
        itemsPerPage
      })
    );

    const window = document.querySelector('html');
    const header = document.querySelector('.hero-section');
    window.scrollTop = header.clientHeight - 60;
  };

  return (
    <Layout>
      <SEO
        title="News and resources | Customer Owned Banking Association | Australia"
        keywords="articles, blog posts, sector news, customer stories, campaigns, updates, customer owned banking association, coba, Australia"
      />

      <div id="news-and-resources" data-testid="news-and-resources">
        <HeroSection
          currentArticlesCategory={currentArticlesCategory}
          caption={articleCategoriesMap[category].heroCaption}
        />

        <section className="categories-wrapper">
          <ArticleCategories />

          <ArticleListing articles={articles} category={category} />

          <ArticleListingPagination
            totalArticles={totalArticles}
            currentPage={currentPage}
            itemsCountPerPage={itemsPerPage}
            onChange={onChangePagination}
            pageRangeDisplayed={totalPage}
          />
        </section>
      </div>
    </Layout>
  );
};

export default ArticleIndexPage;
