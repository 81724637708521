import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { iconArrowThin } from '@/images/icons';
import { getArticle } from '@/store/selectors/articles';

const ArticleFooterHeadingLink = ({ isNewsResources }) => {
  const { category } = useParams();
  const article = useSelector(getArticle);

  const categoryName =
    !!article && !!article.type && !!article.type.name ? article.type.name : '';

  const url = !!isNewsResources
    ? `/news-and-resources/${category}`
    : `/${category}`;

  return (
    <Link to={url} className="btn btn-secondary">
      View all {categoryName} <span className="icon">{iconArrowThin}</span>
    </Link>
  );
};

export default ArticleFooterHeadingLink;
