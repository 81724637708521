import PropTypes from 'prop-types';
import React from 'react';
import { Tag, Title } from '@/components/Portal';

Card.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  link: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  icon: PropTypes.any,
  type: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  secondaryItems: PropTypes.array,
  content: PropTypes.string,
  actions: PropTypes.any,
  categories: PropTypes.array,
  status: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string
  }),
  singleMetaRow: PropTypes.bool,
};

function MetaItem({ icon, text }) {
  return <div className='meta-item'>
    <span className="icon voyage small">{icon}</span>
    <div className="text-meta">{text}</div>
  </div>
}

function RowItems({ items, singleRow }) {

  if (singleRow) {
    return (
      <div className='row inline-meta-row'>
        {
          items.map((item, index) => {
            return (
              <MetaItem key={index} icon={item.icon} text={item.details} />
            );
          })
        }
      </div>
    )
  }

  return items.map((item, index) => {
    return (
      <div className="row" key={index}>
        <MetaItem key={index} icon={item.icon} text={item.details} />
      </div>
    );
  });
}
function Tagged({ categories }) {
  return (
    <div className="tagged">
      {categories.map(({ type, label }, index) => {
        return <Tag key={index} type={type} label={label} />;
      })}
    </div>
  );
}

export default function Card({
  id,
  link,
  size,
  icon,
  type,
  title,
  items,
  secondaryItems,
  content,
  categories,
  status,
  actions,
  singleMetaRow = false
}) {
  return (
    <div key={id} className={`card ${size}`}>
      <div className="card__layout">
        <div className="card__header">
          {!!categories?.length ? <Tagged categories={categories} /> : null}
          <Title
            icon={icon}
            subHeading={type}
            title={title}
            link={link}
          >
            {status && <Tag type={status.type} label={status.label} />}
          </Title>
        </div>

        {items && (
          <div className="card__details">
            <RowItems items={items} singleRow={singleMetaRow} />
          </div>
        )}
        {content && (
          <div className="card__content" dangerouslySetInnerHTML={{ __html: content }}></div>
        )}
        {actions && <div className="card__actions">{actions}</div>}
        {secondaryItems && (
          <div className="card__details">
            <RowItems items={secondaryItems} />
          </div>
        )}
      </div>
    </div>
  );
}
