import axios from 'axios';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { RATE_INFO } from '../constants';
import { requestRateInfoFailed, requestRateInfoSuccess } from '../actions';
import { getUserToken } from '@/selectors';
import { API, BASE_URL } from '@/config';

export function* rateInfoSaga(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(requestRateInfoSuccess(response));
  } catch (err) {
    yield put(requestRateInfoFailed(err));
  }
}

export function* watchRateInfo() {
  yield takeLatest(RATE_INFO.REQUEST_RATE_INFO, rateInfoSaga);
}

const sagas = [fork(watchRateInfo)];

export default sagas;
