import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
// Components
import { Card } from '@/components/Portal/Card';
import { iconArrowThin } from '@/images/icons';

PageCard.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  category: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string
};

export default function PageCard({
  title,
  description,
  path,
  size,
  category = ''
}) {
  const Actions = () => {
    return (
      <Link className="btn btn-text" to={path}>
        Go to page <span className="icon midnight">{iconArrowThin}</span>
      </Link>
    );
  };

  return (
    <Card
      link={path}
      size={size}
      title={title}
      type={category}
      actions={<Actions />}
      content={description}
    />
  );
}
