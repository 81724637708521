import { API, BASE_URL } from '@/config';
import { getUserToken } from '@/selectors';
import axios from 'axios';
import { call, fork, select, takeLatest } from 'redux-saga/effects';
import { ANALYTIC_EVENTS } from '../constants';

export function* watchTrackAnalyticEvent() {
  yield takeLatest(ANALYTIC_EVENTS.TRACK_EVENT, trackAnalyticEvent);
}


/**
 * @param {Object} action 
 * @param {Object} action.payload 
 * @param {string} action.payload.url
 * @param {string | null} action.payload.referrer
 * @param {string} action.payload.deviceName
 * @param {'page_view' | 'download'} action.payload.eventType
 * @param {Object} action.payload.eventProperties
 */
export function* trackAnalyticEvent(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      url: `${BASE_URL}${API[action.type]}`,
      method: 'POST',
      data: {
        url: action.payload.url,
        referrer: action.payload.referrer,
        device_name: action.payload.deviceName,
        event_type: action.payload.eventType,
        ...(action.payload.eventProperties && { event_properties: JSON.stringify(action.payload.eventProperties) })
      }
    });

  try {
    yield call(request);
  } catch (error) {
    console.error(error)
  }
}


const analyticEventsSaga = [
  fork(watchTrackAnalyticEvent),
];

export default analyticEventsSaga;