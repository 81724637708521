import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { iconArrowThin, iconArrowThinLongDown } from '@/images/icons';
import { getHomePageHeader } from '@/selectors/homePage';
import { getContent } from '@/selectors/content';

const HeroSection = () => {
    const header = useSelector(getHomePageHeader);
    const content = useSelector(getContent);

    const heroImage = !!content.contentData.data
        ? content.contentData.data.find(x => x.id == header.header_image)
        : undefined;

    const handleMoreBelowArrow = () => {
        const element = document.getElementById('key-benefits');
        const headerHeight = document.getElementsByClassName('header')[0]
            .clientHeight;
        const PADDING_SPACE = 40;
        const scrollToLoc = element.offsetTop - headerHeight - PADDING_SPACE;
        window.scrollTo({ top: scrollToLoc, behavior: 'smooth' });
    };

    return (
        <section className="hero-section" data-testid="hero-section">
            <div className="content-wrapper">
                <div className="hero-text">
                    {header.header_heading && (
                        <div
                            className="h1"
                            dangerouslySetInnerHTML={{
                                __html: header.header_heading
                            }}
                        />
                    )}

                    {header.header_sub_heading && (
                        <div
                            className="hero-p"
                            dangerouslySetInnerHTML={{
                                __html: header.header_sub_heading
                            }}
                        />
                    )}

                    <Link
                        to={
                            !!header.header_button_link
                                ? header.header_button_link
                                : '#'
                        }
                        className="btn btn-secondary"
                    >
                        {header.header_button_text}
                        <span className="icon">{iconArrowThin}</span>
                    </Link>
                </div>

                <div className="hero-image">
                    <div className="image-container">
                        {heroImage && (
                            <img src={heroImage.url} alt={heroImage.title} />
                        )}
                    </div>
                </div>

                <button
                    id="more-below"
                    onClick={handleMoreBelowArrow}
                    className="btn down-arrow"
                >
                    <span className="icon">{iconArrowThinLongDown}</span>
                </button>
            </div>
        </section>
    );
};

export default HeroSection;
