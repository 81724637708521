import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export default function NewsArticleImageModal({ src, alt = '' }) {
  return !!src ? (
    <Zoom>
      <img src={src} alt={alt} />
    </Zoom>
  ) : null;
}
