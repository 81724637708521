import React from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { requestQuicklinks } from '@/store/actions';
import { selectQuicklinks } from '@/store/selectors';
import QuickLinkButton from './QuickLinkButton';

export default function QuickLinks() {
  const dispatch = useDispatch();
  const quickLinks = useSelector(selectQuicklinks);

  React.useEffect(() => {
    isEmpty(quickLinks) && dispatch(requestQuicklinks());
  }, []);

  return (
    <div className="quick-links">
      {!isEmpty(quickLinks) && quickLinks.map((quickLink, i) => {
        return (
          <QuickLinkButton key={`${quickLink.text}-${i}`} text={quickLink.name} url={quickLink.url} />
        );
      })}
    </div>
  );
}
