import { FEEDS } from '../constants';

export const requestFeedsByPage = (payload) => {
  return {
    type: FEEDS.REQUEST_FEEDS_BY_PAGE,
    payload: {
      ...payload,
      page: 1
    }
  };
};

export const requestFeedsByPageSuccess = (payload) => {
  return {
    type: FEEDS.REQUEST_FEEDS_BY_PAGE_SUCCESS,
    payload
  };
};

export const requestFeedsByPageFailed = (payload) => {
  return {
    type: FEEDS.REQUEST_FEEDS_BY_PAGE_FAILED,
    payload
  };
};
