export const articleCategoriesMap = {
  ['sector-news']: {
    id: 1,
    heroCaption: 'News and updates about the sector'
  },
  ['customer-stories']: {
    id: 2,
    heroCaption: 'Read about our customer experiences'
  },
  ['banking-tips']: {
    id: 3,
    heroCaption: 'Hints and tips for everyday banking'
  },
  campaigns: {
    id: 4,
    heroCaption: 'Our past and current campaigns'
  },
  ['how-it-works']: {
    id: 14,
    heroCaption: ''
  },
  ['about-coba']: {
    id: 15
  },
  ['policy-submissions']: {
    id: 9
  },
  ['campaign-outcomes']: {
    id: 10
  },
  reports: {
    id: 8
  }
};
