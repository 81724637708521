import React from 'react';
import ContactDetailIcon from './ContactItemDetailIcon';
import { iconArrowThin } from '@/images/icons';

const ContactMailto = ({ mailto, children }) => {
  return (
    <a href={`mailto:${mailto}`} className="mailto-link">
      {children}
      <ContactDetailIcon icon={iconArrowThin} />
    </a>
  );
};

export default ContactMailto;
