import axios from 'axios';
import { fork, call, put, takeLatest, select} from 'redux-saga/effects';
import { CEO } from '../constants';
import { 
  requestCEONewsArticleLatestSuccess,
  requestCEONewsArticleLatestFailed,
  requestCEONewsArticleSuccess,
  requestCEONewsArticleFailed
} from '../actions';
import { BASE_URL, API } from '@/config';
import { getUserToken } from '@/store/selectors';

export function* getCEONewsArticleLatest(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
    });

    try {
      const response = yield call(request);
      yield put(requestCEONewsArticleLatestSuccess(response));
    } catch (err) {
      yield put(requestCEONewsArticleLatestFailed(err));
    }
}

export function* getCEONewsArticle(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}/${action.payload.slug}`,
    });

    try {
      const response = yield call(request);
      yield put(requestCEONewsArticleSuccess(response));
    } catch (err) {
      yield put(requestCEONewsArticleFailed(err));
    }
}

export function* watchGetCEONewsArticleLatest() {
  yield takeLatest(CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST, getCEONewsArticleLatest);
}

export function* watchGetCEONewsArticle() {
  yield takeLatest(CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG, getCEONewsArticle);
}

const sagas = [
  fork(watchGetCEONewsArticleLatest),
  fork(watchGetCEONewsArticle)

];

export default sagas;