import React from 'react';
import {
  iconPhone,
  iconMobilePhone,
  iconDocumentBlank,
  iconMail,
  iconLocationSmall,
  iconSettings,
  iconFacebook,
  iconLinkedin,
  iconTwitter,
  iconYoutube
} from '@/images/icons';
import ContactItemDetail from './ContactItemDetail';
import ContactMailto from './ContactMailto';

const ContactDetails = () => {
  return (
    <div className="content-wrapper">
      <section className="contact-section">
        <h4>Customer Owned Banking Association Limited</h4>
        <div>
          <div className="contact-sub-section">
            <div className="contact-item">
              <h5>Contact</h5>
              <ContactItemDetail icon={iconPhone}>02 8035 8400</ContactItemDetail>
              <ContactItemDetail icon={iconDocumentBlank}>02 9262 1040</ContactItemDetail>
              <ContactItemDetail icon={iconMail}>info@coba.asn.au</ContactItemDetail>
            </div>

            <div className="contact-item">
              <h5>Head Office</h5>
              <ContactItemDetail icon={iconLocationSmall}>
                Suite 403, Level 4
              </ContactItemDetail>
              <ContactItemDetail>151 Castlereagh Street</ContactItemDetail>
              <ContactItemDetail>Sydney NSW 2000</ContactItemDetail>
              <ContactItemDetail>Australia</ContactItemDetail>
            </div>

            <div className="contact-item">
              <h5>Postal</h5>
              <ContactItemDetail icon={iconMail}>GPO Box 4686</ContactItemDetail>
              <ContactItemDetail>Sydney NSW 2001</ContactItemDetail>
            </div>
          </div>

          <div className="contact-sub-section">
            <div className="contact-item">
              <h5>Social</h5>
              <ContactItemDetail
                icon={iconTwitter}
                href="https://twitter.com/CustomerOwnedBA"
              >
                Twitter
              </ContactItemDetail>
              <ContactItemDetail
                icon={iconLinkedin}
                href="https://www.linkedin.com/company/customer-owned-banking-association"
              >
                LinkedIn
              </ContactItemDetail>
              <ContactItemDetail
                icon={iconFacebook}
                href="https://www.facebook.com/CustomerOwnedBanking"
              >
                Facebook
              </ContactItemDetail>
              <ContactItemDetail
                icon={iconYoutube}
                href="https://www.youtube.com/user/AbacusCOB"
              >
                YouTube
              </ContactItemDetail>
            </div>

            <div className="contact-item">
              <h5>ABN</h5>
              <ContactItemDetail icon={iconSettings}>
                ABN 98 137 780 897
              </ContactItemDetail>
              <ContactItemDetail icon={iconSettings}>ACN 137 780 897</ContactItemDetail>
            </div>

            <div className="contact-item">
              <h5>Canberra</h5>
              <ContactItemDetail icon={iconLocationSmall}>Suite 4C</ContactItemDetail>
              <ContactItemDetail>16 National Circuit</ContactItemDetail>
              <ContactItemDetail>Barton ACT 2600</ContactItemDetail>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <h4>Media and Policy</h4>
        <div>
          <div className="contact-sub-section">
            <div className="contact-item">
              <h4>Media Contact</h4>
              <h5>Keith Barrett</h5>
              {/*<h6>Director - Corporate Affairs</h6>*/}
              {/*<ContactItemDetail icon={iconPhone}>+61 2 8035 8446</ContactItemDetail>*/}
              <ContactItemDetail icon={iconMobilePhone}>
                  +61 415 751 100
              </ContactItemDetail>
              <ContactMailto mailto="media@coba.asn.au">Email Keith</ContactMailto>
            </div>

            <div className="contact-item">
              <h4>Policy contact</h4>
              <h5>Luke Lawler</h5>
              <h6>Director - Policy</h6>
              <ContactItemDetail icon={iconPhone}>+61 2 8035 8448</ContactItemDetail>
              <ContactItemDetail icon={iconMobilePhone}>
                +61 418 213 025
              </ContactItemDetail>
              <ContactMailto mailto="llawler@coba.asn.au">Email Luke</ContactMailto>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactDetails;
