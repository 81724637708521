import React from 'react';
import { useController } from 'react-hook-form';
import FormField from './FormField';

export default function Textarea2({
  id,
  placeholder,
  cols,
  control,
  defautlValue,
  rows,
  type,
  name,
  onClick,
  onChange,
  labelFor,
  labelText,
  validation,
  wrap
}) {
  const {
    field: { ref, ...inputProps },
    formState: { errors = {} }
  } = useController({
    name,
    control,
    rules: validation,
    defaultValue: defautlValue || ''
  });

  return (
    <FormField labelFor={labelFor} labelText={labelText} name={name} errors={errors}>
      <textarea
        id={id}
        ref={ref}
        className="text-input"
        type={type}
        onClick={onClick}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        cols={cols}
        rows={rows}
        wrap={wrap}
        style={{
          resize: 'none'
        }}
        {...inputProps}
      />
    </FormField>
  );
}
