import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getArticle } from '@/store/selectors/articles';
import AccordionItem from './AccordionItem';

const ArticleAccordion = () => {
  const article = useSelector(getArticle);
  const accordionData = !isEmpty(article) && JSON.parse(article.accordion_details);

  return (
    <div className="accordion">
      {accordionData &&
        accordionData.map((item, index) => {
          return <AccordionItem item={item} key={index} />;
        })}
    </div>
  );
};

export default ArticleAccordion;
