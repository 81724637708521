import { JOBS } from '../constants';
import { PER_PAGE } from '@/config';

const initialState = {
  vacancies: {
    isLoading: false,
    items: [],
    currentPage: 1,
    total: 0,
    hasMore: false,
    errors: []
  },
  listVacancy: {
    errors: [],
    isListing: false,
    hasListed: false
  },
  queries: {
    search: '',
    location: '',
    type: '',
    per_page: PER_PAGE
  }
};

const jobsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case JOBS.SUBMIT_JOB_LISTING:
      return {
        ...state,
        listVacancy: {
          ...state.listVacancy,
          isListing: true,
          errors: []
        }
      };

    case JOBS.SUBMIT_JOB_LISTING_SUCCESS:
      return {
        ...state,
        listVacancy: {
          isListing: false,
          hasListed: true,
          errors: []
        }
      };

    case JOBS.SUBMIT_JOB_LISTING_FAILED:
      return {
        ...state,
        listVacancy: {
          isListing: false,
          hasListed: false,
          errors: action.payload?.response?.data.errors || action.payload?.data
        }
      };

    case JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE:
    case JOBS.REQUEST_JOB_DETAILS:
      return {
        ...state,
        vacancies: {
          ...state.vacancies,
          isLoading: true,
          errors: []
        }
      };

    case JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE_SUCCESS: {
      const payload = action?.payload?.data?.data;
      return {
        ...state,
        vacancies: {
          ...state.vacancies,
          isLoading: false,
          items: payload.data,
          errors: [],
          currentPage: payload.current_page,
          total: payload.total,
          hasMore: !!payload.next_page_url
        }
      };
    }

    case JOBS.REQUEST_JOB_DETAILS_SUCCESS:{
      const payload = action?.payload?.data?.data;
      return {
        ...state,
        vacancies: {
          ...state.vacancies,
          isLoading: false,
          items: [payload],
          errors: []
        }
      };
    }

    case JOBS.REQUEST_JOB_DETAILS_FAILED:
    case JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE_FAILED:
    case JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE_FAILED:
      return {
        ...state,
        vacancies: {
          ...state.vacancies,
          isLoading: false,
          errors: action.payload?.data
        }
      };

    case JOBS.RESET_JOB_LISTING:
      return {
        ...state,
        listVacancy: { ...initialState.listVacancy }
      };

    case JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE:
      return {
        ...state,
        vacancies: {
          ...state.vacancies,
          isLoading: true,
          errors: []
        },
        queries: {
          ...state.queries,
          ...action.payload
        }
      };

    case JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE_SUCCESS: {
      const payload = action?.payload?.data?.data;
      return {
        ...state,
        vacancies: {
          ...state.vacancies,
          isLoading: false,
          items: [...state.vacancies.items, ...payload.data],
          errors: [],
          currentPage: payload.current_page,
          total: payload.total || 0,
          hasMore: !!payload.next_page_url
        }
      };
    }

    case JOBS.CLEAR_SEARCH_JOB:
      return {
        ...state,
        queries: {
          ...state.queries,
          search: ''
        }
      };
  }
  return state;
};

export default jobsReducer;
