import React from 'react'

const Heading = ({h2=false, h3=true, children}) => {
	return !children 
	? null
	: h2 ? <h2>{children}</h2>
	: h3 ? <h3>{children}</h3> 
	: <h3>{children}</h3> 
}

export default Heading