import React, { useEffect } from 'react';
import usePrevious from '@react-hook/previous';
import { pluralize } from 'humanize-plus';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button, TrainingCard, Loading } from '@/components';
import { clearTrainingModules, requestTrainingModules } from '@/store/actions';
import { selectTrainingModules, selectTrainingCategories } from '@/store/selectors';
import { useParams } from 'react-router-dom';

export default function Modules() {
  const prams = useParams();
  const actions = bindActionCreators(
    { clearTrainingModules, requestTrainingModules },
    useDispatch()
  );
  const { loading, loaded, modules, hasMore } = useSelector(
    selectTrainingModules,
    shallowEqual
  );
  const { categories } = useSelector(selectTrainingCategories, shallowEqual);

  const [hasMoreTrainingModules, setHasMoreTrainingModules] = React.useState(false);
  const [filterPurchased, setFilterPurchased] = React.useState(false);
  const [filterCategory, setFilterCategory] = React.useState(null);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [request, setRequest] = React.useState({});
  const previousCategory = usePrevious(filterCategory);
  const previousPageIndex = usePrevious(pageIndex);

  const categoryId = (slug) => {
    const category = categories.find((category) => category.slug === slug);
    return category?.id;
  };

  const onClickLoadMore = () => {
    setPageIndex(pageIndex + 1);
  };

  useEffect(() => {
    setRequest({
      ...request,
      page: pageIndex,
      ...(filterPurchased && { purchased: 1 }),
      ...(categoryId(filterCategory) && { category: categoryId(filterCategory) })
    });
  }, []);

  useEffect(() => {
    setFilterPurchased(isEmpty(prams));

    if (prams.category !== previousCategory) {
      // Clear loaded training modules when changing categories
      actions.clearTrainingModules(request);
      setFilterCategory(prams.category);
      setPageIndex(1);
    }
  }, [prams]);

  useEffect(() => {
    setHasMoreTrainingModules(hasMore);
  }, [loaded]);

  // Request
  useEffect(() => {
    actions.requestTrainingModules(request);
  }, [request]);

  useEffect(() => {
    setRequest({
      ...(filterPurchased && { purchased: 1 }),
      ...(categoryId(filterCategory) && { category: categoryId(filterCategory) })
    });
  }, [filterCategory]);

  useEffect(() => {
    if (previousPageIndex !== pageIndex && hasMoreTrainingModules) {
      setRequest({
        ...request,
        page: pageIndex
      });
    }
  }, [pageIndex]);

  return (
    <div className="training">
      <h2>
        {filterPurchased ? 'Available Modules' : 'Discover our range of training modules'}
      </h2>

      <div className="listing-counter">
        {`${modules.length} ${pluralize(modules.length, 'result')}`}
      </div>

      {loading && !loaded ? (
        <Loading />
      ) : (
        <TransitionGroup className="card-listing">
          {modules.map((trainingModule) => (
            <CSSTransition key={trainingModule.id} timeout={600} classNames="card-item">
              <TrainingCard
                description={trainingModule.description}
                id={trainingModule.id}
                key={trainingModule.id}
                pricing={trainingModule.current_users_training_price}
                slug={trainingModule.slug}
                purchaseStatus={trainingModule.purchaseStatus}
                title={trainingModule.name}
                updatedOn={trainingModule.course_updated}
                readMore
                installation_instructions_data={
                  trainingModule.installation_instructions_data
                }
                training_downloads_data={trainingModule.training_downloads_data}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      )}
      {hasMoreTrainingModules && (
        <div className="load-more">
          <Button variant="btn-outline" onClick={onClickLoadMore}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
}
