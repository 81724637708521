import React from 'react';

export default function CreateAccountFormHeader({ stepIndex, formName }) {
  return (
    <div className="create-account-form-header row">
      <div className="create-acc-form-step-index col-xs-6">
        <h4 className="create-acc-form-step-index">{`Step ${stepIndex} of 3`}</h4>
      </div>

      <div className="create-acc-form-step-label col-xs-6">
        <p>{formName}</p>
      </div>
    </div>
  );
}
