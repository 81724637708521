import { ARTICLES } from '../constants';

const initialState = {
  isLoading: false,
  bankingTips: [],
  totalBankingTips: 0,
  currentPage: 1,
  itemsPerPage: 0
};

const bankingTipsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ARTICLES.REQUEST_BANKING_TIPS:
      return {
        ...state,
        isLoading: true
      };

    case ARTICLES.RECEIVED_BANKING_TIPS:
      return {
        ...state,
        isLoading: false,
        bankingTips: action.payload.data.data,
        currentPage: action.payload.data.current_page,
        totalBankingTips: action.payload.data.total
      };

    case ARTICLES.FAILED_BANKING_TIPS:
      return {
        ...state,
        isLoading: false,
        bankingTipsData: action.payload
      };
  }

  return state;
};

export default bankingTipsReducer;
