import { CONTENT } from '../constants'

//initial state
const initialState = {
    isLoading: false,
    contentData: {}
}

//Reducer
const contentReducer = (state = initialState, action = {}) => {

    switch(action.type) {
        case CONTENT.REQUEST_CONTENT:
            return {...state, isLoading:true}

        case CONTENT.RECEIVED_CONTENT:
            return {...state, isLoading:false, contentData: action.payload}

        case CONTENT.FAILED_CONTENT:
            return {...state, isLoading:false, contentData: action.payload}
    }

    return state
}

export default contentReducer
