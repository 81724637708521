import React from 'react';
import { Link } from 'react-router-dom';
import { iconArrowThin } from '@/images/icons';

export default function QuickLinkButton({ url, text }) {
  return (
    <Link className="btn-quick-link" to={url}>
      <div className='layout'>
        <span className="text">{text}</span>
				<div className="btn btn-round-arrow">
					<span className="icon">{iconArrowThin}</span>
				</div>
      </div>
    </Link>
  );
}
