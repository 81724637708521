import React from 'react';
import { useSelector } from 'react-redux';
import {
  getTestimonialsWithAvatar,
  getTestimonialSectionHeading
} from '@/selectors/homePage';
import { Carousel } from '@/components/common';
import { iconQuote } from '@/images/icons';

export default function Testimonials() {
  const testimonials = useSelector(getTestimonialsWithAvatar);
  const testimonialSectionHeading = useSelector(getTestimonialSectionHeading);

  return (
    <section id="testimonials" data-testid="testimonials">
      <div className="blue-background" />

      <div className="content-wrapper">
        <div className="content">
          <Carousel
            items={testimonials}
            headerTitle={testimonialSectionHeading}
            itemsPerView={1}
            SlideItem={TestimonialCard}
            variant="carousel-with-quote-header"
          />
        </div>
      </div>
    </section>
  );
}

function TestimonialCard(props) {
  return (
    <div className="testimonial-card">
      <div
        dangerouslySetInnerHTML={{
          __html: props.testimonial_body
        }}
      />
      <div className="card-footer">
        <div className="avatar">
          { props.avatar_image_url
            ? <img src={props.avatar_image_url} alt={props.testimonial_person_name} />
            : <span className="icon">{iconQuote}</span>  
          }
        </div>
        <div className="info">
          <p className="name">{props.testimonial_person_name}</p>
          <p className="bank">{props.testimonial_bank_name}</p>
        </div>
      </div>
    </div>
  );
}
