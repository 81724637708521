import React from 'react';
import '../../../sass/component-styles/buttons.scss';

export default function Button({
  variant,
  type,
  children,
  onClick,
  icon,
  iconLeft,
  isDisabled
}) {
  const isDisabledClass = isDisabled ? 'btn-disabled' : '';

  return (
    <button
      className={`btn ${variant} ${isDisabledClass}`}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
    >
      {!!iconLeft && <span className="icon-left">{iconLeft}</span>}
      {children}
      {!!icon && <span className="icon">{icon}</span>}
    </button>
  );
}
