import React from 'react';
import { first } from 'lodash';
import { TextField } from '@/components';
import AddressSuggestions from './AddressSuggestions';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

export default function AddressAutocompleteField({ control, setBranchAddress }) {
  const [showAddressSuggestions, setShowAddressSuggestions] = React.useState(false);

  const map = [
    'subpremise',
    'street_number',
    'route',
    'locality',
    'administrative_area_level_1',
    'country',
    'postal_code'
  ];

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'au' },
      fields: ['address_components']
    },
    debounce: 300
  });

  const onChangeInput = (e) => {
    setValue(e.target.value);
  };

  const onSelectSuggestion =
    ({ description }) =>
    () => {
      setShowAddressSuggestions(false);
      clearSuggestions();

      getGeocode({ address: description }).then((results) => {
        const result = first(results);
        const addressData = result?.address_components;
        const formattedAddress = addressData.reduce((a, c) => {
          const key = map.filter((m) => c.types.includes(m))[0];

          if (!!key) {
            a[key] = c;
            return a;
          }

          return a;
        }, {});

        setBranchAddress({
          branch_address_suite: formattedAddress.subpremise
            ? formattedAddress.subpremise.long_name
            : '',
          streetNumber: formattedAddress.street_number.long_name,
          street: formattedAddress.route.long_name,
          branch_address_postcode: formattedAddress.postal_code.long_name,
          branch_address_state: formattedAddress.administrative_area_level_1.long_name,
          branch_address_city: formattedAddress.locality.long_name
        });
      });
    };

  const onFocusPlacesAutocomplete = () => {
    setShowAddressSuggestions(true);
  };

  const onClickEnterManually = () => {
    clearSuggestions();
    setShowAddressSuggestions(false);
  };

  return (
    <div className="places-autocomplete-input-wrapper">
      <TextField
        id="branch_address_line1"
        name="branch_address_line1"
        labelFor="branch_address_line1"
        labelText="Street Address"
        placeholder="Street Address"
        onFocus={onFocusPlacesAutocomplete}
        onChangeField={onChangeInput}
        defaultValue={value}
        validation={{
          required: {
            value: true,
            message: 'This field is required.'
          }
        }}
        control={control}
      />

      <div className="btn btn-text btn-add-in-manually" onClick={onClickEnterManually}>
        Add In Manually
      </div>

      {status === 'OK' && showAddressSuggestions && (
        <AddressSuggestions onSelectSuggestion={onSelectSuggestion} suggestions={data} />
      )}
    </div>
  );
}
