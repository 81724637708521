import { PortalPageMenuContent, PortalPageMenuItem, Preferences } from '@/components/Portal';
import { ActionCard } from '@/components/Portal/Card';
import { KeyContacts, RelatedDownloads } from '@/components/Portal/Newsroom';
import useMedia from 'use-media';

export default function NewsArticleMenu({ article, mobile, desktop }) {
  const isDesktop = useMedia('(min-width: 1024px)');
  const downloadLink = article ? `/newsroom/${article.slug}/pdf` : '/';
  const articleTitle = article ? article.title : '';

  return (
    <PortalPageMenuContent
      mobileToggleName="Contacts and Downloads"
      mobile={mobile}
      desktop={desktop}
    >
      <KeyContacts article={article} />
      <RelatedDownloads article={article} />

      {isDesktop &&
        <PortalPageMenuItem title="Download as PDF">
          <ActionCard
            title={articleTitle}
            action='download_pdf'
            link={downloadLink}
          />
        </PortalPageMenuItem>
      }
      {isDesktop &&
        <PortalPageMenuItem>
          <Preferences
            title="Subscribe for updates"
            text="You can manage your subscription preferences in account settings."
            btnText="Update preferences"
          />
        </PortalPageMenuItem>
      }
    </PortalPageMenuContent>
  );
}
