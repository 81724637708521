import { API, BASE_URL } from '@/config';
import { getUserToken } from '@/store/selectors';
import axios from 'axios';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

import {
  checkTokenFailed,
  checkTokenSuccess,
  createNewUserBranchFailed,
  createNewUserBranchSuccess,
  createNewUserDivisionFailed,
  createNewUserDivisionSuccess,
  forgotPasswordRequestFailed,
  forgotPasswordRequestSuccess,
  loginUserFailed,
  loginUserSuccess,
  registerUserFailed,
  registerUserSuccess,
  requestUserBranchesFailed,
  requestUserBranchesSuccess,
  requestUserDivisionsFailed,
  requestUserDivisionsSuccess,
  updateAccountDetailsFailed,
  updateAccountDetailsSuccess,
  updateAccountPasswordFailed,
  updateAccountPasswordSuccess,
  updateUserBranchesFailed,
  updateUserBranchesSuccess,
  updateUserDivisionFailed,
  updateUserDivisionSuccess
} from '../actions';
import {
  resetPasswordRequestFailed,
  resetPasswordRequestSuccess
} from '../actions/userActions';
import { USER } from '../constants';

export function* registerUser(action) {
  const request = () => axios.post(`${BASE_URL}${API[action.type]}`, action.payload);

  try {
    const response = yield call(request);
    yield put(registerUserSuccess(response));
  } catch (err) {
    yield put(registerUserFailed(err));
  }
}

export function* watchRegisterUser() {
  yield takeLatest(USER.REGISTER_USER, registerUser);
}

const DEVICE_NAME_COOKIE_KEY = 'device_name';

export function* loginUser(action) {
  let deviceName = Cookies.get(DEVICE_NAME_COOKIE_KEY);
  if (!deviceName) {
    deviceName = uuidv4();
    Cookies.set(
      DEVICE_NAME_COOKIE_KEY,
      deviceName
    );
  }

  const request = () =>
    axios.post(`${BASE_URL}${API[action.type]}`, {
      email: action.payload.email,
      password: action.payload.password,
      device_name: deviceName
    });

  try {
    const response = yield call(request);

    yield put(loginUserSuccess({ ...response.data, deviceName }));
  } catch (err) {
    yield put(loginUserFailed(err));
  }
}

export function* watchLoginUser() {
  yield takeLatest(USER.LOGIN_USER, loginUser);
}

export function* checkToken(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(checkTokenSuccess(response));
  } catch (err) {
    yield put(checkTokenFailed(err));
  }
}

export function* watchCheckToken() {
  yield takeLatest(USER.CHECK_TOKEN, checkToken);
}

export function* forgotPassword(action) {
  const request = () =>
    axios.post(`${BASE_URL}${API[action.type]}`, {
      email: action.payload.email
    });

  try {
    const response = yield call(request);
    yield put(forgotPasswordRequestSuccess(response));
  } catch (err) {
    yield put(forgotPasswordRequestFailed(err));
  }
}

export function* watchForgotPassword() {
  yield takeLatest(USER.FORGOT_PASSWORD, forgotPassword);
}

export function* resetPassword(action) {
  const request = () =>
    axios({
      url: `${BASE_URL}${API[action.type]}`,
      method: 'POST',
      data: {
        token: action.payload.token,
        email: action.payload.email,
        password: action.payload.password,
        password_confirmation: action.payload.password_confirmation
      }
    });

  try {
    const response = yield call(request);
    yield put(resetPasswordRequestSuccess(response));
  } catch (err) {
    yield put(resetPasswordRequestFailed(err));
  }
}

export function* watchResetPassword() {
  yield takeLatest(USER.RESET_PASSWORD, resetPassword);
}

export function* updateAccountDetails(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      data: action.payload
    });

  try {
    const response = yield call(request);
    yield put(updateAccountDetailsSuccess(response));
  } catch (err) {
    yield put(updateAccountDetailsFailed(err));
  }
}

export function* watchUpdateAccountDetails() {
  yield takeLatest(USER.UPDATE_ACCOUNT_DETAILS, updateAccountDetails);
}

export function* updateAccountPassword(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      data: action.payload
    });

  try {
    const response = yield call(request);
    yield put(updateAccountPasswordSuccess(response));
  } catch (err) {
    yield put(updateAccountPasswordFailed(err));
  }
}

export function* watchUpdateAccountPassword() {
  yield takeLatest(USER.UPDATE_ACCOUNT_PASSWORD, updateAccountPassword);
}

export function* requestUserDivisions(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      data: action.payload
    });

  try {
    const response = yield call(request);
    yield put(requestUserDivisionsSuccess(response));
  } catch (err) {
    yield put(requestUserDivisionsFailed(err));
  }
}

export function* watchRequestUserDivisions() {
  yield takeLatest(USER.REQUEST_USER_DIVISIONS, requestUserDivisions);
}

export function* requestUserBranches(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(requestUserBranchesSuccess(response));
  } catch (err) {
    yield put(requestUserBranchesFailed(err));
  }
}

export function* watchRequestUserBranches() {
  yield takeLatest(USER.REQUEST_BRANCHES, requestUserBranches);
}

export function* updateUserBranches(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      url: `${BASE_URL}${API[action.type]}`,
      params: { ...action.payload }
    });

  try {
    const response = yield call(request);
    yield put(updateUserBranchesSuccess(response));
  } catch (err) {
    yield put(updateUserBranchesFailed(err));
  }
}

export function* watchUpdateUserBranches() {
  yield takeLatest(USER.UPDATE_BRANCHES, updateUserBranches);
}

export function* updateUserDivision(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(updateUserDivisionSuccess(response));
  } catch (err) {
    yield put(updateUserDivisionFailed(err));
  }
}

export function* watchUpdateUserDivision() {
  yield takeLatest(USER.UPDATE_DIVISION, updateUserDivision);
}

export function* createNewUserDivision(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(createNewUserDivisionSuccess(response));
  } catch (err) {
    yield put(createNewUserDivisionFailed(err));
  }
}

export function* watchCreateNewUserDivision() {
  yield takeLatest(USER.CREATE_NEW_DIVISION, createNewUserDivision);
}

export function* createNewUserBranch(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(createNewUserBranchSuccess(response));
  } catch (err) {
    yield put(createNewUserBranchFailed(err));
  }
}

export function* watchCreateNewUserBranch() {
  yield takeLatest(USER.CREATE_NEW_BRANCH, createNewUserBranch);
}

const sagas = [
  fork(watchCheckToken),
  fork(watchLoginUser),
  fork(watchRegisterUser),
  fork(watchForgotPassword),
  fork(watchUpdateAccountDetails),
  fork(watchUpdateAccountPassword),
  fork(watchRequestUserDivisions),
  fork(watchRequestUserBranches),
  fork(watchUpdateUserBranches),
  fork(watchUpdateUserDivision),
  fork(watchCreateNewUserDivision),
  fork(watchCreateNewUserBranch),
  fork(watchResetPassword)
];

export default sagas;
