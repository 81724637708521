import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import menuItems from '@/data/static-menus.json';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux'
import { selectStaticPage } from '@/store/selectors';
import{ PortalPageMenuNavigation, CollapsibleMenuOptions } from '@/components/Portal';

StaticPageMenu.propTypes = {
	mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function StaticPageMenu({mobile, desktop}) {
	const { menu } = useSelector(selectStaticPage);
	const [pageMenu, setPageMenu] = useState([]);
	
  useEffect(() => {
		if (!isEmpty(menuItems[menu])) {	
			const pageMenuStructure = menuItems[menu].map( (menuSection) => {
				if (!isEmpty(menuSection.children)) {
					menuSection.Content = CollapsibleMenuOptions;
				}
				return menuSection;
			})
			setPageMenu(pageMenuStructure)
		}
  }, [menu]);

	return (
		<PortalPageMenuNavigation 
			mobile={mobile} 
			desktop={desktop}
			items={pageMenu}
			title={menu}
		/>
	);
	
};
