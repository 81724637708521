import React from 'react';
import {
  iconSatisfaction1,
  iconSatisfaction2,
  iconSatisfaction3,
  iconSatisfaction4,
  iconSatisfaction5
} from '@/images/icons';

/**
 * @param {{ onRate: any; }} props
 */
function RatingSatisfaction(props) {
  const { onRate } = props;

  const handleRate = (/** @type {number} */ index) => {
    const selectedLevel = index + 1;
    onRate(selectedLevel);
  };

  return (
    <>
      <div className="justify-start items-start gap-1.5 inline-flex">
        {Array.from({ length: 5 }, (_, index) => (
          <div
            key={index}
            className="p-4 w-full bg-white rounded-sm border border-stone-300 gap-1.5 flex flex-col justify-center items-center cursor-pointer"
            onClick={() => handleRate(index)}
          >
            <div className="w-3.5 h-3.5 relative">
              {index === 0
                ? iconSatisfaction1
                : index === 1
                ? iconSatisfaction2
                : index === 2
                ? iconSatisfaction3
                : index === 3
                ? iconSatisfaction4
                : iconSatisfaction5}
            </div>
            <div className="text-center text-neutral-800 text-[5.5px] font-['Gotham Narrow'] leading-3">
              {index === 0
                ? 'Highly unsatisfied'
                : index === 1
                ? 'Unsatisfied'
                : index === 2
                ? 'Neutral'
                : index === 3
                ? 'Somewhat satisfied'
                : 'Highly satisfied'}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default RatingSatisfaction;
