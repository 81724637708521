import React from 'react';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { PORTAL_PATHS } from '@/config';
import { iconSearch } from '@/images/icons';
import { TextField } from '@/components';

export default function GlobalSearchPanel({ isOpen }) {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = React.useState('');

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onClickSearch = (ev) => {
    ev.preventDefault();
    history.push({
      pathname: PORTAL_PATHS.GLOABL_SEARCH,
      search: `?query=${searchQuery}`
    });
  };

  const onChangeSearchInput = (ev) => {
    ev.preventDefault();
    setSearchQuery(ev.target.value);
  };

  const searchPanelClasses = isOpen
    ? 'global-search-panel is-open'
    : 'global-search-panel';

  return (
    <div className={searchPanelClasses}>
      <div className="global-search-bar">
        <div className="row center-sm">
          <div className="col-xs-12 col-sm-7 col-md-6">

            <div className="searchbar">
              <FormProvider {...form}>
                <form className="searchbar-container" noValidate>
                  <TextField
                    id="search"
                    name="globalSearch"
                    control={form.control}
                    onChangeField={onChangeSearchInput}
                    placeholder="Search"
                    labelFor="search"
                    labelText="search"
                  />
                  <button className="btn-round" onClick={onClickSearch}>
                    <span className="icon">{iconSearch}</span>
                  </button>
                </form>
              </FormProvider>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}
