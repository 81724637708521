import { Button, PasswordField } from '@/components';
import {
  MemberWelcome,
  MemberWelcomeFooter,
  OnboardingFormTransition
} from '@/components/Portal/Onboarding';
import { PORTAL_PATHS } from '@/config';
import { iconArrowThin, iconTick } from '@/images/icons';
import { getUserError } from '@/selectors';
import { isResetPasswordRequestSubmitting } from '@/selectors/user';
import { logoutUser } from '@/store/actions';
import { resetPasswordRequest } from '@/store/actions/userActions';
import { isEmpty } from 'lodash';
import qs from 'qs';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useMedia from 'use-media';

const PASSWORD_MIN_LENGTH = 8;

export default function ResetPassword() {
  const { token } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const email = qs.parse(search.substring(1)).email;
  const dispatch = useDispatch();
  const [minLettersHintOK, setMinLettersHintOK] = React.useState(false);
  const [capitalLetterHintOK, setCapitalLetterHintOK] = React.useState(false);
  const [specialCharHintOK, setSpecialCharHintOK] = React.useState(false);
  const isMobile = useMedia('(max-width: 1023px)');

  const error = useSelector(getUserError);
  const isRequestSubmitting = useSelector(isResetPasswordRequestSubmitting);

  const formMethods = useForm({
    mode: 'all',
    reValidateMode: 'all'
  });

  const formProps = { ...formMethods };

  const { dirtyFields, isSubmitted } = formMethods.formState;
  const isSubmitDisabled = isEmpty(dirtyFields);
  const watchNewPassword = formMethods.watch('newPassword');
  const watchConfirmPassword = formMethods.watch('confirmPassword');

  const onSubmitForm = (data) => {
    dispatch(
      resetPasswordRequest({
        token: token,
        email: email,
        password: data.newPassword,
        password_confirmation: data.confirmPassword
      })
    );
  };

  const minLettersHintClasses = minLettersHintOK
    ? 'password-hint min-length valid'
    : 'password-hint min-length';

  const capitalLetterHintClasses = capitalLetterHintOK
    ? 'password-hint capital-letter valid'
    : 'password-hint capital-letter';

  const specialCharHintClasses = specialCharHintOK
    ? 'password-hint special-char valid'
    : 'password-hint special-char';

  const onChangePassword = () => {
    const newPasswordError = formMethods?.errors?.newPassword;

    if (!!newPasswordError) {
      formMethods.trigger('newPassword');
    }
  };

  const onChangeConfirmPassword = () => {
    const confirmPasswordError = formMethods?.errors?.confirmPassword;
    const newPasswordError = formMethods?.errors?.newPassword;
    const newPasswordMatchError =
      newPasswordError?.message === 'Password fields do not match.';

    if (!!confirmPasswordError) {
      formMethods.trigger('newPassword');
      formMethods.trigger('confirmPassword');
    }

    if (
      newPasswordMatchError &&
      !confirmPasswordError &&
      watchNewPassword === watchConfirmPassword
    ) {
      formMethods.clearErrors('newPassword');
      formMethods.clearErrors('confirmPassword');
    }
  };

  const passwordValidation = (value) => {
    const SPECIAL_CHAR_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const hasUppercase = value !== value.toLowerCase();
    const hasSpecialChar = SPECIAL_CHAR_REGEX.test(value);

    if (!value) {
      return 'This field is required.';
    }

    if (value.length < PASSWORD_MIN_LENGTH) {
      setMinLettersHintOK(false);
      return `This field must have at least ${PASSWORD_MIN_LENGTH} characters.`;
    }

    if (value.length >= PASSWORD_MIN_LENGTH) {
      setMinLettersHintOK(true);
    }

    if (!hasUppercase) {
      return 'This field must have at least 1 capital letter.';
    }

    if (hasUppercase) {
      setCapitalLetterHintOK(true);
    }

    if (!hasSpecialChar) {
      return 'This field must have at least 1 special character.';
    }

    if (hasSpecialChar) {
      setSpecialCharHintOK(true);
    }

    if (
      !!watchNewPassword &&
      !!watchConfirmPassword &&
      watchNewPassword !== watchConfirmPassword
    ) {
      return 'Password fields do not match.';
    }

    return true;
  };

  React.useEffect(() => {
    dispatch(logoutUser());
  }, []);

  React.useEffect(() => {
    if (isSubmitted && !isRequestSubmitting && !error) {
      history.replace(PORTAL_PATHS.onboarding.signIn);
    }
  }, [isSubmitted, error, isRequestSubmitting]);

  return (
    <div className="portal row no-margin">
      <MemberWelcome />

      <div className="onboarding-form col-xs-12 col-md-5 col-md-offset-7">
        <div className="onboarding-form-wrapper">
          <div className="container">
            <OnboardingFormTransition formPath="/reset-password">
              <FormProvider {...formProps}>
                <form
                  className="form form-reset-password"
                  onSubmit={formMethods.handleSubmit(onSubmitForm)}
                  noValidate
                >
                  <h4>Update your password</h4>
                  <p className="update">Please update your password below.</p>
                  <PasswordField
                    autoComplete="new-password"
                    labelFor="new-password"
                    labelText="New Password"
                    placeholder="New Password"
                    name="newPassword"
                    onChange={onChangePassword}
                    validation={{
                      validate: passwordValidation
                    }}
                  />

                  <PasswordField
                    autoComplete="new-password"
                    labelFor="verify-password"
                    labelText="Verify Password"
                    placeholder="Verify Password"
                    name="confirmPassword"
                    onChange={onChangeConfirmPassword}
                    validation={{
                      validate: passwordValidation
                    }}
                  />

                  <div className="password-hints">
                    <p>Your password needs to include</p>

                    <div className={minLettersHintClasses}>
                      <span className="icon icon-tick">{iconTick}</span>
                      <p>At least 8 letters</p>
                    </div>

                    <div className={capitalLetterHintClasses}>
                      <span className="icon icon-tick">{iconTick}</span>
                      <p>At least 1 capital letter</p>
                    </div>

                    <div className={specialCharHintClasses}>
                      <span className="icon icon-tick">{iconTick}</span>
                      <p>At least 1 special character</p>
                    </div>
                  </div>

                  <br />

                  <Button
                    variant={'btn-fill'}
                    icon={iconArrowThin}
                    isDisabled={isSubmitDisabled}
                    type="submit"
                    onClick={formMethods.handleSubmit(onSubmitForm)}
                  >
                    Update
                  </Button>

                  {/* {error && error.message == 'The given data was invalid.' && (
                    <>
                      <div className="row errors">
                        <ErrorTags errors={error.errors} />
                      </div>
                      <br />
                    </>
                  )} */}
                </form>
              </FormProvider>
            </OnboardingFormTransition>
          </div>
        </div>
        {isMobile && <MemberWelcomeFooter />}
      </div>
    </div>
  );
}
