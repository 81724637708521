import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

export const getContent = state => state.contentReducer;

export const getContentItemById = id =>
  createSelector(
    getContent,
    content => {
      return !isEmpty(content) && !!content.contentData.data
        ? content.contentData.data.find(x => x.id == id)
        : undefined;
    }
  );
