import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getArticleContact } from '@/store/selectors/articles';
import { iconPhone, iconMobilePhone, iconArrowThin } from '@/images/icons';

const ArticleSidebarContact = () => {
  const contactItems = useSelector(getArticleContact);


  if (isEmpty(contactItems)) {
    return null;
  }

  return (
    <div className="policy-contact section">
      {contactItems.map((contactItem, i) => (
        <div className="contact-item" key={i}>
          <h4>{contactItem.contact_title}</h4>

          <p className="name">{contactItem.contact_name}</p>
          <p className="role">{contactItem.poition_title}</p>

          {contactItem.contact_phone_1 && (
            <p>
              <a href={`tel:+61${contactItem.contact_phone_1}`} className="phone">
                <span className="icon">{contactItem.contact_phone_2 !== null ? iconPhone : iconMobilePhone}</span>
                {contactItem.contact_phone_1}
              </a>
            </p>
          )}

          {contactItem.contact_phone_2 && (
            <p>
              <a href={`tel:+61${contactItem.contact_phone_2}`} className="mobile">
                <span className="icon">{iconMobilePhone}</span>
                {contactItem.contact_phone_2}
              </a>
            </p>
          )}

          {contactItem.contact_email && (
            <p className="email">
              <a href={`mailto:${contactItem.contact_email}`}>
                Email {contactItem.contact_name}{' '}
                <span className="icon">{iconArrowThin}</span>
              </a>
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ArticleSidebarContact;
