import { ANNOUNCEMENT } from '../constants';

export const requestAnnouncement = (payload) => {
  return {
    type: ANNOUNCEMENT.REQUEST_ANNOUNCEMENT,
    payload
  };
};

export const requestAnnouncementSuccess = (payload) => {
  return {
    type: ANNOUNCEMENT.REQUEST_ANNOUNCEMENT_SUCCESS,
    payload
  };
};

export const requestAnnouncementFailed = (payload) => {
  return {
    type: ANNOUNCEMENT.REQUEST_ANNOUNCEMENT_FAILED,
    payload
  };
};
