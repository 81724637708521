import axios from 'axios';
import { format, isDate } from 'date-fns';
import { isNumber } from '@annexe/isnumber';
import { omitBy, partialRight } from 'lodash';
import { fork, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { NEWS_ARTICLES } from '../constants';
import {
  requestNewsArticlesFirstPageFailed,
  requestNewsArticlesFirstPageSuccess,
  requestNewsArticlesNextPageFailed,
  requestNewsArticlesNextPageSuccess,
  requestNewsArticlesByIdFailed,
  requestNewsArticlesByIdSuccess,
  requestNewsArticlesBySlugFailed,
  requestNewsArticlesBySlugSuccess,
  requestNewsTopicsFiltersFailed,
  requestNewsTopicsFiltersSuccess,
  requestPolicyStageFiltersFailed,
  requestPolicyStageFiltersSuccess,
  requestRegulatorFiltersFailed,
  requestRegulatorFiltersSuccess,
  requestNewsArticleByBulletinFailed,
  requestNewsArticleByBulletinSuccess,
  submitFeedbackFailed,
  submitFeedbackSuccess
} from '../actions';
import { BASE_URL, API, PER_PAGE } from '@/config';
import { utcToLocal } from '@/utils';
import { getUserToken, selectNewsArticlesQueries } from '@/store/selectors';

const queryPayload = (queries, action) => {
  const formatDate = partialRight(format, 'yyyy-MM-dd');

  const dateStart = isDate(action.payload?.dateStart)
    ? formatDate(utcToLocal(action.payload.dateStart))
    : undefined;

  const dateEnd = isDate(action.payload?.dateEnd)
    ? formatDate(utcToLocal(action.payload.dateEnd))
    : undefined;

  const topics = action.payload.topics
    ? action.payload.topics.join(',')
    : queries.topics.join(',');

  const regulators = queries.regulators.join(',');

  const policy = action.payload.policy;

  const payload = omitBy(
    { ...queries, policy, topics, regulators, dateStart, dateEnd },
    (prop) => (isNumber(prop) ? false : !prop || !prop?.length)
  );

  return payload;
}

export function* getNewsArticlesFirtsPage(action) {
  const token = yield select(getUserToken);
  const queries = yield select(selectNewsArticlesQueries);
  const payload = queryPayload(queries, action);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
        order_direction: 'desc',
        ...payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestNewsArticlesFirstPageSuccess(response));
  } catch (err) {
    yield put(requestNewsArticlesFirstPageFailed(err));
  }
}

export function* watchGetNewsArticlesFirstPage() {
  yield takeLatest(NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE, getNewsArticlesFirtsPage);
}

export function* getNewsArticlesNextPage(action) {
  const token = yield select(getUserToken);
  const queries = yield select(selectNewsArticlesQueries);
  const payload = queryPayload(queries, action);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
        order_direction: 'desc',
        ...payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestNewsArticlesNextPageSuccess(response));
  } catch (err) {
    yield put(requestNewsArticlesNextPageFailed(err));
  }
}

export function* watchGetNewsArticlesNextPage() {
  yield takeLatest(NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE, getNewsArticlesNextPage);
}

export function* getNewsArticlesById(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}/${action.payload.id}`
    });

  try {
    const response = yield call(request);
    yield put(requestNewsArticlesByIdSuccess(response));
  } catch (err) {
    yield put(requestNewsArticlesByIdFailed(err));
  }
}

export function* watchGetNewsArticlesById() {
  yield takeEvery(NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID, getNewsArticlesById);
}

export function* getNewsArticlesByBulletin(action) {

    const token = yield select(getUserToken);
    const request = () =>
        axios({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            method: 'get',
            url: `${BASE_URL}${API[action.type]}/${action.payload.slug}`
        });

    try {
        const response = yield call(request);
        yield put(requestNewsArticleByBulletinSuccess(response));
    } catch (err) {
        yield put(requestNewsArticleByBulletinFailed(err));
    }
}

export function* watchRequestNewsArticleByBulletin() {
    yield takeLatest(NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID, getNewsArticlesByBulletin);
}

export function* getNewsArticlesBySlug(action) {
  const token = yield select(getUserToken);
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}/${action.payload.slug}`
    });

  try {
    const response = yield call(request);
    yield put(requestNewsArticlesBySlugSuccess(response));
  } catch (err) {
    yield put(requestNewsArticlesBySlugFailed(err));
  }
}

export function* watchGetNewsArticlesBySlug() {
  yield takeEvery(NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG, getNewsArticlesBySlug);
}

export function* getNewsTopicFilters(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: action.payload
    });

  try {
    const response = yield call(request);
    yield put(requestNewsTopicsFiltersSuccess(response));
  } catch (err) {
    yield put(requestNewsTopicsFiltersFailed(err));
  }
}

export function* watchGetNewsTopicFilters() {
  yield takeLatest(NEWS_ARTICLES.REQUEST_NEWS_TOPICS, getNewsTopicFilters);
}

export function* getRegulatorFilters(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: action.payload
    });

  try {
    const response = yield call(request);
    yield put(requestRegulatorFiltersSuccess(response));
  } catch (err) {
    yield put(requestRegulatorFiltersFailed(err));
  }
}

export function* watchGetRegulatorFilters() {
  yield takeLatest(NEWS_ARTICLES.REQUEST_REGULATORS, getRegulatorFilters);
}

export function* getPolicyStageFilters(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: action.payload
    });

  try {
    const response = yield call(request);
    yield put(requestPolicyStageFiltersSuccess(response));
  } catch (err) {
    yield put(requestPolicyStageFiltersFailed(err));
  }
}

export function* watchGetPolicyStageFilters() {
  yield takeLatest(NEWS_ARTICLES.REQUEST_POLICY_STAGE, getPolicyStageFilters);
}

export function* submitFeedback(action) {
  const token = yield select(getUserToken);
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      data: {
        feedback: action.payload['article-feedback'],
        news_article_id: action.payload.id
      }
    });

  try {
    const response = yield call(request);
    yield put(submitFeedbackSuccess(response));
  } catch (err) {
    yield put(submitFeedbackFailed(err));
  }
}

export function* watchSubmitFeedback() {
  yield takeLatest(NEWS_ARTICLES.SUBMIT_FEEDBACK, submitFeedback);
}

const sagas = [
  fork(watchGetNewsArticlesFirstPage),
  fork(watchGetNewsArticlesNextPage),
  fork(watchGetNewsArticlesById),
  fork(watchGetNewsArticlesBySlug),
  fork(watchGetNewsTopicFilters),
  fork(watchGetPolicyStageFilters),
  fork(watchGetRegulatorFilters),
  fork(watchSubmitFeedback),
  fork(watchSubmitFeedback),
  fork(watchRequestNewsArticleByBulletin)
];

export default sagas;
