import { call, put, takeEvery } from 'redux-saga/effects';
import { ARTICLES } from '../constants';
import { receivedArticleTypes, failedArticleTypes } from '../actions';
import rootDomain from '../rootDomain';
const api = (url) => fetch(url).then((res) => res.json());

export function* requestArticleTypesAsync() {
  const ROOT = rootDomain();
  const articleTypesUrl = `${ROOT}/api/v1/articles/types`;

  try {
    const response = yield call(api, articleTypesUrl);
    yield put(receivedArticleTypes(response));
  } catch (err) {
    yield put(failedArticleTypes(err));
  }
}

export default function* watchRequestArticleTypes() {
  yield takeEvery(ARTICLES.REQUEST_ARTICLE_TYPES, requestArticleTypesAsync);
}
