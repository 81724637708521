import React from 'react';
import { useController } from 'react-hook-form';
import FormField from './FormField';

export default function TextFieldWithIcon({
  id,
  placeholder,
  control,
  defaultValue,
  type,
  name,
  onClick,
  onChange,
  labelFor,
  labelText,
  validation,
  Icon
}) {
  const {
    field: { ref, ...inputProps },
    formState: { errors = {} }
  } = useController({
    name,
    control,
    rules: validation,
    defaultValue: defaultValue
  });

  return (
    <FormField labelFor={labelFor} labelText={labelText} name={name} errors={errors}>
      <div className="text-input-icon-wrapper">
        <input
          id={id}
          ref={ref}
          className="text-input"
          type={type}
          onClick={onClick}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          {...inputProps}
        />

        {!!Icon && <Icon />}
      </div>
    </FormField>
  );
}
