import { PAGES } from '../constants';

export const requestPagesList = (payload) => {
    return {
        type: PAGES.REQUEST_LIST,
        payload
    };
};

export const requestPagesListSuccess = (payload) => {
    return {
        type: PAGES.REQUEST_LIST_SUCCESS,
        payload
    };
};

export const requestPagesListFailed = (payload) => {
    return {
        type: PAGES.REQUEST_LIST_FAILED,
        payload
    };
};


export const requestPageById = (payload) => {
    return {
        type: PAGES.REQUEST_BY_ID,
        payload
    };
};

export const requestPageByIdSuccess = (payload) => {
    return {
        type: PAGES.REQUEST_BY_ID_SUCCESS,
        payload
    };
};

export const requestPageByIdFailed = (payload) => {
    return {
        type: PAGES.REQUEST_BY_ID_FAILED,
        payload
    };
};

export const requestPageBySlug = (payload) => {
    return {
        type: PAGES.REQUEST_BY_SLUG,
        payload
    };
};

export const requestPageBySlugSuccess = (payload) => {
    return {
        type: PAGES.REQUEST_BY_SLUG_SUCCESS,
        payload
    };
};

export const requestPageBySlugFailed = (payload) => {
    return {
        type: PAGES.REQUEST_BY_SLUG_FAILED,
        payload
    };
};
