import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getNewsArticlesSeekingFeedback } from '@/store/selectors';
import { isEmpty } from 'lodash';
import { Carousel } from '@/components';
import { NewsroomArticleCard } from '@/components/Portal/Card';
import { PORTAL_PATHS } from '@/config';

export default function SeekingFeedback() {
	const articles = useSelector(getNewsArticlesSeekingFeedback, shallowEqual);

	return isEmpty(articles) ? 
		null :
		<Carousel
			headerTitle='Seeking Feedback'
			headerLink={`${PORTAL_PATHS.NEWSROOM}`}
			items={articles}
			SlideItem={NewsroomArticleCard}
			itemsPerView={2}
			maxSlides={3}
		/>
}