import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { requestCoordinates, clearCoordinates } from '@/store/actions';
import DivisionsAndBranchesFilterForm from './DivisionsAndBranchesFilterForm';
import { FindaBankContext } from '@/context';

const DivisionFilter = () => {
  const { searchQuery, setSearchQuery, setRadiusQuery } =
    React.useContext(FindaBankContext);

  const actions = bindActionCreators(
    {
      clearCoordinates,
      requestCoordinates
    },
    useDispatch()
  );

  const radiusArray = [
    { value: 5, label: '5 km' },
    { value: 10, label: '10 km' },
    { value: 15, label: '15 km' },
    { value: 20, label: '20 km' },
    { value: 30, label: '30 km' },
    { value: 40, label: '40 km' },
    { value: 50, label: '50 km' }
  ];

  const handleSubmitLocation = (event) => {
    event.preventDefault();

    if (!!searchQuery) {
      actions.requestCoordinates(searchQuery);
    }
  };

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelect = (newValue) => {
    setRadiusQuery(newValue.value);
  };

  return (
    <div className="institutions-filters">
      <React.Fragment>
        <DivisionsAndBranchesFilterForm
          handleSubmitLocation={handleSubmitLocation}
          handleSearchQuery={handleSearchQuery}
        />

        <div className="radius">
          <label>RADIUS</label>
          <Select
            className="select"
            options={radiusArray}
            onChange={handleSelect}
            placeholder="Select radius"
            isSearchable={false}
            components={makeAnimated()}
            defaultValue={{ value: 20, label: '20 km' }}
          />
        </div>
      </React.Fragment>
    </div>
  );
};

export default DivisionFilter;
