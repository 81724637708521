import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { isEmpty } from 'lodash';
import SubscribeForm from '@/components/SubscribeForm';
import {
  iconTwitter,
  iconLinkedin,
  iconFacebook,
  iconYoutube,
  iconElipse,
  iconArrowThin
} from '@/images/icons';
// import { requestCustomerSupport } from '@/store/actions';
// import { getCustomerSupport } from '@/store/selectors/customerSupport';
import { PORTAL_PATHS } from '@/config';

const Footer = () => {
  // const dispatch = useDispatch();
  // const customerSupport = useSelector(getCustomerSupport);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // useEffect(() => {
  //   if (isEmpty(customerSupport)) {
  //     dispatch(requestCustomerSupport());
  //   }
  // }, []);

  return (
    <footer id="main-footer" className="main-footer" data-testid="main-footer">
      <div className="dark-background" />

      <div className="content-wrapper">
        <div className="container">
          {/* Customer support section commented due await client feedback if this section is still required */}

          {/* <div className="section customer-support">
              <h4>Customer support</h4>
              <ul>
                {!isEmpty(customerSupport) &&
                  customerSupport[0].support_data.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/${item.attributes.customer_support_url}`}>
                          {item.attributes.customer_support_header}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div> */}

          <div className="contact-us">
            <h4>Contact us</h4>
            <ul>
              <li>
                <Link to="/contact">Contact information</Link>
              </li>
              <li>
                <Link to="/contact">Got a complaint?</Link>
              </li>
            </ul>
          </div>

          <div className="socials">
            <h4>Find us</h4>
            <ul>
              <li>
                <a
                  href="https://twitter.com/CustomerOwnedBA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">{iconTwitter}</span>Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/customer-owned-banking-association"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">{iconLinkedin}</span>Linkedin
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/CustomerOwnedBanking"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">{iconFacebook}</span>Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/AbacusCOB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon">{iconYoutube}</span>YouTube
                </a>
              </li>
            </ul>
          </div>

          <div className="member-portal">
            <h4>Member portal</h4>
            <div className="content">
              <p>
                Our primary channel for information sharing & communicating with our
                members. Sign in below.
              </p>
            </div>
            <Link className="btn btn-secondary" to={PORTAL_PATHS.home} target="_blank">
              Sign in <span className="icon">{iconArrowThin}</span>
            </Link>
          </div>

          <section className="subscribe">
            <SubscribeForm heading="COBA Alerts" />
          </section>
        </div>
      </div>
      <section className="copyright">
        <div className="content-wrapper">
          <div>Copyright © 2009 – {currentYear} Customer Owned Banking Association</div>

          <div>
            <Link to="/privacy">Privacy</Link>
            <span className="icon">{iconElipse}</span>
            <Link to="/legal">Legal</Link>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
