import { SEARCH } from '../constants';

const initialState = {
  results: [],
  pageMeta: {},
  isLoading: false,
  error: undefined
};

const globalSearchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SEARCH.SEARCH_ALL_BY_TERM: {
      return { ...state, isLoading: true };
    }

    case SEARCH.SEARCH_ALL_BY_TERM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        results: action.payload.data.data
      };
    }

    case SEARCH.SEARCH_ALL_BY_TERM_FAILED: {
      return {
        ...initialState
      };
    }
  }

  return state;
};

export default globalSearchReducer;
