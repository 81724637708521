import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { PER_PAGE } from '@/config/';
import { TRAINING } from '../constants';
import { 
  requestTrainingCategoriesSuccess,
  requestTrainingCategoriesFailed,
	requestTrainingModuleByIdSuccess,
	requestTrainingModuleByIdFailed,
  requestTrainingModulesSuccess, 
  requestTrainingModulesFailed 
} from '../actions';
import axios from 'axios';
import { getUserToken } from '@/store/selectors';
import { BASE_URL, API } from '@/config';

export function* getTrainingCategories(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
      }
    });

  try {
    const response = yield call(request);
    yield put(requestTrainingCategoriesSuccess(response));
  } catch (err) {
    yield put(requestTrainingCategoriesFailed(err));
  }
}

export function* watchGetTrainingCategories() {
  yield takeLatest(TRAINING.REQUEST_TRAINING_CATEGORIES, getTrainingCategories);
}

export function* getTrainingModuleByID(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}/${action.payload.id}`,
    });

  try {
    const response = yield call(request);
    yield put(requestTrainingModuleByIdSuccess(response));
  } catch (err) {
    yield put(requestTrainingModuleByIdFailed(err));
  }
}

export function* watchGetTrainingModuleByID() {
  yield takeLatest(TRAINING.REQUEST_TRAINING_MODULE_BY_ID, getTrainingModuleByID);
}

export function* getTrainingModules(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
        order_by: 'name',
        order_direction: 'asc',
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestTrainingModulesSuccess(response));
  } catch (err) {
    yield put(requestTrainingModulesFailed(err));
  }
}

export function* watchGetTrainingModules() {
  yield takeLatest(TRAINING.REQUEST_TRAINING_MODULES, getTrainingModules);
}

const sagas = [
  fork(watchGetTrainingCategories),
	fork(watchGetTrainingModuleByID),
  fork(watchGetTrainingModules)
];

export default sagas;
