import React from 'react';
import { PortalPageMenuItem } from '@/components/Portal';
import { ActionCard } from '@/components/Portal/Card';

export default function RelatedDownloads({article}) {
	const relatedDownloads = article?.related_downloads_data || [];

	if (!relatedDownloads.length) {
		return null;
	}

  return (
		<PortalPageMenuItem title='Related Downloads'>
			<div className="menu-card-wrapper">
				{relatedDownloads.map((item) => {					
					return (
						<ActionCard
							key={item.file}
							title={item.download_title}
							action='download' 
							value={item.related_download.size}
							link={item.related_download.url}
						/>
					)
				})}
			</div>
		</PortalPageMenuItem>
  )
}
