import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash'
import { Link, useLocation } from 'react-router-dom';

CollapsibleMenuOptions.propTypes = {
  items: PropTypes.array,
  isParentActive: PropTypes.bool
}

export default function CollapsibleMenuOptions({ items, isParentActive }) {
  const { pathname } = useLocation();

	return isEmpty(items)
	? null
	: <div className="content">
      <ul>
        {items.map((item, i) => {
					const isActive = pathname === item.url && isParentActive
					return (
						<li
							className={isActive ? 'content-link active' : 'content-link'}
							key={`content-item-${item.title}-${i}`}
						>
							<Link to={item.url}>{item.title}</Link>
						</li>
					);
          })}
      </ul>
    </div>
}
