import { Navbar, Preferences } from '@/components/Portal';
import { requestFooter } from '@/store/actions';
import { getUser } from '@/store/selectors';
import { selectFooterContent } from '@/store/selectors/footer';
import publicWebsiteUrlByPath, { getCurrentYear } from '@/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PortalFooterNavCol from './PortalFooterNavCol';

const PortalFooter = () => {
  const user = useSelector(getUser);
  const user_is_training_only = user.training_only;
  const currentYear = getCurrentYear();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestFooter());
  }, []);
  const footerContent = useSelector(selectFooterContent);
  const footerColData = footerContent?.content_blocks
    ? footerContent?.content_blocks[0]?.attributes.content_blocks
    : [];
  return (
    <footer className="footer portal-footer">
      {!user_is_training_only && (
        <div className="content-wrapper">
          <div className="row">
            {footerColData?.map((item) => (
              <div className="col-xs-12 col-md-3" key={item.key}>
                <PortalFooterNavCol
                  navCategoryName={item.attributes.heading}
                  links={item.attributes?.content_blocks.map((link) => {
                    return {
                      name: link.attributes.heading,
                      link: link.attributes.page,
                      is_external: link.attributes.is_external
                    };
                  })}
                />
              </div>
            ))}
            <div className="col-xs-12 col-md-3">
              <Preferences
                title="Update Your Preferences"
                text="You can manage your subscription preferences in account settings."
                btnText="Update preferences"
              />
            </div>
          </div>
        </div>
      )}

      <Navbar
        variant="navbar-bottom navbar-dot-nav"
        attribution={`Copyright © 2009 – ${currentYear} Customer Owned Banking Association`}
        links={[
          {
            url: publicWebsiteUrlByPath('/privacy'),
            text: 'Privacy',
            isExternal: true
          },
          {
            url: publicWebsiteUrlByPath('/legal'),
            text: 'Legal',
            isExternal: true
          }
        ]}
      />
    </footer>
  );
};

export default PortalFooter;
