import { chunk } from 'lodash';
import { createSelector } from 'reselect';

export const selectMemberInstitutions = (state) =>
  state.memberInstitutionsReducer.memberInstitutions;

export const selectMemberInstitutionsDict = createSelector(
  selectMemberInstitutions,
  (memberInstitutions) =>
    memberInstitutions.reduce((a, c) => {
      a[c.id] = { ...c };
      return a;
    }, {})
);

export const selectPaginatedInstitutions = (currentPage) =>
  createSelector(selectMemberInstitutions, (institutions) => {
    const ITEMS_PER_PAGE = 16;
    const trimStart = (currentPage - 1) * ITEMS_PER_PAGE;
    const trimEnd = trimStart + ITEMS_PER_PAGE;
    const institutionsByPage = institutions.slice(trimStart, trimEnd);

    return institutionsByPage.reduce((a, c) => {
      a[c.id] = { ...c };
      return a;
    }, {});
  });

export const selectTotalInstitutions = createSelector(
  selectMemberInstitutions,
  (institutions) => institutions?.length || 0
);

export const selectAllOffers = (state) => state.memberInstitutionsReducer.offers;

export const selectDivisionsBanks = (state) =>
  state.memberInstitutionsReducer.divisionsBanks;

export const selectDivisionsBanksPageMeta = (state) =>
  state.memberInstitutionsReducer.divisionsBanksPageMeta;

export const selectDivisionBanksDesc = createSelector(
  selectDivisionsBanks,
  (divisionBanks) => divisionBanks.sort((a, b) => a.name.localeCompare(b.name))
);

export const selectDivisionBankChunks = createSelector(
  selectDivisionBanksDesc,
  (divisionBanks) => chunk(divisionBanks, 16)
);

export const selectPaginatedDivisionBanks = (currentPage) =>
  createSelector(selectDivisionBankChunks, (divisionBanks) => {
    const page = currentPage >= 1 ? currentPage - 1 : currentPage;
    return divisionBanks[page];
  });

export const selectPaginatedDivisionBanksPageMeta = createSelector(
  selectDivisionBankChunks,
  selectDivisionsBanksPageMeta,
  (divisionBanks, pageMeta) => ({
    ...pageMeta,
    last_page: divisionBanks.length
  })
);

export const selectDivisionsBanksDict = createSelector(
  selectDivisionsBanks,
  (divisions) =>
    divisions.reduce((a, c) => {
      a[c.id] = { ...c };
      return a;
    }, {})
);

export const selectBranchesNearMe = (state) =>
  state.memberInstitutionsReducer.branchesNearMe;

export const selectBranchesNearMeResults = createSelector(
  selectDivisionsBanksDict,
  selectBranchesNearMe,
  (divisionsBanksDict, branchesNearMe) =>
    branchesNearMe.reduce((a, c) => {
      const additionalInfo = divisionsBanksDict[c.bank_id];

      return !!additionalInfo
        ? [
            ...a,
            {
              name: additionalInfo?.name,
              blurb: additionalInfo?.blurb,
              url: additionalInfo?.url,
              offers: additionalInfo?.offers,
              logo_data: additionalInfo?.logo_data,
              ...c
            }
          ]
        : [...a, c];
    }, [])
);

export const selectBranchesNearMePageMeta = (state) =>
  state.memberInstitutionsReducer.branchesNearMePageMeta;

export const selectBranchNameByBankId = (bankId) =>
  createSelector(
    selectDivisionsBanksDict,
    (divisionBanks) => divisionBanks?.[bankId]?.name
  );
