import PropTypes from 'prop-types';
import React from 'react';
import {
  iconPortalPhone,
  iconPortalMobile,
  iconPortalMail,
} from '@/images/icons';
import MiniLogo from '@/components/MiniLogo';

StaffCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  notification_message: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  mobile: PropTypes.string,
  image_text: PropTypes.string,
	image_url: PropTypes.string,
}

export default function StaffCard({ 
  name, 
  title, 
  notification_message, 
  email, 
  phone, 
  mobile, 
  image_text,
	image_url
}) {  
  return <div 
    className="staff-card"
  >
    <div className="card-wrapper">
      <div className="staff-image">
        {
        image_url ? 
        <img src={image_url} alt={image_text ? image_text : 'Staff Image'} /> : 
        <div className='placeholder'> <MiniLogo /> </div>
        }
      </div>
      <div className="content">
        <div className="details">
          <h4>{name}</h4>
          <h5 className="position">{title}</h5>
          { notification_message && <p className="note">{notification_message}</p> }
        </div>
        <div className="contact">
          { email && 
            <a href={`mailto:${email}`}>
              <span className="icon">{iconPortalMail}</span>
              <span className="truncate"><abbr title={email}>{email}</abbr></span>
            </a>
          }
          { phone && 
            <a href={`tel:${phone}`}>
              <span className="icon">{iconPortalPhone}</span>
              {phone}
            </a>
          }
          { mobile && 
            <a href={`tel:${mobile}`}>
              <span className="icon">{iconPortalMobile}</span>
              {mobile}
            </a>
          }
        </div>
      </div>
    </div>
  </div>;
}
