import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { isNumber } from '@annexe/isnumber';
import { isEmpty } from 'lodash';
import { capitalize } from 'humanize-plus';
import Layout from '@/components/Layout';
import { MediaList, MediaReleasesSidebar } from '@/components/MediaRelease';
import SEO from '@/components/SEO';
import {
  MediaListSearchForm,
  MediaReleaseYearFilter,
  MediaReleaseNoFilteredResults,
  MediaReleaseListDisplayHeading,
  MediaReleaseFeaturedItem,
  MediaReleaseHeroSection
} from '@/components/MediaRelease';
import { requestArticlesByCategoryType, requestArticleTypes } from '@/store/actions';
import {
  getArticles,
  getFirstFeaturedArticle,
  getArticlesByQuery,
  getArticlesByYear,
  getArticlesByQueryAndYear,
  getArticleTypes
} from '@/store/selectors';

const MediaIndexPage = () => {
  const [articleListing, setArticleListing] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedYear, setSelectedYear] = React.useState(undefined);
  const [activeYearId, setActiveYearId] = React.useState(0);

  const route = useLocation();
  const location = route.pathname;
  const MEDIA_RELEASE_TYPE_FROM_URL_REGEX = /([^\/]+)\/?$/;
  const category = location.match(MEDIA_RELEASE_TYPE_FROM_URL_REGEX)[0];
  const categoryName = capitalize(category.replace(/-/g, ' '));

  const articles = useSelector(getArticles);
  const featuredArticle = useSelector(getFirstFeaturedArticle);
  const filteredArticlesBySearchQuery = useSelector(getArticlesByQuery(searchQuery));
  const filteredArticlesByYear = useSelector(getArticlesByYear(selectedYear));
  const filteredArticlesByYearAndQuery = useSelector(
    getArticlesByQueryAndYear(searchQuery, selectedYear)
  );
  const articleTypes = useSelector(getArticleTypes);

  const actions = bindActionCreators(
    { requestArticlesByCategoryType, requestArticleTypes },
    useDispatch()
  );

  const handleUpdateSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectYear = (year, index) => () => {
    setActiveYearId(index);
    setSelectedYear(year);
  };

  React.useEffect(() => {
    actions.requestArticleTypes();
  }, []);

  React.useEffect(() => {
    if (!!category && articleTypes !== undefined) {
      const map = articleTypes.find(
        (e) => e.name.toLowerCase().replaceAll(' ', '-') === category
      ).id;

      actions.requestArticlesByCategoryType({
        categoryId: map
      });
    }
  }, [location, articleTypes]);

  React.useEffect(() => {
    setArticleListing(articles);
  }, [articles]);

  React.useEffect(() => {
    if (isNumber(selectedYear) && !searchQuery) {
      setArticleListing(filteredArticlesByYear);
    }

    if (!!searchQuery && !isNumber(selectedYear)) {
      setArticleListing(filteredArticlesBySearchQuery);
    }

    if (!!searchQuery && isNumber(selectedYear)) {
      setArticleListing(filteredArticlesByYearAndQuery);
    }
  }, [selectedYear, searchQuery]);

  return (
    <Layout>
      <SEO
        title="COBA Media | Customer Owned Banking Association | Australia"
        keywords="articles, blog posts, media releases, reports, policy submissions, campaign outcomes, updates, customer owned banking association, coba, Australia"
      />
      <div id="media-index-page">
        <MediaReleaseHeroSection categoryName={categoryName} />

        <div className="media-content-wrapper content-wrapper">
          <MediaReleasesSidebar mediaData={{}} />

          <div className="media-content">
            <MediaReleaseYearFilter
              selectedYear={selectedYear}
              handleSelectYear={handleSelectYear}
            />

            <MediaListSearchForm handleUpdateSearchQuery={handleUpdateSearchQuery} />

            {!!articleListing?.length && (
              <div className="featured-release">
                <MediaReleaseListDisplayHeading
                  totalArticles={articleListing.length}
                  activeYearId={activeYearId}
                  searchQuery={searchQuery}
                />

                {!isEmpty(featuredArticle) && !searchQuery && !selectedYear && (
                  <MediaReleaseFeaturedItem article={featuredArticle} />
                )}
              </div>
            )}

            {!!articleListing?.length && <MediaList mediaReleases={articleListing} />}

            {!articleListing?.length && (
              <MediaReleaseNoFilteredResults searchQuery={searchQuery} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MediaIndexPage;
