import { FOOTER } from '../constants';

//initial state
const initialState = {
  isLoading: false,
  footerData: {}
};

//Reducer
const footerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FOOTER.REQUEST_FOOTER:
      return { ...state, isLoading: true };

    case FOOTER.REQUEST_FOOTER_SUCCESS:
      return { ...state, isLoading: false, footerData: action.payload?.data?.data };

    case FOOTER.REQUEST_FOOTER_FAILED:
      return { ...state, isLoading: false, footerData: action.payload };
  }

  return state;
};

export default footerReducer;
