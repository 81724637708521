import { CUSTOMER_SUPPORT } from '../constants';

const initialState = {
  isLoading: false,
  customerSupportData: {}
};

const customerSupportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CUSTOMER_SUPPORT.REQUEST_CUSTOMER_SUPPORT:
      return {
        ...state,
        isLoading: true
      };

    case CUSTOMER_SUPPORT.RECEIVED_CUSTOMER_SUPPORT:
      return {
        ...state,
        isLoading: false,
        customerSupportData: action.payload
      };

    case CUSTOMER_SUPPORT.FAILED_CUSTOMER_SUPPORT:
      return {
        ...state,
        isLoading: false,
        customerSupportData: action.payload
      };
  }

  return state;
};

export default customerSupportReducer;
