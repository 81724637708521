import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestEventsPastYears } from '@/store/actions';
import { getUserToken } from '@/store/selectors';
import { selectEventsFiterOptions } from '@/selectors/events';
import { PortalPageMenuNavigation, CollapsibleMenuOptions } from '@/components/Portal';
import { isEmpty } from 'lodash';
import { PORTAL_PATHS, PUBLIC_PATHS } from '@/config';
import { getYear } from 'date-fns';

EventsMenu.propTypes = {
  mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function EventsMenu({ mobile, desktop }) {
  const dispatch = useDispatch();
  const { years } = useSelector(selectEventsFiterOptions);
  const userToken = useSelector(getUserToken);

  useEffect(() => {
    isEmpty(years) && dispatch(requestEventsPastYears());
  }, []);

  const upComingEventsMenuItem = {
    title: 'Upcoming',
    url: !userToken
      ? `${PUBLIC_PATHS.EVENTS}/upcoming`
      : `${PORTAL_PATHS.EVENTS}/upcoming`
  };

  const pastEventsMenuItem = {
    title: 'Past Events',
    url: !userToken ? `${PUBLIC_PATHS.EVENTS}/past` : `${PORTAL_PATHS.EVENTS}/past`,
    children: years
      .filter((year) => year <= getYear(new Date()))
      .map((year) => ({
        url: !userToken
          ? `${PUBLIC_PATHS.EVENTS}/past/${year}`
          : `${PORTAL_PATHS.EVENTS}/past/${year}`,
        title: `${year} Events`
      })),
    Content: CollapsibleMenuOptions
  };
  const menuItems = !!years?.length
    ? [upComingEventsMenuItem, pastEventsMenuItem]
    : [upComingEventsMenuItem];

  return (
    <PortalPageMenuNavigation
      mobile={mobile}
      desktop={desktop}
      items={menuItems}
      title="Events"
    />
  );
}
