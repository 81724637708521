import React from 'react';
import { iconArrowThin } from '@/images/icons';

const ArticleCallout = props => {
  const { calloutData } = props;
  const keyIssueCallout = calloutData && JSON.parse(calloutData);

  return (
    calloutData &&
    keyIssueCallout.map((issue, index) => {
      return (
        <div className="article-callout" key={index}>
          <h5>{issue.attributes.callout_key_issues_title}</h5>
          <div
            className="h4"
            dangerouslySetInnerHTML={{
              __html: issue.attributes.callout_key_issues_body
            }}
          />
          <a
            href={`${issue.attributes.callout_key_issues_link}`}
            className="btn btn-primary"
          >
            {issue.attributes.callout_key_issues_link_copy}
            {iconArrowThin}
          </a>
        </div>
      );
    })
  );
};

export default ArticleCallout;
