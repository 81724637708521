import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React from 'react';
import Moment from 'react-moment';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { iconArrowThinLongRight } from '@/images/icons';
import { getContentItemById } from '@/store/selectors/content';

const ArticleCard = ({ 
  imageId, 
  articleUrl,
  size = 'small',
  cardType = '', 
  date, 
  title
}) => {
  const imageItem = useSelector(getContentItemById(imageId));

  return (
    title && (
      <div
        className={`article-card${size === 'large' ? ' large' : ''}${isEmpty(imageItem) ? ' has-no-img' : ''}`}
        data-testid="article-card"
      >
        {!isEmpty(imageItem) && (
          <div className="card__image">
            <img src={imageItem.url} alt={imageItem.title} />
          </div>
        )}

        <div className={`card__title ${isEmpty(imageItem) ? 'white' : cardType}`}>
          {articleUrl && (
            <Link to={articleUrl}>
              <div className="article__link-containter">
                <span className="article__title-containter">
                  <div className="article__date">
                    <Moment format="D MMMM YYYY">{date && date}</Moment>
                  </div>
                  <div
                    className="article__title"
                    dangerouslySetInnerHTML={{ __html: title && title }}
                  />
                </span>
                <span className="icon">{iconArrowThinLongRight}</span>
              </div>
            </Link>
          )}
        </div>
      </div>
    )
  );
};

ArticleCard.propTypes = {
    imageId: PropTypes.number,
    articleUrl: PropTypes.string,
    cardType: PropTypes.oneOf(['white']),
    date: PropTypes.string,
    title: PropTypes.string
  }

export default ArticleCard;
