import { MEMBER_INSTITUTIONS } from '../constants';

const requestMembersInstitutions = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS,
  payload
});

const requestMembersInstitutionsSuccess = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS_SUCCESS,
  payload
});

const requestMembersInstitutionsFailed = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS_FAILED,
  payload
});

const requestDivisionsBanks = ({ page, per_page = 16, order_by = 'desc' }) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS,
  payload: {
    per_page,
    page,
    order_by
  }
});

const requestDivisionsBanksSuccess = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS_SUCCESS,
  payload
});

const requestDivisionsBanksFailed = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS_FAILED,
  payload
});

const requestBranchesNearMe = ({ lat, long, radius, page, per_page, order_by }) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME,
  payload: {
    page,
    per_page,
    order_by,
    lat,
    long,
    radius
  }
});

const requestBranchesNearMeSuccess = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME_SUCCESS,
  payload
});

const requestBranchesNearMeFailed = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME_FAILED,
  payload
});

const clearBranchesNearMe = () => ({
  type: MEMBER_INSTITUTIONS.CLEAR_BRANCHES_NEAR_ME
});

const requestAllOffers = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS,
  payload
});

const requestAllOffersSuccess = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS_SUCCESS,
  payload
});

const requestAllOffersFailed = (payload) => ({
  type: MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS_FAILED,
  payload
});

export {
  requestMembersInstitutions,
  requestMembersInstitutionsSuccess,
  requestMembersInstitutionsFailed,
  requestDivisionsBanks,
  requestDivisionsBanksSuccess,
  requestDivisionsBanksFailed,
  requestBranchesNearMe,
  requestBranchesNearMeSuccess,
  requestBranchesNearMeFailed,
  clearBranchesNearMe,
  requestAllOffers,
  requestAllOffersSuccess,
  requestAllOffersFailed
};
