import { ANALYTIC_EVENTS } from '../constants'

/**
 * 
 * @param {Object} payload 
 * @param {string} payload.url
 * @param {string | null} payload.referrer
 * @param {string} payload.deviceName
 * @param {'page_view' | 'download' | 'search'} payload.eventType
 * @param {Object | undefined} payload.eventProperties
 * @returns 
 */
export const trackAnalyticEvent = (payload) => ({
  type: ANALYTIC_EVENTS.TRACK_EVENT,
  payload
})