import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, TextField, Checkbox } from '@/components';
import { iconArrowThin } from '@/images/icons';
import OnboardingFormTransition from './OnboardingFormTransition';
import CreateAccountFormHeader from './CreateAccountFormHeader';
import { submitStep } from '@/store/actions';
import { getCreateAccountForm } from '@/store/selectors/createAccountForm';
import { validationRegex } from '@/utils';
import { PORTAL_PATHS } from '@/config';

export function CreateAccountFormStep1() {
  const dispatch = useDispatch();
  const history = useHistory();
  const formData = useSelector(getCreateAccountForm);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onSubmitForm = (data) => {
    dispatch(submitStep(data, 1));
    history.push(PORTAL_PATHS.onboarding.createAccount.step(2));
  };

  return (
    <OnboardingFormTransition formPath={PORTAL_PATHS.onboarding.createAccount.index}>
      <CreateAccountFormHeader stepIndex={1} formName="Contact Details" />

      <div className="create-account-form step-1">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmitForm)} noValidate>
            <TextField
              id="member-first-name"
              name="firstName"
              labelFor="First Name"
              labelText="First Name"
              placeholder="First Name"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              defaultValue={formData.firstName}
              control={form.control}
            />

            <TextField
              id="member-last-name"
              name="lastName"
              labelFor="Last Name"
              labelText="Last Name"
              placeholder="Last Name"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              defaultValue={formData.lastName}
              control={form.control}
            />

            <TextField
              id="member-tel"
              type="tel"
              name="tel"
              labelFor="Phone Number"
              labelText="Phone Number"
              placeholder="Phone Number"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.ausPhoneNumber,
                  message: 'Please enter a valid phone number.'
                }
              }}
              defaultValue={formData.tel}
              control={form.control}
            />

            <Checkbox
              id="checkbox-subscribe-bulletin"
              name="subscribeWeeklyBulletins"
              value={formData.subscribeWeeklyBulletins}
              defaultChecked={formData.subscribeWeeklyBulletins}
            >
              Subscribe to the Weekly Bulletin Email
            </Checkbox>

            <br />

            <Button
              variant={'btn btn-fill btn-full-width'}
              icon={iconArrowThin}
              type="submit"
            >
              Next
            </Button>
          </form>
        </FormProvider>
      </div>
    </OnboardingFormTransition>
  );
}

export default React.memo(CreateAccountFormStep1);
