import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import Label from './Label';
import { fieldHasError } from '@/utils';

export default function FormField({ name, labelFor, labelText, children, errors }) {
  return (
    <div className={`form-field ${fieldHasError(name, errors)}`}>
      <Label labelFor={labelFor} labelText={labelText} />

      {children}

      <ErrorMessage
        errors={!!errors ? errors : {}}
        name={name}
        render={({ message }) => <div className="text-field-error">{message}</div>}
      />
    </div>
  );
}
