import React from 'react';
import { useParams } from 'react-router-dom';

const ArticleBreadcrumbs = () => {
  const { category, articleTitle } = useParams();

  const newsAndResourcesCategories = [
    'sector-news',
    'customer-stories',
    'banking-tips',
    'campaigns'
  ];

  const isNewsResources = newsAndResourcesCategories.includes(category);

  const createBreadcrumbs = (category, articleTitle) => {
    let breadcrumb = 'Home / ';

    if (isNewsResources) {
      breadcrumb += 'news-and-resources / ';
    }

    if (!!category) {
      breadcrumb += `${category} / `;
    }

    if (!!articleTitle) {
      breadcrumb += articleTitle;
    }

    return breadcrumb;
  };

  const breadcrumbs = createBreadcrumbs(category, articleTitle);

  return (
    <div className="breadcrumbs">
      <p>{breadcrumbs}</p>
    </div>
  );
};

export default ArticleBreadcrumbs;
