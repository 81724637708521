import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import big_logo from '@/images/big_logo.png';

const SEO = ({ title, keywords, description, image, article }) => {
  const { pathname } = useLocation();

  const defaultTitle = 'Customer Owned Banking Association | COBA';
  const defaultDescription =
    'Customer Owned Banking Association is the industry advocate for Australia’s customer owned banking sector, comprising credit unions, building societies and mutual banks.';
  const siteUrl = 'https://www.customerownedbanking.asn.au/';
  const defaultImage = big_logo;
  const twitterUsername = '@CustomerOwnedBA';
  const defaultKeywords =
    'customer owned banking association, coba, australian mutuals, credit unions, mutual building societies, mutual banks, friendly societies, financial services, australia banking';

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || defaultKeywords,
  };

  return (
    <Helmet title={seo.title}>
      <meta name="keywords" content={seo.keywords} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="url" content={seo.url} />
      <meta property="og:keywords" content={seo.keywords} />
      {!!article && <meta property="og:type" content="article" />}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:keywords" content={seo.keywords} />
    </Helmet>
  );
};

export default SEO;
