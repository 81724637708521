import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { selectEventsFlattened } from '@/store/selectors';
import { Carousel } from '@/components';
import { EventCard } from '@/components/Portal/Card';
import { PORTAL_PATHS } from '@/config';

export default function Events() {
  const events = useSelector(selectEventsFlattened, shallowEqual);

  return isEmpty(events) ? null : (
    <Carousel
      headerTitle="Events"
      headerLink={`${PORTAL_PATHS.EVENTS}`}
      items={events}
      SlideItem={EventCard}
      itemsPerView={2}
      maxSlides={3}
    />
  );
}
