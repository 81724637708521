import { HEADER } from '../constants';

export const requestHeader = (payload) => {
  return {
    type: HEADER.REQUEST_HEADER,
    payload
  };
};

export const requestHeaderSuccess = (payload) => {
  return {
    type: HEADER.REQUEST_HEADER_SUCCESS,
    payload
  };
};

export const requestHeaderFailed = (payload) => {
  return {
    type: HEADER.REQUEST_HEADER_FAILED,
    payload
  };
};
