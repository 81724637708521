import React from 'react'

const Description = ({children}) => {
	return !children
	? null
	: <div
		className="description"
		dangerouslySetInnerHTML={{ __html: children }}
	/>
}

export default Description