import React from 'react';
import { iconLike, iconDislike } from '@/images/icons';

/**
 * @typedef {Object} Props
 * @property {function} onRate - The function to be called when a rating is selected.
 */

/**
 * RatingLikeDislike component.
 * @param {Props} props - The props for the component.
 */
function RatingStar(props) {
  const { onRate } = props;

  const handleRate = (/** @type {string} */ value) => {
    onRate(value); // Send back the selected value
  };

  return (
    <>
      <div className="justify-start items-start gap-3 flex">
        <div
          className="p-2.5 bg-zinc-300 rounded-lg justify-start items-start gap-2.5 flex cursor-pointer"
          onClick={() => handleRate('like')}
        >
          <div className="w-6 h-6 relative">{iconLike}</div>
        </div>
        <div
          className="p-2.5 bg-zinc-300 rounded-lg justify-start items-start gap-2.5 flex cursor-pointer"
          onClick={() => handleRate('dislike')}
        >
          <div className="w-6 h-6 relative">{iconDislike}</div>
        </div>
      </div>
    </>
  );
}

export default RatingStar;
