import { createSelector } from 'reselect';
import { getContent } from './content';

export const getHomePageKeyMetrics = (state) => state.homepageReducer.keyMetrics;
export const getHomePageHeader = (state) => state.homepageReducer.header;
export const getHomePageTestimonialData = (state) => state.homepageReducer.testimonials;

export const getTestimonials = createSelector(
  getHomePageTestimonialData,
  (state) => state.testimonial
);

export const getTestimonialsWithAvatar = createSelector(
  getTestimonials,
  getContent,
  (testimonials = [], content = []) => {
    return testimonials.reduce((acc, testimonial) => {
      const imageId = testimonial.attributes.testimonial_image;
      const imageContent = content?.contentData?.data || [];
      const image = imageContent.filter((item) => item.id == imageId)[0];

      if (!!image) {
        const attributes = {
          ...testimonial.attributes,
          avatar_image_id: image.id,
          avatar_image_url: image.url,
          avatar_image_title: image.title
        };

        return [...acc, attributes];
      }

      return acc;
    }, []);
  }
);

export const getTotalTestimonials = createSelector(getTestimonials, (testimonials) =>
  Array.isArray(testimonials) && testimonials.length ? testimonials.length : 0
);

export const getTestimonialSectionHeading = createSelector(
  getHomePageTestimonialData,
  (state) => state.testimonial_heading
);