import React from 'react';
import Layout from '@/components/Layout';
import {
  DivisionHero,
  DivisionFilter,
  DivisionsAndBranchesList
} from '@/components/FindABank';
import SEO from '@/components/SEO';
import { Helmet } from 'react-helmet';
import { FindaBankContext } from '@/context';

const FindaBankPage = () => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [radiusQuery, setRadiusQuery] = React.useState(20);

  return (
    <FindaBankContext.Provider
      value={{
        radiusQuery,
        setRadiusQuery,
        searchQuery,
        setSearchQuery
      }}
    >
    <Helmet>
        <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NQPLXSN');`}
        </script>
    </Helmet>
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQPLXSN"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <Layout>
        <SEO
          title="Find a bank | Customer Owned Banking Association | Australia"
          description="There over 60 different banking institutions with hundreds of branches available throughout Australia. Find a bank that is closest to you with services that meet your needs."
        />
        <div id="find-a-bank">
          <DivisionHero />

          <section className="page-content content-wrapper">
            <DivisionFilter />
            <DivisionsAndBranchesList />
          </section>
        </div>
      </Layout>
    </FindaBankContext.Provider>
  );
};

export default FindaBankPage;
