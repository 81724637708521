import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Searchbar } from '@/components/Portal';
import { searchEvents, clearSearchQuery } from '@/store/actions';
import { bindActionCreators } from 'redux';
import { selectEventIsSearching, selectEventSearchQueries } from '@/store/selectors';

export default function Search() {
  const actions = bindActionCreators({ searchEvents, clearSearchQuery }, useDispatch());
  const isSearching = useSelector(selectEventIsSearching, shallowEqual);

  const payload = useSelector(selectEventSearchQueries, shallowEqual);

  const searchbarActions = {
    request: {
      dispatch: actions.searchEvents,
      searchParamKey: 'search',
      payload
    },
    clear: {
      dispatch: actions.searchEvents,
      payload: {
        ...payload,
        search: ''
      }
    }
  };

  return (
    <div className="Search">
      <div className="portal-page-search">
        <Searchbar
          defaultValue={payload.search || ''}
          searchFieldName="event-search"
          actions={searchbarActions}
          isSearching={isSearching}
        />
      </div>
    </div>
  );
}
