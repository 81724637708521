import React from 'react';
import { iconPortalPhone, iconPortalLocation } from '@/images/icons';

const ContactItems = () => {
    const contactList = [
        {
            icon: iconPortalLocation,
            subtitle: 'ABN',
            information: <p>ABN 98 137 780 897</p>
        },
        {
            icon: iconPortalLocation,
            subtitle: 'Address',
            information: (
                <p>
                    Suite 403, Level 4<br />
                    151 Castlereagh Street
                    <br />
                    Sydney NSW 2000
                </p>
            )
        },
        {
            icon: iconPortalLocation,
            subtitle: 'Address',
            information: (
                <p>
                    GPO Box 4686 <br /> Sydney NSW 2001
                </p>
            )
        },
        {
            icon: iconPortalPhone,
            subtitle: 'Phone',
            information: <a href="tel:+61 02 8035 8400">02 8035 8400</a>
        }
    ];

    return (
        <div className='contact-info'>
            <legend className="legend H3">Contact Details</legend>
            <div className="contact-list">
                {contactList.map((contact, index) => {
                    return (
                        <div className="contact-details" key={index}>
                            <div className="icon-outline">
                                {contact.icon && <span>{contact.icon}</span>}
                            </div>
                            <div className="details">
                                <div className="text-meta subtitle">{contact.subtitle}</div>
                                <div className="information">{contact.information}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ContactItems;
