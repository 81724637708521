import React from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  if (process.env.GA_KEY) {
    ReactGA.initialize(process.env.GA_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <div id="main-wrapper" data-testid="layout">
      <Helmet>
        <script type="text/javascript">
            _linkedin_partner_id = "4436105";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        </script>
        <script type="text/javascript">{`
            (function(l) {
                if (!l) {
                    window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                    window.lintrk.q=[]
                }
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);})(window.lintrk);
        `}</script>
      </Helmet>
      <Header />
      <main className="page-container" data-testid="page-container">
        {children}
      </main>
      <Footer />
      <noscript>
        <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4436105&fmt=gif" />
      </noscript>
    </div>
  );
};

export default Layout;
