import { Carousel } from '@/components';
import { FeedCard } from '@/components/Portal/Card';
import { PORTAL_PATHS } from '@/config';
import { selectFeeds } from '@/store/selectors';
import { isEmpty } from 'lodash';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

export default function CurrentHeadlines() {
  const { items } = useSelector(selectFeeds, shallowEqual);

  return isEmpty(items) ? null : (
    <Carousel
      headerTitle="Current Headlines"
      headerLink={`${PORTAL_PATHS.FEEDS}`}
      items={items}
      SlideItem={FeedCard}
      itemsPerView={2}
      maxSlides={Math.ceil(items.length / 2)}
    />
  );
}
