import { createSelector } from 'reselect';

const stateEmailSubscriptions = (state) => state.emailSubscriptionsReducer;

const selectStatus = createSelector(
  stateEmailSubscriptions,
  (state) => ({
    errors: state.errors,
    hasFailed: state.hasFailed,
    isLoading: state.isLoading,
    isUpdating: state.isUpdating,
    isSuccessful: state.isSuccessful,
  })
);

const selectSubscribedCampaigns = createSelector(
  stateEmailSubscriptions,
  (state) => state.subscribedCampaigns
);

const selectAvaliableCampaigns = createSelector(
  stateEmailSubscriptions,
  (state) => state.campaigns
);

const selectSubscribedTags = createSelector(
  stateEmailSubscriptions,
  (state) => state.subscribedTags
);

const isSubscribed = (subscribed, id) => {
  const found = subscribed.findIndex((sub) => sub.AC_id === id);
  return found >= 0 ? true : false
}

// Sorted list of avalibale subscriptions
const selectSubscriptions = createSelector(
  selectAvaliableCampaigns,
  selectSubscribedCampaigns,
  selectSubscribedTags,
  (lists, subscribedCampaigns, subscribedTags) => 
    lists.map((campaign) => {
      return {
        id: campaign.AC_id,
        order: campaign.order,
        stringid: campaign.AC_stringid,
        display_name: campaign.display_name,
        tags: campaign.activecampaign_tags.map((tag) => {
          return {
            id: tag.AC_id,
            AC_name: tag.AC_name,
            subscribed: isSubscribed(subscribedTags, tag.AC_id)
          }
        }),
        subscribed: isSubscribed(subscribedCampaigns, campaign.AC_id)
      }
    }).sort((a, b) => a.order - b.order )
);

export const selectEmailSubscriptions = createSelector(
  selectStatus, selectSubscriptions,
  (status, strurtured) => {
    return {
      ...status,
      subscriptions: [...strurtured]
    }
  }
);