import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Utils
import { PORTAL_PATHS, PUBLIC_PATHS } from '@/config';
import { utcToLocal } from '@/utils';
// Components
import { Button } from '@/components';
import { Card } from '@/components/Portal/Card';
import {
	iconArrowThin,
	iconPortalEvent,
	iconPortalCalendar,
	iconPortalLocation
} from '@/images/icons';
import { getUserToken } from '@/store/selectors';

EventCard.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	size: PropTypes.oneOf(['small', 'large']),
	heading: PropTypes.string,
  start_date: PropTypes.string, // Matches API key
	end_date: PropTypes.string,	// Matches API key
	location: PropTypes.string,
	status: PropTypes.oneOf(['past', 'inProgress', 'upcomming']),
	register: PropTypes.string,
}

const action = (id, cardLink, status, register) => {
	if (!register) {
		return (
			<Link
				className="btn btn-text"
				to={cardLink}
			>
				Read More <span className="icon midnight">{iconArrowThin}</span>
			</Link>
		)
	}

	if (status === 'past') {
		return <Button variant='btn-fill' isDisabled >This event has passed</Button>
	}

	return <a className="btn btn-fill" href={register}>
		Register now <span className="icon">{iconArrowThin}</span>
	</a>
}

function formatDate(date) {
	return !!date ? format(utcToLocal(date), 'd LLLL yyyy h:mm ') : date;
}

export default function EventCard({ id, size, heading, start_date, end_date, location, status, register }) {
	const startDate = formatDate(start_date);
	const endDate = formatDate(end_date);
	const	formattedDate = endDate === startDate ? startDate : `${startDate} → ${endDate}`;
    const userToken = useSelector(getUserToken);

    const cardLink = !userToken ? `${PUBLIC_PATHS.EVENTS}/event/${id}` : `${PORTAL_PATHS.EVENTS}/event/${id}`;

	const items = [
		{icon: iconPortalCalendar, details: formattedDate},
		{icon: iconPortalLocation, details: location}
	];

	return (
		<Card
			link={cardLink}
			size={size}
			type='Event'
			icon={iconPortalEvent}
			title={heading}
			items={items}
			actions={action(id, cardLink, status, register)}
		/>
	)
}
