import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useMedia from 'use-media';
import { iconArrowThin, iconArrowThinLeft, iconBarHorizontal } from '@/images/icons';
import MiniLogo from '@/components/MiniLogo';
import ArticleCard from '@/components/ArticleCard';
import { getSectorNews } from '@/selectors/sectorNews';
import { requestSectorNews } from '@/store/actions';

const SectorNews = () => {
  const dispatch = useDispatch();
  const sectorNewsArticles = useSelector(getSectorNews);
  const isMobile = useMedia('(max-width: 767px)');

  const totalNumSectorNews = 3;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [remainingSlides, setRemainingSlides] = useState(0);

  const SLIDE_GAP = 30;
  const slideWidth = SLIDE_GAP + (isMobile ? 381 : 587);
  const sliderPosition = -activeSlideIndex * slideWidth;

  const showNextCard = () => {
    if (remainingSlides > 1) {
      setTimeout(() => {
        setActiveSlideIndex(activeSlideIndex + 1);
      }, 200);
    }
  };

  const showPrevCard = () => {
    if (activeSlideIndex > 0) {
      setTimeout(() => {
        setActiveSlideIndex(activeSlideIndex - 1);
      }, 200);
    }
  };

  useEffect(() => {
    setRemainingSlides(totalNumSectorNews - activeSlideIndex);
  }, [activeSlideIndex]);

  useEffect(() => {
    dispatch(requestSectorNews());
  }, []);

  return (
    <section id="sector-news" data-testid="sector-news">
      <div className="content-wrapper">
        <header>
          <div className="heading">
            <MiniLogo />
            <h2>
              <u>Sector news</u>
            </h2>
          </div>
          <Link className="btn btn-secondary" to="/news-and-resources/sector-news">
            View all sector news <span className="icon">{iconArrowThin}</span>
          </Link>
        </header>

        <div className="carousel">
          <div className="controls-container">
            <div className="controls">
              <div className="buttons">
                <button
                  className={`btn btn-primary left${
                    activeSlideIndex > 0 ? ' btn-active' : ' btn-disabled'
                  }`}
                  onClick={showPrevCard}
                >
                  {iconArrowThinLeft}
                </button>
                <button
                  className={`btn btn-primary right${
                    remainingSlides > 1 ? ' btn-active' : ' btn-disabled'
                  }`}
                  onClick={showNextCard}
                >
                  {iconArrowThin}
                </button>
              </div>

              <div className="breadcrumbs">
                {!isEmpty(sectorNewsArticles) &&
                  sectorNewsArticles.map((story, index) => {
                    if (index < 3) {
                      return (
                        <span
                          className={`icon${
                            index === activeSlideIndex ? ' active-breadcrumb' : ''
                          }`}
                          key={index}
                        >
                          {iconBarHorizontal}
                        </span>
                      );
                    }
                  })}
              </div>
            </div>
          </div>

          <div
            className="slide-container"
            style={{ transform: `translateX(${sliderPosition}px)` }}
          >
            {!isEmpty(sectorNewsArticles) &&
              sectorNewsArticles.map((story, index) => {
                if (index < 3) {
                  return (
                    <div
                      className={`slide${
                        index === activeSlideIndex ? ' active-slide' : ''
                      }${index < activeSlideIndex ? ' hide-slide' : ''}`}
                      key={index}
                    >
                      <ArticleCard
                        imageId={story.feature_image}
                        articleUrl={`/news-and-resources/sector-news/${story.slug}`}
                        size="large"
                        date={story.created_at}
                        title={story.heading}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectorNews;
