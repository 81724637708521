import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getArticle } from '@/store/selectors/articles';
import { iconArrowThin } from '@/images/icons';
import MiniLogo from '@/components/MiniLogo';

const ArticleFindABranch = () => {
  const article = useSelector(getArticle);
  const [findBranchCallout, setFindBranchCallout] = React.useState(null);

  React.useEffect(() => {
    if (!isEmpty(article)) {
      const { find_a_branch } = article && article;

      if (find_a_branch) {
        const parsedFindBranch = JSON.parse(article.find_a_branch_callout);
        const attributes =
          parsedFindBranch && parsedFindBranch[0] && parsedFindBranch[0].attributes;

        if (attributes) {
          setFindBranchCallout(attributes);
        }
      }
    }
  }, [article]);

  if (isEmpty(findBranchCallout)) {
    return null;
  }

  return (
    <div className="find-a-bank">
      <div className="blue-background"></div>
      <div className="content-wrapper">
        <header>
          <div className="blue-background"></div>
          <div className="heading">
            <MiniLogo />
            <h2
              dangerouslySetInnerHTML={{
                __html: findBranchCallout.callout_key_issues_body
              }}
            />
          </div>
          <Link
            to={findBranchCallout.callout_key_issues_link}
            className="btn btn-secondary"
          >
            {findBranchCallout.callout_key_issues_link_copy}{' '}
            <span className="icon">{iconArrowThin}</span>
          </Link>
        </header>
      </div>
    </div>
  );
};

export default ArticleFindABranch;
