import React from 'react';
import { useLocation } from 'react-router-dom';
import ArticleCard from '@/components/ArticleCard';

const MediaReleaseFeaturedItem = ({ article }) => {
  const route = useLocation();
  const location = route.pathname;

  return (
    <ArticleCard
      imageId={false}
      articleUrl={`${location}/${article.slug}`}
      size="large"
      date={article.created_at}
      title={article.heading}
    />
  );
};

export default MediaReleaseFeaturedItem;
