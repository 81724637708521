import React from 'react'
import Heading from './Heading'
import Description from './Description'

const ContentSection = ({headingType='h3', heading, description, children}) => {
	return <div className='content-section'>
		<Heading 
			h2={headingType === 'h2'}
			h3={headingType === 'h3'}
		>
			{heading}
		</Heading>
		<Description>{description}</Description>
		{children}
	</div>
}

export default ContentSection