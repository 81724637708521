import React from 'react';
import { useSelector } from 'react-redux';
import { selectStaticPage } from '@/store/selectors';
import { PortalLayout, PortalPageTitle, CMSPage } from '@/components/Portal';
import { StaticPageMenu } from '@/components/Portal/CMS';

export default function PageBuilder() {
  const { title, description } = useSelector(selectStaticPage);

  return (
    <PortalLayout>
      <PortalPageTitle heading={title} description={description} />

      <StaticPageMenu mobile />

      <div className="container v-pad">
        <div className="row">
          <StaticPageMenu desktop />

          <div className="col-xs-12">
            <div className="content-layout static-page">
              <CMSPage />
            </div>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
