import React from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import PortalHeader from './PortalHeader';
import PortalFooter from './PortalFooter';

export default function PortalLayout({ children }) {
  if (process.env.GA_KEY) {
    ReactGA.initialize(process.env.GA_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <div id="main-wrapper" className="portal" data-testid="layout">
      <Helmet>
        <script>{`
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3056242,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}</script>
    </Helmet>
      <PortalHeader />
      <main className="page-container" data-testid="page-container">
        {children}
      </main>
      <PortalFooter />
    </div>
  );
}
