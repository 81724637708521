import axios from 'axios';
import { fork, call, put, takeLatest } from 'redux-saga/effects';
import { ANNOUNCEMENT } from '../constants';
import { requestAnnouncementFailed, requestAnnouncementSuccess } from '../actions';
import { BASE_URL, API } from '@/config';

export function* announcementSaga(action) {
  const request = () =>
    axios({
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(requestAnnouncementSuccess(response));
  } catch (err) {
    yield put(requestAnnouncementFailed(err));
  }
}

export function* watchRequestAnnouncement() {
  yield takeLatest(ANNOUNCEMENT.REQUEST_ANNOUNCEMENT, announcementSaga);
}

const sagas = [fork(watchRequestAnnouncement)];

export default sagas;
