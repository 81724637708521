import { SUPPORT } from '../constants';

const initialState = {
  subjects: {
    isLoading: false,
    items: [],
		errors: undefined
  },
	contactForm: {
		isSubmitting: false,
		hasSubmitted: undefined,
		errors: undefined
	},
};

const supportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Subjects
    case SUPPORT.REQUEST_CONTACT_SUBJECTS:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          isLoading: true,
					errors: false
        }
      };

    case SUPPORT.REQUEST_CONTACT_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          isLoading: false,
          items: action.payload?.data?.data?.data,
					errors: false
        }
      };

    case SUPPORT.REQUEST_CONTACT_SUBJECTS_FAILED:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          isLoading: false,
					errors: action.payload?.data
        }
      };

    case SUPPORT.SUBMIT_CONTACT_FORM:
      return {
        ...state,
				contactForm: {
					isSubmitting: true,
					submitSuccessful: false,
					errors: false
				}
      };

    case SUPPORT.SUBMIT_CONTACT_FORM_SUCCESS:
      return {
				...state,
				contactForm: {
					isSubmitting: false,
					submitSuccessful: true,
					errors: false
				}
      };

    case SUPPORT.SUBMIT_CONTACT_FORM_FAILED:
      return {
        ...state,
				contactForm: {
					isSubmitting: false,
					submitSuccessful: false,
					error: action.payload?.data
				}
      };

  }

  return state;
};

export default supportReducer;
