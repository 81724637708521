import React from 'react';
import PropTypes from 'prop-types';

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  handleClick: PropTypes.func
};

export default function Tag({ label = '', type = '', handleClick }) {
  return (
    <div className={`tag ${type}`} onClick={handleClick}>
      {label}
    </div>
  );
}
