import React from 'react';

const ArticleMember = ({ memberCalloutData }) => {
  return (
    memberCalloutData && (
      <div className="article-member">
        <section className="member-link">
          <h5>Visit our member</h5>
          <a
            href={`https://${memberCalloutData?.url}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {memberCalloutData?.url}
          </a>
        </section>

        <section className="member-logo">
          <img
            src={memberCalloutData?.logo_data?.url}
            alt={memberCalloutData?.logo_data?.title}
          />
        </section>
      </div>
    )
  );
};

export default ArticleMember;
