import { iconArrowThin } from '@/images/icons';
import {
  selectIsArticlesLoading,
  selectNewsroomArticleBy,
  selectRelatedArticlesFound,
  selectRelatedArticlesMissing
} from '@/store/selectors';
import { utcToLocal } from '@/utils';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { TableBlock } from '@/components';
import {
  Loading,
  NewsroomArticleCard,
  PortalLayout,
  PortalPageTitle,
  Tag
} from '@/components/Portal';
import {
  FeedbackAction,
  FeedbackNotice,
  NewsArticleMenu,
  NewsArticleParsedContent
} from '@/components/Portal/Newsroom';
import { requestNewsArticleByBulletin, requestNewsArticlesById, requestNewsArticlesBySlug } from '@/store/actions';

export default function NewsroomArticle() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const article = useSelector(selectNewsroomArticleBy(params));
  const articleRef = useRef();
  const isLoading = useSelector(selectIsArticlesLoading);

  const [articleID, setArticleID] = useState(-1);

  const relatedArticles = useSelector(
    selectRelatedArticlesFound(articleID),
    shallowEqual
  );
  const missingRelatedArticles = useSelector(
    selectRelatedArticlesMissing(articleID),
    shallowEqual
  );
  const isSeekingFeedback = !!article?.seeking_feedback;
  const table = !!article ? JSON.parse(article.content_table) : [];

  const formattedPublishDate = !!article?.publish_date
    ? format(utcToLocal(article?.publish_date), 'd LLLL yyyy')
    : article?.publish_date;

  const handleTagClick = (type, tagId) => {
    history.push({ pathname: '/newsroom', search: `?${type}=${tagId}` });
  };

  useEffect(() => {
    if (!article && !!params.slug) {
      const regex = /[0-9]{3}-[0-9]{4}/;

      if (params.slug.match(regex)) {
        //IF IT'S BULLETIN (000-0000)
        dispatch(requestNewsArticleByBulletin({ slug: params.slug }));
      } else {
        dispatch(requestNewsArticlesBySlug({ slug: params.slug }));
      }

    }
    if (!article && !!params.id) {
      dispatch(requestNewsArticlesById({ id: params.id }));
    }
  }, []);

  useEffect(() => {
    article && setArticleID(article.id);
  }, [article]);

  useEffect(() => {
    if (!isEmpty(missingRelatedArticles)) {
      missingRelatedArticles.forEach((id) =>
        dispatch(requestNewsArticlesById({ id: id }))
      );
    }
  }, [articleID]);

  return (
    <div ref={articleRef}>
      <PortalLayout>
        <PortalPageTitle
          heading={article?.title || ''}
          description={formattedPublishDate}
          id={article?.bulletin_id}
          secondary
        />
        <NewsArticleMenu article={article} mobile />

        <div className="container">
          <div className="row">
            <NewsArticleMenu article={article} articleRef={articleRef} desktop />

            <div className="col-xs-12 col-sm-8">

              <div style={{ display: 'inline-block', marginBottom: '24px' }}>
                <button
                  className="btn btn-outline btn-full-width"
                  onClick={history.goBack}
                >
                  <span className="icon" style={{ transform: 'rotate(180deg)' }}>{iconArrowThin}</span> Go Back
                </button>

              </div>
              <div className="newsroom article article-wrapper">
                {isLoading && <Loading />}

                {!isLoading && !article && (
                  <div className="">Article details not found</div>
                )}

                {!isLoading && article && (
                  <>
                    {isSeekingFeedback && <FeedbackNotice id={articleID} />}

                    <NewsArticleParsedContent markup={article?.content} />

                    {isSeekingFeedback && (
                      <FeedbackAction
                        id={articleID}
                        content={article.seeking_feedback_message}
                      />
                    )}

                    {!isEmpty(table) && (
                      <div className="content-section">
                        {table.map((content) => (
                          <TableBlock key={content.key} data={content.attributes.table} />
                        ))}
                      </div>
                    )}

                    {!isEmpty(relatedArticles) && (
                      <div className="content-section">
                        <h3>Previous references</h3>
                        {relatedArticles.map((related) => (
                          <NewsroomArticleCard
                            key={related.id}
                            id={related.id}
                            news_topics={related.news_topics}
                            title={related.title}
                            author={related.author}
                            publish_date={related.publish_date}
                            feedback_date={related.feedback_date}
                            seeking_feedback={related.seeking_feedback}
                            content={related.content}
                            slug={related.slug}
                          />
                        ))}
                      </div>
                    )}

                    {!isEmpty(article.news_topics) && (
                      <div className="content-section">
                        <h3>Tags</h3>
                        <div className="tag-group">
                          {article.news_topics.map((tag) => (
                            <Tag
                              key={tag.id}
                              type="article"
                              label={tag.name}
                              handleClick={() => handleTagClick('topics', tag.id)}
                            />
                          ))}
                          {article.regulators.map((tag) => (
                            <Tag
                              key={tag.id}
                              type="article"
                              label={tag.name}
                              handleClick={() => handleTagClick('regulators', tag.id)}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </PortalLayout>
    </div>
  );
}
