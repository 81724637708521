import React from 'react';
import { isEmpty } from 'lodash';
import ArticleCard from '@/components/ArticleCard';

const ArticleListing = ({ articles, category }) => {
  return (
    <div className="article-list content-wrapper">
      {isEmpty(articles) && <p>No articles found.</p>}

      {!isEmpty(articles) &&
        articles.map((article, i) => {
          if (article.is_active === 1) {
            return (
              <ArticleCard
                key={i}
                imageId={article.feature_image}
                articleUrl={`/news-and-resources/${category}/${article.id}/${article.slug}`}
                date={article.created_at}
                title={article.heading}
              />
            );
          }
        })}
    </div>
  );
};

export default ArticleListing;
