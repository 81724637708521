import PropTypes from 'prop-types';
import React from 'react'
import useMedia from 'use-media';
import MenuToggle from './MenuToggle';

PortalPageMenuContent.propTypes = {
  mobileToggleName: PropTypes.string,
	mobile: PropTypes.bool,
	desktop: PropTypes.bool,
	children: PropTypes.node
}

export default function PortalPageMenuContent({
  mobileToggleName, 
  mobile=false, 
  desktop=false, 
  children
}) {
	const isMobile = useMedia('(max-width: 1023px)');

  	// Render for Mobile or Desktop
	if ( (!isMobile && mobile) || (isMobile && desktop)) {
		return null
	}

  return (
    <div className="portal-page-menu content col-xs-12 col-md-4">
      <MenuToggle
        mobileToggleLabel={mobileToggleName}
      >
        <aside className="portal-page-menu__content">
          {children}
        </aside>
      </MenuToggle>
  </div>
  );
}
