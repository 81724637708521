import { createSelector } from 'reselect';
import { first } from 'lodash';
import { NewsTopicsFilterChildren } from '@/components/Portal/Newsroom/Filters/NewsTopicsFilter';
import { format, parseISO, isDate, isFuture, isAfter } from 'date-fns';
import { utcToLocal } from '@/utils';

// State Selectors
export const getNewsArticlesState = (state) => state.newsArticlesReducer;

export const getNewsArticles = createSelector(
  getNewsArticlesState,
  (state) => state.articles.items
);

export const selectNewsArticles = createSelector(
  getNewsArticlesState,
  (state) => state.articles
);

export const selectNewsArticlesQueries = createSelector(
  getNewsArticlesState,
  (state) => state.queries
);

export const getNewsArticlesFilters = createSelector(
  getNewsArticlesState,
  (state) => state.filterOptions
);

export const selectIsArticlesLoading = createSelector(
  getNewsArticlesState,
  (state) => state.isArticlesLoading
);

//    Find News articles
const getNewsroomArticle = (id) =>
  createSelector(getNewsArticlesState, (state) =>
    first(state.articles.items.filter((article) => article.id === Number(id)))
  );

export const selectNewsroomArticleBy = ({ slug }) =>
    createSelector(getNewsArticlesState, (state) => {
        return state.articles.items.find((article) => {
            if (article.slug === slug || article.bulletin_id === slug) {
                return article.slug;
            }
        });
    });

//    News Article Content
const selectRelatedArticlesIDs = (articleId) =>
  createSelector(getNewsroomArticle(articleId), (article) => {
    const related_articles = !!article?.related_articles
      ? JSON.parse(article.related_articles)
      : [];
    return related_articles.map((relatedArticle) => Number(relatedArticle.attributes.id));
  });

export const selectRelatedArticlesFound = (articleId) =>
  createSelector(
    getNewsArticles,
    selectRelatedArticlesIDs(articleId),
    (articles, relatedArticleIds) =>
      articles.filter((article) => relatedArticleIds.includes(Number(article.id)))
  );

export const selectRelatedArticlesMissing = (articleId) =>
  createSelector(
    selectRelatedArticlesIDs(articleId),
    selectRelatedArticlesFound(articleId),
    (relatedArticleIds, foundArticles) =>
      relatedArticleIds.reduce(
        (missing, id) =>
          foundArticles.some((foundArticle) => foundArticle.id === id)
            ? missing
            : [...missing, id],
        []
      )
  );

export const getFeedbackData = (articleId) =>
  createSelector(getNewsroomArticle(articleId), (article) => ({
    articleAuthor: article?.author,
    seekingFeedback: article?.seeking_feedback,
    feedbackEndDate: article?.feedback_date,
    isFeedbackExpired: isAfter(new Date(), parseISO(article?.feedback_date))
  }));

//    Filtering

const getNewsTopicsData = createSelector(getNewsArticlesState, (state) =>
  state.filterOptions.topics.map((newsTopic) => ({
    id: newsTopic.id,
    title: newsTopic.name,
    parentId: newsTopic.parent_id,
    children: []
  }))
);

export const getNewsTopics = createSelector(getNewsTopicsData, (newsTopics) => {
  const newsTopicViewAll = {
    id: -1,
    title: 'View All',
    parentId: null,
    children: []
  };

  return newsTopics.reduce(
    (ac, c, i, a) => {
      const isExists = ac.some((item) => item.id === c?.id);

      if (!c?.parentId && !isExists) {
        return [...ac, c];
      }

      if (!!c?.parentId) {
        const existingParentIndex = ac.findIndex((item) => item.id === c?.parentId);

        if (existingParentIndex >= 0) {
          const targetParent = ac[existingParentIndex];
          targetParent.children = [...targetParent.children, c];
          targetParent.Content = () => (
            <NewsTopicsFilterChildren items={targetParent.children} />
          );
          targetParent.Content.displayName = 'Content';
          return ac;
        }

        const sourceParent = first(a.filter((item) => item.id === c.parentId));

        if (sourceParent) {
          sourceParent.children = [...sourceParent.children, c];
          sourceParent.Content = () => (
            <NewsTopicsFilterChildren items={sourceParent.children} />
          );
          sourceParent.Content.displayName = 'Content';
          return [...ac, sourceParent];
        }
      }

      return ac;
    },
    [newsTopicViewAll]
  );
});

export const getSelectedFilterDates = createSelector(getNewsArticlesState, (state) => {
  const dateStart = state.queries.dateStart;
  const dateEnd = state.queries.dateEnd;

  let returnValue = [];

  if (dateStart !== undefined && isDate(dateStart)) {
    returnValue.push({
      id: 'from',
      name: `${format(utcToLocal(dateStart), 'dd LLL yyyy')}`,
      type: 'dateStart'
    });
  }

  if (dateEnd !== undefined && isDate(dateEnd)) {
    returnValue.push({
      id: 'to',
      name: `${format(utcToLocal(dateEnd), 'dd LLL yyyy')}`,
      type: 'dateEnd'
    });
  }

  return returnValue;
});

export const getSelectedTopicsAndPolicy = createSelector(
  getNewsArticlesFilters,
  selectNewsArticlesQueries,
  (filters, queries) => {
    const filterOptions = { topics: filters.topics, policy: filters.policy };
    const selectedQueries = { topics: queries.topics, policy: queries.policy };

    return Object.keys(filterOptions).reduce((a, key) => {
      const filterItem = filterOptions[key];
      const queries = selectedQueries[key];

      const selected = filterItem.filter((item) =>
        Array.isArray(queries) ? queries.includes(item.id) : queries === item.id
      );

      const selectedFilters = selected.map(({ id, name }) => ({
        id,
        name,
        type: key
      }));

      if (selected.length) {
        return [...a, ...selectedFilters];
      }

      return a;
    }, []);
  }
);

export const getSelectedNewsTopicIds = createSelector(
  selectNewsArticlesQueries,
  (queries) => queries.topics
);

export const getNewsArticlesPolicyFilters = createSelector(
  getNewsArticlesState,
  (state) => state.filterOptions.policy
);

export const getNewsArticlesRegulatorFilters = createSelector(
  getNewsArticlesState,
  (state) => {
    const regulators = [...(state?.filterOptions?.regulators || [])];

    return regulators.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });
  }
);

//    Home Page

export const getNewsArticlesSeekingFeedback = createSelector(
  getNewsArticles,
  (articles) =>
    articles.filter(
      (article) => article.seeking_feedback && isFuture(parseISO(article.feedback_date))
    )
);

export const getNewsArticlesLatestBulletins = createSelector(
  getNewsArticles,
  (articles) =>
    articles.filter((article) => {
      const publish_date = parseISO(article.publish_date);

      if (!publish_date || isFuture(publish_date)) {
        return false;
      }
      // --> Include articles that are not publishedin the future

      const feedback_date = parseISO(article.feedback_date);
      if (feedback_date && isFuture(feedback_date)) {
        return false;
      }
      //  --> Include published articles that are not still seeking feedback

      return true;
    })
);
