import React from 'react';

export default function TextSection({
    H2Title,
		H3Title,
    content
  }) {
    return (
      <div className="content-section">
        { !!H2Title && <h2>{H2Title}</h2> }
				{ !!H3Title && <h3>{H3Title}</h3> }
        <div className="copy" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
}
