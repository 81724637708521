import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { requestNewsArticlesFirstPage } from '@/store/actions';
import { getSelectedFilterDates } from '@/selectors/newsArticles';
import { DatePicker, Label } from '@/components';
import { isDate } from 'moment';

export default function DateFilter() {
  const dispatch = useDispatch();
  const filterDates = useSelector(getSelectedFilterDates);

  const [fromDate, setFromDate] = React.useState(undefined);
  const [toDate, setToDate] = React.useState(undefined);

  const formMethods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onChangeFromDate = (value) => {
    setFromDate(value);
  };

  const onChangeToDate = (value) => {
    setToDate(value);
  };

  React.useEffect(() => {
    if (isDate(toDate)) {
      dispatch(requestNewsArticlesFirstPage({ dateStart: fromDate, dateEnd: toDate }));
    } else {
      dispatch(requestNewsArticlesFirstPage({ dateStart: fromDate }));
    }
  }, [fromDate]);

  React.useEffect(() => {
    if (isDate(fromDate)) {
      dispatch(requestNewsArticlesFirstPage({ dateStart: fromDate, dateEnd: toDate }));
    } else {
      dispatch(requestNewsArticlesFirstPage({ dateEnd: toDate }));
    }
  }, [toDate]);

  React.useEffect(() => {
    if (isEmpty(filterDates.filter((e) => e.id === 'from'))) {
      setFromDate(undefined);
    }
    if (isEmpty(filterDates.filter((e) => e.id === 'to'))) {
      setToDate(undefined);
    }
  }, [filterDates]);

  return (
    <FormProvider {...formMethods}>
      <form noValidate>
        <div className="form-field">
          <Label labelText="From" />
          <DatePicker onChange={onChangeFromDate} value={fromDate} />
        </div>

        <div className="form-field">
          <Label labelText="To" />
          <DatePicker onChange={onChangeToDate} value={toDate} />
        </div>
      </form>
    </FormProvider>
  );
}
