import React from 'react';
import { Tag } from '@/components/Portal'

export default function ErrorTags({errors}) {
  let errorMessages = []
  for (const key in errors) {
    if (Object.hasOwnProperty.call(errors, key)) {
      errorMessages.push(
        <Tag 
					key={key}
          label={errors[key]} 
          type='error'
      />
      )
    }
  }
  return errorMessages
}