import { createSelector } from 'reselect';
import { pick } from 'lodash';

export const selectIsLoading = (state) => state.globalSearchReducer.isLoading;
const selectGlobalSearchResults = (state) => state.globalSearchReducer.results;

const selectFormattedNewsResult = createSelector(selectGlobalSearchResults, (results) =>
  !!results?.newsroom?.length
    ? results.newsroom.map((article) => ({
        id: article.id,
        title: article.title,
        author: article.author,
        publish_date: article.publish_date,
        seeking_feedback: article.seeking_feedback,
        feedback_date: article.feedback_date,
        news_topics: article.news_topics,
        content: article.content,
        slug: article.slug
      }))
    : results?.newsroom
);

const selectValidPagesResult = createSelector(selectGlobalSearchResults, (results) =>
  !!results?.pages?.length
    ? results?.pages.filter((article) => !!article?.page_data?.data)
    : results?.pages
);

const selectFormattedPagesResult = createSelector(selectValidPagesResult, (results) =>
  !!results?.length
    ? results.map((article) => {
        const attribution = article?.page_data;

        return {
          id: attribution.id,
          parent_id: attribution?.parent_id,
          category: attribution?.data?.menu,
          path: attribution?.path,
          title: article?.name?.en,
          excerpt: attribution?.data.description
        };
      })
    : results
);

const selectResourcesAndServicesPages = createSelector(
  selectFormattedPagesResult,
  (pages) =>
    !!pages?.length
      ? pages.filter((page) => page?.category === 'Resource & Services')
      : pages
);

const selectFormattedEventsResult = createSelector(selectGlobalSearchResults, (results) =>
  !!results?.events?.length
    ? results.events.map((event) => ({
        id: event?.id,
        heading: event?.heading,
        start_date: event?.start_date,
        end_date: event?.end_date,
        location: event?.location,
        status: event?.status,
        register: event?.register
      }))
    : results?.events
);

export const selectAllFormattedSearchResults = createSelector(
  selectFormattedNewsResult,
  selectFormattedPagesResult,
  selectFormattedEventsResult,
  selectResourcesAndServicesPages,
  (news, pages, events, resources) => ({
    newsroom: news,
    pages,
    events,
    'resources & services': resources
  })
);

export const selectIsEmptySearchResults = createSelector(
  selectAllFormattedSearchResults,
  (results) => Object.values(results).every((result) => !result || !result?.length)
);

export const selectAllTruncatedSearchResults = createSelector(
  selectAllFormattedSearchResults,
  (results) =>
    Object.keys(results).reduce((truncatedResults, key) => {
      truncatedResults[key] =
        results?.[key]?.length > 3 ? results[key].slice(0, 3) : results[key];
      return truncatedResults;
    }, {})
);

const selectSearchResultsByCategory = (category) =>
  createSelector(selectAllFormattedSearchResults, (results) =>
    !!results?.[category] ? pick(results, category) : {}
  );

export const selectSearchResultsCategoryByPage = (currentPageIndex, category) =>
  createSelector(selectSearchResultsByCategory(category), (results) => {
    const ITEMS_PER_PAGE = 8;
    const trimEnd = currentPageIndex * ITEMS_PER_PAGE;
    const resultsByCategory = results?.[category] || [];
    const resultsByPage = !!resultsByCategory.length
      ? resultsByCategory.slice(0, trimEnd)
      : [];

    return { [category]: resultsByPage };
  });

export const selectAllSearchResultsTotal = createSelector(
  selectGlobalSearchResults,
  (results) => Object.values(results).flat().length || 0
);

export const selectAllSearchResultsTotalByCategories = createSelector(
  selectGlobalSearchResults,
  (results) =>
    Object.keys(results).reduce((categoryCounts, key) => {
      categoryCounts[key] = results?.[key]?.length || 0;
      return categoryCounts;
    }, {})
);
