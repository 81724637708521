import { CUSTOMER_SUPPORT } from '../constants'

const requestCustomerSupport = () => ({
    type: CUSTOMER_SUPPORT.REQUEST_CUSTOMER_SUPPORT
})

const receivedCustomerSupport = (payload) => ({
    type: CUSTOMER_SUPPORT.RECEIVED_CUSTOMER_SUPPORT,
    payload
})

const failedCustomerSupport = (payload) => ({
    type: CUSTOMER_SUPPORT.FAILED_CUSTOMER_SUPPORT,
    payload
})

export {
    requestCustomerSupport, receivedCustomerSupport, failedCustomerSupport
}