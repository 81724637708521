import React from 'react';
import { useLocation } from 'react-router';
import { pluralize } from 'humanize-plus';
import { PER_PAGE } from '@/config';
// Store
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { searchAllByTerm, clearSearchAll } from '@/store/actions';
import {
  selectAllSearchResultsTotal,
  selectAllSearchResultsTotalByCategories,
  selectAllTruncatedSearchResults,
  selectIsEmptySearchResults,
  selectIsLoading,
  selectSearchResultsCategoryByPage
} from '@/selectors';
// Components
import { Button, PortalLayout, Searchbar } from '@/components';
import { NewsroomArticleCard, EventCard, NoArticlesFound, PageCard } from '@/components/Portal';
import { GobalSearchMenu } from '@/components/Portal/GlobalSearch';
import { iconArrowThin } from '@/images/icons';

export default function GlobalSearchResults() {
  const cardMap = {
    events: EventCard,
    newsroom: NewsroomArticleCard,
    pages: PageCard,
    'resources & services': PageCard
  };

  const actions = bindActionCreators({ searchAllByTerm, clearSearchAll }, useDispatch());

  const { search } = useLocation();
  const query = new URLSearchParams(search).get('query') || '';

  const [searchResultsListing, setSearchResultsListing] = React.useState({});
  const [searchResultsCategory, setSearchResultsCategory] = React.useState('');
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const [filterItems, setFilterItems] = React.useState([]);

  const searching = useSelector(selectIsLoading);
  const grandTotalOfSearchResults = useSelector(selectAllSearchResultsTotal);
  const categoriesTotalOfSearchResults = useSelector(
    selectAllSearchResultsTotalByCategories
  );
  const initialSearchResults = useSelector(selectAllTruncatedSearchResults);
  const paginatedSearchResultsByCategory = useSelector(
    selectSearchResultsCategoryByPage(currentPageIndex, searchResultsCategory)
  );
  const isEmptySearchResults = useSelector(selectIsEmptySearchResults);

  const searchbarActions = {
    request: {
      dispatch: actions.searchAllByTerm,
      searchParamKey: 'search_term',
      payload: {
        per_page: PER_PAGE
      }
    },

    clear: {
      dispatch: actions.clearSearchAll,
      payload: {}
    }
  };

  const onClickSelectCategory = (category) => (ev) => {
    ev.preventDefault();
    setSearchResultsCategory(category);
  };

  const onClickLoadMore = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const onClickFilterSearchResults = (ev) => {
    setSearchResultsCategory(ev);
  };

  React.useEffect(() => {
    setSearchResultsListing(initialSearchResults);
  }, [initialSearchResults]);

  React.useEffect(() => {
    if (!!searchResultsCategory) {
      setSearchResultsListing(paginatedSearchResultsByCategory);
    } else {
      setCurrentPageIndex(1);
      setSearchResultsCategory('');
      setSearchResultsListing(initialSearchResults);
    }
  }, [searchResultsCategory, currentPageIndex]);

  React.useEffect(() => {
    let id = 1;
    let filters = [
      {
        id: -1,
        onClickAccordionItem: () => onClickFilterSearchResults(''),
        title: `View All (${grandTotalOfSearchResults})`,
        isActive: searchResultsCategory === ''
      }
    ];
    Object.keys(initialSearchResults).map((category) => {
      const categoryResults = categoriesTotalOfSearchResults[category] || 0;
      filters.push({
        id: ++id,
        onClickAccordionItem: () => onClickFilterSearchResults(category),
        title: `${category} (${categoryResults})`,
        isActive: searchResultsCategory === category
      });
    });

    setFilterItems(filters);
  }, [initialSearchResults, searchResultsCategory]);

  return (
    <PortalLayout>
      <div className="global-search-results">
        <div className="global-search-bar">
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-7 col-md-6">
              <Searchbar
                defaultValue={query}
                totalSearchResults={grandTotalOfSearchResults}
                actions={searchbarActions}
                isSearching={searching}
              />
            </div>
          </div>
        </div>

        <GobalSearchMenu
          filterItems={filterItems}
          mobile
        />

        <div className="global-search-results-container container v-pad">
          <div className="row">
            <GobalSearchMenu
              filterItems={filterItems}
              desktop
            />

            <div className="col-xs-12 col-sm-8">
              {!searching && isEmptySearchResults && <NoArticlesFound />}

              {!isEmptySearchResults &&
                Object.keys(searchResultsListing).map((category, i) => {
                  const items =
                    searchResultsListing[category] ?? searchResultsListing[category];
                  const categoryResults = categoriesTotalOfSearchResults[category] || 0;
                  const Card = cardMap[category];

                  if (!items?.length) {
                    return null;
                  }

                  return (
                    <div className="search-result-category" key={`${category}-${i}`}>
                      <div className="search-result-category-header">
                        <div className="heading">
                          <h3>{category}</h3>
                          <p className="count">
                            {categoryResults} {pluralize(categoryResults, 'result')}
                          </p>
                        </div>

                        <Button
                          variant={'btn btn-text'}
                          icon={iconArrowThin}
                          onClick={onClickSelectCategory(category)}
                        >
                          View All
                        </Button>
                      </div>

                      <div className="search-result-items-list">
                        {items.map((result) => {
                          return (
                            <div key={result.id}>
                              <Card {...result} />
                              <br />
                            </div>
                          );
                        })}
                      </div>

                      <br />

                      {!!searchResultsCategory &&
                        !isEmptySearchResults &&
                        searchResultsListing[searchResultsCategory] &&
                        categoriesTotalOfSearchResults[searchResultsCategory] >
                          Object.keys(searchResultsListing[searchResultsCategory])
                            ?.length && (
                          <Button variant="btn-outline" onClick={onClickLoadMore}>
                            Load More
                          </Button>
                        )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
