import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const defaultAnimationConfig = {
  initial: {
    opacity: 0
  },
  in: {
    display: 'block',
    opacity: 1,
    transition: {
      opacity: {
        duration: 0.3,
        delay: 0.3
      }
    }
  },
  out: {
    display: 'none',
    opacity: 0,
    transition: {
      opacity: {
        duration: 0.3
      }
    }
  }
};

export default function Motion({
  children,
  animate,
  animationConfig = defaultAnimationConfig,
  classNames = ''
}) {
  return (
    <AnimatePresence>
      <motion.div
        key="detail"
        className={classNames}
        initial="initial"
        animate={!!animate ? 'in' : 'out'}
        exit="out"
        variants={animationConfig}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
