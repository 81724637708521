import { createSelector } from 'reselect';

export const getContactSubjectsState = (state) => state.supportReducer.subjects;
export const getSubmitContactFormState = (state) => state.supportReducer.contactForm;

export const selectContactSubjects = createSelector(
  getContactSubjectsState, (subjects) => subjects.items
);

export const selectSubmitContactForm = createSelector(
  getSubmitContactFormState, (contactForm) => ({
		...contactForm,
		success: contactForm.isSubmitting == false && contactForm.submitSuccessful == true
	})
);