import { call, put, takeLatest } from 'redux-saga/effects';
import { ARTICLES } from '../constants';
import { receivedSectorNews, failedSectorNews } from '../actions';
import rootDomain from '../rootDomain';
const api = url => fetch(url, {}).then(res => res.json());

export function* requestSectorNewsAsync(action) {
  const ROOT = rootDomain();
  const sectorNewsUrl = `${ROOT}/api/v1/articles/type?per_page=12&type_id=1&page=${action.payload}`;

  try {
    const response = yield call(api, sectorNewsUrl);
    yield put(receivedSectorNews(response));
  } catch (err) {
    yield put(failedSectorNews(err));
  }
}

export default function* watchRequestSectorNews() {
  yield takeLatest(ARTICLES.REQUEST_SECTOR_NEWS, requestSectorNewsAsync);
}
