import { API, BASE_URL, PER_PAGE } from '@/config';
import { getUserToken, selectFeedsQueries } from '@/store/selectors';
import { isNumber } from '@annexe/isnumber';
import axios from 'axios';
import { omitBy } from 'lodash';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { requestFeedsByPageFailed, requestFeedsByPageSuccess } from '../actions';
import { FEEDS } from '../constants';

/**
 * @typedef {object} FeedItem
 * @property {number} id
 * @property {string} title
 * @property {string} creator
 * @property {string} author_image
 * @property {string} link
 * @property {string} description
 * @property {string} pub_date
 * @property {string} source
 * @property {string[]} thumbnail_urls
 * @property {string} created_at
 * @property {string} updated_at
 */

/**
 *
 * @param {FeedItem[]} items
 * @returns
 */
const transformFeedItems = (items) => {
  const transformedItems = items.map((item) => ({
    id: item.id,
    title: item.title,
    description: item.description,
    url: item.link,
    author: item.creator,
    publicationName: item.source,
    publishDate: item.created_at
  }));

  return transformedItems;
};

const queryPayload = (queries) => {
  const payload = omitBy({ ...queries }, (prop) =>
    isNumber(prop) ? false : !prop || !prop?.length
  );

  return payload;
};

export function* getFeedsByPage(action) {
  const token = yield select(getUserToken);
  const queries = yield select(selectFeedsQueries);
  const payload = queryPayload(queries);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
        order_direction: 'desc',
        ...payload
      }
    });

  try {
    const response = yield call(request);

    response.data.data.data = transformFeedItems(response.data.data.data);

    yield put(requestFeedsByPageSuccess(response));
  } catch (err) {
    yield put(requestFeedsByPageFailed(err));
  }
}

export function* watchgetFeedsByPage() {
  yield takeLatest(FEEDS.REQUEST_FEEDS_BY_PAGE, getFeedsByPage);
}

const sagas = [fork(watchgetFeedsByPage)];

export default sagas;
