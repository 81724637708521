import React from 'react';
import { Link } from 'react-router-dom';
// Store
import { useSelector } from 'react-redux';
import { selectHeaderContent } from '@/store/selectors/header';

// Components
import { PortalHeaderContext } from '@/context';
import { iconChevronDown } from '@/images/icons';
import PortalNavSubMenu from './PortalNavSubMenu';

export default function PortalNav() {
  const ref = React.useRef(null);

  const { onClickDropdownMenu, selectedMenuTitle } =
    React.useContext(PortalHeaderContext);

  React.useEffect(() => {
    const onClickAwayPortalMenu = (e) => {
      if (!ref?.current?.contains(e.target)) {
        onClickDropdownMenu(e);
      }
    };
    document.addEventListener('click', onClickAwayPortalMenu);

    return () => {
      document.removeEventListener('click', onClickAwayPortalMenu);
    };
  }, []);
  const headerContent = useSelector(selectHeaderContent);

  const menuHandler = headerContent?.content_blocks?.map((menu) => menu.attributes);
  return (
    <nav className="nav" ref={ref}>
      <ul>
        {menuHandler?.map((menu, i) => {
          return (
            <li key={`${menu.heading}-${i}`}>
              {menu.is_external ? (
                <a href={menu.page} target="_blank" rel="noreferrer">
                  {menu.heading}
                </a>
              ) : menu?.content_blocks?.length ? (
                <button
                  className="menu-item"
                  data-title={menu.heading}
                  onClick={onClickDropdownMenu}
                >
                  {menu.heading}
                  {!!menu?.content_blocks?.length && (
                    <span className="icon">{iconChevronDown}</span>
                  )}
                </button>
              ) : (
                <Link
                  to={menu.page || '#'}
                  className="menu-item"
                  data-title={menu.heading}
                >
                  {menu.heading}
                </Link>
              )}

              {selectedMenuTitle === menu.heading && (
                <PortalNavSubMenu content_blocks={menu.content_blocks} />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
