import { SUPPORT } from '../constants'

const requestContactSubjects = (payload) => ({
	type: SUPPORT.REQUEST_CONTACT_SUBJECTS,
	payload
})

const requestContactSubjectsSuccess = (payload) => ({
	type: SUPPORT.REQUEST_CONTACT_SUBJECTS_SUCCESS,
	payload
})

const requestContactSubjectsFailed = (payload) => ({
	type: SUPPORT.REQUEST_CONTACT_SUBJECTS_FAILED,
	payload
})

const submitContactForm = (payload) => ({
	type: SUPPORT.SUBMIT_CONTACT_FORM,
	payload
})

const submitContactFormSuccess = (payload) => ({
	type: SUPPORT.SUBMIT_CONTACT_FORM_SUCCESS,
	payload
})

const submitContactFormFailed = (payload) => ({
	type: SUPPORT.SUBMIT_CONTACT_FORM_FAILED,
	payload
})

export { 
	requestContactSubjects, 
	requestContactSubjectsSuccess, 
	requestContactSubjectsFailed,
	submitContactForm,
	submitContactFormSuccess,
	submitContactFormFailed
}