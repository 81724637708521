import React from 'react';
import { first, isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Dropdown, Button } from '@/components';
import { iconPlus } from '@/images/icons';
import EditExsitingBranch from './EditExsitingBranch';
import AddNewBranch from './AddNewBranch';
import { getUserBranches, getUserDivisionOptions } from '@/store/selectors';
import {
  requestUserBranches,
  updateUserBranches,
  requestDivisionsBanks
} from '@/store/actions';

export default function Branch() {
  const actions = bindActionCreators(
    { requestUserBranches, updateUserBranches, requestDivisionsBanks },
    useDispatch()
  );

  const [selectedExistingBranch, setSelectedExistingBranch] = React.useState(undefined);
  const [selectedDivision, setSelectedDivision] = React.useState(undefined);
  const [showAddNewBranch, setShowAddNewBrannch] = React.useState(false);
  const [showUpdateExistingBranch, setShowUpdateExistingBranch] = React.useState(false);
  const [isDisabledAddNewBranch, setIsDisabledAddNewBranch] = React.useState(false);

  const userBranches = useSelector(getUserBranches);
  const userDivisionOptions = useSelector(getUserDivisionOptions);

  const userBranchesOptions = userBranches.map((branch) => ({
    id: branch.id,
    name: branch.city
  }));

  const currentBranch = first(
    userBranches.filter((branch) => branch.id == selectedExistingBranch?.id)
  );

  const form = useForm({
    mode: 'all',
    reValidateMode: 'all'
  });

  const onClickAddNewBranch = (ev) => {
    ev.preventDefault();
    setShowAddNewBrannch(!isEmpty(selectedDivision));
  };

  const onClickCancelAddNewBranch = (ev) => {
    ev.preventDefault();
    setShowAddNewBrannch(false);
  };

  const onClickDivisionDropdownOption = (ev) => {
    const targetId = ev.target.dataset.id;
    const targetName = ev.target.textContent;

    setSelectedDivision({
      id: targetId,
      value: targetName
    });
  };

  const onClickBranchDropdownOption = (ev) => {
    const targetId = ev.target.dataset.id;
    const targetName = ev.target.textContent;

    setSelectedExistingBranch({
      id: targetId,
      value: targetName
    });
  };

  const onClickCloseEditBranch = (ev) => {
    ev?.preventDefault();
    setShowUpdateExistingBranch(false);
    setSelectedExistingBranch(undefined);
  };

  React.useEffect(() => {
    actions.requestUserBranches();
    actions.requestDivisionsBanks({
      page: 1,
      per_page: 99999999
    });
  }, []);

  React.useEffect(() => {
    setIsDisabledAddNewBranch(isEmpty(selectedDivision));

    if (!selectedDivision || !selectedExistingBranch) {
      const portalNav = document.querySelector('.portal-header');
      const portalPageTitle = document.querySelector('.portal-page-title');
      const portalNavHeight = portalNav.clientHeight;
      const portalPageTitleHeight = portalPageTitle.clientHeight;

      window.scrollTo({
        top: portalNavHeight + portalPageTitleHeight,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [selectedDivision, selectedExistingBranch]);

  React.useEffect(() => {
    setShowUpdateExistingBranch(!isEmpty(selectedExistingBranch));
  }, [selectedExistingBranch]);

  return (
    <div className="form my-account branch">
      <div className="row start-md">
        <div className="form-header col-xs-12 col-md-10">
          <div className="form-title H2">Update branch details</div>
          <div className="form-description">
            Please use the form below to tell us about any changes to your existing branch
            locations or to create new branch locations. Update existing branches by
            selecting them from the dropdown, or add a new branch below. COBA will review
            these changes before approving, or contact you for more information.
          </div>
        </div>

        <div className="col-xs-12 col-md-10">
          <FormProvider {...form}>
            <form noValidate>
              <fieldset className="fieldset">
                {!!userDivisionOptions?.length ? (
                  <Dropdown
                    id="division-id"
                    name="divisionId"
                    options={userDivisionOptions}
                    placeholder="Division"
                    labelFor="division-id"
                    labelText="Select Existing Division"
                    onClickDropdownOption={onClickDivisionDropdownOption}
                    selectedOption={selectedDivision}
                    control={form.control}
                  />
                ) : (
                  <p>No user divisions found.</p>
                )}
              </fieldset>

              {!isEmpty(selectedDivision) && !showAddNewBranch && (
                <>
                  <div className="hr" />

                  <fieldset className="fieldset">
                    {!!userBranches?.length ? (
                      <Dropdown
                        id="branch-id"
                        name="branchId"
                        options={userBranchesOptions}
                        placeholder="Branch"
                        labelFor="branch-id"
                        labelText="Select Existing Branch"
                        onClickDropdownOption={onClickBranchDropdownOption}
                        selectedOption={selectedExistingBranch}
                        control={form.control}
                      />
                    ) : (
                      <p>No user branches found.</p>
                    )}
                  </fieldset>
                </>
              )}
            </form>
          </FormProvider>

          {!isEmpty(selectedDivision) && !showUpdateExistingBranch && !showAddNewBranch && (
            <Button
              variant={'btn btn-fill'}
              icon={iconPlus}
              onClick={onClickAddNewBranch}
              isDisabled={isDisabledAddNewBranch}
            >
              Add Branch
            </Button>
          )}

          {showUpdateExistingBranch && (
            <EditExsitingBranch
              id={selectedExistingBranch?.id}
              currentBranch={currentBranch}
              onClickCloseEditBranch={onClickCloseEditBranch}
            />
          )}

          {showAddNewBranch && (
            <AddNewBranch
              showAddNewBranch={showAddNewBranch}
              onClickCancelAddNewBranch={onClickCancelAddNewBranch}
            />
          )}
        </div>
      </div>
    </div>
  );
}
