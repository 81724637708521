import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// Store
import { bindActionCreators } from 'redux';
import { submitStep, requestMembersInstitutions } from '@/store/actions';
import { getCreateAccountForm, selectMemberInstitutions } from '@/store/selectors';
// Components
import { Button, Dropdown, TextField } from '@/components';
import { FormBackButton } from '@/components/Portal/Onboarding';
import { iconArrowThin } from '@/images/icons';
import OnboardingFormTransition from './OnboardingFormTransition';
import CreateAccountFormHeader from './CreateAccountFormHeader';
import { PORTAL_PATHS } from '@/config';

export default function CreateAccountFormStep2() {
  const actions = bindActionCreators(
    {
      submitStep,
      requestMembersInstitutions
    },
    useDispatch()
  );

  const institutions = useSelector(selectMemberInstitutions);
  const institutionOptions = institutions.map((institution) => ({
    id: institution.id,
    name: institution.trading_name
  }));

  const history = useHistory();
  const formData = useSelector(getCreateAccountForm);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onClickDropdownOption = (ev) => {
    const selectedId = ev.target.dataset.id;
    const targetName = ev.target.textContent;

    form.setValue(
      'companyId',
      { id: selectedId, value: targetName },
      { shouldDirty: true }
    );
  };

  const onSubmitForm = (data) => {
    actions.submitStep(data, 2);
    history.push(PORTAL_PATHS.onboarding.createAccount.step(3));
  };

  React.useEffect(() => {
    actions.requestMembersInstitutions();
  }, []);

  return (
    <OnboardingFormTransition formPath={PORTAL_PATHS.onboarding.createAccount.step(2)}>
      <CreateAccountFormHeader stepIndex={2} formName="Company Details" />

      <div className="create-account-form step-2">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmitForm)} noValidate>
            {institutions?.length > 0 && (
              <Dropdown
                id="company-id"
                name="companyId"
                control={form.control}
                options={institutionOptions}
                placeholder="Company Name"
                labelFor="company-name"
                labelText="Company Name"
                onClickDropdownOption={onClickDropdownOption}
                validation={{
                  required: {
                    value: true,
                    message: 'Please select an option.'
                  }
                }}
              />
            )}

            <TextField
              id="company-department"
              labelFor="company-department"
              labelText="Department"
              placeholder="Department"
              name="department"
              defaultValue={formData.department}
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              control={form.control}
            />

            <TextField
              id="company-position-title"
              labelFor="company-position-title"
              labelText="Position Title"
              placeholder="Position Title"
              name="positionTitle"
              defaultValue={formData.positionTitle}
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              control={form.control}
            />

            <br />

            <div className="row">
              <div className="btn-back col-xs-6">
                <FormBackButton path={PORTAL_PATHS.onboarding.createAccount.index} />
              </div>

              <div className="btn-next col-xs-6 ">
                <Button
                  variant={'btn-fill btn-full-width'}
                  icon={iconArrowThin}
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </OnboardingFormTransition>
  );
}
