import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { iconAccordionOpen, iconAccordionClose } from '@/images/icons';

Accordion.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    small: PropTypes.string,
    blurb: PropTypes.string
  })
};

function Accordion({ item }) {
  const [active, isActive] = useState(false);
  const ref = useRef(null);

  const handleActive = () => {
    ref.current.scrollIntoView({
      block: 'center',
      inline: 'nearest',
      behavior: 'smooth'
    });
    isActive(!active);
  };

  return (
    <div className="accordion-item" ref={ref}>
      <div className="accordion-title">
        <button onClick={handleActive}>
          <div className="board-member">
            <img
              src={item.image}
              alt={`Photo of ${item.name}`}
              className="board-member-image"
            />
            <div>
              <h3>{item.name}</h3>
              <h5>{item.role}</h5>
              {item.small && <h6>{item.small}</h6>}
            </div>
          </div>
          <span className="icon">{active ? iconAccordionClose : iconAccordionOpen}</span>
        </button>
      </div>
      {active && (
        <div
          className="accordion-content"
          dangerouslySetInnerHTML={{ __html: item.blurb }}
        ></div>
      )}
    </div>
  );
}

export default Accordion;
