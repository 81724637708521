import PropTypes from 'prop-types';
import React from 'react';
// Components
import { EventCard, Tag } from '@/components/Portal';

CardListing.propTypes = {
  results: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          heading: PropTypes.string,
          start_date: PropTypes.string,
          end_date: PropTypes.string,
          location: PropTypes.string
        })
      )
    )
  )
};

export default function CardListing({ results }) {
  const currentYear = new Date().getFullYear();

  return Object.keys(results).map((year) => {
    const months = results[year];

    return Object.keys(months).map((month) => {
      const events = months[month];
      const tagLabel = year == currentYear ? month : `${month} - ${year}`;

      return (
        <div key={month} className="card-listing">
          <Tag label={tagLabel} />
          {events.map((event) => (
            <EventCard
              key={event.id}
              id={event.id}
              heading={event.heading}
              start_date={event.start_date}
              end_date={event.end_date}
              location={event.location}
            />
          ))}
        </div>
      );
    });
  });
}