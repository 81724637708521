import { call, put, takeEvery } from 'redux-saga/effects';
import { SUBSCRIPTION } from '../constants';
import { successfulSubscription, failedSubscription } from '../actions';
import rootDomain from '../rootDomain';
const api = (url) =>
  fetch(url, {
    method: 'post'
  }).then((res) => res.json());

export function* requestSubscriptionAsync(action) {
  const ROOT = rootDomain();
  const subscriptionUrl = `${ROOT}/api/v1/signup?email=${action.payload.email}`;

  try {
    const response = yield call(api, subscriptionUrl);
    yield put(successfulSubscription(response));
  } catch (err) {
    yield put(failedSubscription(err));
  }
}

export default function* watchRequestSubscription() {
  yield takeEvery(SUBSCRIPTION.REQUEST_SUBSCRIPTION, requestSubscriptionAsync);
}
