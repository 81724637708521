import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { updateAccountDetails } from '@/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@/store/selectors';
import { Button, TextField } from '@/components';
import { iconArrowThin, iconTick } from '@/images/icons';
import { validationRegex } from '@/utils';

export default function UpdateAccountDetails() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [isUserBeingUpdated, setIsUserBeingUpdated] = useState(false);
  const [isUserUpdated, setIsUserUpdated] = useState(false);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'all'
  });

  const { isDirty, isValid } = form.formState;
  const isSubmitDisabled = !isDirty || !isValid;

  const onSubmitForm = (data) => {
    setIsUserBeingUpdated(true);
    dispatch(
      updateAccountDetails({
        first_name: data.firstName,
        last_name: data.lastName,
        contact_number: data.phoneNumber,
        institution_department: data.department,
        institution_title: data.positionTitle
      })
    );
  };

  useEffect(() => {
    if (isUserBeingUpdated) {
      setIsUserUpdated(!isUserUpdated);
      setIsUserBeingUpdated(!isUserBeingUpdated);
    }
  }, [user]);

  return (
    <div className="form my-account account">
      <div className="row start-md">
        <div className="col-xs-12 col-md-10">
          <h2 className="form-title H2">Update account details</h2>
        </div>

        <div className="col-xs-12 col-md-10">
          <FormProvider {...form}>
            <form noValidate>
              <fieldset className="fieldset">
                <legend className="legend H3">Contact Details</legend>

                <TextField
                  id="member-first-name"
                  control={form.control}
                  name="firstName"
                  labelFor="First Name"
                  labelText="First Name"
                  placeholder="First Name"
                  validation={{
                    required: {
                      value: true,
                      message: 'This field is required.'
                    }
                  }}
                  defaultValue={user.first_name || ''}
                />

                <TextField
                  id="member-last-name"
                  name="lastName"
                  control={form.control}
                  labelFor="Last Name"
                  labelText="Last Name"
                  placeholder="Last Name"
                  validation={{
                    required: {
                      value: true,
                      message: 'This field is required.'
                    }
                  }}
                  defaultValue={user.last_name}
                />

                <TextField
                  id="member-tel"
                  type="tel"
                  control={form.control}
                  name="phoneNumber"
                  labelFor="Phone Number"
                  labelText="Phone Number"
                  placeholder="Phone Number"
                  validation={{
                    required: {
                      value: true,
                      message: 'This field is required.'
                    },
                    pattern: {
                      value: validationRegex.ausPhoneNumber,
                      message: 'Please enter a valid phone number.'
                    }
                  }}
                  defaultValue={user.contact_number}
                />
              </fieldset>

              <fieldset className="fieldset">
                <legend className="legend H3">Company Details</legend>

                <TextField
                  id="member-company-department"
                  control={form.control}
                  labelFor="member-company-department"
                  labelText="Department"
                  placeholder="Department"
                  name="department"
                  validation={{
                    required: {
                      value: true,
                      message: 'This field is required.'
                    }
                  }}
                  defaultValue={user.institution_department}
                />

                <TextField
                  id="member-position-title"
                  control={form.control}
                  labelFor="member-position-title"
                  labelText="Position Title"
                  placeholder="Position Title"
                  name="positionTitle"
                  validation={{
                    required: {
                      value: true,
                      message: 'This field is required.'
                    }
                  }}
                  defaultValue={user.institution_title}
                />
              </fieldset>

              <Button
                variant={'btn btn-fill'}
                icon={iconArrowThin}
                isDisabled={isSubmitDisabled}
                type="submit"
                onClick={form.handleSubmit(onSubmitForm)}
              >
                Save Changes
              </Button>

              <div className={`form-success ${isUserUpdated ? 'display' : 'hidden'}`}>
                <span className="icon">{iconTick}</span>
                Your updates have been saved
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
