import React, { useState } from 'react';
import useMedia from 'use-media';
import { Motion } from '@/components/common';
import CollapsibleMobileToggle from '@/components/common/Collapsible/CollapsibleMobileToggle.jsx';
import Animation from './Animation'

export default function MenuToggle({
  mobileToggleLabel,
  toggleVariant,
  children
}) {
  const isDesktop = useMedia('(min-width: 1024px)');
  const [isOpenCollapsible, setIsOpenCollapsible] = useState(false);

  const onClickCloseCollapsible = () => {
    setIsOpenCollapsible(!isOpenCollapsible);
  };
  
  if (isDesktop){
    return children
  }

  return (
    <>
      {mobileToggleLabel &&
        <CollapsibleMobileToggle
          name={mobileToggleLabel}
          onClick={onClickCloseCollapsible}
          isOpenCollapsible={isOpenCollapsible}
          variant={toggleVariant}
        />
      }
      <Motion
        animate={isOpenCollapsible}
        animationConfig={Animation.menuToggle}
        classNames="menu-toggle"
      >
        {children}
      </Motion>
    </>
  );
}
