import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '@/components/Layout';
import {
  CustomerStories,
  HeroSection,
  KeyBenefits,
  Testimonials,
  KeyMetrics,
  IndustryAdvocate,
  SectorNews,
  DiscoverMembers
} from '@/components/HomePage';
import SEO from '@/components/SEO';
import { requestHomepage } from '@/store/actions';

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestHomepage());
  }, []);

  return (
    <Layout>
      <SEO title="Home | Customer Owned Banking Association | Australia | COBA" />
      <div id="home-page" data-testid="home-page">
        <HeroSection />
        <KeyBenefits />
        <Testimonials />
        <CustomerStories />
        <KeyMetrics />
        <IndustryAdvocate />
        <SectorNews />
        <DiscoverMembers />
      </div>
    </Layout>
  );
};

export default HomePage;
