import React from 'react';

export default function UserHeading() {
  return (
    <div className="heading">
      <div className="name h5">name</div>
      <div className="email h5">email</div>
      <div className="actions h5">
      </div>
    </div>
  );
}
