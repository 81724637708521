import axios from 'axios';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { FOOTER } from '../constants';
import { requestFooterSuccess, requestFooterFailed } from '../actions';
import { BASE_URL, API } from '@/config';
import { getUserToken } from '@/store/selectors';

export function* footerSaga(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(requestFooterSuccess(response));
  } catch (err) {
    yield put(requestFooterFailed(err));
  }
}

export function* watchRequestFooter() {
  yield takeLatest(FOOTER.REQUEST_FOOTER, footerSaga);
}

const sagas = [fork(watchRequestFooter)];

export default sagas;
