import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { compact, first } from 'lodash';
import { Button, Motion, TextField } from '@/components';
import { iconArrowThin, iconArrowThinLeft, iconTick } from '@/images/icons';
import AddressAutocompleteField from './AddressAutocompleteField';
import { createNewUserBranch } from '@/store/actions';
import { validationRegex } from '@/utils';
import { useGoogleMapsAPI, Status } from '@/hooks';
import { getGeocode } from 'use-places-autocomplete';

export default function AddNewBranch({ showAddNewBranch, onClickCancelAddNewBranch }) {
  const dispatch = useDispatch();
  const { status } = useGoogleMapsAPI();

  const [branchAddress, setBranchAddress] = React.useState({
    branch_address_suite: '',
    streetNumber: '',
    street: '',
    branch_address_postcode: '',
    branch_address_state: '',
    branch_address_city: ''
  });

  const form = useForm({
    mode: 'all',
    reValidateMode: 'all'
  });

  const { isSubmitted, isValid } = form.formState;
  const isSubmitDisabled = !isValid;

  const FIELD_NAMES = [
    'branch_address_suite',
    'branch_address_line1',
    'branch_address_city',
    'branch_address_state',
    'branch_address_postcode'
  ];

  const addressFieldValues = form.watch(FIELD_NAMES);

  const onSubmitForm = (data) => {
    dispatch(
      createNewUserBranch({
        latitude: data['branch_address_latitude'],
        longitude: data['branch_address_longitude'],
        branch_address_suite: data['branch_address_suite'],
        branch_address_line1: data['branch_address_line1'],
        branch_address_city: data['branch_address_city'],
        branch_address_state: data['branch_address_state'],
        branch_address_postcode: data['branch_address_postcode'],
        branch_phone: data['branch_phone'],
        branch_sector: 'AUS'
      })
    );
  };

  const onChangeTextField = (ev) => {
    form.setValue(ev.target.name, ev.target.value, { shouldValidate: true });

    setBranchAddress({
      ...branchAddress,
      [ev.target.name]: ev.target.value
    });
  };

  React.useEffect(() => {
    form.register('branch_address_latitude', {
      required: {
        value: true,
        message: 'Unable to process address latitude. Please try again later.'
      }
    });

    form.register('branch_address_longitude', {
      required: {
        value: true,
        message: 'Unable to process address longitude. Please try again later.'
      }
    });
  }, []);

  React.useEffect(() => {
    FIELD_NAMES.forEach((fieldName) => {
      const fieldValue =
        fieldName === 'branch_address_line1'
          ? `${branchAddress?.streetNumber} ${branchAddress?.street}`
          : branchAddress?.[fieldName];

      if (!!fieldValue) {
        form.setValue(fieldName, fieldValue, { shouldValidate: true });
      }
    });
  }, [branchAddress]);

  React.useEffect(() => {
    const address = compact(addressFieldValues).join(' ');

    if (!!window?.google?.maps?.Geocoder) {
      getGeocode({ address })
        .then((results) => {
          const result = first(results);

          if (!!result) {
            form.setValue('branch_address_latitude', result?.geometry?.location?.lat(), {
              shouldValidate: true
            });
            form.setValue('branch_address_longitude', result?.geometry?.location?.lng(), {
              shouldValidate: true
            });
          }
        })
        .catch(() => {});
    }
  }, [addressFieldValues]);

  return (
    <div className="form my-account account">
      <Motion animate={!!showAddNewBranch}>
        <Button
          variant={'btn btn-text btn-go-back'}
          iconLeft={iconArrowThinLeft}
          onClick={onClickCancelAddNewBranch}
        >
          Go Back
        </Button>

        <br />
        <br />

        <FormProvider {...form}>
          <form
            className="form add-new-branch"
            onSubmit={form.handleSubmit(onSubmitForm)}
            noValidate
          >
            <legend className="legend small">Add branch details below</legend>

            <TextField
              id="branch_phone"
              control={form.control}
              name="branch_phone"
              labelFor="branch_phone"
              labelText="Phone Number"
              placeholder="Phone Number"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.ausPhoneNumber,
                  message: 'Please enter a valid phone number.'
                }
              }}
              type="tel"
              onChangeField={onChangeTextField}
            />

            {status === Status.SUCCESS && (
              <AddressAutocompleteField
                control={form.control}
                setBranchAddress={setBranchAddress}
              />
            )}

            <TextField
              id="branch_address_suite"
              control={form.control}
              name="branch_address_suite"
              labelFor="branch_address_suite"
              labelText="Suite/Unit (Optional)"
              placeholder="Suite/Unit"
              onChangeField={onChangeTextField}
            />

            <TextField
              id="branch_address_city"
              control={form.control}
              name="branch_address_city"
              labelFor="branch_address_city"
              labelText="City"
              placeholder="City"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              onChangeField={onChangeTextField}
            />

            <TextField
              id="branch_address_state"
              control={form.control}
              name="branch_address_state"
              labelFor="branch_address_state"
              labelText="State"
              placeholder="State"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              onChangeField={onChangeTextField}
            />

            <TextField
              id="branch_address_postcode"
              control={form.control}
              name="branch_address_postcode"
              labelFor="branch_address_postcode"
              labelText="Postcode"
              placeholder="Postcode"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.ausPostcode,
                  message: 'Please enter a valid postcode.'
                }
              }}
              onChangeField={onChangeTextField}
            />

            <input type="text" name="branch_address_latitude" hidden />
            <input type="text" name="branch_address_longitude" hidden />

            <div className={`form-success ${isSubmitted ? 'display' : 'hidden'}`}>
              <span className="icon">{iconTick}</span>
              Thanks - your update has been submitted and is awaiting approval.
            </div>

            {!isSubmitted && (
              <div className="btn-group">
                <Button
                  variant={'btn btn-fill'}
                  icon={iconArrowThin}
                  isDisabled={isSubmitDisabled}
                >
                  Add Branch
                </Button>

                <Link
                  to="#"
                  className={'btn btn-cancel btn-text btn-text-underline'}
                  onClick={onClickCancelAddNewBranch}
                >
                  Cancel
                </Link>
              </div>
            )}
          </form>
        </FormProvider>
      </Motion>
    </div>
  );
}
