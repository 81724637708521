export const selectRateInfo = (state) => state.rateInfoReducer.rateInfo;

import { createSelector } from 'reselect';

export const selectRateInfoState = (state) => state.rateInfoReducer;

export const selectRateInfoContent = createSelector(
  selectRateInfoState,
  (state) => state.info
);

export const selectRateInfoActive = createSelector(
  selectRateInfoState,
  (state) => state.isActive
);
