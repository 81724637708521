import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { iconPortalFilter, iconCloseSmall } from '@/images/icons';
import CollapsibleMobileToggle from '@/components/common/Collapsible/CollapsibleMobileToggle.jsx';

PortalPageMenuModal.propTypes = {
  toggleLabel: PropTypes.string,
  toggleVariant: PropTypes.string,
	children: PropTypes.node
}

export default function PortalPageMenuModal({
  toggleLabel,
  toggleVariant,
  children
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  }

  function openModal() {
    setModalIsOpen(true);
  }
  
  return (
    <div>
      <CollapsibleMobileToggle
        name={toggleLabel}
        onClick={openModal}
        icon={iconPortalFilter}
        variant={toggleVariant}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Filter"
        className='portal-modal--filter'
        overlayClassName='portal-modal__overlay--filter'
        appElement={document.getElementById('app')}
      >
        <CollapsibleMobileToggle
          name={toggleLabel}
          onClick={closeModal}
          icon={iconCloseSmall}
          isOpenCollapsible={true}
          variant={toggleVariant}
        />
        {children}
      </Modal>
    </div>
  );
}
