import moment from 'moment';
import { find, head, isEmpty } from 'lodash';
import { getContent } from './content';
import { createSelector } from 'reselect';

export const getArticles = (state) => state.articlesReducer.articles;

export const getArticle = (state) => state.articlesReducer.article;

export const getFooterArticles = (state) => state.articlesReducer.footerArticles;

export const getArticleType = createSelector(getArticle, (article) => article.type);

export const getFooterArticlesConfig = createSelector(getArticle, (article) => {
  if (!isEmpty(article.latest_articles)) {
    return JSON.parse(article.latest_articles);
  }

  if (!isEmpty(article.latest_articles)) {
    return JSON.parse(article.related_articles);
  }

  return undefined;
});

export const getIsLatestArticles = createSelector(getArticle, (article) => {
  return !isEmpty(article) ? !isEmpty(JSON.parse(article.latest_articles)) : false;
});

export const getIsRelatedArticles = createSelector(getArticle, (article) => {
  return !isEmpty(article) ? !isEmpty(JSON.parse(article.related_articles)) : false;
});

export const getArticleSortedFooterArticles = createSelector(
  getFooterArticles,
  (latestArticles) => {
    if (isEmpty(latestArticles)) {
      return [];
    }

    const sortedArticles = latestArticles.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    return sortedArticles.slice(0, 2);
  }
);

export const getArticleBanner = createSelector(getArticle, (article) => ({
  id: article.id,
  author: article.author,
  heading: article.heading,
  featureImageId: article.feature_image,
  createdAt: article.created_at,
  categoryName: !!article.type && !!article.type.name ? article.type.name : ''
}));

export const getArticleFeatureImageId = createSelector(
  getArticle,
  (article) => article.feature_image
);

export const getArticleContact = createSelector(
  getArticle,
  (article) => article.sidebar_contact
);

export const getArticleDownloads = createSelector(
  getArticle,
  (article) => article.sidebar_download
);

export const getArticleDownloadIds = createSelector(getArticle, (article) =>
  !isEmpty(article)
    ? article.sidebar_download.reduce((a, c) => [...a, Number(c.download_item)], [])
    : []
);

export const getArticleSidebarDownloadItems = createSelector(
  [getArticleDownloadIds, getContent, getArticleDownloads],
  (downloadIds, content, downloads) => {
    if (isEmpty(content) || !content.contentData.data || isEmpty(downloads)) {
      return [];
    }

    return content.contentData.data.reduce((a, contentItem) => {
      if (downloadIds.includes(contentItem.id)) {
        const downloadItem =
          downloads.find(
            (download) => Number(download.download_item) === contentItem.id
          ) || {};

        const downloadContentItem = { ...contentItem, ...downloadItem };

        return [...a, downloadContentItem];
      }

      return a;
    }, []);
  }
);

export const getTotalArticles = (state) => state.articlesReducer.totalArticles;

export const getNumberOfItemsPerPage = (state) => state.articlesReducer.itemsPerPage;

export const getCurrentArticlesPage = (state) => state.articlesReducer.currentPage;

export const getArticleById = (id) =>
  createSelector(getArticles, (articles) => head(find(articles, id)));

// * Media
export const getFirstFeaturedArticle = createSelector(getArticles, (articles) => {
  return articles.find((article) => article.is_featured == 1);
});

export const getArticlesByYear = (year) =>
  createSelector(getArticles, (articles) => {
    return articles.filter((article) => {
      return moment(article.created_at).format('YYYY') === String(year);
    });
  });

export const getArticlesByQuery = (query) =>
  createSelector(getArticles, (articles) => {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    const words = query
      .split(/\s+/g)
      .map((s) => s.toLowerCase().trim())
      .filter((s) => !!s);

    const hasTrailingSpace = query.endsWith(' ');

    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join('') + '.+',
      'gi'
    );

    return articles.filter((item) => {
      return searchRegex.test(item.heading);
    });
  });

export const getArticlesByQueryAndYear = (query, year) =>
  createSelector(getArticlesByQuery(query), (articles) =>
    articles.filter(
      (article) => moment(article.created_at).format('YYYY') === String(year)
    )
  );
