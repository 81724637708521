import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePrevLocation = () => {
  const location = useLocation();
  const [locationState, setLocationState] = useState({ current: null, previous: null });

  useEffect(() => {
    if (location.pathname !== locationState.current?.pathname) {
      setLocationState((prevState) => ({ current: location, previous: prevState.current }))
    }
  }, [locationState, location.pathname])

  return {previousLocation: locationState.previous, currentLocation: location}
}

export default usePrevLocation;