import { JOBS } from '../constants';

const requestJobVacanciesFirstPage = (payload) => ({
  type: JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE,
  payload: {
    ...payload,
    page: 1
  }
});

const requestJobVacanciesFirstPageSuccess = (payload) => ({
  type: JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE_SUCCESS,
  payload
});

const requestJobVacanciesFirstPageFailed = (payload) => ({
  type: JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE_FAILED,
  payload
});

const requestJobDetails = (payload) => ({
  type: JOBS.REQUEST_JOB_DETAILS,
  payload
});

const requestJobDetailsSuccess = (payload) => ({
  type: JOBS.REQUEST_JOB_DETAILS_SUCCESS,
  payload
});

const requestJobDetailsFailed = (payload) => ({
  type: JOBS.REQUEST_JOB_DETAILS_FAILED,
  payload
});

const submitJobListing = (payload) => ({
  type: JOBS.SUBMIT_JOB_LISTING,
  payload
});

const submitJobListingSuccess = (payload) => ({
  type: JOBS.SUBMIT_JOB_LISTING_SUCCESS,
  payload
});

const submitJobListingFailed = (payload) => ({
  type: JOBS.SUBMIT_JOB_LISTING_FAILED,
  payload
});

const resetJobListing = () => ({
  type: JOBS.RESET_JOB_LISTING
});

const requestJobVacanciesNextPage = (payload) => ({
  type: JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE,
  payload
});

const requestJobVacanciesNextPageSuccess = (payload) => ({
  type: JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE_SUCCESS,
  payload
});

const requestJobVacanciesNextPageFailed = (payload) => ({
  type: JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE_FAILED,
  payload
});

const clearQueriesSearch = () => ({
  type: JOBS.CLEAR_SEARCH_JOB
});

export {
  requestJobVacanciesFirstPage,
  requestJobVacanciesFirstPageSuccess,
  requestJobVacanciesFirstPageFailed,
  requestJobVacanciesNextPage,
  requestJobVacanciesNextPageSuccess,
  requestJobVacanciesNextPageFailed,
  requestJobDetails,
  requestJobDetailsSuccess,
  requestJobDetailsFailed,
  submitJobListing,
  submitJobListingSuccess,
  submitJobListingFailed,
  resetJobListing,
  clearQueriesSearch
};
