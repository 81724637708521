import { COORDINATES } from '../constants';

export const requestCoordinates = (payload, page) => ({
  type: COORDINATES.REQUEST_COORDINATES,
  payload,
  page
});

export const receivedCoordinates = (payload) => ({
  type: COORDINATES.RECEIVED_COORDINATES,
  payload
});

export const failedCoordinates = (payload) => ({
  type: COORDINATES.FAILED_COORDINATES,
  payload
});

export const clearCoordinates = (payload) => ({
  type: COORDINATES.CLEAR_COORDINATES,
  payload
});
