import PropTypes from 'prop-types';
import React from 'react'
import useMedia from 'use-media';

PortalPageMenu.propTypes = {
	mobile: PropTypes.bool,
	desktop: PropTypes.bool,
	children: PropTypes.node
}

export default function PortalPageMenu({mobile=false, desktop=false, children}) {
	const isMobile = useMedia('(max-width: 1023px)');

	// Render for Mobile or Desktop
	if ( (!isMobile && mobile) || (isMobile && desktop)) {
		return null
	}

  return (
		<aside className="portal-page-menu col-xs-12 col-md-4">
			{children}
		</aside>
	)
}
