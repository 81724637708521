import axios from 'axios';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { SUPPORT } from '../constants';
import { 
  requestContactSubjectsSuccess, 
  requestContactSubjectsFailed,
  submitContactFormSuccess,
	submitContactFormFailed
} from '../actions';
import { BASE_URL, API, PER_PAGE } from '@/config';
import { getUserToken } from '@/store/selectors';

export function* getContactSubjects(action) {
  const token = yield select(getUserToken);
  
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(requestContactSubjectsSuccess(response));
  } catch (err) {
    yield put(requestContactSubjectsFailed(err));
  }
}

export function* postContactForm(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });


  try {
    const response = yield call(request);
    yield put(submitContactFormSuccess(response));
  } catch (err) {
    yield put(submitContactFormFailed(err));
  }
}

export function* watchGetContactSubjects() {
  yield takeLatest(SUPPORT.REQUEST_CONTACT_SUBJECTS, getContactSubjects);
}

export function* watchPostContactForm() {
  yield takeLatest(SUPPORT.SUBMIT_CONTACT_FORM, postContactForm);
}

const sagas = [
  fork(watchGetContactSubjects),
  fork(watchPostContactForm),
];

export default sagas;