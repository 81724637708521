import PropTypes from 'prop-types';
import React from 'react';
import{ PortalPageMenuNavigation } from '@/components/Portal';

StaffDirectoryMenu.propTypes = {
	departments: PropTypes.array,
	mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function StaffDirectoryMenu ({ departments, mobile, desktop }) {
	return (
		<PortalPageMenuNavigation 
			mobile={mobile} 
			desktop={desktop}
			items={departments}
			title="Staff Directory"
		/>
	);
};
