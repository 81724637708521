import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pluralize } from 'humanize-plus';
import { isEmpty } from 'lodash';
import qs from 'qs';
// Store
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    clearFilters,
    requestNewsArticlesFirstPage,
    requestNewsArticlesNextPage
} from '@/store/actions';
import { selectNewsArticles, selectIsArticlesLoading } from '@/store/selectors';
// Components
import { NewsroomArticleCard, NoArticlesFound, LoadMore } from '@/components/Portal';

function Listing({ results }) {
    if (!results?.length > 0) {
        return null;
    }

    return results.map((item) => (
        <NewsroomArticleCard key={item.id} id={item.id} {...item} />
    ));
}

export default function NewsListing() {
    const location = useLocation();
    const filter = qs.parse(location.search.substring(1));

    const actions = bindActionCreators(
        {
            clearFilters,
            requestNewsArticlesFirstPage,
            requestNewsArticlesNextPage
        },
        useDispatch()
    );

    const isLoading = useSelector(selectIsArticlesLoading);
    const { items, hasMore, total, currentPage } = useSelector(
        selectNewsArticles,
        shallowEqual
    );
    const resultsDisplaying = items.length;
    const resultsTotal = total;
    const articleListing = items;

    const onClickLoadMore = (perPage) => () => {
        actions.requestNewsArticlesNextPage({
            page: currentPage + 1,
            per_page: perPage
        });
    };

    useEffect(() => {
        // If not redirected from an clicking a tag in an Article
        if (isEmpty(filter)) {
            actions.clearFilters();
        }

        actions.requestNewsArticlesFirstPage();
    }, []);

    return (
        <div className="newsroom listing">
            <div className="listing-counter">
                {isLoading
                    ? 'Loading...'
                    : `Showing ${resultsDisplaying} of ${total} ${pluralize(total, 'result')}`}
            </div>

            <div className="card-listing">
                {!isLoading && <Listing results={articleListing} />}

                {!isLoading && isEmpty(articleListing) && <NoArticlesFound />}

                <LoadMore
                    isLoading={isLoading}
                    hasMore={hasMore}
                    onClickLoadMore={onClickLoadMore}
                    resultsDisplaying={resultsDisplaying}
                    resultsTotal={resultsTotal}
                />
            </div>
        </div>
    );
}
