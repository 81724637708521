import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components';
import { iconArrowThin } from '@/images/icons';
import { PORTAL_PATHS } from '@/config';

export default function CreateAccountEmailVerified() {
  return (
    <div className="create-account-form form-submitted">
      <br />

      <h4 className="form-title">Thanks for verifying your email.</h4>
      <p>
        Once your request has been approved by your organisation, we'll notify you by
        email.
      </p>
      <br />
      <Button variant={'btn-fill btn-full-width'} icon={iconArrowThin}>
        <Link to={PORTAL_PATHS.home}>Back to home</Link>
      </Button>
    </div>
  );
}
