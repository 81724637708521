import { EMAIL_SUBSCRIPTIONS } from '../constants';

const requestEmailSubscriptions = () => ({
  type: EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS
});

const requestEmailSubscriptionsSuccess = (payload) => ({
  type: EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS_SUCCESS,
  payload
});

const requestEmailSubscriptionsFailed = (payload) => ({
  type: EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS_FAILED,
  payload
});

const updateEmailSubscriptions = (payload) => ({
  type: EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS,
  payload
});

const updateEmailSubscriptionsSuccess = (payload) => ({
  type: EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS_SUCCESS,
  payload
});

const updateEmailSubscriptionsFailed = (payload) => ({
  type: EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS_FAILED,
  payload
});

export {
  requestEmailSubscriptions,
  requestEmailSubscriptionsSuccess,
  requestEmailSubscriptionsFailed,
  updateEmailSubscriptions,
  updateEmailSubscriptionsSuccess,
  updateEmailSubscriptionsFailed
};
