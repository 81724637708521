import React from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

export default function OnboardingFormTransition({ children, formPath }) {
  const { pathname } = useLocation();

  const messageAnimation = {
    initial: {
      opacity: 0,
      translateX: '-5%'
    },
    in: {
      opacity: 1,
      translateX: 0,
      transition: {
        translateX: {
          duration: 0.3,
          delay: 0.3
        },
        opacity: {
          duration: 0.3,
          delay: 0.32
        }
      }
    },
    out: {
      opacity: 0,
      translateX: '-100%',
      transition: {
        translateX: {
          duration: 0.3
        },
        opacity: {
          duration: 0.3
        }
      }
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        key="detail"
        initial="initial"
        animate={pathname.includes(formPath) ? 'in' : 'out'}
        exit="out"
        variants={messageAnimation}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
