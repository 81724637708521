import React from 'react';
import { Link } from 'react-router-dom';
import { NavMenuContext } from '@/context';
import { iconArrowThin, iconCross, iconChevronLeft } from '@/images/icons';

const NavSubMenu = () => {
  const {
    isMobile,
    onClickCloseSubMenu,
    selectedMenuTitle,
    selectedMenuData
  } = React.useContext(NavMenuContext);

  return (
    <div className="menu-dropdown" data-testid="menu-dropdown">
      <div className="blurred-background"></div>
      <div className="content">
        <div className="control">
          <span className="close">
            <a href="#" onClick={onClickCloseSubMenu}>
              <span className="icon">
                {isMobile ? iconChevronLeft : iconCross}
              </span>
              {isMobile && <h3>{selectedMenuTitle}</h3>}
            </a>
          </span>
        </div>

        <div className="details">
          {selectedMenuData &&
            selectedMenuData.map(data => {
              return (
                <div key={data.heading} className="section">
                  <h3>{data.heading}</h3>
                  <ul>
                    {data.links.map(link => {
                      return (
                        <li key={link.text}>
                          <Link to={`${link.url}`}>
                            {link.text}
                            <span className="icon">{iconArrowThin}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default NavSubMenu;
