import { call, put, select, takeEvery } from 'redux-saga/effects';
import { RATE_EXPERIENCE } from '../constants';
import { successfulSubscription, failedSubscription } from '../actions';
import { getUserToken } from '@/selectors';
import { API, BASE_URL } from '@/config';
import axios from 'axios';

export function* rateExperienceAsync(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(successfulSubscription(response));
  } catch (err) {
    yield put(failedSubscription(err));
  }
}

export default function* watchRateExperienceSubscription() {
  yield takeEvery(RATE_EXPERIENCE.RATE_EXPERIENCE, rateExperienceAsync);
}
