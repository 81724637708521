import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { requestSubscription } from '@/store/actions';
import {
  getIsSubscriptionLoading,
  getSubscriptionSubmissionSuccess,
  getSubscriptionSubmissionFailed
} from '@/store/selectors/subscription';
import { iconArrowThin } from '@/images/icons';
import { EmailField } from '@/components';

const SubscribeForm = ({ heading }) => {
  const isLoading = useSelector(getIsSubscriptionLoading);
  const isSuccessful = useSelector(getSubscriptionSubmissionSuccess);
  const isFailed = useSelector(getSubscriptionSubmissionFailed);

  const dispatch = useDispatch();
  const [formEmail, setFormEmail] = useState('');
  const { control, handleSubmit, formState } = useForm();

  const { errors } = formState;

  const onChangeEmail = (e) => {
    setFormEmail(e.target.value);
  };

  const onSubmit = (data) => {
    dispatch(requestSubscription(data));
  };

  const handleResetForm = () => {
    setFormEmail('');
  };

  useEffect(() => {
    !!isSuccessful && handleResetForm();
  }, [isSuccessful]);

  return (
    <section className="subscribe-form">
      <div className="content">
        <h4>{heading}</h4>

        <p>
          To receive our latest media releases and policy submissions sent straight to
          your inbox, subscribe to COBA Alerts.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <div>
              <EmailField onChangeField={onChangeEmail} control={control} value={formEmail} />

              <button type="submit" className={`btn ${isLoading && ' disabled'}`}>
                <span className="icon">{iconArrowThin}</span>
              </button>
            </div>

            {errors.email && errors.email.type === 'required' && (
              <span className="error-msg">
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>Email is
                required
              </span>
            )}

            {errors.email && errors.email.type === 'pattern' && (
              <span className="error-msg">
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>Please
                enter a valid email address
              </span>
            )}
          </div>
        </form>

        {isFailed && <p className="error-msg">Something went wrong. Please try again.</p>}
        {isSuccessful && <p className="success-msg">Successful subscription</p>}
      </div>
    </section>
  );
};

export default SubscribeForm;
