import { USER } from '../constants';

const initialState = {
  user: {},
  isUserRegistered: false,
  isSubmitting: false,
  error: undefined
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER.CHECK_TOKEN:
    case USER.CHECK_TOKEN_SUCCESS:
    case USER.REGISTER_USER:
    case USER.FORGOT_PASSWORD:
    case USER.FORGOT_PASSWORD_SUCCESS:
    case USER.UPDATE_ACCOUNT_DETAILS:
    case USER.UPDATE_ACCOUNT_PASSWORD:
    case USER.REQUEST_USER_DIVISIONS:
    case USER.REQUEST_BRANCHES:
    case USER.RESET_PASSWORD:
    case USER.CREATE_NEW_DIVISION:
    case USER.CREATE_NEW_BRANCH:
    case USER.UPDATE_DIVISION:
      return {
        ...state,
        error: initialState.error,
        isSubmitting: true
      };

    case USER.REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data.data
        },
        isUserRegistered: true
      };

    case USER.LOGIN_USER:
      return {
        ...state,
        error: initialState.error
      };

    case USER.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload.user
        },
        deviceName: action.payload.deviceName,
        token: action.payload.token
      };

    case USER.UPDATE_ACCOUNT_DETAILS_SUCCESS:
    case USER.UPDATE_ACCOUNT_PASSWORD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data.data
        }
      };

    case USER.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmitting: false
      };

    case USER.REGISTER_USER_FAILED:
    case USER.LOGIN_USER_FAILED:
    case USER.FORGOT_PASSWORD_FAILED:
    case USER.UPDATE_ACCOUNT_DETAILS_FAILED:
    case USER.UPDATE_ACCOUNT_PASSWORD_FAILED:
    case USER.REQUEST_USER_DIVISIONS_FAILED:
    case USER.REQUEST_BRANCHES_FAILED:
    case USER.RESET_PASSWORD_FAILED:
    case USER.UPDATE_DIVISION_FAILED:
    case USER.CREATE_NEW_DIVISION_FAILED:
    case USER.CREATE_NEW_BRANCH_FAILED: {
      return {
        ...state,
        error: action.payload?.response?.data || action.payload?.data?.data,
        isSubmitting: false
      };
    }

    case USER.REQUEST_USER_DIVISIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          divisions: action.payload?.data?.data
        }
      };

    case USER.REQUEST_BRANCHES_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          branches: action.payload.data.data
        }
      };

    case USER.CHECK_TOKEN_FAILED:
    case USER.LOGOUT_USER:
      return {
        ...initialState
      };

    case USER.UPDATE_DIVISION_SUCCESS: {
      const divisionsData = action.payload?.data?.data || [];

      return {
        ...state,
        user: {
          ...state.user,
          divisions: [...state.user.divisions, ...divisionsData]
        }
      };
    }

    case USER.CREATE_NEW_DIVISION_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user
        }
      };
    }

    case USER.CREATE_NEW_BRANCH_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          branches: [...state.user.branches, action.payload?.data]
        }
      };
    }

    default:
      return state;
  }
};

export default userReducer;
