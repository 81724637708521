import { EMAIL_SUBSCRIPTIONS } from '../constants';

const initialState = {
  errors: [],
  hasFailed: false,
  isLoading: false,
  isUpdating: false,
  isSuccessful: false,
  campaigns: [],
  subscribed: [],
  activeTags: []
};

const emailSubscriptionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS:
      return {
        ...state,
        errors: [],
        hasFailed: false,
        isLoading: true,
        isSuccessful: false
      };

    case EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        hasFailed: false,
        isLoading: false,
        isSuccessful: true,
        campaigns: action?.payload?.data?.data['lists'] || [],
        subscribedCampaigns: action?.payload?.data?.data['subscribed'] || [],
        subscribedTags: action?.payload?.data?.data['active_tags'] || []
      };

    case EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        errors: action?.payload?.data,
        hasFailed: true,
        isLoading: false,
        isSuccessful: false
      };
    
    case EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS:
      return {
        ...state,
        isUpdating: true,
        isSuccessful: false
      }

    case EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isSuccessful: true
      }

    case EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        errors: action?.payload?.data,
        hasFailed: true,
        isUpdating: false,
        isSuccessful: false
      };
  }

  return state;
};

export default emailSubscriptionsReducer;
