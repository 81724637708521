import React from 'react';
import { PortalPageMenuContent, PortalPageMenuItem, ContactCard } from '@/components/Portal';

export default function CEOdeskMenu({ article, mobile, desktop }) {
  return !article
	? <PortalPageMenuContent mobile={mobile} desktop={desktop} />
  : <PortalPageMenuContent 
      mobile={mobile} 
      desktop={desktop}
      mobileToggleName="Key Contacts"
    >
      <PortalPageMenuItem title='Key Contacts'>
        <ContactCard
          name={article.key_contact_name}
          title={'CEO, COBA'}
          email={article.key_contact_email}
          phone={article.key_contact_phone}
        />
      </PortalPageMenuItem>
    </PortalPageMenuContent>
}
