import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMedia from 'use-media';
import { first } from 'lodash';
import { Link } from 'react-router-dom';
import MiniLogo from '../MiniLogo';
import { iconArrowThin, iconArrowThinLeft, iconBarHorizontal } from '@/images/icons';
import { requestDivisionsBanks } from '@/store/actions';
import {
  selectInstitutionDivisionsInChunks,
  selectTotalDivisionsChunks
} from '@/selectors/discoverMembers';

const EXTRACT_URL_WITHOUT_PROTOCOL_REGEX =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/;

const DiscoverMembers = () => {
  const dispatch = useDispatch();
  const isMobile = useMedia('(max-width: 767px)');
  const isDesktop = useMedia('(min-width: 1024px)');
  const totalNumDiscoverMembers = useSelector(selectTotalDivisionsChunks);
  const groupedDivisions = useSelector(selectInstitutionDivisionsInChunks());

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [remainingSlides, setRemainingSlides] = useState(0);

  const SLIDE_GAP = 5;
  const slideWidth = SLIDE_GAP + (isMobile ? 149 : 275);
  const sliderPosition = -activeSlideIndex * slideWidth;

  const showNextCard = () => {
    if (remainingSlides > 1) {
      setTimeout(() => {
        setActiveSlideIndex(activeSlideIndex + 1);
      }, 200);
    }
  };

  const showPrevCard = () => {
    if (activeSlideIndex > 0) {
      setTimeout(() => {
        setActiveSlideIndex(activeSlideIndex - 1);
      }, 200);
    }
  };
  const showSpecificCard = (index) => {
    setActiveSlideIndex(index);
  };
  useEffect(() => {
    setRemainingSlides(totalNumDiscoverMembers - activeSlideIndex);
  }, [activeSlideIndex]);

  useEffect(() => {
    if (!!totalNumDiscoverMembers) {
      setRemainingSlides(totalNumDiscoverMembers - activeSlideIndex);
    }
  }, [totalNumDiscoverMembers]);

  useEffect(() => {
    dispatch(
      requestDivisionsBanks({
        per_page: 99999999
      })
    );
  }, []);

  const Header = () => (
    <header>
      <div className="heading">
        <MiniLogo />
        <h2>
          <u>Discover</u> <br /> our members
        </h2>
      </div>
      <Link to="/find-a-bank" className="btn btn-primary">
        Find a Customer Owned Bank
        <span className="icon">{iconArrowThin}</span>
      </Link>
    </header>
  );

  const Division = (division) => {
    const urlLinkWithoutProtocol = first(
      division.url.match(EXTRACT_URL_WITHOUT_PROTOCOL_REGEX)
    );
    const urlLink = !!urlLinkWithoutProtocol
      ? `https://${urlLinkWithoutProtocol}`
      : division.url;

    return (
      <div className="discover-division" key={division.id}>
        <a href={urlLink} target="_blank" rel="noreferrer noopener">
          {division.logo_data && division.logo_data.url && (
            <img src={division.logo_data.url} alt={division.name} />
          )}
        </a>
      </div>
    );
  };

  return (
    <section
      id="discover-members"
      className="discover-members"
      data-testid="discover-members"
    >
      <div className="content-wrapper">
        {!isDesktop && <Header />}

        <div className="carousel">
          <div className="carousel-conatainer">
            <div className="controls-container">
              {isDesktop && <Header />}

              <div className="controls">
                <div className="breadcrumbs">
                  {groupedDivisions.map((group, index) => (
                    <span
                      key={index}
                      className={`icon cursor-pointer ${
                        index === activeSlideIndex ? ' active-breadcrumb' : ''
                      }`}
                      onClick={() => showSpecificCard(index)}
                    >
                      {iconBarHorizontal}
                    </span>
                  ))}
                </div>

                <div className="buttons">
                  <button
                    className={`btn btn-primary left${
                      activeSlideIndex > 0 ? ' btn-active' : ' btn-disabled'
                    }`}
                    onClick={showPrevCard}
                  >
                    {iconArrowThinLeft}
                  </button>
                  <button
                    className={`btn btn-primary right${
                      remainingSlides > 1 ? ' btn-active' : ' btn-disabled'
                    }`}
                    onClick={showNextCard}
                  >
                    {iconArrowThin}
                  </button>
                </div>
              </div>
            </div>
            <div
              className="slide-container"
              style={{
                transform: `translateX(${sliderPosition}px)`
              }}
            >
              {groupedDivisions.map((divisions, index) => (
                <div
                  key={index}
                  className={`slide ${
                    index === activeSlideIndex
                      ? ' active-slide'
                      : index < activeSlideIndex
                      ? ' hide-slide'
                      : ''
                  }`}
                >
                  {divisions.map((division) => Division(division))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscoverMembers;
