import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { requestStaffDirectory } from '@/store/actions';
import { 
	selectDepartmentStaff,
	selectStaffDirectoryIsLoading,
	selectDepartmentsNames
} from '@/store/selectors';
import { PortalLayout, PortalPageTitle, StaffCard } from '@/components/Portal';
import { StaffDirectoryMenu } from '@/components/Portal/StaffDirectory'

function staffCardListing(staff) {
  return Object.keys(staff).map((department) => {
    return (
      <CSSTransition 
				in={false}
				appear={true}
				exit={true}
        key={department}
        classNames='department'
        timeout={800}
      >
        <div className='department'>
          <h2>{department}</h2>
          <div className="staff-list">
          {staff[department].map((person) => { return (
            <StaffCard 
              key={person.id}
              name={person.full_name}
              title={person.title}
              phone={person.phone_number}
              mobile={person.mobile_number}
              email={person.email}
              notification_message={person.notification_message}
              image_text={person.staff_image_data.title}
              image_url={person.staff_image_data.url}
            />
          ) }) }
          </div>          
        </div>
      </CSSTransition>
    );
  });
}

export default function StaffDirectory() {
	const dispatch = useDispatch();

	const [selectedDepartment, setSelectedDepartment] = useState('all')
	const departmentNames = useSelector(selectDepartmentsNames);
	const staff = useSelector(selectDepartmentStaff(selectedDepartment))
	const Loading = useSelector(selectStaffDirectoryIsLoading)

  React.useEffect(() => {
		if (isEmpty(staff) && !Loading) {
			dispatch(requestStaffDirectory())
		}
  }, []);

	const onDepartmentClick = (name) => (ev) => {
		ev.preventDefault();
    setSelectedDepartment(name)
	}
	
	const departments = departmentNames.map( (name) => ({
		title: name,
		isActive: name === selectedDepartment ? true : false,
		onClickAccordionItem: onDepartmentClick(name)
	}))

  return (
    <PortalLayout>
      <PortalPageTitle heading={'Coba Staff Directory'} description="" />
      <StaffDirectoryMenu departments={departments} mobile />

      <div className="container v-pad">
        <div className="row">
					<StaffDirectoryMenu departments={departments} desktop />

          <div className="col-xs-12 col-md-8">
            <TransitionGroup className="department-listing">
							{ !Loading && !isEmpty(staff) && staffCardListing(staff) }
            </TransitionGroup>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
