import { MEMBER_INSTITUTIONS } from '../constants';

const initialState = {
  isLoading: false,
  memberInstitutions: [],
  totalMembersInstitutions: 0,
  divisionsBanks: [],
  divisionsBanksPageMeta: {},
  branchesNearMe: [],
  branchesNearMePageMeta: {},
  offers: []
};

const memberInstitutionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS:
      return {
        ...state,
        isLoading: true
      };

    case MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        memberInstitutions: action.payload?.data?.data
      };
    }

    case MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS:
      return {
        ...state,
        isLoading: true
      };

    case MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS_SUCCESS:
      return {
        ...state,
        divisionsBanks: action.payload?.data?.data?.data,
        divisionsBanksPageMeta: {
          current_page: action.payload?.data?.data.current_page,
          last_page: action.payload?.data?.data.last_page,
          total: action.payload?.data?.data.total,
          from_item_index: action.payload?.data?.data?.from,
          to_item_index: action.payload?.data?.data?.to
        },
        isLoading: false
      };

    case MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME:
      return {
        ...state,
        isLoading: true
      };

    case MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        branchesNearMe: action.payload?.data?.data?.data,
        branchesNearMePageMeta: {
          current_page: action.payload?.data?.data.current_page,
          last_page: action.payload?.data?.data.last_page,
          total: action.payload?.data?.data.total,
          from_item_index: action.payload?.data?.data?.from,
          to_item_index: action.payload?.data?.data?.to
        }
      };
    }

    case MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS:
      return {
        ...state,
        isLoading: true
      };

    case MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action?.payload?.data?.data?.data
      };

    case MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case MEMBER_INSTITUTIONS.CLEAR_BRANCHES_NEAR_ME:
      return {
        ...state,
        branchesNearMe: initialState.branchesNearMe
      };
  }

  return state;
};

export default memberInstitutionsReducer;
