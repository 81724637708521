import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
// Utils
import { first } from 'lodash';
import { format, isAfter } from 'date-fns';
import { PORTAL_PATHS } from '@/config';
import { truncateText, utcToLocal } from '@/utils';
// Components
import { Card } from '@/components/Portal/Card';
import { iconArrowThin, iconPortalEdit, iconPortalUser } from '@/images/icons';

NewsroomArticleCard.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  news_topics: PropTypes.array,
  title: PropTypes.string,
  publish_date: PropTypes.string,
  author: PropTypes.string,
  seeking_feedback: PropTypes.number,
  feedback_date: PropTypes.string,
  content: PropTypes.string,
  slug: PropTypes.string,
  action: PropTypes.node
}

const validStatus = {
  'feedback-required': { type: 'article-feedback-required', label: '! seeking feedback' },
  'feedback-closed': { type: 'article-feedback-closed', label: 'feedback closed' },
  'for-the-attention-of': { type: 'article-for-the-attention-of', label: 'for the attention of' }
}

const FIRST_TAG_REGEX = /<(\w+)>(.*?)<\/\1>/;

function feedbackStatus(seeking_feedback, feedback_date) {
  let status = null

  if (seeking_feedback && feedback_date) {
    status = isAfter(new Date(), utcToLocal(feedback_date)) ?
      'feedback-closed' :
      'feedback-required'
  }

  return status
}

export default function NewsroomArticleCard({
  id,
  size = 'large',
  title,
  author,
  publish_date,
  seeking_feedback,
  feedback_date,
  news_topics,
  content,
  slug,
  external_link
}) {
  const cardLink = `${PORTAL_PATHS.NEWSROOM}/${slug}`;

  const status = feedbackStatus(seeking_feedback, feedback_date);
  const categoryList = news_topics.slice(0, 2).map((topic) => {
    return { label: topic.name };
  });

  if (Object.prototype.hasOwnProperty.call(validStatus, status)) {
    categoryList.unshift(validStatus[status]);
  }

  const firstParagraphText = !!content && content.match(FIRST_TAG_REGEX) ? truncateText(first(content.match(FIRST_TAG_REGEX)).replace(/<[^>]+>/g, ''))
    : '';


  const formattedDate = !!publish_date ? format(utcToLocal(publish_date), 'd LLLL yyyy') : publish_date;

  const items = [
    { icon: iconPortalEdit, details: formattedDate },
    { icon: iconPortalUser, details: author }
  ]

  return (
    <Card
      key={id}
      link={external_link ?? cardLink}
      size={size}
      title={title}
      categories={categoryList}
      items={items}
      content={firstParagraphText}
      actions={(
        <>
          {!!external_link ?
            <a href={external_link} className='btn btn-text' target='_blank' rel='noreferrer noopener'>
              Read More <span className="icon midnight">{iconArrowThin}</span>
            </a>
            :
            <Link
              className="btn btn-text"
              to={cardLink}>
              Read More <span className="icon midnight">{iconArrowThin}</span>
            </Link>
          }
        </>
      )

      }

    />
  )
}
