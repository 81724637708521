import React from 'react';

export default function AddressSuggestions({ loading, suggestions, onSelectSuggestion }) {
  return (
    <div className="address-suggestions active">
      {loading && <div>Loading...</div>}

      <div className="heading">Suggestions</div>

      {suggestions.map((suggestion) => {
        return (
          <div
            className="suggestion-item"
            key={suggestion.place_id}
            onClick={onSelectSuggestion(suggestion)}
            role="button"
          >
            <span>{suggestion.description}</span>
          </div>
        );
      })}
    </div>
  );
}
