import { NAV } from '../constants'

// Quicklinks
const requestQuicklinks = (payload) => ({
	type: NAV.REQUEST_QUICKLINKS,
	payload
})
const requestQuicklinksSuccess = (payload) => ({
	type: NAV.REQUEST_QUICKLINKS_SUCCESS,
	payload
})
const requestQuicklinksFailed = (payload) => ({
	type: NAV.REQUEST_QUICKLINKS_FAILED,
	payload
})

export {
	requestQuicklinks,
	requestQuicklinksSuccess,
	requestQuicklinksFailed
}