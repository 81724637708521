import publicWebsiteUrlByPath, { getCurrentYear } from '@/utils';
import React from 'react';

export default function MemberWelcomeFooter() {
  const currentYear = getCurrentYear();

  return (
    <div className="attribution-and-legals">
      <div className="container">
        <div className="row">
          <div className="attributions col-xs-12 col-lg-8">
            <p>{`Copyright 2009 - ${currentYear}`}</p>
            <p>Customer Owned Banking Association</p>
          </div>

          <div className="privacy-legals col-xs-12 col-lg-4">
            <nav className="privacy-legals-nav">
              <a href={publicWebsiteUrlByPath('/privacy')} target='_blank' rel='noreferrer' >Privacy</a>
              <div className="separator" />
              <a href={publicWebsiteUrlByPath('/privacy')} target='_blank' rel='noreferrer' >Legal</a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
