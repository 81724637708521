import React from 'react';
import { bindActionCreators } from 'redux';
import { useForm, FormProvider } from 'react-hook-form';
import { compact, isEmpty } from 'lodash';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Button, Dropdown, TextField, Textarea } from '@/components';
import { iconArrowThin, iconTick } from '@/images/icons';
import { getExistingUserDivisionById, selectAllOffers } from '@/store/selectors';
import { updateUserDivision, requestAllOffers } from '@/store/actions';
import { getChangedFormData } from '@/utils';

export default function UpdateExistingDivision({ selectedDivisionOption }) {
  const actions = bindActionCreators(
    {
      updateUserDivision,
      requestAllOffers
    },
    useDispatch()
  );

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      divisionUrl: '',
      image: '',
      companyOffer1: '',
      companyOffer2: '',
      companyOffer3: ''
    }
  });

  const companyOfferKeys = Object.keys(form.getValues()).filter((key) =>
    key.includes('companyOffer')
  );

  const watchOfferIds = compact(companyOfferKeys.map((offer) => form.watch(offer)?.id));

  const { dirtyFields, isDirty, isValid, isSubmitted } = form.formState;
  const isSubmitDisabled = !isDirty || !isValid;

  const selectedUserDivision = useSelector(
    getExistingUserDivisionById(selectedDivisionOption.id),
    shallowEqual
  );
  const availableOffers = useSelector(selectAllOffers);
  const offersCount =
    availableOffers.length >= 3
      ? [...Array(3).keys()]
      : [...Array(availableOffers.length).keys()];

  const onClickOffersDropdownOption = (ev) => {
    const selectedOfferId = ev.target.dataset.id;
    const dropdownOfferId = ev.target.dataset.dropdownId;
    const targetName = ev.target.textContent;

    form.setValue(
      dropdownOfferId,
      { id: selectedOfferId, value: targetName },
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const offersValidation = () => {
    const totalUniqueSelections = watchOfferIds.filter(
      (item, i, arr) => arr.indexOf(item) === i
    ).length;

    const isUniqueSelection = totalUniqueSelections === watchOfferIds.length;

    return watchOfferIds?.length > 1 && !isUniqueSelection
      ? 'Duplicate offer selections are not allowed.'
      : true;
  };

  const blurbValidation = (value) => {
    if (!!value && value.split(' ').filter((n) => n !== '').length > 40) {
      return 'Input is over 40 words.';
    }

    return true;
  };

  const onSubmitForm = (data) => {
    const selectedOfferIds = compact([
      data?.companyOffer1?.id,
      data?.companyOffer2?.id,
      data?.companyOffer3?.id
    ]);

    const payload = getChangedFormData(dirtyFields, data, {
      bank_id: selectedUserDivision.id,
      ...(!!selectedOfferIds.length && { offers: selectedOfferIds })
    });

    actions.updateUserDivision({
      ...payload
    });
  };

  React.useEffect(() => {
    actions.requestAllOffers();
  }, []);

  React.useEffect(() => {
    form.setValue('divisionUrl', selectedUserDivision?.url, { shouldValidate: true });

    if (!isEmpty(selectedUserDivision) && !!selectedUserDivision?.offers?.length) {
      selectedUserDivision.offers.forEach((offer, i) => {
        const index = i + 1;

        form.setValue(
          `companyOffer${index}`,
          {
            id: offer.id,
            value: offer.name
          },
          { shouldDirty: true, shouldValidate: true }
        );
      });
    }
  }, [selectedUserDivision]);

  return (
    <FormProvider {...form}>
      <form noValidate>
        <fieldset className="fieldset">
          <legend className="legend H3">Division details</legend>

          <TextField
            id="division-url"
            name="url"
            labelFor="division-url"
            labelText="division URL"
            validation={{
              required: {
                value: true,
                message: 'This field is required.'
              }
            }}
            type="url"
            control={form.control}
          />
        </fieldset>

        <br />

        <fieldset className="fieldset">
          <legend className="legend H3">Division logo</legend>

          <TextField
            id="image"
            name="image"
            labelFor="image"
            labelText="Logo Image URL"
            type="url"
            control={form.control}
          />
        </fieldset>

        <br />

        <fieldset>
          <legend className="legend H3">About your member brand division</legend>
          <p>Please limit your about text to 40 words or less</p>

          <br />

          <Textarea
            id="blurb"
            control={form.control}
            name="blurb"
            labelFor="blurb"
            labelText="About"
            rows={7}
            validation={{
              validate: blurbValidation
            }}
          />
        </fieldset>

        <br />
        <br />

        {!!availableOffers?.length && (
          <fieldset className="fieldset">
            <legend className="legend H3">What we offer</legend>

            {offersCount.map((i) => {
              return (
                <Dropdown
                  id={`company-offer-${i}`}
                  name={`companyOffer${i}`}
                  key={`company-offer-${i}`}
                  options={availableOffers}
                  placeholder="Company Offer"
                  labelFor={`companyOffer${i}`}
                  labelText="We offer"
                  onClickDropdownOption={onClickOffersDropdownOption}
                  control={form.control}
                  validation={{
                    validate: offersValidation
                  }}
                />
              );
            })}
          </fieldset>
        )}

        <div className={`form-success ${isSubmitted ? 'display' : 'hidden'}`}>
          <span className="icon">{iconTick}</span>
          Thanks - your update has been submitted and is awaiting approval.
        </div>

        {!isSubmitted && (
          <Button
            variant={'btn btn-fill'}
            icon={iconArrowThin}
            isDisabled={isSubmitDisabled}
            type="submit"
            onClick={form.handleSubmit(onSubmitForm)}
          >
            Save Division Details
          </Button>
        )}
      </form>
    </FormProvider>
  );
}
