export const PORTAL_PATHS = {
  home: '/',
  ABOUT: '/about',
  EVENTS: '/events',
  MYACCOUNT: '/my-account',
  NEWSROOM: '/newsroom',
  FEEDS: '/feeds',
  GLOABL_SEARCH: '/search',
  SUPPORT: '/support',
  VACANCIES: '/resources-and-services/industry-recruitment-and-vacancies',
  TRAINING: '/resources-and-services/training',
  RESOURCESSERVICES: '/resources-and-services',
  notFound: '/404',
  myAccount: {
    index: '/my-account',
    settings: {
      index: '/my-account/account-settings',
      details: '/my-account/account-settings/account-details',
      loginDetails: '/my-account/account-settings/login-details'
    },
    institutionBranchDetails: {
      index: '/my-account/institution-branch-details',
      institutionDetails: '/my-account/institution-branch-details/institution-details',
      branchDetails: '/my-account/institution-branch-details/branch-details'
    },
    manageUsers: '/my-account/manage-users',
    emailSubscription: '/my-account/email-subscriptions'
  },
  contact: '/support/contact',
  faq: '/support/frequently-asked-questions',
  staffDirectory: '/about/staff-directory',

  onboarding: {
    index: '/onboarding',
    signIn: '/onboarding/sign-in',
    forgotPassword: '/onboarding/sign-in/forgot-password',
    resetPassword: '/onboarding/reset-password',
    createAccount: {
      index: '/onboarding/create-account',
      /**
       *
       * @param {number} stepNumber
       * @returns string
       */
      step: (stepNumber) => `/onboarding/create-account/step-${stepNumber}`,
      accountCreated: '/onboarding/create-account/account-created',
      emailVerified: '/onboarding/create-account/email-verified'
    }
  },
  resources: {
    training: {
      my: {
        index: '/resources-and-services/training/my/'
      },
      support: {
        index: '/resources-and-services/training/support/',
        cancellationPolicy:
          '/resources-and-services/training/support/cancellation-policy',
        faqs: '/resources-and-services/training/support/faqs',
        managingSubscription:
          '/resources-and-services/training/support/managing-subscriptions'
      }
    },
    vacancies: {
      submitted:
        '/resources-and-services/industry-recruitment-and-vacancies/submitted-vacancy',
      industry: '/resources-and-services/industry-recruitment-and-vacancies',
      view: '/resources-and-services/industry-recruitment-and-vacancies/view-vacancies',
      list: '/resources-and-services/industry-recruitment-and-vacancies/list-vacancy',
      support:
        '/resources-and-services/industry-recruitment-and-vacancies/vacancies-support'
    }
  }
};
