import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PORTAL_PATHS } from '@/config';
import { PortalLayout, PortalPageTitle, CMSPage } from '@/components/Portal';
import { Contact, SupportMenu } from '@/components/Portal/Support';
import { useSelector } from 'react-redux';
import { selectStaticPage } from '@/selectors/page';

export default function PortalSupport() {
  const { title, description } = useSelector(selectStaticPage);
  return (
    <PortalLayout>
      <PortalPageTitle
        heading={title || 'Support'}
        description={description || 'Get in touch with the COBA team'}
      />

      <SupportMenu mobile />

      <div className="container v-pad">
        <div className="row">
          <SupportMenu desktop />

          <div className="col-xs-12 col-md-8">
            <Switch>
              <Redirect
                exact
                from={`${PORTAL_PATHS.SUPPORT}`}
                to={`${PORTAL_PATHS.SUPPORT}/contact`}
              />
              <Route component={Contact} exact path={`${PORTAL_PATHS.SUPPORT}/contact`} />
              <Route component={CMSPage} exact path={`${PORTAL_PATHS.SUPPORT}/:slug`} />
            </Switch>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
