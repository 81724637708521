import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitFeedback } from '@/store/actions';
import { getFeedbackData } from '@/store/selectors';
import { Button, Textarea } from '@/components';
import { Tag } from '@/components/Portal';
import { iconArrowThin, iconTick } from '@/images/icons';

FeedbackAction.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.any
};

export default function FeedbackAction({
  id,
  title = 'Seeking feedback from our members',
  content = 'If you have time, please let us know if you have any specific feedback on the Proposed Responsible Lending Reforms below.'
}) {
  const dispatch = useDispatch();
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const { isFeedbackExpired } = useSelector(getFeedbackData(id));

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onSubmitFeedback = (data) => {
    setIsFeedbackSent(true);

    const payload = {
      ...data,
      id: parseInt(id)
    };

    dispatch(submitFeedback(payload));
  };

  if (isFeedbackExpired) {
    return null;
  } else {
    return (
      <div className="feedback action" id="feedback-action">
        <div className="layout">
          <Tag
            type={
              isFeedbackExpired ? 'article-feedback-closed' : 'article-feedback-required'
            }
            label={isFeedbackExpired ? 'Feedback Closed' : '! Seeking Feedback'}
          />

          <h4 className="title">{title}</h4>
          <p className="content" dangerouslySetInnerHTML={{ __html: content }} />

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmitFeedback)} noValidate>
              <Textarea
                id="article-feedback"
                placeholder="Leave feedback here"
                name="article-feedback"
                validation={{
                  required: {
                    value: true,
                    message: 'This field is required.'
                  }
                }}
                control={form.control}
              />

              {isFeedbackSent ? (
                <div className="form-success">
                  <span className="icon icon-tick">{iconTick}</span>
                  Thank you for your feedback. A copy has been sent to your email.
                </div>
              ) : (
                <Button
                  variant={'btn-fill'}
                  icon={iconArrowThin}
                  type="submit"
                  isDisabled={isFeedbackSent}
                >
                  Submit Feedback
                </Button>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    );
  }
}
