import React from 'react';

const logoCobaSmall = (
  <svg 
		viewBox="0 0 63 63" 
		fill="none" 
		xmlns="http://www.w3.org/2000/svg"
	>
    <path
      d="M31.4961 63C48.7849 63 62.9069 48.8571 62.9069 31.5429C62.9069 14.1429 48.7849 0 31.4961 0V14.9143C40.654 14.9143 48.1002 22.3714 48.1002 31.5429C48.1002 40.7143 40.654 48.1714 31.4961 48.1714V63Z"
      fill="#076282"
    />
    <path
      d="M31.4964 0C14.122 0 0 14.1429 0 31.5429C0 48.8571 14.122 63 31.4964 63V48.1714C22.3385 48.1714 14.8923 40.7143 14.8923 31.5429C14.8923 22.3714 22.3385 14.9143 31.4964 14.9143V0Z"
      fill="#2AB6C4"
    />
  </svg>
);

const logoCobaBig = (
  <svg
    className="icon-big-logo"
    width="588"
    height="589"
    viewBox="0 0 588 589"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M294 0C131.821 0 0 132.224 0 294.901C0 456.775 131.821 589 294 589V450.365C208.516 450.365 139.011 380.646 139.011 294.901C139.011 209.155 208.516 139.437 294 139.437V0Z"
      fill="#22B7C4"
    />
    <path
      d="M294 589C456.179 589 588 456.776 588 294.099C588 132.224 456.179 -1.15241e-05 294 -2.57023e-05L294 138.635C379.484 138.635 448.989 208.354 448.989 294.099C448.989 379.845 379.484 449.563 294 449.563L294 589Z"
      fill="#006282"
    />
  </svg>
);

const logoCobaText = (
  <svg
    className="icon-coba-logo-text"
    viewBox="0 0 105 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3363 43C33.048 43 42.6146 33.3469 42.6146 21.5293C42.6146 9.65306 33.048 0 21.3363 0V10.1796C27.54 10.1796 32.5842 15.2694 32.5842 21.5293C32.5842 27.7891 27.54 32.8789 21.3363 32.8789V43Z"
      fill="#076282"
    />
    <path
      d="M21.3363 0C9.56654 0 0 9.65306 0 21.5293C0 33.3469 9.56654 43 21.3363 43V32.8789C15.1325 32.8789 10.0883 27.7891 10.0883 21.5293C10.0883 15.2694 15.1325 10.1796 21.3363 10.1796V0Z"
      fill="#2AB6C4"
    />
    <path
      d="M54.1524 12.6952C53.5726 13.2802 52.8189 13.5727 51.9492 13.5727C49.804 13.5727 48.8763 12.1101 48.8763 10.5891C48.8763 9.06796 49.862 7.54688 51.9492 7.54688C52.7029 7.54688 53.4567 7.83939 54.0944 8.42443L53.3407 9.06796C52.9349 8.71694 52.471 8.54143 51.9492 8.54143C50.5577 8.54143 49.9779 9.59449 49.9779 10.5891C49.9779 11.5251 50.5577 12.5782 51.9492 12.5782C52.471 12.5782 52.9928 12.3442 53.3987 11.9931L54.1524 12.6952Z"
      fill="#011A2F"
    />
    <path
      d="M59.7184 7.60547V10.9402C59.7184 12.6368 58.6747 13.5143 57.2253 13.5143C55.8338 13.5143 54.6742 12.6953 54.6742 10.9402V7.60547H55.7758V10.9402C55.7758 11.9932 56.3556 12.5198 57.2833 12.5198C58.1529 12.5198 58.6747 11.9347 58.6747 10.9402V7.60547H59.7184Z"
      fill="#011A2F"
    />
    <path
      d="M64.2986 9.00952C64.1247 8.6585 63.5449 8.36599 62.9071 8.36599C62.0954 8.36599 61.6895 8.71701 61.6895 9.12653C61.6895 9.65306 62.2693 9.77007 62.9651 9.82857C64.1826 10.0041 65.2842 10.2966 65.2842 11.7007C65.2842 12.9878 64.1247 13.5728 62.8491 13.5728C61.6895 13.5728 60.7619 13.2218 60.356 12.1687L61.2837 11.7007C61.5156 12.3442 62.2113 12.6367 62.9071 12.6367C63.6028 12.6367 64.2406 12.4027 64.2406 11.7592C64.2406 11.2327 63.6608 10.9986 62.9071 10.8816C61.7475 10.7646 60.6459 10.4136 60.6459 9.12653C60.6459 7.95646 61.8055 7.42993 62.8491 7.42993C63.7188 7.42993 64.7044 7.66395 65.1103 8.6L64.2986 9.00952Z"
      fill="#011A2F"
    />
    <path
      d="M67.6616 8.60003H65.8063V7.60547H70.5606V8.60003H68.7052V13.3973H67.6616V8.60003Z"
      fill="#011A2F"
    />
    <path
      d="M71.7199 10.5892C71.7199 11.5253 72.2417 12.5783 73.6332 12.5783C75.0247 12.5783 75.4886 11.5253 75.5465 10.5892C75.5465 9.59465 75.0247 8.54159 73.6332 8.54159C72.2417 8.54159 71.7199 9.59465 71.7199 10.5892ZM76.5902 10.5307C76.5902 12.0518 75.6625 13.5144 73.6332 13.5144C71.6039 13.5144 70.6763 12.0518 70.6763 10.5307C70.6763 9.00961 71.6619 7.48853 73.6332 7.48853C75.6045 7.48853 76.5902 9.00961 76.5902 10.5307Z"
      fill="#011A2F"
    />
    <path
      d="M82.3881 9.18506L80.4748 11.7592H80.3009L78.4455 9.18506V13.4558H77.3439V7.60547H78.6195L80.4168 10.1211L82.2142 7.60547H83.4897V13.4558H82.3881V9.18506Z"
      fill="#011A2F"
    />
    <path
      d="M88.7658 13.3973H84.4173V7.60547H88.7658V8.65853H85.5189V10.0041H88.6498V11.0572H85.5189V12.3442H88.7658V13.3973Z"
      fill="#011A2F"
    />
    <path
      d="M90.6792 8.65853V10.5306H92.3606C93.0564 10.5306 93.3462 10.0626 93.3462 9.59458C93.3462 9.12656 93.0564 8.65853 92.3606 8.65853H90.6792ZM94.6798 13.3973H93.4042L91.7228 11.4667H90.6792V13.3973H89.5776V7.60547C90.5053 7.60547 91.4329 7.60547 92.3026 7.60547C93.6361 7.60547 94.3899 8.54152 94.3899 9.53608C94.3899 10.3551 94.042 11.1157 92.9404 11.3497L94.6798 13.3388V13.3973Z"
      fill="#011A2F"
    />
    <path
      d="M49.9201 18.5455C49.9201 19.4816 50.4419 20.5346 51.8334 20.5346C53.2249 20.5346 53.6887 19.4816 53.7467 18.5455C53.7467 17.5509 53.2249 16.4979 51.8334 16.4979C50.4419 16.4979 49.8621 17.5509 49.9201 18.5455ZM54.7323 18.487C54.7323 20.0081 53.8046 21.4707 51.7754 21.4707C49.7461 21.4707 48.8185 20.0081 48.8185 18.487C48.8185 16.9659 49.8041 15.4448 51.7754 15.4448C53.8046 15.4448 54.7903 16.9659 54.7323 18.487Z"
      fill="#011A2F"
    />
    <path
      d="M61.3417 21.3536H60.414L59.6603 19.54L59.0805 17.7264L58.5007 19.54L57.805 21.3536H56.8773L54.7321 15.5618H55.9497L57.3412 19.774L58.7327 15.5618H59.4284L60.8779 19.774L62.2114 15.5618H63.429L61.3417 21.3536Z"
      fill="#011A2F"
    />
    <path
      d="M67.8354 15.5618H68.937V21.3536H68.2412L65.2263 17.4339V21.3536H64.1247V15.5618H64.9944L67.8354 19.1305V15.5618Z"
      fill="#011A2F"
    />
    <path
      d="M74.1552 21.3536H69.8647V15.5618H74.1552V16.6148H70.9084V17.9604H74.0392V18.955H70.9084V20.3005H74.1552V21.3536Z"
      fill="#011A2F"
    />
    <path
      d="M76.1844 20.3007H77.3439C78.6775 20.3007 79.1993 19.3646 79.1413 18.3701C79.1413 17.4925 78.5615 16.5565 77.3439 16.5565H76.1844V20.3007ZM80.3009 18.4286C80.3009 19.8912 79.4312 21.4123 77.4019 21.4123H75.1407V15.6204H77.4019C79.3732 15.5619 80.2429 16.966 80.3009 18.4286Z"
      fill="#011A2F"
    />
    <path
      d="M50.1518 28.7252H51.9491C52.5289 28.7252 53.3406 28.5497 53.3406 27.7892C53.3406 27.0871 52.5869 26.7361 51.9491 26.7361H50.0938V28.7252H50.1518ZM50.1518 26.0926H52.0071C52.7028 26.0926 53.2247 25.8585 53.2247 25.215C53.2247 24.5715 52.7028 24.2204 52.0071 24.2204H50.2097V26.0926H50.1518ZM51.9491 23.5769C52.9927 23.5769 53.8624 24.1034 53.8624 25.215C53.8624 25.7415 53.5725 26.2096 52.9927 26.3851C53.6885 26.5606 54.0364 27.2041 54.0364 27.7892C54.0364 29.0177 52.9927 29.3687 51.9491 29.3687H49.456V23.5769H51.9491Z"
      fill="#011A2F"
    />
    <path
      d="M57.5733 24.2789L56.2397 27.4381H58.9648L57.5733 24.2789ZM57.9791 23.5769L60.5882 29.3687H59.8344L59.2547 28.0817H55.9499L55.3701 29.3687H54.6163L57.2254 23.5769H57.9791Z"
      fill="#011A2F"
    />
    <path
      d="M61.9795 23.5769L65.5162 28.0232V23.5769H66.2119V29.3687H65.6901L62.1534 24.981V29.3687H61.4576V23.5769H61.9795Z"
      fill="#011A2F"
    />
    <path
      d="M67.4295 23.5769H68.1832V26.1511L70.7343 23.5769H71.604V23.6354L68.821 26.3851L71.8359 29.3687H70.9662L68.1832 26.6776V29.3687H67.4295V23.5769Z"
      fill="#011A2F"
    />
    <path d="M73.2855 23.5769H72.5898V29.3687H73.2855V23.5769Z" fill="#011A2F" />
    <path
      d="M75.0829 23.5769L78.6196 28.0232V23.5769H79.3153V29.3687H78.7935L75.2568 24.981V29.3687H74.5611V23.5769H75.0829Z"
      fill="#011A2F"
    />
    <path
      d="M85.1712 24.8054C84.7074 24.3373 84.0696 24.1033 83.4898 24.1033C81.9823 24.1033 81.2286 25.2149 81.2286 26.502C81.2286 27.672 81.9823 28.7836 83.5478 28.7836C84.0696 28.7836 84.6494 28.6081 85.1132 28.1986V26.7945H83.3159V26.1509H85.751V28.4326C85.1712 29.0761 84.4754 29.4271 83.5478 29.4271C81.5185 29.4271 80.5329 28.023 80.5329 26.4435C80.5329 24.6884 81.6345 23.3428 83.5478 23.3428C84.3015 23.3428 85.0552 23.6353 85.635 24.2203L85.1712 24.8054Z"
      fill="#011A2F"
    />
    <path
      d="M51.8333 32.3523L50.4418 35.453H53.1668L51.8333 32.3523ZM52.2391 31.5918L54.8482 37.3836H54.0944L53.5146 36.0966H50.2098L49.6301 37.3836H48.8763L51.4854 31.5918H52.2391Z"
      fill="#011A2F"
    />
    <path
      d="M57.6891 32.1184C56.9353 32.1184 56.1816 32.4109 56.1816 33.1129C56.1816 33.7565 56.8774 33.932 57.747 34.049C58.9066 34.2245 60.0082 34.4585 60.0082 35.8041C60.0082 37.0912 58.7907 37.5592 57.6891 37.5592C56.6454 37.5592 55.7178 37.2082 55.2539 36.2136L55.8337 35.8626C56.1816 36.5646 56.9933 36.8572 57.6891 36.8572C58.3848 36.8572 59.3125 36.6232 59.3125 35.8041C59.3125 35.1021 58.5008 34.8681 57.6311 34.751C56.5295 34.5755 55.4279 34.3415 55.4279 33.1129C55.4279 31.8844 56.6454 31.4749 57.6311 31.4749C58.5008 31.4749 59.3125 31.6504 59.8343 32.5279L59.3125 32.8789C58.9646 32.3524 58.3268 32.1184 57.6891 32.1184Z"
      fill="#011A2F"
    />
    <path
      d="M63.0811 32.1184C62.3274 32.1184 61.5737 32.4109 61.5737 33.1129C61.5737 33.7565 62.2694 33.932 63.1391 34.049C64.2987 34.2245 65.4003 34.4585 65.4003 35.8041C65.4003 37.0912 64.1827 37.5592 63.0811 37.5592C62.0375 37.5592 61.1099 37.2082 60.646 36.2136L61.2258 35.8626C61.5737 36.5646 62.3854 36.8572 63.0811 36.8572C63.7769 36.8572 64.7046 36.6232 64.7046 35.8041C64.7046 35.1021 63.8928 34.8681 63.0232 34.751C61.9216 34.5755 60.82 34.3415 60.82 33.1129C60.82 31.8844 62.0375 31.4749 63.0232 31.4749C63.8928 31.4749 64.7046 31.6504 65.2264 32.5279L64.7046 32.8789C64.4147 32.3524 63.7189 32.1184 63.0811 32.1184Z"
      fill="#011A2F"
    />
    <path
      d="M69.1109 32.1769C67.6614 32.1769 66.9077 33.2885 66.9077 34.517C66.9077 35.6871 67.6034 36.8572 69.1109 36.8572C70.6183 36.8572 71.3141 35.6871 71.3141 34.517C71.3141 33.347 70.6183 32.1769 69.1109 32.1769ZM72.0098 34.517C72.0098 36.0381 71.0822 37.5007 69.1109 37.5007C67.1396 37.5007 66.1539 36.0381 66.1539 34.517C66.1539 32.8204 67.2555 31.4749 69.1109 31.4749C71.0822 31.5334 72.0678 32.9959 72.0098 34.517Z"
      fill="#011A2F"
    />
    <path
      d="M75.8365 37.5007C73.8072 37.5007 72.8216 36.0966 72.8216 34.517C72.8216 32.9959 73.8072 31.4749 75.8365 31.4749C76.5902 31.4749 77.3439 31.7674 77.9237 32.3524L77.4019 32.8204C76.9381 32.3524 76.3583 32.1769 75.7785 32.1769C74.2131 32.1769 73.4594 33.2885 73.5173 34.517C73.5173 35.6871 74.2711 36.7987 75.7785 36.7987C76.3583 36.7987 76.9961 36.5647 77.4019 36.1551L77.9237 36.6817C77.4019 37.2082 76.5902 37.5007 75.8365 37.5007Z"
      fill="#011A2F"
    />
    <path d="M79.6631 31.5918H78.9673V37.3836H79.6631V31.5918Z" fill="#011A2F" />
    <path
      d="M83.5477 32.3523L82.1562 35.5115H84.8812L83.5477 32.3523ZM83.9536 31.5918L86.5626 37.3836H85.8089L85.2291 36.0966H81.9243L81.3445 37.3836H80.5908L83.1999 31.5918H83.9536Z"
      fill="#011A2F"
    />
    <path
      d="M85.8668 32.2353V31.5918H90.5052V32.2353H88.5918V37.4421H87.8381V32.2353H85.8668Z"
      fill="#011A2F"
    />
    <path d="M92.1287 31.5918H91.4329V37.3836H92.1287V31.5918Z" fill="#011A2F" />
    <path
      d="M96.1872 32.1769C94.7377 32.1769 93.984 33.2885 93.984 34.517C93.984 35.6871 94.6797 36.8572 96.1872 36.8572C97.6946 36.8572 98.3904 35.6871 98.3904 34.517C98.3904 33.347 97.6946 32.1769 96.1872 32.1769ZM99.0861 34.517C99.0861 36.0381 98.1584 37.5007 96.1872 37.5007C94.2159 37.5007 93.2302 36.0381 93.2302 34.517C93.2302 32.8204 94.3318 31.4749 96.1872 31.4749C98.1584 31.5334 99.1441 32.9959 99.0861 34.517Z"
      fill="#011A2F"
    />
    <path
      d="M104.304 31.5918V36.0381L100.768 31.5918H100.246V37.4421H100.942V32.9959L104.478 37.4421H105V31.5918H104.304Z"
      fill="#011A2F"
    />
  </svg>
);

export {
  logoCobaSmall,
  logoCobaBig,
  logoCobaText
}