import { ARTICLES } from '../constants';

const initialState = {
  isLoading: false,
  customerStories: [],
  totalCustomerStories: 0,
  currentPage: 1,
  itemsPerPage: 0
};

const customerStoriesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ARTICLES.REQUEST_CUSTOMER_STORIES:
      return {
        ...state,
        isLoading: true,
        currentPage: action.payload
      };

    case ARTICLES.RECEIVED_CUSTOMER_STORIES:
      return {
        ...state,
        isLoading: false,
        totalCustomerStories: action.payload.data.total,
        customerStories: action.payload.data.data,
        currentPage: action.payload.data.current_page,
        itemsPerPage: action.payload.per_page
      };

    case ARTICLES.FAILED_CUSTOMER_STORIES:
      return {
        ...state,
        isLoading: false
      };
  }

  return state;
};

export default customerStoriesReducer;
