import React from 'react';

export default function IndexBars({ activeSlideIndex, indexes, showSpecificCard }) {
  let bars = [];
  for (let index = 0; index < indexes; index++) {
    bars.push(
      <li
        key={index}
        className={`carousel-indicator cursor-pointer${
          index === activeSlideIndex ? ' active' : ''
        }`}
        onClick={() => showSpecificCard(index)}
      />
    );
  }

  return <ul className="index-bars">{bars}</ul>;
}
