import { SUBSCRIPTION } from '../constants'

const requestSubscription = (payload) => ({
    type: SUBSCRIPTION.REQUEST_SUBSCRIPTION,
    payload
})

const successfulSubscription = (payload) => ({
    type: SUBSCRIPTION.SUCCESSFUL_SUBSCRIPTION,
    payload
})

const failedSubscription = (payload) => ({
    type: SUBSCRIPTION.FAILED_SUBSCRIPTION,
    payload
})

export { requestSubscription, successfulSubscription, failedSubscription }