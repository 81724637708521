import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getNewsArticlesPolicyFilters,
  getSelectedTopicsAndPolicy
} from '@/store/selectors';
import { requestNewsArticlesFirstPage, requestPolicyStageFilters } from '@/store/actions';
import { RadioButton } from '@/components';
import { bindActionCreators } from 'redux';

export default function PolicyStageFilter() {
  const actions = bindActionCreators(
    { requestPolicyStageFilters, requestNewsArticlesFirstPage },
    useDispatch()
  );
  const policyStages = useSelector(getNewsArticlesPolicyFilters);
  const selectedTopicsAndPolicy = useSelector(getSelectedTopicsAndPolicy);
  const [selectedPolicyStage, setSelectedPolicyStage] = React.useState(undefined);

  const onSelectPolicyStage = (id) => () => {
    setSelectedPolicyStage(id);
  };

  React.useEffect(() => {
    actions.requestPolicyStageFilters();
  }, []);

  React.useEffect(() => {
    if (isEmpty(selectedTopicsAndPolicy.filter((e) => e.type === 'policy'))) {
      setSelectedPolicyStage(undefined);
    }
  }, [selectedTopicsAndPolicy]);

  React.useEffect(() => {
    !!selectedPolicyStage &&
      actions.requestNewsArticlesFirstPage({
        policy: selectedPolicyStage
      });
  }, [selectedPolicyStage]);

  return policyStages.map(({ id, name }) => {
    return (
      <RadioButton
        key={`policy-stage-${name}-${id}`}
        text={name}
        id={id}
        name="policy-stages"
        onChange={onSelectPolicyStage(id)}
        value={selectedPolicyStage}
      />
    );
  });
}
