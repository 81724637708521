import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { COORDINATES } from '../constants';
import { receivedCoordinates, failedCoordinates } from '../actions';
import { BASE_URL, API } from '@/config';

export function* requestCoordinates(action) {
  const payloadIsPostcode =
    action?.payload?.length === 4 && !isNaN(parseInt(action?.payload));

  const addressToSearch = payloadIsPostcode
    ? `${action.payload} postcode`
    : action.payload;

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        address: addressToSearch
      }
    });

  try {
    const response = yield call(request);
    yield put(receivedCoordinates(response));
  } catch (err) {
    yield put(failedCoordinates(err));
  }
}

export default function* watchRequestCoordinates() {
  yield takeEvery(COORDINATES.REQUEST_COORDINATES, requestCoordinates);
}
