import { ARTICLES } from '../constants';

const initialState = {
  isLoading: false,
  sectorNews: [],
  totalSectorNews: 0,
  currentPage: 1,
  itemsPerPage: 0
};

const sectorNewsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ARTICLES.REQUEST_SECTOR_NEWS:
      return {
        ...state,
        isLoading: true
      };

    case ARTICLES.RECEIVED_SECTOR_NEWS:
      return {
        ...state,
        isLoading: false,
        sectorNews: action.payload.data.data,
        totalSectorNews: action.payload.data.total,
        currentPage: action.payload.data.current_page,
        itemsPerPage: action.payload.data.per_page
      };

    case ARTICLES.FAILED_SECTOR_NEWS:
      return {
        ...state,
        isLoading: false
      };
  }

  return state;
};

export default sectorNewsReducer;
