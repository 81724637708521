import React from 'react';

export default function TableBlock({ data }) {
    return <div className="static-table-wrapper">
        <table className="static-table">
            <tbody>
            {
                data.map((item, index) => (index === 0) ?
                    (<tr key={index}>
                        {item.map((val, index) => (
                            <th key={index}>{val}</th>
                        ))}
                    </tr>) :
                    (<tr key={index}>
                        {item.map((val, index) => (
                            <td key={index}>{val}</td>
                        ))}
                    </tr>)
                )
            }
            </tbody>
        </table>
    </div>;
}
