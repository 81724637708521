import React from 'react';
import { PortalPageMenuItem } from '@/components/Portal';
import { ContactCard } from '@/components/Portal/Card';

export default function KeyContacts({article}) {
  const keyContacts = article?.key_contacts; 
  const contacts = !!keyContacts ? JSON.parse(keyContacts) : [];

	if (!contacts.length) {
		return null;
	}

  return (
		<PortalPageMenuItem title='Key Contacts'>
			<div className="menu-card-wrapper">
				{contacts.map(({key, attributes}) => {
					const {
						contact_email,
						contact_title,
						contact_name,
						contact_phone
					} = attributes;

					return (
						<ContactCard
							key={key}
							name={contact_name}
							title={contact_title}
							email={contact_email}
							phone={contact_phone}
						/>
					)
				})}
			</div>
		</PortalPageMenuItem>
  )
}
