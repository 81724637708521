import React from 'react';
import { isEmpty } from 'lodash';
import { pluralize } from 'humanize-plus';
import MiniLogo from '@/components/MiniLogo';

const MediaReleaseListDisplayHeading = ({
  totalArticles = 0,
  searchQuery,
  activeYearId
}) => {
  return (
    <div className="heading">
      {isEmpty(searchQuery) && activeYearId == 0 && (
        <React.Fragment>
          <MiniLogo />
          <h3>Featured</h3>
        </React.Fragment>
      )}

      <div className="total-displayed">
        Displaying {totalArticles} {pluralize(totalArticles, 'release')}
      </div>
    </div>
  );
};

export default MediaReleaseListDisplayHeading;
