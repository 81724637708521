import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { getFeedbackData } from '@/store/selectors';
import { Button, Tag } from '@/components';
import { iconPortalComment } from '@/images/icons';
import { utcToLocal } from '@/utils';

FeedbackNotice.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  content: PropTypes.any
};

export default function FeedbackNotice({ id,  title = 'Action Required', size = 'large' }) {
  const { feedbackEndDate, isFeedbackExpired } = useSelector(getFeedbackData(id));

  const feedbackDeadline = feedbackEndDate ? ` ${format(utcToLocal(feedbackEndDate), 'EEEE do MMMM yyyy')}` : feedbackEndDate;

  return (
    <div className={`feedback notice ${size}`}>
      <div className="layout">
        <Tag
          type={
            isFeedbackExpired ? 'article-feedback-closed' : 'article-feedback-required'
          }
          label={isFeedbackExpired ? 'Feedback Closed' : '! Seeking Feedback'}
        />

        <h4 className="title">{title}</h4>
        <p className="content">
          Members are invited to provide further feedback on this consultation to COBA by
          <strong>{`${feedbackDeadline}`}</strong>.
        </p>

        {!isFeedbackExpired && (
          <a href="#feedback-action">
            <Button variant="btn-text" iconLeft={iconPortalComment}>
              Submit feedback
            </Button>
          </a>
        )}
      </div>
    </div>
  );
}
