import React from 'react';
import '../../../../sass/component-styles/forms/label.scss';

export default function Label({ labelFor, labelText }) {
  return (
    <label className="label" htmlFor={labelFor}>
      {labelText}
    </label>
  );
}
