import React from 'react';
import { VacanciesCard } from '@/components/Portal/Card';

const DisplayVacancyCard = ({vacancy, size}) => {
  if (!vacancy) {
    return null
  }
  const type = !!vacancy?.apply_now_url ? 'apply-link' : 'apply-email';

  return (
    <VacanciesCard
      id={vacancy?.id}
      bank={vacancy?.institution?.trading_name}
      closes={vacancy?.date_end}
      contact={vacancy?.key_contact_name}
      email={vacancy?.key_contact_email}
      employment={vacancy?.work_type}
      location={vacancy?.location}
      size={size}
      title={vacancy?.title}
      type={type}
      url={vacancy?.apply_now_url}
    />
  );
};

export default DisplayVacancyCard