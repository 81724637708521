import { ARTICLES } from '../constants';
// Article By ID
const requestArticle = payload => {
  return {
    type: ARTICLES.REQUEST_ARTICLE,
    payload: {
      id: payload.id,
      slug: payload.slug
    }
  };
};

const receivedArticle = payload => ({
  type: ARTICLES.RECEIVED_ARTICLE,
  payload
});

const failedArticle = payload => ({
  type: ARTICLES.FAILED_ARTICLE,
  payload
});

// Article By Slug
const requestArticleBySlug = payload => {
  return {
    type: ARTICLES.REQUEST_ARTICLE_BY_SLUG,
    payload: {
      slug: payload.slug
    }
  };
};

const receivedArticleBySlug = payload => ({
  type: ARTICLES.RECEIVED_ARTICLE_BY_SLUG,
  payload
});

const failedArticleBySlug = payload => ({
  type: ARTICLES.FAILED_ARTICLE_BY_SLUG,
  payload
});

// Footer Articles
const requestFooterArticles = payload => {
  return {
    type: ARTICLES.REQUEST_FOOTER_ARTICLES,
    payload: {
      categoryId: payload.categoryId,
      itemsPerPage: payload.itemsPerPage || 12,
      pageNumber: payload.pageNumber || 1
    }
  };
};

const receivedFooterArticles = payload => ({
  type: ARTICLES.RECEIVED_FOOTER_ARTICLES,
  payload
});

const failedFooterArticles = payload => ({
  type: ARTICLES.FAILED_FOOTER_ARTICLES,
  payload
});

// Articles By Category Type
const requestArticlesByCategoryType = payload => {
  return {
    type: ARTICLES.REQUEST_ARTICLES_BY_CATEGORY_TYPE,
    payload: {
      categoryId: payload.categoryId,
      itemsPerPage: payload.itemsPerPage || 12,
      pageNumber: payload.pageNumber || 1
    }
  };
};

const receivedArticlesByCategoryType = payload => ({
  type: ARTICLES.RECEIVED_ARTICLES_BY_CATEGORY_TYPE,
  payload
});

const failedArticlesByCategoryType = payload => ({
  type: ARTICLES.FAILED_ARTICLES_BY_CATEGORY_TYPE,
  payload
});

//Article Types
const requestArticleTypes = () => ({
  type: ARTICLES.REQUEST_ARTICLE_TYPES
});

const receivedArticleTypes = payload => ({
  type: ARTICLES.RECEIVED_ARTICLE_TYPES,
  payload
});

const failedArticleTypes = payload => ({
  type: ARTICLES.FAILED_ARTICLE_TYPES,
  payload
});

//Banking tips
const requestBankingTips = payload => ({
  type: ARTICLES.REQUEST_BANKING_TIPS,
  payload
});

const receivedBankingTips = payload => ({
  type: ARTICLES.RECEIVED_BANKING_TIPS,
  payload
});

const failedBankingTips = payload => ({
  type: ARTICLES.FAILED_BANKING_TIPS,
  payload
});

//Campaigns
const requestCampaigns = payload => ({
  type: ARTICLES.REQUEST_CAMPAIGNS,
  payload
});

const receivedCampaigns = payload => ({
  type: ARTICLES.RECEIVED_CAMPAIGNS,
  payload
});

const failedCampaigns = payload => ({
  type: ARTICLES.FAILED_CAMPAIGNS,
  payload
});

//Customer Stories
const requestCustomerStories = payload => ({
  type: ARTICLES.REQUEST_CUSTOMER_STORIES,
  payload
});

const receivedCustomerStories = payload => ({
  type: ARTICLES.RECEIVED_CUSTOMER_STORIES,
  payload
});

const failedCustomerStories = payload => ({
  type: ARTICLES.FAILED_CUSTOMER_STORIES,
  payload
});

//General Articles
const requestGeneralArticles = payload => ({
  type: ARTICLES.REQUEST_GENERAL_ARTICLES,
  payload
});

const receivedGeneralArticles = payload => ({
  type: ARTICLES.RECEIVED_GENERAL_ARTICLES,
  payload
});

const failedGeneralArticles = payload => ({
  type: ARTICLES.FAILED_GENERAL_ARTICLES,
  payload
});

//Sector News
const requestSectorNews = payload => ({
  type: ARTICLES.REQUEST_SECTOR_NEWS,
  payload
});

const receivedSectorNews = payload => ({
  type: ARTICLES.RECEIVED_SECTOR_NEWS,
  payload
});

const failedSectorNews = payload => ({
  type: ARTICLES.FAILED_SECTOR_NEWS,
  payload
});

export {
  // Article
  requestArticle,
  receivedArticle,
  failedArticle,
  // Article By Slug
  requestArticleBySlug,
  receivedArticleBySlug,
  failedArticleBySlug,
  // Article Footer Articles
  requestFooterArticles,
  receivedFooterArticles,
  failedFooterArticles,
  // Articles By Category Type
  requestArticlesByCategoryType,
  receivedArticlesByCategoryType,
  failedArticlesByCategoryType,
  //Article Types
  requestArticleTypes,
  receivedArticleTypes,
  failedArticleTypes,
  //Banking Tips
  requestBankingTips,
  receivedBankingTips,
  failedBankingTips,
  //Campaigns
  requestCampaigns,
  receivedCampaigns,
  failedCampaigns,
  //Campaign outcomes
  //requestCampaignOutcomes,
  //receivedCampaignOutcomes,
  //failedCampaignOutcomes,
  //Customer Stories
  requestCustomerStories,
  receivedCustomerStories,
  failedCustomerStories,
  //General Articles
  requestGeneralArticles,
  receivedGeneralArticles,
  failedGeneralArticles,
  //Reports
  //requestReports,
  //receivedReports,
  //failedReports,
  //Sector News
  requestSectorNews,
  receivedSectorNews,
  failedSectorNews
};
