import PropTypes from 'prop-types';
import React, { useEffect }  from 'react';
import { isEmpty } from 'lodash';
import { PORTAL_PATHS } from '@/config';
import { useSelector, useDispatch } from 'react-redux';
import { requestTrainingCategories } from '@/store/actions';
import { selectTrainingCategories, selectTrainingCart } from '@/store/selectors';
import { PortalPageMenuNavigation, CollapsibleMenuOptions } from '@/components/Portal';

TrainingMenu.propTypes = {
	mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function TrainingMenu({mobile, desktop}) {
  const dispatch = useDispatch();

  const trainingCategories = useSelector(selectTrainingCategories);
	const trainingCart = useSelector(selectTrainingCart);
	const cartItemsCount = trainingCart.items.length;

  useEffect(()=>{
    dispatch(requestTrainingCategories())
  }, []);

  const menuMyTraining = [{
    'title': 'My Training',
    'url': PORTAL_PATHS.resources.training.my.index,
    'children': [
      {
        'title': 'Available training',
        'url': `${PORTAL_PATHS.TRAINING}/my/available`
      },
      {
        'title': `Training Cart ${cartItemsCount > 0 ? `(${cartItemsCount})` : ''}` ,
        'url': `${PORTAL_PATHS.TRAINING}/my/cart`
      }
    ]
  }];

  const menuCategories = trainingCategories.categories.map((category) => (
    isEmpty(category.slug)
    ? null
    : {
      title: category.name,
      url: `${PORTAL_PATHS.TRAINING}/${category.slug}`,
      children: [
        {
          title: 'Why training is important',
          url: `${PORTAL_PATHS.TRAINING}/${category.slug}`
        },
        {
          title: 'Training to purchase',
          url: `${PORTAL_PATHS.TRAINING}/${category.slug}/modules`
        }
      ]
    })
  );

  const menuSupport = [
    {
      'title': 'Support',
      'url': PORTAL_PATHS.resources.training.support.index,
      'children': [
        {
          'title': 'Cancellation policy',
          'url': PORTAL_PATHS.resources.training.support.cancellationPolicy
        },
        {
          'title': 'FAQs',
          'url': PORTAL_PATHS.resources.training.support.faqs
        },
        {
          'title': 'Managing subscriptions',
          'url': PORTAL_PATHS.resources.training.support.managingSubscription
        }
      ]
    }
  ];

  const menuItems = !trainingCategories.loaded
  ? []
  : [...menuMyTraining, ...menuCategories, ...menuSupport].map(
    (menuSection) => {
      if (!isEmpty(menuSection.children)) {
        menuSection.Content = CollapsibleMenuOptions;
      }
      return menuSection;
    }
  );

  return (
    <PortalPageMenuNavigation
      mobile={mobile}
      desktop={desktop}
      items={menuItems}
      title="Training"
    />
  );

};
