import React from 'react';
import { PortalLayout, PortalQuickLinksBanner } from '@/components/Portal';

export default function Portal404() {
  return (
    <PortalLayout>
      <div className='portal-section container'>
        <PortalQuickLinksBanner
          heading={'Error 404'}
          subheading='Page Not Found'
          text='This page doesn’t exist. Try one of our quick links.'
        />
      </div>
    </PortalLayout>
  );
}
