import React from 'react';
import ContactItemDetailIcon from './ContactItemDetailIcon';

const ContactItemDetail = ({ icon, href, children }) => {
  return (
    <React.Fragment>
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <ContactItemDetailIcon icon={icon} />
          {children}
        </a>
      ) : (
        <span>
          <ContactItemDetailIcon icon={icon} />
          {children}
        </span>
      )}
    </React.Fragment>
  );
};

export default ContactItemDetail;
