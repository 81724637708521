import React from 'react';
import { useController } from 'react-hook-form';
import FormField from './FormField';
import { iconPasswordHide, iconPasswordShow } from '@/images/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';

export default function PasswordField2({
  autoComplete = 'current-password',
  control,
  id,
  placeholder = 'Password',
  name = 'password',
  defaultValue,
  onChangeField,
  labelFor,
  labelText = 'Password',
  validation
}) {
  const {
    field: { ref, onChange, ...inputProps },
    formState: { errors }
  } = useController({
    name,
    control,
    ...(!isEmpty(validation) && { rules: validation }),
    defaultValue: defaultValue || ''
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const passwordToggleIconAnimation = {
    initial: {
      scale: 0
    },
    in: {
      scale: 1,
      transition: {
        scale: {
          duration: 0.2,
          delay: 0.2
        }
      }
    },
    out: {
      scale: 0,
      transition: {
        scale: {
          duration: 0.2
        }
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormField labelFor={labelFor} labelText={labelText} name={name} errors={errors}>
      <div className="input-password-wrapper">
        <input
          id={id}
          ref={ref}
          autoComplete={autoComplete}
          className="text-input"
          onChange={(value) => {
            onChange(value);
            !!onChangeField && onChangeField(name);
          }}
          placeholder={placeholder}
          name={name}
          type={showPassword ? 'text' : 'password'}
          {...inputProps}
        />

        <div className="show-password-toggle" onClick={toggleShowPassword} role="button">
          <AnimatePresence>
            <motion.div
              key="password-show"
              className="icon-passowrd-show-wrapper"
              initial="initial"
              animate={showPassword ? 'in' : 'out'}
              exit="out"
              variants={passwordToggleIconAnimation}
            >
              <span className="icon">{iconPasswordShow}</span>
            </motion.div>

            <motion.div
              key="password-hide"
              className="icon-passowrd-hide-wrapper"
              initial="initial"
              animate={!showPassword ? 'in' : 'out'}
              exit="out"
              variants={passwordToggleIconAnimation}
            >
              <span className="icon">{iconPasswordHide}</span>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </FormField>
  );
}
