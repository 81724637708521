import PropTypes from 'prop-types';
import React from 'react';
import { pluralize } from 'humanize-plus';
import { Button } from '@/components';
import { PER_PAGE } from '@/config/';

const LoadMore = ({
                      hasMore,
                      isLoading,
                      onClickLoadMore,
                      resultsDisplaying,
                      resultsTotal,
                      perPage = PER_PAGE
                  }) => {
    if (!hasMore) {
        return false;
    }

    return (
        <div className="load-more">
            {!isLoading && (
                <p className="meta">
                    {`${resultsDisplaying} ${pluralize(
                        resultsDisplaying,
                        'result'
                    )} of ${resultsTotal}`}
                </p>
            )}

            <Button
                isDisabled={isLoading}
                variant="btn-outline"
                onClick={onClickLoadMore(perPage)}
            >
                {isLoading ? 'Loading...' : `Load ${perPage} More`}
            </Button>
        </div>
    );
};

LoadMore.propTypes = {
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClickLoadMore: PropTypes.func,
    totalResults: PropTypes.number,
    perPage: PropTypes.number
};

export default LoadMore;
