import { createSelector } from 'reselect';

export const selectAnnouncementState = (state) => state.portalAnnouncementReducer;

export const selectAnnouncementContent = createSelector(
    selectAnnouncementState,
    (state) => state.announcement
);

export const selectAnnouncementActive = createSelector(
    selectAnnouncementState,
    (state) => state.isActive
);
