import React from 'react';
// import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Label from './Label';
import { validationRegex, fieldHasError } from '@/utils';
import { useController } from 'react-hook-form';

export default function EmailField({
  defaultValue,
  autoComplete = 'username',
  id = 'member-email',
  placeholder = 'Email',
  name = 'email',
  onClick,
  onChangeField,
  labelFor = 'Email',
  labelText = 'Email',
  validation = {
    required: {
      value: true,
      message: 'This field is required.'
    },
    pattern: {
      value: validationRegex.email,
      message: 'Please enter a valid e-mail address.'
    }
  },
  control
}) {
  const {
    field: { ref, onChange,...inputProps },
    formState: { errors }
  } = useController({
    name,
    control,
    rules: validation,
    defaultValue: defaultValue || ''
  });

  const onChangeEmailField = (e) => {
    onChangeField && onChangeField(e);
    onChange(e);
  };

  return (
    <div className={`form-field ${fieldHasError(name, errors)}`}>
      <Label for={labelFor} labelText={labelText} />

      <input
        autoComplete={autoComplete}
        id={id}
        className="text-input"
        ref={ref}
        onClick={onClick}
        onChange={onChangeEmailField}
        placeholder={placeholder}
        name={name}
        type="email"
        {...inputProps}
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="text-field-error">{message}</div>}
      />
    </div>
  );
}
