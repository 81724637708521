import React from 'react';
import { useSelector } from 'react-redux';
import SubscribeForm from '../SubscribeForm';
import { getArticle } from '@/store/selectors/articles';
import ArticleSidebarContact from './ArticleSidebarContact';
import ArticleSidebarDownload from './ArticleSidebarDownload';

const ArticleSidebar = () => {
  const article = useSelector(getArticle);

  return (
    <aside className="article-sidebar">
      <ArticleSidebarDownload />
      <ArticleSidebarContact />
      {article.sidebar_subscribe == 1 && (
        <div className="subscribe section">
          <SubscribeForm heading="Subscribe" />
        </div>
      )}
    </aside>
  );
};

export default ArticleSidebar;
