import { call, put, takeLatest } from 'redux-saga/effects';
import { ARTICLES } from '../constants';
import { receivedBankingTips, failedBankingTips } from '../actions';
import rootDomain from '../rootDomain';
const api = (url) => fetch(url, {}).then((res) => res.json());

export function* requestBankingTipsAsync(action) {
  const ROOT = rootDomain();
  const bankingTipsUrl = `${ROOT}/api/v1/articles/type?per_page=12&type_id=3&page=${action.payload}`;

  try {
    const response = yield call(api, bankingTipsUrl);
    yield put(receivedBankingTips(response));
  } catch (err) {
    yield put(failedBankingTips(err));
  }
}

export default function* watchRequestBankingTips() {
  yield takeLatest(ARTICLES.REQUEST_BANKING_TIPS, requestBankingTipsAsync);
}
