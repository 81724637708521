import React from 'react';
import { Link } from 'react-router-dom';
import { iconArrowThin, iconMan, iconLoudSpeaker, iconTeam } from '@/images/icons';
import half_logo from '@/images/half_logo.png';
import MiniLogo from '@/components/MiniLogo';

const KeyBenefits = () => {
  return (
    <section id="key-benefits" data-testid="key-benefits">
      <div className="background-half-logo">
        <img src={half_logo} alt="Coba half logo" />
      </div>
      <div className="content-wrapper">
        <header>
          <div className="heading">
            <MiniLogo />
            <h2>
              Take back what’s <u>yours</u> with customer owned banking
            </h2>
          </div>
          <Link to="/how-it-works/how-we-are-different" className="btn btn-secondary">
            Learn how we are different <span className="icon">{iconArrowThin}</span>
          </Link>
        </header>

        <div className="card-container">
          <div className="card">
            <div className="blurred-background"></div>
            <div className="card-heading">
              <span className="icon">{iconMan}</span>
              <span className="reference">01</span>
            </div>
            <div className="card-content">
              <h3>People before profits</h3>
              <p>
                Australians want a banking institution they can trust to put them first.
                Customer owned banking is the alternative where customer interests are not
                in conflict with shareholder interests. We are solely customer focused –
                all profits are used to benefit you.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-heading">
              <span className="icon">{iconLoudSpeaker}</span>
              <span className="reference">02</span>
            </div>
            <h3>A strong history</h3>
            <p>
              We are Australia’s credit unions, mutual banks and building societies with a
              strong 150-year old history but we’re not old and crusty. We have a long and
              proud history of helping Australians achieve their financial dream.
            </p>
          </div>

          <div className="card">
            <div className="card-heading">
              <span className="icon">{iconTeam}</span>
              <span className="reference">03</span>
            </div>
            <h3>Community focused</h3>
            <p>
              We serve local communities with a &apos;people helping people&apos;
              philosophy. We have a rich and proud history of cooperation, which is still
              seen today as regions and workers supporting each other and their
              communities through our trusted banking institutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyBenefits;
