import { ARTICLES } from '../constants'

//initial state
const initialState = {
    isLoading: false,
    articleTypesData: {}
}

//Reducer
const articleTypesReducer = (state = initialState, action = {}) => {

    switch(action.type) {
        case ARTICLES.REQUEST_ARTICLE_TYPES:
            return {...state, isLoading:true}

        case ARTICLES.RECEIVED_ARTICLE_TYPES:
            return {...state, isLoading:false, articleTypesData: action.payload}

        case ARTICLES.FAILED_ARTICLE_TYPES:
            return {...state, isLoading:false, articleTypesData: action.payload}
    }

    return state
}

export default articleTypesReducer
