import { PORTAL_PATHS } from '@/config';
import { logoCobaText } from '@/images/logos';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useMedia from 'use-media';
import MemberWelcomeFooter from './MemberWelcomeFooter';
import MemberWelcomeMessage from './MemberWelcomeMessage';
import NewMemberWelcomeMessage from './NewMemberWelcomeMessage';

export default function MemberWelcome() {
  const isMobile = useMedia('(max-width: 1023px)');

  return (
    <div className="member-welcome col-xs-12 col-md-6 col-md-offset-1">
      <div className="container">
        <div className="row">
          <div className="logo">
            {logoCobaText}
          </div>
        </div>

        <div className="introduction">
          <div className="row">
            <Switch>
              <Route exact path={PORTAL_PATHS.onboarding.index}>
                <MemberWelcomeMessage />
              </Route>

              <Route path={PORTAL_PATHS.onboarding.signIn}>
                <MemberWelcomeMessage />
              </Route>

              <Route path={PORTAL_PATHS.onboarding.forgotPassword}>
                <MemberWelcomeMessage />
              </Route>

              <Route path={PORTAL_PATHS.onboarding.resetPassword}>
                <MemberWelcomeMessage />
              </Route>

              <Route path={PORTAL_PATHS.onboarding.createAccount.index}>
                <NewMemberWelcomeMessage />
              </Route>

              <Route path={`${PORTAL_PATHS.onboarding.createAccount.index}/step-:step`}>
                <NewMemberWelcomeMessage />
              </Route>

              <Route path="/reset-password">
                <MemberWelcomeMessage />
              </Route>
            </Switch>
          </div>
        </div>
      </div>

      {!isMobile && <MemberWelcomeFooter />}
    </div>
  );
}
