import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getArticleSidebarDownloadItems } from '@/store/selectors/articles';
import { iconSaveAlt } from '@/images/icons';

const ArticleSidebarDownload = () => {
  const downloadItems = useSelector(getArticleSidebarDownloadItems);

  if (isEmpty(downloadItems)) {
    return null;
  }

  return (
    <div className="download section">
      {downloadItems.map((downloadItem, i) => (
        <div key={i}>
          <div className="download-item">
            <h4>Download {downloadItem.type}</h4>
            <a href={downloadItem.url} target="_blank" rel="noopener noreferrer" download>
              <span className="icon">{iconSaveAlt}</span>
              <span>
                <p className="title">{downloadItem.download_title}</p>
                <p>{downloadItem.options.size}</p>
              </span>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArticleSidebarDownload;
