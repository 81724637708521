import { TRAINING_CART } from '../constants';

// Cart
export const trainingCartItemAdd = (payload) => ({
	type: TRAINING_CART.ITEM_ADD,
	payload
})

export const trainingCartItemRemove = (payload) => ({
	type: TRAINING_CART.ITEM_REMOVE,
	payload
})

export const submitTrainingPurchase = (payload) => ({
	type: TRAINING_CART.SUBMIT_PURCHASE,
	payload
})

export const submitTrainingPurchaseSuccess = (payload) => ({
	type: TRAINING_CART.SUBMIT_PURCHASE_SUCCESS,
	payload
})

export const submitTrainingPurchaseFailed = (payload) => ({
	type: TRAINING_CART.SUBMIT_PURCHASE_FAILED,
	payload
})