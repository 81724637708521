import { NEWS_ARTICLES } from '../constants';

const initialState = {
  isArticlesLoading: true,
  isFiltersLoading: true,
  isFeedbackSaving: false,
  queries: {
    topics: [],
    search: '',
    regulators: [],
    policy: undefined,
    dateStart: undefined,
    dateEnd: undefined,
    errors: []
  },
  filterOptions: {
    topics: [],
    policy: [],
    regulators: []
  },
  articles: {
    items: [],
    currentPage: 1,
    total: 0,
    hasMore: false,
    errors: []
  },
  errors: {
    feedback: ''
  },
  feedback: {
    feedback: ''
  }
};

const newsArticlesReducer = (state = initialState, action = {}) => {
  const results = action.payload?.data?.data || [];

  switch (action.type) {
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE:
      return {
        ...state,
        isArticlesLoading: true,
        articles: {
          ...state.articles,
          errors: []
        },
        queries: {
          ...state.queries,
          ...action.payload
        }
      };

    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID:
      return {
        ...state,
        isArticlesLoading: true,
        articles: {
          ...state.articles,
          errors: []
        }
      };

    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE_SUCCESS: {
      const resultsData = results?.data || [];

      return {
        ...state,
        isArticlesLoading: false,
        articles: {
          ...state.articles,
          items: resultsData,
          currentPage: results?.current_page,
          total: results?.total,
          hasMore: !!results?.next_page_url
        }
      };
    }

    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE_SUCCESS: {
      const resultsData = results?.data || [];

      return {
        ...state,
        isArticlesLoading: false,
        articles: {
          ...state.articles,
          items: [...state.articles.items, ...resultsData],
          currentPage: results?.current_page,
          total: results?.total,
          hasMore: !!results?.next_page_url
        }
      };
    }

    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID_SUCCESS:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG_SUCCESS:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_SUCCESS: {
      return {
        ...state,
        isArticlesLoading: false,
        articles: {
          ...state.articles,
          items: [...state.articles.items, results]
        }
      };
    }

    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE_FAILED:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE_FAILED:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID_FAILED:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG_FAILED:
    case NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_FAILED:
      return {
        ...state,
        isArticlesLoading: false,
        articles: {
          ...state.articles,
          errors: 'Failed to receive requested news articles.'
        }
      };

    case NEWS_ARTICLES.CLEAR_FILTERS:
      return {
        ...state,
        queries: {
          ...initialState.queries
        }
      };

    case NEWS_ARTICLES.REQUEST_NEWS_TOPICS:
    case NEWS_ARTICLES.REQUEST_REGULATORS:
    case NEWS_ARTICLES.REQUEST_POLICY_STAGE:
      return {
        ...state,
        isFiltersLoading: true,
        errors: {
          ...state.errors,
          filters: ''
        }
      };

    case NEWS_ARTICLES.REQUEST_NEWS_TOPICS_SUCCESS:
      return {
        ...state,
        isFiltersLoading: false,
        filterOptions: {
          ...state.filterOptions,
          topics: results.data
        }
      };

    case NEWS_ARTICLES.REQUEST_REGULATORS_SUCCESS:
      return {
        ...state,
        isFiltersLoading: false,
        filterOptions: {
          ...state.filterOptions,
          regulators: results.data
        }
      };

    case NEWS_ARTICLES.REQUEST_POLICY_STAGE_SUCCESS:
      return {
        ...state,
        isFiltersLoading: false,
        filterOptions: {
          ...state.filterOptions,
          policy: results.data
        }
      };

    case NEWS_ARTICLES.REQUEST_NEWS_TOPICS_FAILED:
    case NEWS_ARTICLES.REQUEST_REGULATORS_FAILED:
    case NEWS_ARTICLES.REQUEST_POLICY_STAGE_FAILED:
      return {
        ...state,
        isFiltersLoading: false,
        errors: {
          ...state.errors,
          filters: 'Failed to receive filters.'
        }
      };

    case NEWS_ARTICLES.SELECT_NEWS_TOPICS: {
      if (action.payload === -1) {
        return {
          ...state,
          queries: {
            ...state.queries,
            topics: []
          }
        };
      }
      const topicIds = state.queries.topics.includes(action.payload)
        ? state.queries.topics.filter((id) => id !== action.payload)
        : [...state.queries.topics, action.payload];

      return {
        ...state,
        queries: {
          ...state.queries,
          topics: topicIds
        }
      };
    }

    case NEWS_ARTICLES.SUBMIT_FEEDBACK: {
      return {
        ...state,
        isFeedbackSaving: true,
        feedback: {
          feedback: action.payload
        }
      };
    }

    case NEWS_ARTICLES.SUBMIT_FEEDBACK_SUCCESS: {
      return {
        ...state,
        isFeedbackSaving: false
      };
    }

    case NEWS_ARTICLES.SUBMIT_FEEDBACK_FAILED: {
      return {
        ...state,
        isFeedbackSaving: false,
        errors: {
          ...state.errors,
          feedback: 'Failed to save feedback.'
        }
      };
    }

    default:
      return state;
  }
};

export default newsArticlesReducer;
