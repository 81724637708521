import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { utcToLocal } from '@/utils';
import { requestCEONewsArticle } from '@/store/actions';
import {
  selectCEONewsArticleBySlug,
  selectCEONewsArticlesLoading
} from '@/store/selectors';
import {
  PortalLayout,
  PortalPageTitle,
  PortalQuickLinksBanner,
  Tag
} from '@/components/Portal';
import { CEOdeskMenu } from '@/components/Portal/Home';

export default function CEOsDesk() {
  const dispatch = useDispatch();
  const params = useParams();
  const loading = useSelector(selectCEONewsArticlesLoading);
  const article = useSelector(selectCEONewsArticleBySlug(params.slug));
  const [articleFound, setArticleFound] = useState(!loading && !!article);

  useEffect(() => {
    !article && !!params.slug && dispatch(requestCEONewsArticle({ slug: params.slug }));
  }, [article]);

  useEffect(() => {
    setArticleFound(!loading && !!article);
  }, [loading]);

  return (
    <PortalLayout>
      <PortalPageTitle heading={"From the CEO's Desk"} description="Mike Lawrence" />
      <CEOdeskMenu article={article} mobile />

      <div className="container">
        {!loading && !articleFound && (
          <PortalQuickLinksBanner
            heading={'Article Not Found'}
            subheading="From the CEO’s Desk"
            text="This news article doesn’t not exist. Try one of our quick links."
          />
        )}

        {!loading && articleFound && (
          <div className="row">
            <CEOdeskMenu article={article} desktop />

            <div className="col-xs-12 col-sm-8">
              <div className="article-wrapper">
                {!!article.date_published && (
                  <Tag
                    label={format(utcToLocal(article.date_published), 'd LLLL yyyy')}
                  />
                )}
                <div
                  className="article-content"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />
                <div>
                  {!!article.key_contact_signature_data && (
                    <img
                      className="signature"
                      src={article.key_contact_signature_data.url}
                      alt="CEO signarture"
                    />
                  )}
                  {!!article.key_contact_name && (
                    <p className="bold">{article.key_contact_name}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PortalLayout>
  );
}
