import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// State
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { requestUserDivisions, submitJobListing, resetJobListing } from '@/store/actions';
import { getUser, getUserDivisionOptions, selectListing } from '@/store/selectors';
// Components
import { Button, DatePicker, TextField, Textarea, Dropdown, Label } from '@/components';
import { iconArrowThin } from '@/images/icons';
import { ErrorTags } from '@/components/Portal';
// Utils
import { validationRegex } from '@/utils';
import { format } from 'date-fns';
import { partialRight, isEmpty } from 'lodash';
import { PORTAL_PATHS } from '@/config';

export function List() {
  const actions = bindActionCreators(
    { requestUserDivisions, submitJobListing },
    useDispatch()
  );

  const history = useHistory();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const dropDownDivisions = useSelector(getUserDivisionOptions);
  const { isListing, hasListed, errors } = useSelector(selectListing);
  const [closingDate, setClosingDate] = useState(undefined);
  const [applicationMethod, setApplicationMethod] = useState('');

  useEffect(() => {
    if (hasListed && isEmpty(errors)) {
      dispatch(resetJobListing());
      history.push(PORTAL_PATHS.resources.vacancies.submitted);
    }
  }, [hasListed]);

  const dropDownWorkTypes = [
    {
      id: 'Fulltime',
      name: 'Full Time'
    },
    {
      id: 'Parttime',
      name: 'Part Time'
    },
    {
      id: 'Contract',
      name: 'Contract'
    },
    {
      id: 'Temporary',
      name: 'Temporary'
    },
    {
      id: 'Volunteer',
      name: 'Volunteer'
    },
    {
      id: 'Internship',
      name: 'Internship'
    },
    {
      id: 'Other',
      name: 'Other'
    }
  ];

  const dropDownApplicationMethods = [
    {
      id: 'online',
      name: 'Online URL'
    },
    {
      id: 'email',
      name: 'Email'
    }
  ];

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      bank_id: '',
      work_type: '',
      application_method: ''
    }
  });

  const formatDate = partialRight(format, 'yyyy-MM-dd');

  const onChangeDate = (value) => {
    //TODO: Check this saves into correct time in the DB.
    const date = formatDate(new Date(value));
    setClosingDate(date);
  };

  const onClickDivisionOption = (e) => {
    const selectedId = e.target.dataset.id;
    const targetName = e.target.textContent;

    form.setValue(
      'bank_id',
      { id: selectedId, value: targetName },
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const onClickDropdownWorktype = (e) => {
    const targetId = e.target.dataset.id;
    const targetName = e.target.textContent;

    form.setValue(
      'work_type',
      { id: targetId, value: targetName },
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const onClickDropDownAppMethod = (e) => {
    const targetId = e.target.dataset.id;
    const targetName = e.target.textContent;

    setApplicationMethod(targetId);

    form.setValue(
      'application_method',
      { id: targetId, value: targetName },
      { shouldDirty: true }
    );
  };

  const onChangeContactName = (ev) => {
    form.setValue('contact_name', ev.target.value)
  }

  const onChangeContactEmail = (ev) => {
    form.setValue('contact_email', ev.target.value)
  }

  const onSubmitForm = (data) => {
    actions.submitJobListing({
      ...data,
      bank_id: data.bank_id.id,
      date_end: closingDate,
      work_type: data.work_type.value
    });
  };

  useEffect(() => {
    actions.requestUserDivisions({ institution_id: user.institution_id });
  }, []);

  return (
    <div className="vacancies view form">
      <FormProvider {...form}>
        <form onSubmit={onSubmitForm} id="contact-form" name="contact-form" noValidate>
          <div className="form-group">
            <h2>List Vacancy</h2>
            <p>
              Vacancies should be submitted by COB Tuesday to have your position vacant
              advertisement included in that week’s Bulletin pack.
            </p>
            <p>
              Any forms received after COB Tuesday may be held over to the following week.
            </p>
          </div>

          <div className="form-group">
            <h3>Select Division</h3>
            <p>Select the division this vacancy is for</p>

            <Dropdown
              id="division-id"
              name="bank_id"
              control={form.control}
              labelFor="division-id"
              labelText="Division*"
              placeholder="Please select"
              options={dropDownDivisions}
              onClickDropdownOption={onClickDivisionOption}
              validation={{
                required: {
                  value: true,
                  message: 'Please select an option.'
                }
              }}
            />
          </div>

          <div className="form-group">
            <h3>Job details</h3>
            <p>Fields marked with an asterisk are mandatory.</p>

            <TextField
              id="title"
              name="title"
              labelFor="title"
              labelText="Position Title*"
              placeholder="Position Title"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              control={form.control}
            />

            <TextField
              id="location"
              name="location"
              labelFor="location"
              labelText="Location*"
              placeholder="Location"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              control={form.control}
            />

            <Dropdown
              id="work-type"
              name="work_type"
              control={form.control}
              labelFor="work-type"
              labelText="Employment Type*"
              placeholder="Please select"
              options={dropDownWorkTypes}
              onClickDropdownOption={onClickDropdownWorktype}
              validation={{
                required: {
                  value: true,
                  message: 'Please select an option.'
                }
              }}
            />

            <div className="form-field">
              <Label labelText="Closing Date*" />
              <DatePicker onChange={onChangeDate} />
            </div>
          </div>

          <div className="form-group">
            <h2>Job description</h2>
            <p>
              We suggest using short paragraphs and dot points so that it is easy to read.
              Fields marked with an asterisk are mandatory.
            </p>

            <Textarea
              id="description"
              name="description"
              labelFor="description"
              labelText="About The Role*"
              placeholder="A general overview of the position"
              rows={7}
              control={form.control}
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
            />

            <Textarea
              id="roles-reponsibility"
              name="roles_and_responsibilities"
              labelFor="roles-reponsibility"
              labelText="Roles and Responsibilities*"
              placeholder="A list of roles and responsibilities"
              rows={7}
              control={form.control}
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
            />

            <Textarea
              id="about-you"
              name="about_you"
              labelFor="about-you"
              labelText="About You"
              placeholder="Description of the ideal candidate"
              rows={7}
              control={form.control}
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
            />

            <Textarea
              id="about-us"
              name="about_us"
              labelFor="about-us"
              labelText="About Us"
              placeholder="Description of your company and culture"
              rows={7}
              control={form.control}
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
            />
          </div>

          <div className="form-group">
            <h2>Key Contact</h2>
            <p>
              This is recommended so that applicants can reach out to you if they have any
              questions
            </p>

            <TextField
              id="key-contact-name"
              name="key_contact_name"
              labelFor="key-contact-name"
              labelText="Name"
              placeholder="Name"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              control={form.control}
              onChangeField={onChangeContactName}
            />

            <TextField
              id="key-contact-title"
              name="key_contact_title"
              labelFor="key-contact-title"
              labelText="Position Title"
              placeholder="Position Title"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
              control={form.control}
            />

            <TextField
              id="key-contact-phone"
              name="key_contact_phone"
              labelFor="key-contact-phone"
              labelText="Phone"
              placeholder="Phone"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.ausPhoneNumber,
                  message: 'Please enter a valid phone number.'
                }
              }}
              control={form.control}
            />

            <TextField
              id="key-contact-email"
              type="email"
              name="key_contact_email"
              labelFor="key-contact-email"
              labelText="Email"
              placeholder="Email"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.email,
                  message: 'Please enter a valid e-mail address.'
                }
              }}
              control={form.control}
              onChangeField={onChangeContactEmail}
            />
          </div>

          <div className="form-group">
            <h2>How to apply</h2>
            <p>Fields marked with an asterisk are mandatory.</p>

            <Dropdown
              id="application-method"
              name="application_method"
              control={form.control}
              labelFor="application-method"
              labelText="Application Method*"
              placeholder="Please select"
              options={dropDownApplicationMethods}
              onClickDropdownOption={onClickDropDownAppMethod}
              value={applicationMethod}
              validation={{
                required: {
                  value: true,
                  message: 'Please select an option.'
                }
              }}
            />

            {applicationMethod === 'online' && (
              <TextField
                id="apply-now-url"
                type="url"
                name="apply_now_url"
                labelFor="apply-now-url"
                labelText="Paste Url Here"
                placeholder="Paste Url Here"
                validation={{
                  required: {
                    value: true,
                    message: 'This field is required.'
                  },
                  pattern: {
                    value: validationRegex.httpUrl,
                    message: 'Please enter a valid url address.'
                  }
                }}
                control={form.control}
              />
            )}

            {applicationMethod === 'email' && (
              <>
                <TextField
                  id="contact-name"
                  name="contact_name"
                  labelFor="contact-name"
                  labelText="Contact Name"
                  placeholder="Contact Name"
                  isDisabled={true}
                  control={form.control}
                />

                <TextField
                  id="contact-email"
                  name="contact_email"
                  labelFor="contact-email"
                  labelText="Contact Email"
                  placeholder="Contact Email"
                  isDisabled={true}

                  control={form.control}
                />
              </>
            )}
          </div>
          <Button
            variant={'btn btn-fill'}
            icon={iconArrowThin}
            type="submit"
            isDisabled={isListing}
            onClick={form.handleSubmit(onSubmitForm)}
          >
            {isListing ? 'Submitting' : 'Submit for approval'}
          </Button>
        </form>
        <br />
        {!isEmpty(errors) && <ErrorTags errors={errors} />}
      </FormProvider>
    </div>
  );
}

export default React.memo(List);
