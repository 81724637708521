import React, { useEffect, useState } from 'react'
import usePrevious from '@react-hook/previous';
import { useDispatch, useSelector } from 'react-redux'
import { requestUsers } from '@/store/actions'
import { selectInstitutionUsers } from '@/store/selectors'
import { LoadMoreButton }  from '@/components/Portal'
import User from './User'
import UserHeading from './UserHeading'

export default function ManageUsers() {
	const dispatch = useDispatch();
	const {userList, hasMore, isLoading} = useSelector(selectInstitutionUsers);
	const [pageIndex, setPageIndex] = useState(1);
	const previousPageIndex = usePrevious(pageIndex);

	const onClickLoadMore = () => {
    setPageIndex(pageIndex + 1);
  };
	
	useEffect(() => {
		dispatch( 
			requestUsers({ page: pageIndex }) 
		);
  }, []);

	useEffect(() => {
    if (previousPageIndex !== pageIndex && hasMore) {
      dispatch(
        requestUsers({ page: pageIndex })
      );
    }
  }, [pageIndex]);

  return (
    <div className="my-account manage-users">
			<h2>Manage users</h2>

			<div className="user-list">
				<UserHeading />
				{userList.map((user) => { return (
					<User 
						key={user.id}	
						user={user}
					/>
				)})}
			</div>

			<LoadMoreButton
				isLoading={isLoading}
				hasMore={hasMore}
				onClickLoadMore={onClickLoadMore}
			/>
		</div>
  );
}
