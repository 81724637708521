import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedFn } from '@artifak/usedebouncedfn';
import { isEmpty } from 'lodash';
//Store
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectVacancies } from '@/store/selectors';
import { bindActionCreators } from 'redux';
import {
  clearQueriesSearch,
  requestJobVacanciesFirstPage,
  requestJobVacanciesNextPage
} from '@/store/actions';
// Components
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { TextField, Dropdown } from '@/components';
import { Searchbar, LoadMore } from '@/components/Portal';
import { VacanciesCard } from '@/components/Portal/Card';
// Utils
import { pluralize } from 'humanize-plus';
import { PER_PAGE } from '@/config';

function cardListing(results) {
  if (results?.length > 0) {
    return results.map((item) => {
      return (
        <CSSTransition key={item.id} classNames="vacancy" timeout={600}>
          <VacanciesCard
            key={item.id}
            id={item.id}
            type="read-more"
            bank={item.institution.trading_name}
            title={item.title}
            employment={item.work_type}
            location={item.location}
            closes={item.date_end}
          />
        </CSSTransition>
      );
    });
  }
  return;
}

export default function View() {
  const actions = bindActionCreators(
    { requestJobVacanciesFirstPage, clearQueriesSearch, requestJobVacanciesNextPage },
    useDispatch()
  );
  const [location, setLocation] = useState('');
  const [workType, setWorkType] = useState('');
  const { hasMore, isLoading, items, total, page } = useSelector(
    selectVacancies,
    shallowEqual
  );
  const resultsDisplaying = items.length;
  const resultsTotal = total;

  const onClickLoadMore = (perPage) => () => {
    actions.requestJobVacanciesNextPage({
      page: page + 1,
      per_page: perPage
    });
  };

  const searchbarActions = {
    request: {
      dispatch: actions.requestJobVacanciesFirstPage,
      searchParamKey: 'search',
      payload: {
        per_page: PER_PAGE
      }
    },
    clear: {
      dispatch: actions.requestJobVacanciesFirstPage,
      payload: {
        per_page: PER_PAGE,
        search: ''
      }
    }
  };

  useEffect(() => {
    debouncedGetSearchResults({ location });
  }, [location]);

  const debouncedGetSearchResults = useDebouncedFn(
    ({ location }) =>
      actions.requestJobVacanciesFirstPage({
        location
      }),
    500
  );

  const onLocationChange = (e) => {
    let location = e.target.value;
    setLocation(location);
  };

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: ''
  });

  const dropDownWorkTypes = [
    { id: 'all', name: 'All' },
    {
      id: 'Fulltime',
      name: 'Full Time'
    },
    {
      id: 'Parttime',
      name: 'Part Time'
    },
    {
      id: 'Contract',
      name: 'Contract'
    },
    {
      id: 'Temporary',
      name: 'Temporary'
    },
    {
      id: 'Volunteer',
      name: 'Volunteer'
    },
    {
      id: 'Internship',
      name: 'Internship'
    },
    {
      id: 'Other',
      name: 'Other'
    }
  ];

  const onClickDropdownWorktype = (e) => {
    const targetId = e.target.dataset.id;
    const targetName = e.target.textContent;

    setWorkType(targetId === 'all' ? '' : targetName);

    form.setValue('work_type', { id: targetId, value: targetName });
  };

  useEffect(() => {
    actions.requestJobVacanciesFirstPage();
  }, []);

  useEffect(() => {
    actions.requestJobVacanciesFirstPage({ type: workType });
  }, [workType]);

  return (
    <div className="vacancies view">
      <div className="portal-page-search">
        <Searchbar
          searchFieldName="vacancy-search"
          actions={searchbarActions}
          isSearching={isLoading}
        />
      </div>
      <div className="portal-query-filters-container">
        <TextField
          id="location"
          name="location"
          labelFor="location"
          labelText="Location"
          placeholder="Search location"
          control={form.control}
          onChangeField={onLocationChange}
        />
        <Dropdown
          id="work-type"
          name="work_type"
          control={form.control}
          labelFor="work-type"
          labelText="Employment Type"
          placeholder="Please select"
          options={dropDownWorkTypes}
          onClickDropdownOption={onClickDropdownWorktype}
        />
      </div>
      <div className="listing">
        <div className="listing-counter">{`${total} ${pluralize(total, 'result')}`}</div>

        <TransitionGroup className="card-listing">
          {!isEmpty(items) && cardListing(items)}
          <LoadMore
            isLoading={isLoading}
            hasMore={hasMore}
            onClickLoadMore={onClickLoadMore}
            resultsDisplaying={resultsDisplaying}
            resultsTotal={resultsTotal}
          />
        </TransitionGroup>
      </div>
    </div>
  );
}
