import { FEEDS } from '../constants';

const initialState = {
  isArticlesLoading: true,
  articles: {
    items: [],
    currentPage: 1,
    total: 0,
    hasMore: false,
    errors: []
  }
};

const feedsReducer = (state = initialState, action = {}) => {
  const results = action.payload?.data?.data || [];

  switch (action.type) {
    case FEEDS.REQUEST_FEEDS_BY_PAGE:
      return {
        ...state,
        isArticlesLoading: true,
        articles: {
          ...state.articles,
          errors: []
        },
        queries: {
          ...state.queries,
          ...action.payload
        }
      };

    case FEEDS.REQUEST_FEEDS_BY_PAGE_SUCCESS: {
      const resultsData = results?.data || [];

      return {
        ...state,
        isArticlesLoading: false,
        articles: {
          ...state.articles,
          items: resultsData,
          currentPage: results?.current_page,
          total: results?.total,
          hasMore: !!results?.next_page_url
        }
      };
    }

    case FEEDS.REQUEST_FEEDS_BY_PAGE_FAILED:
      return {
        ...state,
        isArticlesLoading: false,
        articles: {
          ...state.articles,
          errors: 'Failed to receive requested news articles.'
        }
      };

    default:
      return state;
  }
};

export default feedsReducer;
