import React from 'react';
import { isEmpty } from 'lodash';
import { PortalPageMenuContent, PortalPageMenuItem } from '@/components/Portal';
import { ActionCard, ContactCard, EventCard } from '@/components/Portal/Card';

export default function EventDetailsMenu({ event, mobile, desktop }) {
  const icalDownloadLink = encodeURI(
    `data:text/calendar;charset=utf8,${event?.ical_data}`
  );

  return !event ? (
    <PortalPageMenuContent 
      mobile={mobile} 
      desktop={desktop}
    />
  ) : (
    <PortalPageMenuContent 
      mobile={mobile} 
      desktop={desktop}
      mobileToggleName="Key Contacts"
    >
      { desktop && 
        <PortalPageMenuItem>
          <EventCard
            key={event?.id}
            id={event?.id}
            heading={event?.heading}
            start_date={event?.start_date}
            end_date={event?.end_date}
            location={event?.location}
            register={event?.register_url}
          />
        </PortalPageMenuItem>
      }

      {!isEmpty(event.key_contacts) && (
        <PortalPageMenuItem title="Key Contacts">
          <div className="menu-card-wrapper">
            {event.key_contacts.map((key_contact) => {
              let contact = key_contact.attributes;
              return (
                <ContactCard
                  key={key_contact.key}
                  name={contact?.contact_name}
                  title={contact?.contact_title}
                  email={contact?.contact_email}
                  phone={contact?.contact_phone}
                />
              );
            })}
          </div>
        </PortalPageMenuItem>
      )}

      <PortalPageMenuItem title="Add to Calendar">
        <ActionCard action="calendar" title="Add to calendar" link={icalDownloadLink} />
      </PortalPageMenuItem>

    </PortalPageMenuContent>
  );
}
