import { PORTAL_PATHS } from '@/config';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function FormTypeSelector() {
  const { pathname } = useLocation();

  const isSignIn =
    pathname.includes(PORTAL_PATHS.onboarding.signIn) ||
    pathname === PORTAL_PATHS.onboarding.index;
  const isCreateAcc = pathname.includes(PORTAL_PATHS.onboarding.createAccount.index);

  const activeForm = isSignIn ? 'btn-signin-acitve' : 'btn-create-acc-active';
  const btnSignInActive = isSignIn ? 'btn-signin-active' : '';
  const btnCreateAccActive = isCreateAcc ? 'btn-create-acc-active' : '';

  return (
    <div className={`form-type-selector ${activeForm}`}>
      <div className="btn-bg" />

      <Link
        className={`btn btn-signin ${btnSignInActive}`}
        to={PORTAL_PATHS.onboarding.signIn}
      >
        <p>Sign In</p>
      </Link>

      <Link
        className={`btn btn-create-acc ${btnCreateAccActive}`}
        to={PORTAL_PATHS.onboarding.createAccount.index}
      >
        <p>Create Account</p>
      </Link>
    </div>
  );
}
