import { ARTICLES } from '../constants';

const initialState = {
  isLoading: false,
  campaigns: [],
  totalCampaigns: 0,
  currentPage: 1,
  itemsPerPage: 0
};

const campaignsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ARTICLES.REQUEST_CAMPAIGNS:
      return {
        ...state,
        isLoading: true
      };

    case ARTICLES.RECEIVED_CAMPAIGNS:
      return {
        ...state,
        isLoading: false,
        campaigns: action.payload.data.data,
        currentPage: action.payload.data.current_page,
        totalCampaigns: action.payload.data.total
      };

    case ARTICLES.FAILED_CAMPAIGNS:
      return {
        ...state,
        isLoading: false,
        campaignsData: action.payload
      };
  }

  return state;
};

export default campaignsReducer;
