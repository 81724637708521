import React from 'react';
import { Button } from '@/components';

export default function LoadMoreButton({ hasMore, isLoading, onClickLoadMore }) {
  if (!hasMore) {
    return false;
  }

  return (
    <div className="load-more">
      <Button isDisabled={isLoading} variant="btn-outline" onClick={onClickLoadMore}>
        {isLoading ? 'Loading...' : 'Load More'}
      </Button>
    </div>
  );
}
