import { HOME_PAGE } from '../constants';

const initialState = {
  isLoading: false,
	header: {},
	testimonials: {},
	keyMetrics: {},
	quicklinks: {}
};

const homepageReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case HOME_PAGE.REQUEST_HOME_PAGE:
			return { ...state, isLoading: true };

		case HOME_PAGE.REQUEST_HOME_PAGE_SUCCESS:
			return {
				...state,
				isLoading: false,
				header: action.payload.data.data.header,
				testimonials: action.payload.data.data.testimonials,
				keyMetrics: action.payload.data.data.key_metrics
			};

		case HOME_PAGE.REQUEST_HOME_PAGE_FAILED:
			return {
				...state,
				isLoading: false
			};
        }

	return state;
};

export default homepageReducer;
