import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MiniLogo from '@/components/MiniLogo';
import { getIsLatestArticles, getIsRelatedArticles } from '@/store/selectors/articles';
import ArticleFooterHeadingLink from './ArticleFooterHeadingLink';

const ArticleFooterHeading = () => {
  const { pathname } = useLocation();

  const isLatestArticles = useSelector(getIsLatestArticles);
  const isRelatedArticles = useSelector(getIsRelatedArticles);

  const isNewsResources = pathname.includes('news-and-resources');

  const title = isLatestArticles
    ? 'Latest articles'
    : isRelatedArticles
    ? 'Related articles'
    : '';

  return (
    <header>
      <div className="heading">
        <MiniLogo />
        <h2>{title}</h2>
      </div>

      <ArticleFooterHeadingLink isNewsResources={isNewsResources} />
    </header>
  );
};

export default ArticleFooterHeading;
