import React from 'react';

export default function PortalBannerText({ subheading, heading, text }) {
  return (
    <div className="portal-banner-text">
      <header>
        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
        <h4>{subheading}</h4>
      </header>
      <div
        className="introduction-text-wrapper"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}
