import { ARTICLES } from '../constants';

const initialState = {
  isLoading: false,
  isFooterArticlesLoading: false,
  articles: [],
  article: {},
  footerArticles: [],
  totalArticles: 0,
  currentPage: 1,
  itemsPerPage: 0
};

const articlesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ARTICLES.REQUEST_ARTICLE:
      return {
        ...state,
        isLoading: true
      };

    case ARTICLES.RECEIVED_ARTICLE:
      return {
        ...state,
        isLoading: false,
        article: action.payload.data
      };

    case ARTICLES.FAILED_ARTICLE:
      return {
        ...state,
        isLoading: false
      };

    case ARTICLES.REQUEST_ARTICLE_BY_SLUG:
      return {
        ...state,
        isLoading: true
      };

    case ARTICLES.RECEIVED_ARTICLE_BY_SLUG:
      return {
        ...state,
        article: action?.payload?.data,
        isLoading: false
      };

    case ARTICLES.FAILED_ARTICLE_BY_SLUG:
      return {
        ...state,
        isLoading: false
      };

    case ARTICLES.REQUEST_FOOTER_ARTICLES:
      return {
        ...state,
        isFooterArticlesLoading: true
      };

    case ARTICLES.RECEIVED_FOOTER_ARTICLES:
      return {
        ...state,
        isFooterArticlesLoading: false,
        footerArticles: action.payload.data.data
      };

    case ARTICLES.FAILED_FOOTER_ARTICLES:
      return {
        ...state,
        isFooterArticlesLoading: false
      };

    case ARTICLES.REQUEST_ARTICLES_BY_CATEGORY_TYPE:
      return {
        ...state,
        isLoading: true
      };

    case ARTICLES.RECEIVED_ARTICLES_BY_CATEGORY_TYPE:
      return {
        ...state,
        isLoading: false,
        articles: action.payload.data.data,
        totalArticles: action.payload.data.total,
        currentPage: action.payload.data.current_page,
        itemsPerPage: action.payload.per_page
      };

    case ARTICLES.FAILED_ARTICLES_BY_CATEGORY_TYPE:
      return {
        ...state,
        isLoading: false
      };
  }

  return state;
};

export default articlesReducer;
