import { RATE_INFO } from '../constants';

const initialState = {
  info: {
    type: '',
    options: []
  },
  isActive: false,
  isLoading: false
};

const rateInfoReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RATE_INFO.REQUEST_RATE_INFO: {
      return {
        ...state,
        isLoading: true
      };
    }

    case RATE_INFO.REQUEST_RATE_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        info: action.payload.data.data.info,
        isActive: !action.payload.data.data.rate_experience_active
      };
    }

    case RATE_INFO.REQUEST_RATE_INFO_FAILED: {
      return {
        ...state,
        isLoading: false
      };
    }
  }

  return state;
};

export default rateInfoReducer;
