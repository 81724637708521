import { STAFF } from '../constants';

const initialState = {
	isLoading: false,
	structured: {},
	errors: undefined
};

const staffReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case STAFF.REQUEST_STAFF_LIST:
      return {
				...initialState,
				isLoading: true,
      };

		case STAFF.REQUEST_STAFF_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				structured: action.payload?.data?.data
			};

		case STAFF.REQUEST_STAFF_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload.data
			};
	}
	return state;
};

export default staffReducer;

