import { TRAINING } from '../constants';
import { uniqBy } from 'lodash';

const initialState = {
  cart: {
    items: [],
    isOrdering: false,
    errors: {}
  },
  categories: {
    isLoading: false,
    hasLoaded: false,
    list: [],
    errors: {}
  },
  modules: {
    isLoading: false,
    hasLoaded: false,
    list: [],
    total: 0,
    currentPage: 1,
    hasMore: false,
    errors: {}
  }
};

const trainingReducer = (state = initialState, action = {}) => {
  const payload = action.payload?.data;

  switch (action.type) {
    case TRAINING.CLEAR_TRAINING_MODULES:
      return {
        ...state,
        modules: {
          ...initialState.modules
        }
      };

    case TRAINING.TRAINING_CART_ITEM_ADD:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: uniqBy([...state.cart.items, action.payload], 'id')
        }
      };

    case TRAINING.TRAINING_CART_ITEM_REMOVE:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: state.cart.items.filter((item) => item.id !== action.payload?.id)
        }
      };

    case TRAINING.SUBMIT_TRAINING_PURCHASE:
      return {
        ...state,
        cart: {
          ...state.cart,
          isOrdering: true
        }
      };

    case TRAINING.SUBMIT_TRAINING_PURCHASE_SUCCESS:
      return {
        ...state,
        cart: {
          ...initialState.cart
        }
      };

    case TRAINING.SUBMIT_TRAINING_PURCHASE_FAILED:
      return {
        ...state,
        cart: {
          ...state.cart,
          isOrdering: false,
          errors: payload || {}
        }
      };

    case TRAINING.REQUEST_TRAINING_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          errors: {},
          isLoading: true,
          hasLoaded: false
        }
      };

    case TRAINING.REQUEST_TRAINING_CATEGORIES_SUCCESS: {
      const categoriesList = payload?.data?.data || [];

      return {
        ...state,
        categories: {
          ...state.categories,
          errors: {},
          isLoading: false,
          hasLoaded: true,
          list: categoriesList
        }
      };
    }

    case TRAINING.REQUEST_TRAINING_CATEGORIES_FAILED: {
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: false,
          hasLoaded: true,
          errors: payload || {}
        }
      };
    }

    case TRAINING.REQUEST_TRAINING_MODULE_BY_ID:
    case TRAINING.REQUEST_TRAINING_MODULES:
      return {
        ...state,
        modules: {
          ...state.modules,
          isLoading: true,
          errors: {}
        }
      };

    case TRAINING.REQUEST_TRAINING_MODULE_BY_ID_SUCCESS: {
      return {
        ...state,
        modules: {
          ...state.modules,
          isLoading: false,
          hasLoaded: true,
          errors: {},
          list: uniqBy([...state.modules.list, payload?.data], 'id'),
          currentPage: payload?.current_page,
          total: payload?.total,
          hasMore: !!payload?.data?.next_page_url
        }
      };
    }

    case TRAINING.REQUEST_TRAINING_MODULES_SUCCESS: {
      const listItems = payload?.data?.data || [];

      return {
        ...state,
        modules: {
          ...state.modules,
          isLoading: false,
          hasLoaded: true,
          errors: {},
          list: uniqBy([...state.modules.list, ...listItems], 'id'),
          currentPage: payload?.current_page,
          total: payload?.total,
          hasMore: !!payload?.data?.next_page_url
        }
      };
    }

    case TRAINING.REQUEST_TRAINING_MODULE_BY_ID_FAILED:
    case TRAINING.REQUEST_TRAINING_MODULES_FAILED:
      return {
        ...state,
        modules: {
          ...state.modules,
          isLoading: false,
          hasLoaded: true,
          errors: payload || {}
        }
      };
  }

  return state;
};

export default trainingReducer;
