import React, { useRef, useState } from 'react';
import { iconPlus, iconMinus } from '@/images/icons';

function AccordionItem({ item }) {
  const [active, isActive] = useState(false);
  const ref = useRef(null);

  const handleActive = () => {
    ref.current.scrollIntoView({
      block: 'center',
      inline: 'nearest',
      behavior: 'smooth'
    });
    isActive(!active);
  };

  if (!item && !item.attributes) {
    return;
  }

  return (
    <div className="accordion-item" ref={ref}>
      <div className="accordion-title">
        <button onClick={handleActive}>
          {item.attributes.accordion_title}
          <span className="icon">{active ? iconMinus : iconPlus}</span>
        </button>
      </div>

      {active && (
        <div
          className="accordion-content"
          dangerouslySetInnerHTML={{
            __html: item.attributes.accordion_content
          }}
        ></div>
      )}
    </div>
  );
}

export default AccordionItem;
