import axios from 'axios';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { EMAIL_SUBSCRIPTIONS } from '../constants';
import {
  requestEmailSubscriptionsFailed,
  requestEmailSubscriptionsSuccess,
  updateEmailSubscriptionsFailed,
  updateEmailSubscriptionsSuccess
} from '../actions';
import { getUserToken } from '@/selectors';
import { BASE_URL, API } from '@/config';

export function* getEmailSubscriptions(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`
    });

  try {
    const response = yield call(request);
    yield put(requestEmailSubscriptionsSuccess(response));
  } catch (err) {
    yield put(requestEmailSubscriptionsFailed(err));
  }
}

export function* watchGetEmailSubscriptions() {
  yield takeLatest(
    EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS,
    getEmailSubscriptions
  );
}

export function* postUpdateEmailSubscriptions(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(updateEmailSubscriptionsSuccess(response));
  } catch (err) {
    yield put(updateEmailSubscriptionsFailed(err));
  }
}

export function* watchPostUpdateEmailSubscriptions() {
  yield takeLatest(
    EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS,
    postUpdateEmailSubscriptions
  );
}

const sagas = [
  fork(watchGetEmailSubscriptions),
  fork(watchPostUpdateEmailSubscriptions)
];

export default sagas;
