export const addParamsToURL = (uri, queries) => {
  if (queries) {
    const joint = queries
      .map((query) => {
        return !!query.values && !!query.values.replace
          ? `${query.key}=${query.values.replace(/,/g, `&${query.key}=`)}`
          : '';
      })
      .filter((queryString) => !!queryString);

    const params = joint.length > 1 ? joint.join('&') : joint.join('');
    const separator = !!params ? '?' : '';

    return `${uri}${separator}${params}`;
  } else {
    return uri;
  }
};
