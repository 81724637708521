import { call, put, takeEvery } from 'redux-saga/effects';
import { ARTICLES } from '../constants';
import { receivedGeneralArticles, failedGeneralArticles } from '../actions';
import rootDomain from '../rootDomain';
const api = (url) => fetch(url).then((res) => res.json());

export function* requestGeneralArticlesAsync() {
  const ROOT = rootDomain();
  const generalArticlesUrl = `${ROOT}/api/v1/articles/type?per_page=10&type_id=11&page=1`;

  try {
    const callGeneralArticles = yield call(api, generalArticlesUrl);
    const totalGeneralArticles = callGeneralArticles.data.total;
    const allGeneralArticlesUrl = `${ROOT}/api/v1/articles/type?per_page=${totalGeneralArticles}&type_id=11&page=1`;
    const response = yield call(api, allGeneralArticlesUrl);

    yield put(receivedGeneralArticles(response));
  } catch (err) {
    yield put(failedGeneralArticles(err));
  }
}

export default function* watchRequestGeneralArticles() {
  yield takeEvery(ARTICLES.REQUEST_GENERAL_ARTICLES, requestGeneralArticlesAsync);
}
