import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '@/store/actions';
import { getUser } from '@/store/selectors';
import { PortalPageMenuNavigation } from '@/components/Portal';
import { MyAccountMenuContent } from '@/components/Portal/MyAccount';
import { PORTAL_PATHS } from '@/config';

// Page menus filtering based on user types
const permissionCheckMenuItems = (user, menuItems) => {
  const userIsKeyContact = user?.is_key_contact || false;
  const userIsTrainingOnly = user?.training_only || false;

  const isKeyContactRequired = [
    PORTAL_PATHS.myAccount.institutionBranchDetails,
    PORTAL_PATHS.myAccount.manageUsers
  ];

  const isTrainingOnlyNotRequired = [PORTAL_PATHS.myAccount.emailSubscription];

  return menuItems.filter((el) => {
    if (!userIsKeyContact && isKeyContactRequired.includes(el.url)) {
      // Limit users that are NOT -> Key Contacts
      return false;
    }

    if (userIsTrainingOnly && isTrainingOnlyNotRequired.includes(el.url)) {
      // Limit users that are -> Training Only
      return false;
    }

    return true;
  });
};

MyAccountMenu.propTypes = {
  mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function MyAccountMenu({ mobile, desktop }) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const menuItems = permissionCheckMenuItems(user, [
    {
      title: 'Account Settings',
      url: PORTAL_PATHS.myAccount.settings.index,
      children: [
        {
          url: PORTAL_PATHS.myAccount.settings.details,
          text: 'Account Details'
        },
        {
          url: PORTAL_PATHS.myAccount.settings.loginDetails,
          text: 'Login Details'
        }
      ],
      Content: MyAccountMenuContent
    },
    {
      title: 'Division and branch details',
      url: PORTAL_PATHS.myAccount.institutionBranchDetails.index,
      children: [
        {
          url: PORTAL_PATHS.myAccount.institutionBranchDetails.institutionDetails,
          text: 'Update division'
        },

        {
          url: PORTAL_PATHS.myAccount.institutionBranchDetails.branchDetails,
          text: 'Update branch'
        }
      ],
      Content: MyAccountMenuContent
    },
    {
      title: 'Manage Users',
      url: PORTAL_PATHS.myAccount.manageUsers
    },
    {
      title: 'Email Subscriptions',
      url: PORTAL_PATHS.myAccount.emailSubscription
    },
    {
      title: 'Logout',
      url: '#',
      icon: 'icon-arrow',
      onClickAccordionItem: () => {
        dispatch(logoutUser());
      }
    }
  ]);

  return (
    <PortalPageMenuNavigation
      mobile={mobile}
      desktop={desktop}
      items={menuItems}
      title="My Account"
    />
  );
}
