import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function MyAccountMenuContent({ items, isParentActive }) {
  const { pathname } = useLocation();

  return (
    <div className="content">
      <ul>
        {items &&
          items.length &&
          items.map((item, i) => {
            return (
              <li
                className={
                  pathname === item.url && isParentActive
                    ? 'content-link active'
                    : 'content-link'
                }
                key={`content-item-${item.text}-${i}`}
              >
                <Link to={item.url}>{item.text}</Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
