import React from 'react';
import useMedia from 'use-media';
import ButtonNav from './ButtonNav';
import CarouselHeader from './CarouselHeader';
import CarouselQuoteHeader from './CarouselQuoteHeader';
import IndexBars from './IndexBars';
import Slides from './Slides';

export default function Carousel({
                                     variant = '',
                                     headerTitle,
                                     headerLink,
                                     itemsPerView = 2,
                                     maxSlides = 3,
                                     items,
                                     SlideItem
                                 }) {
    const isMobile = useMedia('(max-width: 767px)');
    itemsPerView = isMobile ? 1 : itemsPerView;
    const itemsGap =  30;
    const maxItems = maxSlides * itemsPerView;
    const slideItems = items.slice(0, items.length >= maxItems ? maxItems : items.length);
    const totalSlides = Math.ceil(slideItems.length / itemsPerView);

    const [sliders, setSliders] = React.useState([]);

    const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
    const [slideWidth, setSlideWidth] = React.useState(0);
    const sliderPosition = -activeSlideIndex * slideWidth;

    const showNextCards = () => {
        let index = activeSlideIndex;
        setTimeout(() => {
            setActiveSlideIndex(index + 1);
        }, 200);

        setSliders([...sliders, ...slideItems]);
    };

    const showPrevCards = () => {
        if (activeSlideIndex > 0) {
            setTimeout(() => {
                setActiveSlideIndex(activeSlideIndex - 1);
            }, 200);
        } else {
            setTimeout(() => {
                setActiveSlideIndex(slideItems.length / itemsPerView - 1);
            }, 200);
        }
    };
    const isQuoteHeaderVariant = variant === 'carousel-with-quote-header';
    const showSpecificCard = (index) => {
        setActiveSlideIndex(index);
    };
    React.useEffect(() => {
        setSliders([...slideItems]);
    }, [items]);
    return (
        <div className={`carousel ${variant}`}>
            {!isQuoteHeaderVariant && (
                <CarouselHeader heading={headerTitle} link={headerLink} />
            )}

            {isMobile && isQuoteHeaderVariant && <CarouselQuoteHeader heading={headerTitle} />}

            <div className={`carousel-conatainer ${variant}`}>
                <Slides
                    setSlideWidth={setSlideWidth}
                    sliderPosition={sliderPosition}
                    activeSlideIndex={activeSlideIndex}
                    SlideItem={SlideItem}
                    items={sliders}
                    itemsPerView={itemsPerView}
                    itemsGap={itemsGap}
                />

                <div className="controls-container">
                    {isQuoteHeaderVariant && !isMobile && (
                        <CarouselQuoteHeader heading={headerTitle} />
                    )}

                    <div className="controls">
                        <IndexBars
                            showSpecificCard={showSpecificCard}
                            indexes={totalSlides}
                            activeSlideIndex={activeSlideIndex % slideItems.length}
                        />
                        <ButtonNav showPrevCard={showPrevCards} showNextCard={showNextCards} />
                    </div>
                </div>
            </div>
        </div>
    );
}
