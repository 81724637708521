import PropTypes from 'prop-types';
import React from 'react';
import { PortalPageMenuNavigation } from '@/components/Portal';

GobalSearchMenu.propTypes = {
  filterItems: PropTypes.array,
	mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function GobalSearchMenu({
  filterItems,
  mobile,
  desktop
}) {
  return (
    <PortalPageMenuNavigation 
      mobile={mobile} 
      desktop={desktop}
      items={filterItems}
      title="Results"
    />
  );
};
