import PropTypes from 'prop-types';
import React from 'react';
import menuItems from '@/data/static-menus.json';
import{ PortalPageMenuNavigation } from '@/components/Portal';

SupportMenu.propTypes = {
	mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function SupportMenu ({mobile, desktop}) {

	return (
		<PortalPageMenuNavigation 
			mobile={mobile} 
			desktop={desktop}
			items={menuItems['Contact']}
			title="Support"
		/>
	);
};
