import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { TRAINING_CART } from '../constants';
import { 
  submitTrainingPurchaseSuccess,
  submitTrainingPurchaseFailed
} from '../actions';
import axios from 'axios';
import { getUserToken } from '@/store/selectors';
import { BASE_URL, API } from '@/config';

export function* postTrainingPurchase(action) {
  const token = yield select(getUserToken);
  
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      url: `${BASE_URL}${API[action.type]}`,
      data: {
        cart: JSON.stringify(action.payload.cart)
      }
    });

  try {
    const response = yield call(request);
    yield put(submitTrainingPurchaseSuccess(response));
  } catch (err) {
    yield put(submitTrainingPurchaseFailed(err));
  }
}

export function* watchPostTrainingPurchase() {
  yield takeLatest(TRAINING_CART.SUBMIT_PURCHASE, postTrainingPurchase);
}

const sagas = [
  fork(watchPostTrainingPurchase)
];

export default sagas;
