import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Dropdown, TextField, Textarea } from '@/components';
import { iconArrowThin, iconTick } from '@/images/icons';
import { createNewUserDivision, requestAllOffers } from '@/store/actions';
import { selectAllOffers } from '@/store/selectors';
import { validationRegex } from '@/utils';

export default function AddNewDivision() {
  const actions = bindActionCreators(
    {
      createNewUserDivision,
      requestAllOffers
    },
    useDispatch()
  );

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      divisionName: '',
      divisionUrl: '',
      image: '',
      companyOffer1: '',
      companyOffer2: '',
      companyOffer3: ''
    }
  });

  const { isSubmitted, isDirty, isValid } = form.formState;
  const isSubmitDisabled = !isDirty || !isValid;

  const [divisionName, setDivisionName] = React.useState('');
  const [divisionUrl, setDivisionUrl] = React.useState('');
  const [selectedOffers, setSelectedOffers] = React.useState({});
  const [currentOfferDropdown, setCurrentOfferDropdown] = React.useState(undefined);

  const availableOffers = useSelector(selectAllOffers);

  const onChangeDivisionName = (ev) => {
    setDivisionName(ev.target.value);
  };

  const onChangeDivisionUrl = (ev) => {
    setDivisionUrl(ev.target.value);
  };

  const onSubmitForm = (data) => {
    const selectedOfferIds = Object.keys(selectedOffers).map(
      (index) => selectedOffers[index].id
    );

    actions.createNewUserDivision({
      name: divisionName,
      url: divisionUrl,
      offers: selectedOfferIds,
      blurb: data?.blurb,
      image: data?.image
    });
  };

  const offersCount =
    availableOffers.length >= 3
      ? [...Array(3).keys()]
      : [...Array(availableOffers.length).keys()];

  const onClickOffersDropdownOption = (index) => (ev) => {
    const selectedOfferId = ev.target.dataset.id;
    const dropdownOfferId = ev.target.dataset.dropdownId;
    const targetName = ev.target.textContent;

    setCurrentOfferDropdown(dropdownOfferId);

    setSelectedOffers({
      ...selectedOffers,
      [index]: { id: selectedOfferId, value: targetName }
    });

    form.setValue(
      dropdownOfferId,
      { id: selectedOfferId, value: targetName },
      { shouldDirty: true }
    );
  };

  const offersValidation = () => {
    const selectedOfferIds = Object.keys(selectedOffers).map(
      (index) => selectedOffers[index].id
    );

    const totalUniqueSelections = selectedOfferIds.filter(
      (item, i, arr) => arr.indexOf(item) === i
    ).length;

    const isUniqueSelection = totalUniqueSelections === selectedOfferIds.length;

    if (!selectedOfferIds?.length) {
      return 'Please select an offering.';
    }

    if (selectedOfferIds?.length > 1 && !isUniqueSelection) {
      return 'Duplicate offer selections are not allowed.';
    }

    return true;
  };

  const blurbValidation = (value) => {
    if (!!value && value.split(' ').filter((n) => n !== '').length > 40) {
      return 'Input is over 40 words.';
    }

    return true;
  };

  React.useEffect(() => {
    actions.requestAllOffers();
  }, []);

  React.useEffect(() => {
    if (!!currentOfferDropdown) {
      form.trigger(currentOfferDropdown);
    }
  }, [selectedOffers, currentOfferDropdown]);

  return (
    <div className="form my-account add-new-division">
      <FormProvider {...form}>
        <form noValidate>
          <fieldset className="fieldset">
            <legend className="legend H3">Division details</legend>

            <TextField
              id="name"
              control={form.control}
              name="name"
              labelFor="name"
              labelText="division name"
              onChangeField={onChangeDivisionName}
              validation={{
                required: {
                  value: true,
                  message: 'Please enter a division name.'
                }
              }}
              type="text"
              value={divisionName}
            />

            <TextField
              id="url"
              control={form.control}
              name="url"
              labelFor="url"
              labelText="division URL"
              onChangeField={onChangeDivisionUrl}
              validation={{
                required: {
                  value: true,
                  message: 'Please enter a division URL.'
                },
                pattern: {
                  value: validationRegex.noProtocolUrl,
                  message:
                    'Please enter a valid URL address eg. "www.mysite.com.au" or "mysite.com.au"'
                }
              }}
              type="url"
              value={divisionUrl}
            />
          </fieldset>

          <fieldset className="fieldset">
            <legend className="legend H3">Division logo</legend>

            <TextField
              id="image"
              name="image"
              labelFor="image"
              labelText="Logo Image URL"
              type="url"
              control={form.control}
              validation={{
                required: {
                  value: true,
                  message: 'Please enter an image URL.'
                }
              }}
            />
          </fieldset>

          <br />

          <fieldset>
            <legend className="legend H3">About your member brand division</legend>
            <p>Please limit your about text to 40 words or less</p>

            <br />

            <Textarea
              id="blurb"
              control={form.control}
              name="blurb"
              labelFor="blurb"
              labelText="About"
              rows={7}
              validation={{
                validate: blurbValidation
              }}
            />
          </fieldset>

          <br />

          <fieldset>
            <legend className="legend H3">What we offer</legend>

            {offersCount.map((count) => {
              const index = count + 1;

              return (
                <Dropdown
                  id={`company-offer-${index}`}
                  name={`companyOffer${index}`}
                  key={`company-offer-${index}`}
                  options={availableOffers}
                  placeholder="Company Offer"
                  labelFor={`companyOffer${index}`}
                  labelText="We offer"
                  onClickDropdownOption={onClickOffersDropdownOption(index)}
                  control={form.control}
                  selectedOption={selectedOffers[index]}
                  validation={{
                    validate: offersValidation
                  }}
                />
              );
            })}
          </fieldset>

          <div className={`form-success ${isSubmitted ? 'display' : 'hidden'}`}>
            <span className="icon">{iconTick}</span>
            Thanks - your update has been submitted and is awaiting approval.
          </div>

          {!isSubmitted && (
            <Button
              variant={'btn btn-fill'}
              icon={iconArrowThin}
              isDisabled={isSubmitDisabled}
              type="submit"
              onClick={form.handleSubmit(onSubmitForm)}
            >
              Add division
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
