import { createSelector } from 'reselect';

// Helpers
const sortUserList = (a, b) =>{
	let appovedOrder = a.coba_approved - b.coba_approved
	
	if 	(appovedOrder == 0){
		const fullNameA = a.full_name != ' ' ? a.full_name.toUpperCase() : a.email.toUpperCase()
		const fullNameB = b.full_name != ' ' ? b.full_name.toUpperCase() : b.email.toUpperCase()

		if (fullNameA < fullNameB) {
			return -1;
		}
		if (fullNameA > fullNameB) {
			return 1;
		}
	}

	return appovedOrder
}	

// State selection
const userState = (state) => state.manageUsersReducer;

// Selectors
const selectInstitutionUsersSorted = createSelector(
  userState,
  (state) => state.users.sort(sortUserList)	
);

export const selectInstitutionUsers = createSelector(
	userState,
  selectInstitutionUsersSorted,
  (state,  userList) => ({
		userList: userList,
		isLoading: state.isLoading,
		hasMore: state.hasMore,
		page: state.currentPage,
		total: state.total
	})
);
