import React from 'react';
import { useLocation } from 'react-router-dom';
import useMedia from 'use-media';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import qs from 'qs';
import { requestNewsArticlesFirstPage, requestRegulatorFilters } from '@/store/actions';
import { getNewsArticlesRegulatorFilters } from '@/store/selectors';
import { Badge } from '@/components';

export default function RegulatorFilter() {
  const isDesktop = useMedia('(min-width: 1024px)');

  const actions = bindActionCreators(
    { requestNewsArticlesFirstPage, requestRegulatorFilters },
    useDispatch()
  );

  const [selectedRegulators, setSelectedRegulators] = React.useState([]);
  const regulators = useSelector(getNewsArticlesRegulatorFilters, shallowEqual);
  const location = useLocation();

  const onSelectRegulator = (id) => () => {
    selectedRegulators.includes(id)
      ? setSelectedRegulators(
          selectedRegulators.filter((regulatorId) => regulatorId !== id)
        )
      : setSelectedRegulators([...selectedRegulators, id]);
  };

  React.useEffect(() => {
    actions.requestRegulatorFilters();
  }, []);

  React.useEffect(() => {
    if (location.search !== '') {
      const query = qs.parse(location.search.substring(1));
      if (!!query?.regulators) {
        setSelectedRegulators([parseInt(query.regulators)]);
      }
    } else {
      setSelectedRegulators([]);
    }
  }, [location.search]);

  React.useEffect(() => {
    actions.requestNewsArticlesFirstPage({
      regulators: selectedRegulators
    });
  }, [selectedRegulators]);

  return (
    <div className="filter-regulators">
      {isDesktop && <div className="filter-label">Filter by regulators</div>}

      <div className="filter-list">
        {regulators.map(({ name, id }) => {
          const badgeState = selectedRegulators.includes(id) ? 'is-active' : '';

          return (
            <Badge
              key={`regulator-${name}-${id}`}
              type="regulator"
              state={badgeState}
              handleOnClick={onSelectRegulator(id)}
            >
              {name}
            </Badge>
          );
        })}
      </div>
    </div>
  );
}
