import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SubpageHeader from '@/components/SubpageHeader';
import Layout from '@/components/Layout';
import { EventsMenu, Results, Search } from '@/components/Portal/Events';
import { Dropdown } from '@/components';
import { useForm } from 'react-hook-form';
import {
  selectEvents,
  selectEventSearchQueries,
  selectEventsFlattened,
} from '@/selectors/events';
import { clearSearchQuery, searchEvents } from '@/store/actions';
import { PUBLIC_PATHS } from '@/config';


export default function Events() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isPast = pathname.includes(`${PUBLIC_PATHS.EVENTS}/past/`);
  const year = isPast ? pathname.substring(pathname.lastIndexOf('/') + 1) : undefined;
  const [month, setMonth] = useState('');
  const { results, loading } = useSelector(selectEvents, shallowEqual);
  const queries = useSelector(selectEventSearchQueries, shallowEqual);
  const resultsCount = useSelector(selectEventsFlattened, shallowEqual).length;

  const eventListing = results;
  const dropDownMonths = [
    { id: '', name: 'All' },
    {
      id: '1',
      name: 'January'
    },
    {
      id: '2',
      name: 'February'
    },
    {
      id: '3',
      name: 'March'
    },
    {
      id: '4',
      name: 'April'
    },
    {
      id: '5',
      name: 'May'
    },
    {
      id: '6',
      name: 'June'
    },
    {
      id: '7',
      name: 'July'
    },
    {
      id: '8',
      name: 'August'
    },
    {
      id: '9',
      name: 'September'
    },
    {
      id: '10',
      name: 'October'
    },
    {
      id: '11',
      name: 'November'
    },
    {
      id: '12',
      name: 'December'
    }
  ];

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: ''
  });

  const onClickDropdownMonth = (e) => {
    const targetId = e.target.dataset.id;
    const targetName = e.target.textContent;
    setMonth(targetId);
    form.setValue('month', { id: targetId, value: targetName });
  };

  useEffect(() => {
    if (month) {
      dispatch(searchEvents({ ...queries, month, year }));
    }
  }, [month]);

  useEffect(() => {
    setMonth('');
    form.setValue('month', { id: '', value: 'All' });
    if (year) {
      dispatch(searchEvents({ ...queries, year: year }));
    } else {
      dispatch(searchEvents({ ...queries, year: '' }));
    }
    return () => {
      dispatch(clearSearchQuery());
    };
  }, [year]);

  return (
    <Layout>
      <SubpageHeader
          breadcrumbs="Home / news and resources / events"
          title="Events"
        />
      <EventsMenu mobile />

      <div id="event-index-page" className="container v-pad">
        <div className="row">
          <EventsMenu desktop />

          <div className="col-xs-12 col-md-8 event-index-page">
            <Search />
            <div className="portal-query-filters-container">
              <Dropdown
                id="month"
                name="month"
                control={form.control}
                labelFor="month"
                labelText="Month"
                placeholder="Please select"
                options={dropDownMonths}
                onClickDropdownOption={onClickDropdownMonth}
              />
            </div>
            <Results loading={loading} count={resultsCount} eventResults={eventListing} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
