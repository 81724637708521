import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { isEmpty } from 'lodash';
import Pagination from 'react-js-pagination';
import { FindaBankContext } from '@/context';
import {
  clearBranchesNearMe,
  requestBranchesNearMe,
  requestDivisionsBanks
} from '@/store/actions';
import {
  getCoordinates,
  selectPaginatedDivisionBanks,
  selectPaginatedDivisionBanksPageMeta,
  selectBranchesNearMeResults,
  selectBranchesNearMePageMeta
} from '@/store/selectors';
import { iconArrowThinLongLeft, iconArrowThinLongRight } from '@/images/icons';
import DivisionCard from './DivisionCard';
import DivisionAndBranchesDisplayCount from './DivisionAndBranchesDisplayCount';
import usePrevious from '@react-hook/previous';

const DivisionsAndBranchesList = () => {
  const ITEMS_PER_PAGE = 16;
  const actions = bindActionCreators(
    {
      requestBranchesNearMe,
      requestDivisionsBanks,
      clearBranchesNearMe
    },
    useDispatch()
  );
  const { searchQuery, radiusQuery } = React.useContext(FindaBankContext);

  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
  const prevPageIndex = usePrevious(currentPageIndex);
  const [divisionListing, setDivisionListing] = React.useState({});
  const [divisionListingTotal, setDivisionListingTotal] = React.useState(0);

  const coordinates = useSelector(getCoordinates);
  const prevCoordinates = usePrevious(coordinates);

  const branchesNearMe = useSelector(selectBranchesNearMeResults);
  const branchesNearMePageMeta = useSelector(selectBranchesNearMePageMeta);
  const divisionBanksByPage = useSelector(
    selectPaginatedDivisionBanks(currentPageIndex),
    shallowEqual
  );
  const divisionsBanksPageMeta = useSelector(selectPaginatedDivisionBanksPageMeta);

  const onChangePagination = (pageNumber) => {
    setCurrentPageIndex(pageNumber);
  };

  React.useEffect(() => {
    actions.requestDivisionsBanks({
      page: currentPageIndex,
      per_page: 99999999
    });
  }, []);

  React.useEffect(() => {
    if (!!prevPageIndex && prevPageIndex !== currentPageIndex) {
      window.requestAnimationFrame(() => {
        const window = document.querySelector('html');
        const header = document.querySelector('.institutions-hero-section');

        window.scrollTo({
          top: header.clientHeight - 120,
          behavior: 'smooth'
        });
      });
    }
  }, [currentPageIndex]);

  React.useEffect(() => {
    if (isEmpty(coordinates)) {
      actions.clearBranchesNearMe();
      setCurrentPageIndex(1);
    }
  }, [coordinates]);

  React.useEffect(() => {
    if (!!divisionBanksByPage?.length && (!searchQuery || isEmpty(coordinates))) {
      setDivisionListing(divisionBanksByPage);
    }
  }, [divisionBanksByPage]);

  React.useEffect(() => {
    if (!!searchQuery) {
      setDivisionListing(branchesNearMe);
    }
  }, [branchesNearMe]);

  React.useEffect(() => {
    const totalListings = !isEmpty(branchesNearMePageMeta)
      ? branchesNearMePageMeta.total
      : divisionsBanksPageMeta.total;

    setDivisionListingTotal(totalListings);
  }, [divisionListing]);

  React.useEffect(() => {
    if (!!searchQuery && !isEmpty(coordinates)) {
      const pageIndex = isEmpty(prevCoordinates) ? 1 : currentPageIndex;

      actions.requestBranchesNearMe({
        page: pageIndex,
        per_page: ITEMS_PER_PAGE,
        lat: coordinates.lat,
        long: coordinates.lng,
        radius: radiusQuery,
        order_by: 'desc'
      });
    }
  }, [coordinates, currentPageIndex]);

  return (
    <React.Fragment>
      <div className="institutions-wrapper">
        <DivisionAndBranchesDisplayCount grandTotal={divisionListingTotal} />

        {!isEmpty(divisionListing) && (
          <div className="division-list">
            {divisionListing.map((item) => {
              return !isEmpty(item) ? <DivisionCard key={item.id} {...item} /> : null;
            })}
          </div>
        )}

        <div className="pagination-wrapper">
          <Pagination
            totalItemsCount={divisionListingTotal || 0}
            activePage={currentPageIndex}
            itemsCountPerPage={ITEMS_PER_PAGE}
            onChange={onChangePagination}
            nextPageText={iconArrowThinLongRight}
            prevPageText={iconArrowThinLongLeft}
            hideFirstLastPages={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DivisionsAndBranchesList;
