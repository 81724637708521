import { CREATE_ACCOUNT_FORM } from '../constants';

export const submitStep = (payload, stepIndex) => {
  return {
    type: CREATE_ACCOUNT_FORM[`STEP_${stepIndex}`],
    payload
  };
};

export const clearForm = () => {
  return {
    type: CREATE_ACCOUNT_FORM.CLEAR_FORM
  };
};
