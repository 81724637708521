import { SEARCH } from '../constants';

export const searchAllByTerm = (payload) => ({
  type: SEARCH.SEARCH_ALL_BY_TERM,
  payload
});

export const searchAllByTermSuccess = (payload) => ({
  type: SEARCH.SEARCH_ALL_BY_TERM_SUCCESS,
  payload
});

export const searchAllByTermFailed = (payload) => ({
  type: SEARCH.SEARCH_ALL_BY_TERM_FAILED,
  payload
});

export const clearSearchAll = (payload) => ({
  type: SEARCH.CLEAR_SEARCH_QUERY,
  payload
});
