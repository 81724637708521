import React, { useState } from 'react';
import Layout from '@/components/Layout';
import SubpageHeader from '@/components/SubpageHeader';
import { BoardPageSection } from '@/components/BoardPage';
import SEO from '@/components/SEO';

const boardAccordianData = [
  {
    name: 'Elizabeth Crouch AM',
    role: 'Independent Chair',
    blurb: `<p>Elizabeth is the Chair of the Customer Owned Banking Association, the Sydney Children’s Hospital Network and SGS Economics and Planning.  She is the Emeritus Deputy Chancellor of Macquarie University and currently sits on a number of listed Boards including Bingo Industries and ReadyTech.  She has recently been re-appointed to the Board of the NSW Institute of Sport and also sits on the Boards of Hearing Australia and Health Infrastructure, the organisation that builds all public hospitals in NSW.  Elizabeth has held previous roles on the Boards of Macquarie University Hospital, Chandler Macleod Group, McGrath Estate Agents, RailCorp and the Museum of Applied Arts and Sciences.</p>
<p>She Chairs the NSW Government’s Non Government Schools Council and the audit committees for the NSW Independent Pricing and Regulatory Tribunal and the City of Sydney.</p>
<p>Elizabeth is a respective executive coach and moderator with the Orijen Group and spent 16 years with the Federal Government before moving into the private sector where she held various roles including as the Chief Executive of the Housing Industry Association.  She holds a Bachelor of Economics and in 2020 completed her Masters of Cyber Security.  In 2019 she was awarded an Order of Australia for her services to higher education and the rail transport sector.</p>`,
    image: '/images/board-members/ElizabethCrouch.jpg'
  },
  {
    name: 'Steve Chugg, Director',
    role: 'Chief Operating Officer, Great Southern Bank, Brisbane',
    blurb: `<p>Steve has more than 20 years’ experience in banking operations in Australia, including a variety of executive roles spanning both the listed bank and mutual sector. During the past 2½ years at Great Southern Bank (formerly CUA), Steve has held the roles of Chief Operating Officer and more recently Chief Financial Officer. He is passionate about the future of the mutual sector – in particular, Steve has been active in exploring mutual collaboration opportunities and has played a leading role in industry advocacy on sector issues such as capital reform for mutual ADIs. Steve is also a strong advocate for greater industry level collaboration.</p>
<p>Steve is a Director on the Board of Mutual Marketplace. He is also a Director of Credicorp Finance Pty Ltd, CUA Health Ltd and Chairman of CUA Management Pty Ltd (formerly Financial Planning Pty Ltd).</p>
<p>Steve has extensive experience in strategic management, change leadership, building strong and engaged teams, and delivering positive customer outcomes and strong financial returns.</p>`,
    image: '/images/board-members/SteveChugg.jpg'
  },
  {
    name: 'Ashley Hood, Director',
    role: 'Chief Operating Officer, Bank First',
    blurb: `<p>Ashley holds the role of COO of Bank First. Prior to this position, Ashley was the COO at Beyond Bank and CEO of Nexus Mutual. He is a banker with over 25 years' experience, having held senior executive roles at Bendigo and Adelaide Bank and executive director roles in various of the bank's subsidiary and joint venturers companies.</p>
`,
    image: '/images/board-members/AshleyHood.jpg'
  },
  {
    name: 'Peter Lock, Director',
    role: 'Chief Executive Officer, Heritage Bank, Toowoomba',
    blurb: `<p>Mr Peter Lock has been the CEO of Heritage Bank since 2015. Mr Lock has more than 30 years' experience in the financial services industry, spanning insurance, institutional banking, business development, business banking and corporate banking. He has held senior management roles at the Bank of Melbourne, St George Bank, and the Commonwealth Bank, following a career with the National Australia Bank. He has also run his own corporate advisory firm.</p>
<p>Mr Lock is a Senior Fellow of the Financial Services Institute of Australasia (FINSIA), and a Graduate Member of the Australian Institute of Company Directors (AICD). He sits on the Board of Australian Settlements Limited (ASL) and the Board of Toowoomba and Surat Basin Enterprise (TSBE).</p>
<p>Mr Lock holds a Bachelor of Business and Master of Business Administration from the University of South Australia, and a Graduate Diploma in Applied Finance from the Finance and Securities Institute of Australia and has completed the AsiaLink Leaders Program.</p>`,
    image: '/images/board-members/PeterLock.jpg'
  },
  {
    name: 'Darlene Mattiske-Wood, Director',
    role: 'Chief Executive Officer, Australian Military Bank, Sydney',
    blurb: `<p>Darlene was appointed as the Chief Executive Officer for Australian Military Bank (AMB) in March 2020.  AMB is Australia’s longest serving National Defence related financial institution.  Darlene brings 15 years’ executive experience within the Mutual Sector and more than 20 years’ experience as a strategic leader reporting at the CEO and Board level in both private and public organisations. Her functional responsibilities have included transformational change management, strategy, organisational development, public affairs, stakeholder relations, marketing and customer operations and human resource management.</p>
    <p>Most recently, prior to her appointment with Australian Military Bank Darlene held the position of People’s Choice Deputy Chief Executive from February 2016. Her position included Chief Strategy, Marketing and People Officer,  responsible for the development and implementation of the People’s Choice Strategic Plan as well as the leadership of the credit union’s strategies for People and Culture, Member Strategy, Marketing & Products, Communications & Public Affairs, and the transformation framework to develop and deliver a new Member Centric Target Operating Model. This position included director of Mutual Market Place representing People’s Choice in the Joint Venture Procurement company.</p>
    <p>Darlene completed the advanced management program with Harvard University in 2018 and she has received the following external awards and recognition:</p>
    <ul><li>The 2016 Telstra Business Women’s Award, Corporate & Private South Australian;</li>
    <li>Finalist in the 2017 National Retail Banking Awards for Executive of the Year;</li>
    <li>Australian Human Resource Institute (AHRI) 2008 People Leadership and People StrategyAwards.</li></ul>`,
    image: '/images/board-members/DarleneMattiskeWood.jpg'
  },
  {
    name: 'Stephen Nugent, Director',
    role: 'Chair, Community First Credit Union, Sydney',
    blurb: `<p>Stephen Nugent joined the Community First Credit Union Board as a Director in May 2002, and has been Chair since November 2015. He has been a Director of the Customer Owned Banking Association (COBA) since September 2016. Stephen has more than 38 years experience in the utilities, health, financial services, superannuation and insurance industries including State Building Society, St George, GIO Australia and HCF.</p>
    <p>Stephen brings to the Board a wide range of mutual sector experience as an executive and independent director. He has experience in project and operations management, property management, mergers and integration, process improvement, IT systems development especially in ehealth, sales, marketing, customer service, hospitality, financial management, retirement, home community care, aged care, strategic planning, corporate governance and risk.</p>`,
    image: '/images/board-members/StephenNugent.jpg'
  },
  {
    name: 'Elsbeth Torelli, Director',
    role: 'Chief Operating Officer, G&C Mutual Bank, Melbourne',
    blurb: `<p>Elsbeth has substantial executive experience at G&C Mutual Bank where she is Chief Operating Officer. Elsbeth is a qualified Accountant and Fellow of the IPA and holds a Certificate in Business Studies – Law. She is a member of FINSIA, AICD and the Governance Institute.</p>
<p>Elsbeth has been actively involved in the sector over a number of years with a very good appreciation of the journey and challenges in developing capital options for the mutual sector and meeting the requirements of APRA and ASIC. She has been involved on a number of industry working group initiatives and in representations on regulatory and government policy fronts. Elsbeth is also a Director of Traditional Credit Union.</p>`,
    image: '/images/board-members/ElsbethTorelli.jpg'
  },
  {
    name: 'Therese Turner, Director',
    role: 'Chief Executive Officer, MOVE Bank, Brisbane',
    blurb: `<p>Therese is the CEO of MOVE Bank, based in Brisbane. She has previously held the CEO role at My Credit Union, Quay, Capital and Sutherland Credit Unions, as well as a senior executive position at Illawarra Credit Union. </p>
<p>Therese is highly committed to the ideals and goals of the sector, with over 30 years experience in the mutual banking industry.  During her career, she has been pivotal in driving change for positive outcomes by delivering profitable organic growth, efficient and compliant operations and improved member value. </p>
<p>She holds a Master of Business Administration and is a qualified accountant.</p>`,
    image: '/images/board-members/ThereseTurner.jpg'
  },
  {
    name: 'John Williams',
    role: 'Chief Executive Officer, Summerland Credit Union, Lismore',
    blurb: `<p>John Williams was appointed Chief Executive Officer of Summerland Credit Union in July 2019.</p>
<p>John has 30 years’ experience in the banking industry and was most recently Managing Director of H3 Consulting Group which provides strategy, technology and payments consulting services.</p>
<p>John was previously CEO of a technology company providing banking solutions to the Australian, New Zealand and USA financial services markets. This role followed an extensive executive career at Heritage Bank in COO and CIO roles.</p>
<p>John has been a Director of several financial services and health related organisations including Australian Settlements Limited (ASL), Shared Service Partners, Lifeline Darling Downs, Darling Downs and West Moreton Primary Health Network, and TSWG. He was awarded the Medal for Management Excellence by the Australian Institute of Managers and Leaders. John is proudly a White Ribbon Ambassador and a strong champion for equality.</p>
<p>John is a Fellow of the Australian Institute of Managers and Leaders, a Fellow of the Australian Computer Society and a Gradate Member of the Australian Institute of Company Directors.</p>
<p>John holds an MBA and Associate Diplomas in Engineering and Applied Science. </p>`,
    image: '/images/board-members/JohnWilliams.jpg'
  }
];

const managementAccordianData = [
  {
    name: 'Michael Lawrence',
    role: 'Chief Executive Officer',
    blurb: `<p>Mike is the Chief Executive Officer of the Customer Owned Banking Association (COBA), the industry body for Australia’s credit unions, building societies and mutual banks, following his appointment on 4 December 2017.</p>
<p>In April 2018, Mike became a Director of the World Council of Credit Unions (WOCCU), the peak international body for the credit union movement globally and in 2019 was appointed a Director of the Australian Mutuals Foundation.</p>
<p>He was Managing Director of AMP Bank for 8 years and was responsible for all day to day operations of the organisation, together with the responsibilities associated with running an ADI.</p>
<p>Prior to joining AMP Bank Mike spent a short period for Westpac within Working Capital & Trade Solutions, which consisted of delivering International Trade, Merchant Acquiring and Payment products to each of the distribution channels within Westpac.</p>
<p>Before Westpac, Mike was General Manager Third Party Business where he was directly accountable for running National Australia Bank’s third party banking business. In this role Mike was responsible for the end to end mortgage origination business covering product manufacturing, operations and distribution.</p>
<p>Prior to this, Mike undertook senior roles in all distribution channels of banking, namely, Corporate, Commercial and Retail and having done so across three continents, Australia, UK and USA.</p>
<p>Mike is passionate about community involvement and using his skills that have been acquired over a very fortunate career in playing his part in helping others, more recently as Vice Chair of Heart Research Australia and the many activities associated with Special Needs.</p>`,
    image: '/images/board-members/MichaelLawrence.jpg'
  },
  {
    name: 'Luke Lawler',
    role: 'Director – Policy',
    blurb: `<p>Luke is Director – Policy for COBA, managing COBA’s engagement with policymakers, lawmakers, regulators and other stakeholders in regulatory policy development and implementation.</p>
<p>Luke has held various roles with COBA and its predecessor organisations, including as Head of Public Affairs. He has been a professional advocate for the customer owned banking sector since 1996, participating in a broad range of regulatory policy debates, including on consumer protection, prudential regulation, financial services, credit, corporations, payments, competition, anti-money laundering and taxation.</p>
<p>Prior to joining the customer owned banking sector, Luke was a journalist and media adviser. Luke was a reporter and producer for print and broadcast media in Darwin and Sydney and for the ABC in the Federal Parliamentary Press Galley in Canberra. He was media adviser to a senior Cabinet Minister in the Finance and Treasury portfolios.</p>
<p>Luke has a Master of Business Administration degree from the University of Canberra.</p>`,
    image: '/images/board-members/LukeLawler.jpg'
  },
  {
    name: 'Stephanie Elliott',
    role: 'Director – Corporate Affairs for COBA',
    blurb: `<p>Stephanie Elliott is Director – Corporate Affairs for COBA, leading COBA’s strategic engagement and reputational initiatives.  Her team includes government relations, media, marketing and communications, stakeholder relations, events and campaign functions.</p>

    <p>Prior to joining COBA, Stephanie served as a lawyer, a federal and state public servant, a ministerial adviser and a senior manager of government relations within the financial sector.  She has advised both domestic and offshore governments.  As an experienced corporate affairs professional she specialises in managing organisational and reputational risks.</p>

    <p>Outside of work, Stephanie is a board member and company secretary of, and provides consulting advice to, non-profit organisations.  Stephanie holds a Bachelor of Laws, a Bachelor of Arts and a Master of Laws from the Australian National University.</p>`,
    image: '/images/board-members/stephanie.jpg'
  },
  {
    name: 'Leanne Vale',
    role: 'Director – Financial Crimes and Cyber Resilience',
    blurb: `<p>Leanne Vale is Director - Financial Crimes and Cyber Resilience leading COBA’s Financial Crimes team in the delivery of specialist technical advisory to members, sector representation, financial crimes training and events, regulatory risk and policy interpretation in Payments, Financial Crimes and Cybersecurity governance portfolios and the national coordination of COBA’s law enforcement and industry stakeholders.</p>
    <p>With more than 30 years’ management experience in the financial services sector she has held roles within Australian major, regional and customer owned banks across regulatory compliance, audit and financial crimes risk management. A former sworn Australian Federal Police Officer, Leanne’s holds an Advanced Diploma in Finance, Masters of Business Administration (CSU), Cybersecurity Governance and Risk (RMIT) and Cybersecurity Executive Leadership CERT (CMU) in addition to being a qualified Certified Fraud Examiner (CFE) and Certified Anti- Money Laundering Specialist (CAMLS).
    </p>
    `,
    image: '/images/board-members/LeanneVale.jpg'
  }
];

const BoardPage = () => {
  const [currentActive, setCurrentActive] = useState(null);

  const handleToggleAccordionItem = (index) => {
    setCurrentActive(index);
  };

  return (
    <Layout>
      <SEO
        title="COBA Board members | Customer Owned Banking Association | Australia"
        keywords="board members, director, Services and Financial Crimes, stakeholders, Strategy and Stakeholders, Policy, Chief Executive Officer, Australia banking, Chief Operating Officer,"
        description="The Customer Owned Banking Association is governed by a board of directors elected by our member credit unions, mutual banks and building societies."
      />
      <div id="board-page" data-testid="board-page">
        <SubpageHeader
          breadcrumbs="Home / Board and Management"
          title="Board and Management"
        />
        <BoardPageSection
          title="Our Board"
          description="The Customer Owned Banking Association is governed by a board of directors elected by our member credit unions, mutual banks and building societies."
          accordionData={boardAccordianData}
          currentActive={currentActive}
          handleToggleAccordionItem={handleToggleAccordionItem}
        />
        <BoardPageSection
          title="Management"
          description="The management team of the Customer Owned Banking Association."
          accordionData={managementAccordianData}
          initialIndex={boardAccordianData.length}
          currentActive={currentActive}
          handleToggleAccordionItem={handleToggleAccordionItem}
        />
      </div>
    </Layout>
  );
};

export default BoardPage;
