import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import rootSaga from './sagas/sagas';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['createAccountFormReducer', 'userReducer', 'trainingCartReducer']
};

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleWare = applyMiddleware(sagaMiddleware);
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composeEnhancers =
    (!!window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  let store = createStore(persistedReducer, composeEnhancers(middleWare));
  let persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return {
    store,
    persistor
  };
}

export const { store, persistor } = configureStore();
