import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { NavMenuContext } from '@/context';
import navMenuManifest from '@/data/navmenu-manifest.json';
import { iconChevronDown, iconChevronRight, iconArrowThin } from '@/images/icons';


const NavMenu = () => {


  const { isMobile, onClickOpenSubMenu, onClickCloseSubMenu } =
    React.useContext(NavMenuContext);

  const { pathname } = useLocation();


  React.useEffect(() => {
    onClickCloseSubMenu();
  }, [pathname]);
  return (
    <nav>
      <ul className="navigation">
        {Object.values(navMenuManifest).map((menu, i) => (
          <li key={`${menu.slug}-${i}`}>
            <NavLink
              to="#"
              className="menu-item"
              data-title={menu.slug}
              onClick={onClickOpenSubMenu}
            >
              {menu.name}
              <span className="icon">
                {isMobile ? iconChevronRight : iconChevronDown}
              </span>
            </NavLink>
          </li>
        ))}
        <li>
          <NavLink to="/find-a-bank" className="btn btn-primary">
            Find a Customer Owned Bank
            <span className="icon">{iconArrowThin}</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavMenu;
