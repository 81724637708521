import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Moment from 'react-moment';
import { iconDot } from '@/images/icons';
import MiniLogo from '@/components/MiniLogo';
import { getArticleBanner } from '@/store/selectors/articles';
import { getContentItemById } from '@/store/selectors/content';

const ArticleBanner = () => {
  const { createdAt, heading, author, categoryName, featureImageId } = useSelector(
    getArticleBanner,
    shallowEqual
  );

  const content = useSelector(getContentItemById(featureImageId));
  const hasFeatureImage = !!content && !!content.url;

  return (
    <section className={`article-banner${hasFeatureImage ? ' has-feature-image' : ''}`}>
      <div className={`content-wrapper${hasFeatureImage ? ' has-feature-image' : ''}`}>
        <div className="article-type">
          <span className="icon">{iconDot}</span>
          {categoryName}
        </div>

        <div
          className="h1"
          dangerouslySetInnerHTML={{
            __html: heading
          }}
        />

        <div className="written">
          <p className="author">{author}</p>
          <p className="date">
            <Moment format="D MMMM YYYY">{createdAt}</Moment>
          </p>
        </div>

        <MiniLogo width="60px" />

        {!!content && (
          <div className="featured-image">
            <img src={content.url} alt={content.title} />
          </div>
        )}
      </div>
    </section>
  );
};

export default ArticleBanner;
