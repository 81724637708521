import { PORTAL_PATHS } from '@/config';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import SignInForm from './SignInForm';

export default function SignIn() {
  return (
    <Switch>
      <Route path={PORTAL_PATHS.onboarding.forgotPassword}>
        <ForgotPasswordForm />
      </Route>


      <Route path={PORTAL_PATHS.onboarding.signIn}>
        <SignInForm />
      </Route>
    </Switch>
  );
}
