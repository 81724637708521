import React from 'react';
import { Link } from 'react-router-dom';
import { iconArrowThin } from '@/images/icons';
import { Button } from '@/components';
import { PORTAL_PATHS } from '@/config';

export default function CreateAccountFormSubmitted() {
  return (
    <div className="create-account-form form-submitted">
      <h4 className="form-title">Your request for a new account has been submitted</h4>
      <p>
        Approval for all new user sign-up requests will be sought from the nominated
        organisation.
      </p>

      <br />

      <p>
        Allow for 2/3 working days for your member portal login details to be confirmed
        and emailed to the address provided.
      </p>

      <br />

      <Button variant={'btn-fill btn-full-width'} icon={iconArrowThin}>
        <Link to={PORTAL_PATHS.home}>Back to home</Link>
      </Button>
    </div>
  );
}
