import { createSelector } from 'reselect';
import { selectTrainingCart } from './trainingCart'

const getTrainingCategories = (state) => state.trainingReducer.categories;
const getTrainingModules = (state) => state.trainingReducer.modules;

export const selectTrainingCategories = 
  createSelector(getTrainingCategories, (categories) => ({
    loading: categories.isLoading,
    loaded: categories.hasLoaded,
    categories: categories.list,
    errors: categories.errors
  }))

export const selectTrainingModuleById = (id) =>
  createSelector( selectTrainingModules, (trainingModules) => {
    const moduleList = trainingModules.modules.find((tm) => tm.id === Number(id)) || {}
    
    return {
      loading: trainingModules.isLoading,
      loaded: trainingModules.hasLoaded,
      module: moduleList,
      errors: trainingModules.errors
    }
  })

export const selectTrainingModules = 
  createSelector( 
    selectTrainingCart,
    getTrainingModules, 
    (cart, trainingModules) => {
      const cartItemsIds = cart.items.map(item => item.id)
      const mouldesList = trainingModules.list.map((tm) => {
        tm.in_cart = cartItemsIds.includes(tm.id)
        tm.purchaseStatus = purchaseStatus(tm)
        return tm
      })
      
      return {
        loading: trainingModules.isLoading,
        loaded: trainingModules.hasLoaded,
        modules: mouldesList,
        hasMore: trainingModules.hasMore,
        errors: trainingModules.errors
      }
  })


const purchaseStatus = (trainingModule) => {
  if (trainingModule.has_purchased && trainingModule.purchase_approved) { return 'ready-to-download' }
  if (trainingModule.has_purchased && !trainingModule.purchase_approved) { return 'requested' }
  if (trainingModule.in_cart) { return 'in-cart' }

  return null
}
