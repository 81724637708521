import { fork, call, put, takeLatest } from 'redux-saga/effects';
import { ARTICLES } from '../constants';
import {
  receivedArticle,
  failedArticle,
  receivedArticleBySlug,
  failedArticleBySlug,
  receivedFooterArticles,
  failedFooterArticles,
  receivedArticlesByCategoryType,
  failedArticlesByCategoryType
} from '../actions';
import { BASE_URL, API } from '@/config';
import rootDomain from '../rootDomain';
const api = (url) => fetch(url, {}).then((res) => res.json());

export function* articlesSaga(action) {
  const ROOT = rootDomain();

  const url = `${ROOT}/api/v1/articles/type?per_page=${action.payload.itemsPerPage || 12}&type_id=${action.payload.categoryId}&page=${action.payload.pageNumber}`;

  try {
    const response = yield call(api, url);
    yield put(receivedArticlesByCategoryType(response));
  } catch (err) {
    yield put(failedArticlesByCategoryType(err));
  }
}

export function* watchRequestArticles() {
  yield takeLatest(ARTICLES.REQUEST_ARTICLES_BY_CATEGORY_TYPE, articlesSaga);
}

export function* articleSaga(action) {
  const ROOT = rootDomain();
  const url = `${ROOT}/api/v1/articles/id/${action.payload.id}`;

  try {
    const response = yield call(api, url);
    yield put(receivedArticle(response));
  } catch (err) {
    yield put(failedArticle(err));
  }
}

export function* watchRequestArticle() {
  yield takeLatest(ARTICLES.REQUEST_ARTICLE, articleSaga);
}

export function* articleBySlugSaga(action) {
  const url = `${BASE_URL}${API[action.type]}/${action.payload.slug}`;

  try {
    const response = yield call(api, url);
    yield put(receivedArticleBySlug(response));
  } catch (err) {
    yield put(failedArticleBySlug(err));
  }
}

export function* watchRequestArticleBySlug() {
  yield takeLatest(ARTICLES.REQUEST_ARTICLE_BY_SLUG, articleBySlugSaga);
}

export function* articleFooterArticlesSaga(action) {
  const ROOT = rootDomain();
  const url = `${ROOT}/api/v1/articles/type?per_page=${action.payload.itemsPerPage}&type_id=${action.payload.categoryId}`;

  try {
    const response = yield call(api, url);
    yield put(receivedFooterArticles(response));
  } catch (err) {
    yield put(failedFooterArticles(err));
  }
}

export function* watchFooterArticles() {
  yield takeLatest(ARTICLES.REQUEST_FOOTER_ARTICLES, articleFooterArticlesSaga);
}

const sagas = [
  fork(watchRequestArticles),
  fork(watchRequestArticle),
  fork(watchFooterArticles),
  fork(watchRequestArticleBySlug)
];

export default sagas;
