import React from 'react';
import PropTypes from 'prop-types';
import{
	PortalPageMenuContent,
	PortalPageMenuItem
} from '@/components/Portal';
import { ContactCard } from '@/components/Portal/Card';
import { DisplayVacancyCard } from '@/components/Portal/Vacancies'

VacancyDetailsMenu.propTypes = {
	vacancy: PropTypes.object
}

export default function VacancyDetailsMenu ({vacancy, mobile, desktop}) {
	if (!vacancy) {
		return null
	}

	return (
		<PortalPageMenuContent 
			mobile={mobile}	
			desktop={desktop}
			mobileToggleName="Key Contacts"
		>
			{desktop && <>
				<PortalPageMenuItem>
					<div className='institution-logo'>
						<img src={vacancy?.institution?.logo_data?.url} alt={vacancy?.institution?.name} />
					</div>
				</PortalPageMenuItem>
				<PortalPageMenuItem>
					<DisplayVacancyCard vacancy={vacancy}/>
				</PortalPageMenuItem>
			</>
			}
			<PortalPageMenuItem title='Key Contact'>
				<ContactCard
					name={vacancy?.key_contact_name}
					title={vacancy?.key_contact_title}
					email={vacancy?.key_contact_email}
					phone={vacancy?.key_contact_phone}
				/>
			</PortalPageMenuItem>
		</PortalPageMenuContent>
	)

}
