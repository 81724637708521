import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { iconSaveAlt, iconPhone, iconMobilePhone, iconArrowThin } from '@/images/icons';
import SubscribeForm from '../SubscribeForm';

const MediaReleasesSidebar = (props) => {
  const { mediaData } = props;
  const store = useSelector((store) => store);
  const contentStore = store.contentReducer.contentData;

  return (
    <aside className="article-sidebar">
      {!isEmpty(mediaData.sidebar_download) && (
        <div className="download section">
          {mediaData.sidebar_download.map((item, index) => {
            return (
              <div key={index}>
                {!isEmpty(contentStore) &&
                  contentStore.data.map((contentItem, index) => {
                    if (item.download_item == contentItem.id) {
                      return (
                        <div className="download-item" key={index}>
                          <h4>Download {contentItem.type}</h4>
                          <a
                            href={contentItem.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            <span className="icon">{iconSaveAlt}</span>
                            <span>
                              <p className="title">{item.download_title}</p>
                              <p>{contentItem.options.size}</p>
                            </span>
                          </a>
                        </div>
                      );
                    }
                  })}
              </div>
            );
          })}
        </div>
      )}

      {!isEmpty(mediaData.sidebar_contact) ? (
        <div className="policy-contact section">
          {mediaData.sidebar_contact.map((item, index) => {
            return (
              <div className="contact-item" key={index}>
                <h4>{item.contact_title}</h4>
                <p className="name">{item.contact_name}</p>
                <p className="role">{item.poition_title}</p>
                {item.contact_phone_1 && (
                  <p>
                    <a href={`tel:${item.contact_phone_1}`} className="phone">
                      <span className="icon">{iconPhone}</span>
                      {item.contact_phone_1}
                    </a>
                  </p>
                )}
                {item.contact_phone_2 && (
                  <p>
                    <a href={`tel:${item.contact_phone_2}`} className="mobile">
                      <span className="icon">{iconMobilePhone}</span>
                      {item.contact_phone_2}
                    </a>
                  </p>
                )}
                {item.contact_email && (
                  <p className="email">
                    <a href={`mailto:${item.contact_email}`}>
                      Email {item.contact_name}{' '}
                      <span className="icon">{iconArrowThin}</span>
                    </a>
                  </p>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <Fragment>
          <div className="policy-contact section">
            <div className="contact-item">
              <h4>Media Contact</h4>
              <p className="name">Keith Barrett</p>
              {/*<p className="role">Director - Corporate Affairs</p>*/}
              <p className="mobile">
                <a href="tel:+61 477 353 684" className="phone">
                  <span className="icon">{iconMobilePhone}</span>+61 415 751 100
                </a>
              </p>
              <p className="email">
                <a href={'mailto:media@coba.asn.au'}>
                  Email Keith <span className="icon">{iconArrowThin}</span>
                </a>
              </p>
            </div>
          </div>
        </Fragment>
      )}

      <div className="subscribe section">
        <SubscribeForm heading="Subscribe" />
      </div>
    </aside>
  );
};

export default MediaReleasesSidebar;
