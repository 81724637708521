import { FOOTER } from '../constants';

export const requestFooter = (payload) => {
  return {
    type: FOOTER.REQUEST_FOOTER,
    payload
  };
};

export const requestFooterSuccess = (payload) => {
  return {
    type: FOOTER.REQUEST_FOOTER_SUCCESS,
    payload
  };
};

export const requestFooterFailed = (payload) => {
  return {
    type: FOOTER.REQUEST_FOOTER_FAILED,
    payload
  };
};
