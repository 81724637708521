import { call, put, takeEvery } from 'redux-saga/effects';
import { CONTENT } from '../constants';
import { receivedContent, failedContent } from '../actions';
import rootDomain from '../rootDomain';
const api = (url) => fetch(url).then((res) => res.json());

export function* requestContentAsync() {
  const ROOT = rootDomain();
  const contentUrl = `${ROOT}/api/v1/content/index`;

  try {
    const response = yield call(api, contentUrl);
    yield put(receivedContent(response));
  } catch (err) {
    yield put(failedContent(err));
  }
}

export default function* watchRequestContent() {
  yield takeEvery(CONTENT.REQUEST_CONTENT, requestContentAsync);
}
