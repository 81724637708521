import { call, put, takeEvery } from 'redux-saga/effects';
import { CUSTOMER_SUPPORT } from '../constants';
import { receivedCustomerSupport, failedCustomerSupport } from '../actions';
import rootDomain from '../rootDomain';
const api = (url) => fetch(url).then((res) => res.json());

export function* requestCustomerSupportAsync() {
  const ROOT = rootDomain();
  const customerSupportUrl = `${ROOT}/api/v1/settings`;

  try {
    const response = yield call(api, customerSupportUrl);
    yield put(receivedCustomerSupport(response));
  } catch (err) {
    yield put(failedCustomerSupport(err));
  }
}

export default function* watchRequestCustomerSupport() {
  yield takeEvery(CUSTOMER_SUPPORT.REQUEST_CUSTOMER_SUPPORT, requestCustomerSupportAsync);
}
