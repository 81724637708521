import { createSelector } from 'reselect';
import { first, isEmpty } from 'lodash';
import { eventStatus } from '../../utils';

const eventQueries = (state) => state.eventsReducer.queries;
const stateEvents = (state) => state.eventsReducer.events;
const stateFilterOptions = (state) => state.eventsReducer.filterOptions;

export const selectEvents = createSelector(stateEvents, (events) => events);

export const selectEventsFiterOptions = createSelector(
  stateFilterOptions,
  (options) => options
);

//Selector for get search result
export const selectEventSearchQueries = createSelector(
  eventQueries,
  (queries) => queries
);


export const selectEventIsSearching = createSelector(
  stateEvents,
  (events) => events.loading
);

export const selectEventHasFilterQueries = createSelector(
  selectEventSearchQueries,
  (queries) => {
    return Object.keys(queries).some((key) =>
      Array.isArray(queries[key]) ? queries[key]?.length : !!queries[key]
    );
  }
);

/**
 * Return the first matching event by id
 */
export const selectEventById = (id) =>
  createSelector(selectEventsFlattened, (events) =>
    first(events.filter((item) => item?.id === Number(id)))
  );

// /**
//  * Collapse [{month_number, month_name, events...}] to [events...]
//  */
export const selectEventsFlattened = createSelector(selectEvents, (events) =>
  isEmpty(events.results)
    ? []
    : Object.values(events.results).reduce(
        (list, year) => [
          ...list,
          ...Object.values(year).reduce((yearEvents, monthEvents) => {
            monthEvents = monthEvents.map((event) => {
              event['status'] = eventStatus(event);
              return event;
            });
            return [...yearEvents, ...monthEvents];
          }, [])
        ],
        []
      )
);
