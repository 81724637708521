import axios from 'axios';
import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { SEARCH } from '../constants';
import { searchAllByTermSuccess, searchAllByTermFailed } from '../actions';
import { BASE_URL, API } from '@/config';
import { getUserToken } from '@/store/selectors';

export function* searchAllByTerm(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

  try {
    const response = yield call(request);
    yield put(searchAllByTermSuccess(response));
  } catch (err) {
    yield put(searchAllByTermFailed(err));
  }
}

export function* watchSearchAllByTerm() {
  yield takeLatest(SEARCH.SEARCH_ALL_BY_TERM, searchAllByTerm);
}

const sagas = [fork(watchSearchAllByTerm)];

export default sagas;
