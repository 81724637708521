import { EVENTS } from '../constants';
import { structureEvents, byDatesFlatened } from '@/utils';

const initialState = {
  events: {
    loading: true,
    results: {},
    currentPage: 1,
    total: 0,
    hasMore: false,
    errors: []
  },
  filterOptions: {
    loading: true,
    years: [],
    errors: []
  },
  queries: {
    year: undefined,
    month: undefined,
    search: '',
    errors: []
  }
};

const eventsReducer = (state = initialState, action = {}) => {
  const results = action?.payload?.data?.data || {};

  switch (action.type) {
    case EVENTS.REQUEST_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true
        }
      };

    case EVENTS.REQUEST_EVENT_SUCCESS: {
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          results: structureEvents([results])
        }
      };
    }

    case EVENTS.REQUEST_EVENT_FAILED:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          errors: action.payload
        }
      };

    case EVENTS.REQUEST_EVENTS_IN_YEAR:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true
        },
        queries: {
          ...state.queries,
          ...action.payload
        }
      };

    case EVENTS.REQUEST_EVENTS_IN_YEAR_FAILED:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          errors: action?.payload?.response?.data?.errors
        }
      };
    
    case EVENTS.REQUEST_EVENTS_IN_YEAR_SUCCESS: {
      const events = {};
      events[state.queries.year] = results;

      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          results: events,
          currentPage: 1,
          hasMore: false
        }
      };
    }

    case EVENTS.REQUEST_PAST_YEARS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          loading: true,
          errors: []
        }
      };

    case EVENTS.REQUEST_PAST_YEARS_FAILED:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          loading: false,
          errors: action?.payload?.data || []
        }
      };

    case EVENTS.REQUEST_PAST_YEARS_SUCCESS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          loading: false,
          years: action?.payload?.data?.data.sort() || []
        }
      };

    case EVENTS.SEARCH_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
          errors: []
        },
        queries: {
          ...state.queries,
          ...action.payload
        }
      };

    case EVENTS.SEARCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: {
          loading: false,
          results: structureEvents(byDatesFlatened(results))
        }
      };

    case EVENTS.SEARCH_EVENTS_FAILED:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          errors: action.payload?.data
        }
      };

    case EVENTS.CLEAR_SEARCH_EVENTS:
      return {
        ...state,
        queries: initialState.queries
      };
  }
  return state;
};

export default eventsReducer;
