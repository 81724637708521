import React, { useEffect } from 'react';
import { isLength } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { requestContent, requestGeneralArticles } from '@/store/actions';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollTo from './scrollTo';
import AppRoutes from './routes';

export default function App() {
  const store = useSelector((store) => store);
  const contentStore = store.contentReducer.contentData;
  const generalArticlesStore = store.generalArticlesReducer.generalArticlesData;
  const dispatch = useDispatch();

  useEffect(() => {
    !isLength(contentStore) && dispatch(requestContent());
    !isLength(generalArticlesStore) && dispatch(requestGeneralArticles());
  }, []);

  return (
    <Router>
      <ScrollTo />
      <AppRoutes />
    </Router>
  );
}
