import React from 'react';
import { iconSearch } from '@/images/icons';

const MediaListSearchForm = ({ handleUpdateSearchQuery }) => {
  return (
    <div className="search">
      <label>SEARCH</label>
      <div className="search-field">
        <input
          type="text"
          placeholder="Search by title"
          onChange={handleUpdateSearchQuery}
        />
        <button className="icon">{iconSearch}</button>
      </div>
    </div>
  );
};

export default MediaListSearchForm;
