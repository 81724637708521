import React from 'react';
import { logoCobaSmall } from '@/images/logos';


// This component should replace all instances of mini logo to avoid repetition
export default function MiniLogo({ width }) {
  const addedStyles = {
    width: width
  };

  return (
    <span className="mini-logo-common" style={width && addedStyles}>
      {logoCobaSmall}
    </span>
  );
}
