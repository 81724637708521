import { COORDINATES } from '../constants';
import { first } from 'lodash';

const initialState = {
  isLoading: false,
  coordinates: {}
};

const coordinatesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case COORDINATES.REQUEST_COORDINATES:
      return {
        ...state,
        isLoading: true
      };

    case COORDINATES.RECEIVED_COORDINATES: {
      return {
        ...state,
        isLoading: false,
        coordinates: first(action.payload?.data?.data)
      };
    }

    case COORDINATES.FAILED_COORDINATES:
      return {
        ...state,
        isLoading: false
      };

    case COORDINATES.CLEAR_COORDINATES:
      return {
        ...initialState
      };
  }

  return state;
};

export default coordinatesReducer;
