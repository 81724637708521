import React from 'react';
import useMedia from 'use-media';
import { 
	PortalPageMenuFilter, 
	PortalPageMenuItem,
	MenuToggle, 
	Preferences 
} from '@/components/Portal';
import { DateFilter, PolicyStageFilter, NewsTopicsFilter, RegulatorFilter } from '@/components/Portal/Newsroom'

export default function NewsroomMenu({mobile, desktop}) {
	const isDesktop = useMedia('(min-width: 1024px)');

	if ( (isDesktop && mobile) || (!isDesktop && desktop)) {
		return null;
	}

  return (
		<PortalPageMenuFilter 
			mobileToggleName="Filters" 
			mobile={mobile}
			desktop={desktop}
		>
			<MenuToggle	mobileToggleLabel="News Topics">
				<PortalPageMenuItem 
					title={isDesktop ? 'News Topics' : undefined}
					mobileToggleLabel="News Topics"
				>
					<NewsTopicsFilter />
				</PortalPageMenuItem>
			</MenuToggle>

			<MenuToggle	mobileToggleLabel="COBA Submission">
				<PortalPageMenuItem 
					title={isDesktop ? 'COBA Submission' : undefined}
				>
					<PolicyStageFilter />
				</PortalPageMenuItem>
			</MenuToggle>
			
			<MenuToggle	mobileToggleLabel="Date">
				<PortalPageMenuItem 
					title={isDesktop ? 'Date' : undefined}
				>
					<DateFilter />
				</PortalPageMenuItem>
			</MenuToggle>
			
			<MenuToggle	mobileToggleLabel="Regulators">
				<PortalPageMenuItem displayItemMobile >
					<RegulatorFilter />
				</PortalPageMenuItem>
			</MenuToggle>
				
			<PortalPageMenuItem displayItemDesktop>
				<Preferences
					title="Manage Preferences"
					text="You can manage your subscription preferences in account settings."
					btnText="Update Preferences"
				/>		
			</PortalPageMenuItem>
		</PortalPageMenuFilter>
  );
}
