import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react'
import { iconPlus, iconMinus } from '@/images/icons';

AccordionItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string
}

export default function AccordionItem({ title, content }) {
	const [expanded, setExpanded] = useState(false)
	const [contentHeight, setContentHeight] = useState(0)

	const contentContainerRef = useRef(null);

	function toggleAccordion() {
		setExpanded((prev) => !prev)
	}

	useEffect(() => {
		if (!contentContainerRef.current) return;
		setContentHeight(contentContainerRef.current.scrollHeight)
	}, [])

	return (
		<div className='accordion-item'>
			<button className={`accordion ${expanded}`} onClick={toggleAccordion}>
				<p className='accordion-title bold'>{title}</p>
				<span className='icon'>
					{expanded ? iconMinus : iconPlus}
				</span>
			</button>
			<div
				ref={contentContainerRef}
				className='accordion-content'
				style={{ maxHeight: expanded ? `${contentHeight}px` : 0 }}
			>
				<p
					className='accordion-text small'
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</div>
		</div>
	)
}