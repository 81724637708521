import React from 'react';
import { mapValues, groupBy } from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import ArticleCard from '../ArticleCard';

const MediaList = ({ mediaReleases }) => {
  // helper function to get the month name from an item
  const monthName = (item) => moment(item.created_at, 'YYYY-MM-DD').format('MMMM YYYY');

  const groupedReleases = mediaReleases && mapValues(groupBy(mediaReleases, monthName));
  const releaseMonths = mediaReleases && Object.getOwnPropertyNames(groupedReleases);

  const { pathname } = useLocation();
  const location = pathname;

  return (
    <section className="media-list">
      {mediaReleases &&
        releaseMonths.map((month, index) => {
          return (
            <div className="list-wrapper" key={index}>
              <div className="month-year">{month}</div>

              <div className="list">
                {mediaReleases.map((release, index) => {
                  if (month == moment(release.created_at).format('MMMM YYYY')) {
                    return (
                      <ArticleCard
                        imageId={false}
                        articleUrl={`${location}/${release.slug}`}
                        date={release.created_at}
                        title={release.heading}
                        key={index}
                      />
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
    </section>
  );
};

export default MediaList;
