import React from 'react';
import ContactItems from './ContactItems';
import ContactForm from './ContactForm';

export default function Contact() {
    return (
        <div className="Contact">
            <div className="contact-container">
                <ContactItems />
                <ContactForm />
            </div>
        </div>
    );
}
