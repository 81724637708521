import React from 'react';
import { NavMenuContext } from '@/context';
import { iconMenu, iconCrossThin } from '@/images/icons';

const NavMenuMobileToggle = () => {
  const {
    onClickToggleMobileNavMenu,
    isMenuOpen,
    isSubMenuOpen
  } = React.useContext(NavMenuContext);

  return (
    <a href="#" className="menu">
      <span className="icon menu-icon" onClick={onClickToggleMobileNavMenu}>
        {isMenuOpen || isSubMenuOpen ? iconCrossThin : iconMenu}
      </span>
    </a>
  );
};

export default NavMenuMobileToggle;
