import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  requestEmailSubscriptions,
  updateEmailSubscriptions
} from '@/store/actions';

import { selectEmailSubscriptions } from '@/store/selectors';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Loading } from '@/components';
import { iconArrowThin } from '@/images/icons';
import EmailList from './EmailList';

const transform = (data) => {
  return data.reduce((results, subscribed, id) => {
    results[id] = subscribed;
    return results;
  }, {});
};

export default function Subscriptions() {
  const dispatch = useDispatch();

  const EmailSubscriptions = useSelector(selectEmailSubscriptions, shallowEqual);

  const isLoading = !!EmailSubscriptions?.isLoading;
  const isUpdating = !!EmailSubscriptions?.isUpdating;

  const form = useForm({
    mode: 'all',
    reValidateMode: 'all'
  });

  const { isDirty, isValid } = form.formState;
  const isSubmitDisabled = isUpdating || !isDirty || !isValid;

  const onSubmit = (data) => {
    const updates = {
      campaign: transform(data.campaign),
      tags: transform(data.tags)
    };

    dispatch(updateEmailSubscriptions(updates));
  };

  React.useEffect(() => {
    dispatch(requestEmailSubscriptions());
  }, []);

  return (
    <div className="content-layout my-account subscriptions">
      <h2>Update and Manage Subscriptions</h2>

      <FormProvider {...form}>
        <form noValidate>
          <fieldset className="fieldset campaign-list">
            <p>
              You can unsubscribe to the below emails at any time by unchecking the box
              and saving changes.
            </p>

            {isLoading && <Loading />}

            {!isLoading &&
              EmailSubscriptions.subscriptions.map((emailList) => (
                <EmailList key={emailList.id} control={form.control} {...emailList} />
              ))}
          </fieldset>
        </form>
      </FormProvider>

      <Button
        variant={'btn btn-fill'}
        icon={isUpdating ? null : iconArrowThin}
        type="submit"
        onClick={form.handleSubmit(onSubmit)}
        isDisabled={isSubmitDisabled}
      >
        {isUpdating ? 'Saving Changes...' : 'Save Changes'}
      </Button>
    </div>
  );
}
