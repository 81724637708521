import useAnalyticEventsTracking from '@/hooks/useAnalyticEventsTracking';
import {
  iconPortalCalendar,
  iconPortalDownload,
  iconPortalSurvey,
  iconPortalVideocam
} from '@/images/icons';
import PropTypes from 'prop-types';
import React from 'react';

ActionCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  action: PropTypes.oneOf([
    'calendar',
    'download',
    'download_pdf',
    'recording',
    'survey'
  ]),
  value: PropTypes.string,
  link: PropTypes.string
};

function actionProps(action) {
  const actionList = {
    calendar: { icon: iconPortalCalendar, linkLabel: 'Download iCal' },
    download: { icon: iconPortalDownload, linkLabel: 'Download File' },
    download_pdf: { icon: iconPortalDownload, linkLabel: 'Download bulletin as PDF' },
    recording: { icon: iconPortalVideocam, linkLabel: 'View Now' },
    survey: { icon: iconPortalSurvey, linkLabel: 'Take the survery' }
  };

  if (Object.prototype.hasOwnProperty.call(actionList, action)) {
    return actionList[action];
  }
}

export default function ActionCard({ title, subTitle, action, value, link }) {
  const a = actionProps(action);

  const trackEvent = useAnalyticEventsTracking();
  const handleOnClick = () => {
    trackEvent({ type: 'download' });
  };

  return (
    <div className="action-card">
      <div className="layout">
        <span className="icon gradient outline">{a.icon}</span>

        <div className="content">
          <div className="wrapper">
            <span className="title">{title}</span>
            {value && <span className="text-meta value">{`[${value}]`}</span>}
          </div>

          {subTitle && <p className="sub-title">{subTitle}</p>}

          <div onClick={handleOnClick}>
            {action === 'survey' ? (
              <a className="link" target="_blank" rel="noopener noreferrer" href={link}>
                {' '}
                {a.linkLabel}{' '}
              </a>
            ) : (
              <a className="link" href={link} download>
                {' '}
                {a.linkLabel}{' '}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
