import React from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
// Store
import { useDispatch, useSelector } from 'react-redux';
import { submitTrainingPurchase } from '@/store/actions';
import { selectTrainingCart } from '@/store/selectors'
// Components
import { iconArrowThin } from '@/images/icons';
import { Button } from '@/components';
import { CartItem } from '@/components/Portal/Training';
// Utils
import { PORTAL_PATHS } from '@/config';

export default function Cart() {
	const dispatch = useDispatch();
	const cart = useSelector(selectTrainingCart);
	const ordering = cart.isOrdering;
	const cartItems = cart.items;

	let costTotal = cartItems.reduce((total, item) => {
		const price = isNaN(item.price) ? 0 : item.price;
		return total + price;
	}, 0);

	costTotal = '$'+costTotal.toLocaleString();

	const onClickPurchase = () => {
		const modules = cartItems.map((item) => (
			{
				id: item.id,
				type: 'training'
		 })
		);

		dispatch(submitTrainingPurchase({cart: modules}))
	}

  return (
		<div className="training-cart content-layout">
			<h2>Training Cart</h2>

			<div className="cart">
				{/* Item Listing */}
				<div className="item-listing-headers">
					<span className="text-small"> Request For Purchase </span>
					<span className="text-small"> Price </span>
				</div>
				<div className="cart-list">
				{!isEmpty(cartItems) && cartItems.map((item, index) =>
					<CartItem
						key={index}
						id={item.id}
						heading={item.category}
						title={item.title}
						price={item.price}
					/>
				)}
				</div>

				{/* Total */}
				<div className="total">
					<div>
						<div className="heading h5 total-message">TOTAL</div>
						<div className="heading h5 total-message">INC. GST</div>
					</div>
					<div className="heading h3 total-cost">
						{costTotal}
					</div>
				</div>
				
				<div className="bottom">
					<p className="small">
						Please note: You will be notified via email once your training is ready to be downloaded.
						It will be located in "<Link className='text-link' to={`${PORTAL_PATHS.TRAINING}/my/available`} >My Training</Link>"
					</p>
					<Button
						variant='btn-fill'
						isDisabled={ordering || isEmpty(cart.items)}
						onClick={onClickPurchase}
					>
						Request Purchase <span className="icon">{iconArrowThin}</span>
					</Button>
				</div>
			</div>
		</div>
  );
}
