import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestUsers, requestUserApprove, requestUserDelete } from '@/store/actions';
import  { Button } from '@/components';

export default function User({ user }) {
  const actions = bindActionCreators(
    { requestUsers, requestUserApprove, requestUserDelete },
    useDispatch()
  );

  const [requestAprrove, setRequestAprrove] = useState(false);
  const [requestRemove, setRequestRemove] = useState(false);

  const onUserApprove = (userId) => () => {
    setRequestAprrove(true);
    actions.requestUserApprove({
      user_id: userId,
      approved: 1
    });
    actions.requestUsers();
  };

  const onUserRemove = (userId) => () => {
    setRequestRemove(true);
    actions.requestUserDelete({
      user_id: userId
    });
    actions.requestUsers();
  };

  return (
    <div className="user" key={user.id}>
      <div className="name">{user.full_name}</div>
      <div className="email">{user.email}</div>
      <div className="actions">
        {!user.coba_approved && (
          <Button
            variant={'btn-fill'}
            onClick={onUserApprove(user.id)}
            isDisabled={requestAprrove}
          >
            {' '}
            Approve
          </Button>
        )}
        <Button
          variant={'btn-text'}
          onClick={onUserRemove(user.id)}
          isDisabled={requestRemove}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}
