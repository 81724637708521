const menuToggle = {
  initial: {
    height: 0,
    pointerEvents: 'none',
    opacity: 0,
    translateY: '-30%',
    zIndex: 1
  },
  in: {
    height: 'auto',
    pointerEvents: 'auto',
    opacity: 1,
    translateY: 0,
    transition: {
      ease: 'easeInOut',
      translateY: {
        duration: 0.3
      },
      opacity: {
        duration: 0.2,
        delay: 0.1
      }
    }
  },
  out: {
    opacity: 0,
    height: 0,
    translateY: '-30%',
    transition: {
      ease: 'easeInOut',
      translateY: {
        duration: 0.3,
        delay: 0.2
      },
      opacity: {
        duration: 0.2
      }
    }
  }
};

export default {
  menuToggle: menuToggle
}