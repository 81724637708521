import React from 'react';
import { iconArrowThin, iconPortalLayers } from '@/images/icons';

export default function CtaCard({ title, copy, buttonText, buttonUrl }) {
  return (
    <div className="cta-card">
      <div className="cta-card-wrapper">
        <span className="icon outline bg-white">{iconPortalLayers}</span>
        <h4>{title}</h4>
        <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
        <a className="btn btn-text" href={buttonUrl}>
          {buttonText ? buttonText : 'Read more'}{' '}
          <span className="icon midnight">{iconArrowThin}</span>
        </a>
      </div>
    </div>
  );
}
