import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { iconCrossThin } from '@/images/icons';
import { requestAnnouncement } from '@/store/actions';
import {
  selectAnnouncementContent,
  selectAnnouncementActive
} from '@/store/selectors/announcement';

function getSessionStorageItem(key, defaultValue) {
  const sessionItem = sessionStorage.getItem(key);
  return !sessionItem ? defaultValue : JSON.parse(sessionItem);
}

export default function AnnouncementBar() {
  const dispatch = useDispatch();

  const isActive = useSelector(selectAnnouncementActive);
  const announcementContent = useSelector(selectAnnouncementContent);

  const [showAnnouncement, setShowAnnouncement] = React.useState(
    getSessionStorageItem('showAnnouncement', true)
  );

  const onClickHideAnnouncement = () => {
    setShowAnnouncement(false);
  };

  React.useEffect(() => {
    dispatch(requestAnnouncement());
  }, []);

  React.useEffect(() => {
    sessionStorage.setItem('showAnnouncement', JSON.stringify(showAnnouncement));
  }, [showAnnouncement]);

  if (showAnnouncement && isActive && !!announcementContent) {
    return (
      <div className="announcement-bar">
        <div className="content-wrapper">
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: announcementContent
            }}
          />

          <span className="icon menu-icon" onClick={onClickHideAnnouncement}>
            {iconCrossThin}
          </span>
        </div>
      </div>
    );
  }

  return null;
}
