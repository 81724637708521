import React from 'react';
import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import SubpageHeader from '@/components/SubpageHeader';
import {
  ContactDetails,
  ContactComplaints,
  ContactFooterCircle
} from '@/components/ContactPage';

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact us | Customer Owned Banking Association | Australia" />
      <div id="contact-page" data-testid="contact-page">
        <SubpageHeader breadcrumbs="Home / Contact Information" title="Contact us" />
        <ContactDetails />
        <ContactComplaints />
        <ContactFooterCircle />
      </div>
    </Layout>
  );
};

export default ContactPage;
