import React from 'react';
import { useLocation } from 'react-router-dom';
import { Motion } from '@/components';
import { PortalBannerText } from '@/components/Portal';
import { PORTAL_PATHS } from '@/config';

export default function MemberWelcomeMessage() {
  const { pathname } = useLocation();

  return (
    <Motion
      animate={
        pathname.includes(PORTAL_PATHS.onboarding.signIn) ||
        pathname === PORTAL_PATHS.onboarding.index ||
        pathname.includes('/reset-password')
      }
    >
      <PortalBannerText
        subheading="Welcome to the "
        heading={'COBA <br /> Member Portal'}
        text="Our primary channel for information sharing & communicating with our members"
      />
    </Motion>
  );
}
