import { isFuture } from 'date-fns';
import { utcToLocal } from '@/utils';
import { format } from 'date-fns';

export const eventStatus = (event) => {
  if (event) {
    const startIsFuture = isFuture(utcToLocal(event.start_date));
    const endIsFuture = isFuture(utcToLocal(event.end_date));
    let eventStatus = 'past';

    if (endIsFuture && startIsFuture) {
      eventStatus = 'upcomming';
    } else if (endIsFuture) {
      eventStatus = 'inProgress';
    }
    return eventStatus;
  }
};

/**
 *	Returns an array of events
 *
 * @param {Array.<Object.<month_name:String month_number:Number Number:<EventObject>>} [events] 
 *
 * @returns {Array.<EventObject>}
 */
export const byDatesFlatened = (data) => {
  return data.reduce((flattened, monthGroup) => { 
    const events = Object.keys(monthGroup).reduce((monthEvents, key) => {
      if ( !['month_name', 'month_number'].includes(key)) {
        monthEvents.push(monthGroup[key]);
      }
      
      return monthEvents;
    } , []);

    return [...flattened, ...events];
  } , []);
}

/**
 *	Returns a structured events object from an array of events
 *
 * @param {<EventObject>} event An events
 * @param {Object.<String.<year>: Object.<String.<month>: Array.<EventObject> > >} [existingEvents] Optional - Adds events to an existing events strucuture.
 *
 * @returns {Array.<Object.<month>, Array.<EventObject>>}
 */

export const structureEvents = (events, existingEvents = {}) => {
  return events.reduce((results, event) => {
    const year = !!event.start_date ? format(utcToLocal(event.start_date), 'yyyy') : '';
    const month = !!event.start_date ? format(utcToLocal(event.start_date), 'LLLL') : '';
    const updatedYear = results[year] || {};
    const updatedMonth = updatedYear[month] || [];

    updatedMonth.push(event);
    updatedYear[month] = updatedMonth;
    results[year] = updatedYear;

    return results;
  }, existingEvents);
};
