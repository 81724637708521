import React from 'react';
import { createPortal } from 'react-dom';

export default function PortalNavMobileOverlay({ isOpenMobileNav }) {
  const portalNavOverlayClass = isOpenMobileNav
    ? 'portal-nav-mobile-overlay active'
    : 'portal-nav-mobile-overlay';

  return createPortal(<div className={portalNavOverlayClass} />, document.body);
}
