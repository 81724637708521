import { createSelector } from 'reselect';
import { first } from 'lodash'

const ceoNewsArticles = (state) => state.ceoReducer.articles
export const selectCEONewsArticlesLoading = (state) => state.ceoReducer.isLoading
export const selectCEONewsArticleLatest = (state) => state.ceoReducer.latestArticle || {};

export const selectCEONewsArticleBySlug = (slug) => 
  createSelector( ceoNewsArticles, (articles) => 
    first(articles.filter((item) => item?.slug === slug))
  )
