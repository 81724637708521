import {
  ANNOUNCEMENT,
  ARTICLES,
  CEO,
  COORDINATES,
  EMAIL_SUBSCRIPTIONS,
  EVENTS,
  ANALYTIC_EVENTS,
  JOBS,
  MANAGE_USERS,
  MEMBER_INSTITUTIONS,
  NAV,
  NEWS_ARTICLES,
  FEEDS,
  SEARCH,
  STAFF,
  PAGES,
  SUPPORT,
  TRAINING,
  TRAINING_CART,
  USER,
  PORTAL_ANNOUNCEMENT,
  HEADER,
  FOOTER,
  RATE_EXPERIENCE,
  RATE_INFO
} from '@/store/constants';

export const BASE_URL = process.env.APP_URL;

export const API = {
  [RATE_INFO.REQUEST_RATE_INFO]: '/api/v1/voting-poll/rate-info',
  [RATE_EXPERIENCE.RATE_EXPERIENCE]: '/api/v1/voting-poll/rate-experience',
  [ARTICLES.REQUEST_ARTICLE_BY_SLUG]: '/api/v1/articles/slug',
  [CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG]: '/api/v1/ceo-news/slug',
  [CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST]: '/api/v1/ceo-news/latest',
  [COORDINATES.REQUEST_COORDINATES]: '/api/v1/geo/all/address',
  [EMAIL_SUBSCRIPTIONS.REQUEST_EMAIL_SUBSCRIPTIONS]:
    '/api/v1/my-account/subscriptions/list',
  [EMAIL_SUBSCRIPTIONS.UPDATE_EMAIL_SUBSCRIPTIONS]:
    '/api/v1/my-account/subscriptions/modify',
  [EVENTS.REQUEST_EVENT]: '/api/v1/events',
  [EVENTS.REQUEST_PAST_YEARS]: '/api/v1/events/years',
  [EVENTS.SEARCH_EVENTS]: '/api/v1/events/search/dates',
  [ANALYTIC_EVENTS.TRACK_EVENT]: '/api/v1/analytic-events',
  [JOBS.REQUEST_JOB_DETAILS]: '/api/v1/jobs',
  [JOBS.REQUEST_JOB_VACANCIES_FIRST_PAGE]: '/api/v1/jobs/search',
  [JOBS.REQUEST_JOB_VACANCIES_NEXT_PAGE]: '/api/v1/jobs/search',
  [JOBS.SUBMIT_JOB_LISTING]: '/api/v1/jobs/store',
  [MANAGE_USERS.REQUEST_USERS]: '/api/v1/users',
  [MANAGE_USERS.REQUEST_USER_APPROVE]: '/api/v1/users/approve',
  [MANAGE_USERS.REQUEST_USER_DELETE]: '/api/v1/users/destroy',
  [MEMBER_INSTITUTIONS.REQUEST_ALL_OFFERS]: '/api/v1/offers',
  [MEMBER_INSTITUTIONS.REQUEST_BRANCHES_NEAR_ME]: '/api/v1/branches/geoLocate',
  [MEMBER_INSTITUTIONS.REQUEST_DIVISIONS_BANKS]: '/api/v1/bank',
  [MEMBER_INSTITUTIONS.REQUEST_MEMBERS_INSTITUTIONS]: '/api/v1/institutions',
  [NAV.REQUEST_QUICKLINKS]: '/api/v1/quick-links',
  [NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE]: '/api/v1/news-articles/filter',
  [NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE]: '/api/v1/news-articles/filter',
  [NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID]: '/api/v1/news-articles',
  [NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG]: '/api/v1/news-articles/slug',
  [NEWS_ARTICLES.REQUEST_NEWS_TOPICS]: '/api/v1/news-topic',
  [NEWS_ARTICLES.REQUEST_POLICY_STAGE]: '/api/v1/policy-stages',
  [NEWS_ARTICLES.REQUEST_REGULATORS]: '/api/v1/regulator',
  [NEWS_ARTICLES.SUBMIT_FEEDBACK]: '/api/v1/feedback/create',
  [NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID]: '/api/v1/news-articles/bulletin',
  [FEEDS.REQUEST_FEEDS_BY_PAGE]: '/api/v1/meltwater-rss',
  [PAGES.REQUEST_LIST]: '/api/v1/pages',
  [PAGES.REQUEST_BY_ID]: '/api/v1/pages/id',
  [PAGES.REQUEST_BY_SLUG]: '/api/v1/pages/slug',
  [SEARCH.SEARCH_ALL_BY_TERM]: '/api/v1/search/everything',
  [STAFF.REQUEST_STAFF_LIST]: '/api/v1/directory/staff',
  [SUPPORT.REQUEST_CONTACT_SUBJECTS]: '/api/v1/contact/subject',
  [SUPPORT.SUBMIT_CONTACT_FORM]: '/api/v1/contact/store',
  [TRAINING.REQUEST_TRAINING_CATEGORIES]: '/api/v1/training/categories',
  [TRAINING.REQUEST_TRAINING_MODULES]: '/api/v1/training',
  [TRAINING.REQUEST_TRAINING_MODULE_BY_ID]: '/api/v1/training',
  [TRAINING_CART.SUBMIT_PURCHASE]: '/api/v1/orders/create',
  [USER.CHECK_TOKEN]: '/api/v1/token/check',
  [USER.CREATE_NEW_BRANCH]: '/api/v1/banks/create/branch',
  [USER.CREATE_NEW_DIVISION]: '/api/v1/banks/create/bank',
  [USER.FORGOT_PASSWORD]: '/api/v1/auth/reset/email',
  [USER.LOGIN_USER]: '/api/v1/auth/login',
  [USER.LOGOUT_USER]: '/api/v1/auth/logout',
  [USER.REGISTER_USER]: '/api/v1/auth/register',
  [USER.REQUEST_BRANCHES]: '/api/v1/banks/list/branches',
  [USER.REQUEST_USER_DIVISIONS]: '/api/v1/banks/list/bank',
  [USER.RESET_PASSWORD]: '/api/v1/auth/reset/password',
  [USER.UPDATE_ACCOUNT_DETAILS]: '/api/v1/my-account/update/account',
  [USER.UPDATE_ACCOUNT_PASSWORD]: '/api/v1/my-account/update/password',
  [USER.UPDATE_BRANCHES]: '/api/v1/banks/update/branch',
  [USER.UPDATE_DIVISION]: '/api/v1/banks/update/bank',
  [USER.UPDATE_INSTITUTION]: '/api/v1/banks/update/institution',
  [ANNOUNCEMENT.REQUEST_ANNOUNCEMENT]: '/api/v1/hellobar',
  [PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT]: '/api/v1/portal/hellobar',
  [HEADER.REQUEST_HEADER]: '/api/v1/menus/1?slug=main-menu-member',
  [FOOTER.REQUEST_FOOTER]: '/api/v1/menus/2?slug=footer-menu-member'
};
