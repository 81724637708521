import { EVENTS } from '../constants';

// Filter Options

// Returns a list of years which there are events for
const requestEventsPastYears = () => ({
  type: EVENTS.REQUEST_PAST_YEARS
});

const requestEventsPastYearsSuccess = (payload) => ({
  type: EVENTS.REQUEST_PAST_YEARS_SUCCESS,
  payload
});

const requestEventsPastYearsFailed = (payload) => ({
  type: EVENTS.REQUEST_PAST_YEARS_FAILED,
  payload
});

// Singular Event
const requestEvent = (payload) => ({
  type: EVENTS.REQUEST_EVENT,
  payload
});

const requestEventSuccess = (payload) => ({
  type: EVENTS.REQUEST_EVENT_SUCCESS,
  payload
});

const requestEventFailed = (payload) => ({
  type: EVENTS.REQUEST_EVENT_FAILED,
  payload
});

const searchEvents = (payload) => ({
  type: EVENTS.SEARCH_EVENTS,
  payload
});

const searchEventsSuccess = (payload) => ({
  type: EVENTS.SEARCH_EVENTS_SUCCESS,
  payload
});

const searchEventsFailed = (payload) => ({
  type: EVENTS.SEARCH_EVENTS_FAILED,
  payload
});

const clearSearchQuery = () => ({
  type: EVENTS.CLEAR_SEARCH_EVENTS
});

export {
  requestEvent,
  requestEventFailed,
  requestEventSuccess,
  requestEventsPastYears,
  requestEventsPastYearsFailed,
  requestEventsPastYearsSuccess,
  searchEvents,
  searchEventsSuccess,
  searchEventsFailed,
  clearSearchQuery
};
