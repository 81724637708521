import { iconArrowThin } from '@/images/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import useMedia from 'use-media';

export default function FeedsListingSidebar() {
  const isDesktop = useMedia('(min-width: 1024px)');

  if (!isDesktop) {
    return null;
  }

  return (
    <div id='feeds-listing-sidebar' className='ml-2'>
      <div id='newsroom-cta-box'>
        <h3>Looking for the latest COBA bulletins?</h3>
        <div>Our newsroom provides all the analysis and insights you need to know.</div>
        <Link className='btn btn-fill' to={'/newsroom'}>
          Visit our newsroom <span className='icon midnight'>{iconArrowThin}</span>
        </Link>
      </div>
    </div>
  );
}
