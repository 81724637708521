import React, { useEffect } from 'react';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Components
import { Collapsible } from '@/components';
import PortalNavMobileOverlay from './PortalNavMobileOverlay';
import { selectHeaderContent } from '@/store/selectors/header';
import { requestHeader } from '@/store/actions';
import PortalNavSubMenuMobile from './PortalNavSubMenuMobile';

export default function PortalNavMobile({ isOpenMobileNav }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestHeader());
  }, []);
  const headerContent = useSelector(selectHeaderContent);

  const menuHandler = headerContent?.content_blocks?.map((menu) => {
    return {
      title: menu.attributes.heading,
      url: menu.attributes.content_blocks.length ? '' : menu.attributes.page,
      icon: menu.attributes.content_blocks.length ? 'icon-plus' : '',
      isExternal: menu.attributes.is_external,

      Content() {
        return <PortalNavSubMenuMobile items={menu.attributes.content_blocks} />;
      }
    };
  });

  return (
    <>
      <Collapsible
        variant="mobile-menu"
        items={menuHandler}
        openCollapsible={isOpenMobileNav}
        childItemToggleIcon="icon-plus-minus"
      />
      <PortalNavMobileOverlay isOpenMobileNav={isOpenMobileNav} />
    </>
  );
}
