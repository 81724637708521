import React from 'react';
import { iconArrowThin, iconArrowThinLeft } from '@/images/icons';

export default function ButtonNav({ showPrevCard, showNextCard }) {
  return (
    <div className="btn-group">
      <button className={'btn btn-round-arrow left btn-active'} onClick={showPrevCard}>
        <span className="icon">{iconArrowThinLeft}</span>
      </button>

      <button className={'btn btn-round-arrow right btn-active'} onClick={showNextCard}>
        <span className="icon">{iconArrowThin}</span>
      </button>
    </div>
  );
}
