import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getArticleSortedFooterArticles,
  getArticleType,
  getFooterArticlesConfig,
  getArticleFeatureImageId
} from '@/store/selectors/articles';
import ArticleCard from '@/components/ArticleCard';

const ArticleFooterArticles = () => {
  const { base, category } = useParams();
  const footerArticles = useSelector(getArticleSortedFooterArticles);
  const articleType = useSelector(getArticleType);
  const footerArticlesConfig = useSelector(getFooterArticlesConfig);
  const featureImageId = useSelector(getArticleFeatureImageId);

  if (isEmpty(footerArticles)) {
    return null;
  }

  return (
    <div className="articles">
      {!isEmpty(footerArticles) &&
        footerArticles.map((article, index) => {
          return (
            <ArticleCard
              imageId={
                !!footerArticlesConfig.attributes &&
                !!footerArticlesConfig.attributes.show_images
                  ? featureImageId
                  : false
              }
              articleUrl={`/${base}/${category}/${article.slug}`}
              date={article.created_at}
              title={article.heading}
              cardType={articleType.id === 2 ? 'white' : 'blue'}
              key={index}
            />
          );
        })}
    </div>
  );
};

export default ArticleFooterArticles;
