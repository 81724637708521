import { USER } from '../constants';

export const registerUser = (payload) => {
  return {
    type: USER.REGISTER_USER,
    payload
  };
};

export const registerUserSuccess = (payload) => ({
  type: USER.REGISTER_USER_SUCCESS,
  payload
});

export const registerUserFailed = (payload) => ({
  type: USER.REGISTER_USER_FAILED,
  payload
});

export const loginUser = (payload) => {
  return {
    type: USER.LOGIN_USER,
    payload
  };
};

export const logoutUser = () => {
  return {
    type: USER.LOGOUT_USER
  };
};

export const loginUserSuccess = (payload) => {
  return {
    type: USER.LOGIN_USER_SUCCESS,
    payload
  };
};

export const loginUserFailed = (payload) => {
  return {
    type: USER.LOGIN_USER_FAILED,
    payload
  };
};

export const checkToken = (payload) => {
  return {
    type: USER.CHECK_TOKEN,
    payload
  };
};

export const checkTokenSuccess = (payload) => {
  return {
    type: USER.CHECK_TOKEN_SUCCESS,
    payload
  };
};

export const checkTokenFailed = (payload) => {
  return {
    type: USER.CHECK_TOKEN_FAILED,
    payload
  };
};

export const forgotPasswordRequest = (payload) => {
  return {
    type: USER.FORGOT_PASSWORD,
    payload
  };
};

export const forgotPasswordRequestSuccess = () => {
  return {
    type: USER.FORGOT_PASSWORD_SUCCESS
  };
};

export const forgotPasswordRequestFailed = (payload) => {
  return {
    type: USER.FORGOT_PASSWORD_FAILED,
    payload
  };
};

export const resetPasswordRequest = (payload) => {
  return {
    type: USER.RESET_PASSWORD,
    payload
  };
};

export const resetPasswordRequestSuccess = () => {
  return {
    type: USER.RESET_PASSWORD_SUCCESS
  };
};

export const resetPasswordRequestFailed = (payload) => {
  return {
    type: USER.RESET_PASSWORD_FAILED,
    payload
  };
};

export const updateAccountDetails = (payload) => {
  return {
    type: USER.UPDATE_ACCOUNT_DETAILS,
    payload
  };
};

export const updateAccountDetailsSuccess = (payload) => {
  return {
    type: USER.UPDATE_ACCOUNT_DETAILS_SUCCESS,
    payload
  };
};

export const updateAccountDetailsFailed = (payload) => {
  return {
    type: USER.UPDATE_ACCOUNT_DETAILS_FAILED,
    payload
  };
};

export const updateAccountPassword = (payload) => {
  return {
    type: USER.UPDATE_ACCOUNT_PASSWORD,
    payload
  };
};

export const updateAccountPasswordSuccess = (payload) => {
  return {
    type: USER.UPDATE_ACCOUNT_PASSWORD_SUCCESS,
    payload
  };
};

export const updateAccountPasswordFailed = (payload) => {
  return {
    type: USER.UPDATE_ACCOUNT_PASSWORD_FAILED,
    payload
  };
};

export const requestUserDivisions = (payload) => {
  return {
    type: USER.REQUEST_USER_DIVISIONS,
    payload
  };
};

export const requestUserDivisionsSuccess = (payload) => {
  return {
    type: USER.REQUEST_USER_DIVISIONS_SUCCESS,
    payload
  };
};

export const requestUserDivisionsFailed = (payload) => {
  return {
    type: USER.REQUEST_USER_DIVISIONS_FAILED,
    payload
  };
};

export const requestUserBranches = (payload) => {
  return {
    type: USER.REQUEST_BRANCHES,
    payload
  };
};

export const requestUserBranchesSuccess = (payload) => {
  return {
    type: USER.REQUEST_BRANCHES_SUCCESS,
    payload
  };
};

export const requestUserBranchesFailed = (payload) => {
  return {
    type: USER.REQUEST_BRANCHES_FAILED,
    payload
  };
};

export const updateUserBranches = (payload) => {
  return {
    type: USER.UPDATE_BRANCHES,
    payload
  };
};

export const updateUserBranchesSuccess = (payload) => {
  return {
    type: USER.UPDATE_BRANCHES_SUCCESS,
    payload
  };
};

export const updateUserBranchesFailed = (payload) => {
  return {
    type: USER.UPDATE_BRANCHES_FAILED,
    payload
  };
};

export const updateUserDivision = (payload) => {
  return {
    type: USER.UPDATE_DIVISION,
    payload
  };
};

export const updateUserDivisionSuccess = (payload) => {
  return {
    type: USER.UPDATE_DIVISION_SUCCESS,
    payload
  };
};

export const updateUserDivisionFailed = (payload) => {
  return {
    type: USER.UPDATE_DIVISION_FAILED,
    payload
  };
};

export const createNewUserBranch = (payload) => {
  return {
    type: USER.CREATE_NEW_BRANCH,
    payload
  };
};

export const createNewUserBranchSuccess = (payload) => {
  return {
    type: USER.CREATE_NEW_BRANCH_SUCCESS,
    payload
  };
};

export const createNewUserBranchFailed = (payload) => {
  return {
    type: USER.CREATE_NEW_BRANCH_FAILED,
    payload
  };
};

export const createNewUserDivision = (payload) => {
  return {
    type: USER.CREATE_NEW_DIVISION,
    payload
  };
};

export const createNewUserDivisionSuccess = (payload) => {
  return {
    type: USER.CREATE_NEW_DIVISION_SUCCESS,
    payload
  };
};

export const createNewUserDivisionFailed = (payload) => {
  return {
    type: USER.CREATE_NEW_DIVISION_FAILED,
    payload
  };
};
