import { HEADER } from '../constants';

//initial state
const initialState = {
  isLoading: false,
  headerData: {}
};

//Reducer
const headerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case HEADER.REQUEST_HEADER:
      return { ...state, isLoading: true };

    case HEADER.REQUEST_HEADER_SUCCESS:
      return { ...state, isLoading: false, headerData: action.payload?.data?.data };

    case HEADER.REQUEST_HEADER_FAILED:
      return { ...state, isLoading: false, headerData: action.payload };
  }

  return state;
};

export default headerReducer;
