import { MANAGE_USERS } from '../constants'

const requestUsers = (payload) => ({
	type: MANAGE_USERS.REQUEST_USERS,
	payload
})

const requestUsersSuccess = (payload) => ({
	type: MANAGE_USERS.REQUEST_USERS_SUCCESS,
	payload
})

const requestUsersFailed = (payload) => ({
	type: MANAGE_USERS.REQUEST_USERS_FAILED,
	payload
})


const requestUserApprove = (payload) => ({
	type: MANAGE_USERS.REQUEST_USER_APPROVE,
	payload
})

const requestUserApproveSuccess = (payload) => ({
	type: MANAGE_USERS.REQUEST_USER_APPROVE_SUCCESS,
	payload
})

const requestUserApproveFailed = (payload) => ({
	type: MANAGE_USERS.REQUEST_USER_APPROVE_FAILED,
	payload
})

const requestUserDelete = (payload) => ({
	type: MANAGE_USERS.REQUEST_USER_DELETE,
	payload
})

const requestUserDeleteSuccess = (payload) => ({
	type: MANAGE_USERS.REQUEST_USER_DELETE_SUCCESS,
	payload
})

const requestUserDeleteFailed = (payload) => ({
	type: MANAGE_USERS.REQUEST_USER_DELETE_FAILED,
	payload
})

export { 
	requestUsers, 
	requestUsersSuccess, 
	requestUsersFailed,
	requestUserApprove,
	requestUserApproveSuccess,
	requestUserApproveFailed,
	requestUserDelete,
	requestUserDeleteSuccess,
	requestUserDeleteFailed,
}