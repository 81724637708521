import PropTypes from 'prop-types';
import React from 'react';
// Utils
import { utcToLocal } from '@/utils';
import { format } from 'date-fns';
// Components
import { Card } from '@/components/Portal/Card';
import { iconArrowThin, iconPortalEdit, iconPortalUser, iconPublisher } from '@/images/icons';

function FeedCard({ id, title, author, publishDate, publicationName, description, url }) {
  const formattedContent = !!description ? description.replace(/<[^>]+>/g, '') : '';

  const formattedDate = !!publishDate
    ? format(utcToLocal(new Date(publishDate)), 'd LLLL yyyy')
    : publishDate;

  const items = [
    { icon: iconPortalEdit, details: formattedDate },
    { icon: iconPortalUser, details: author },
    { icon: iconPublisher, details: publicationName }
  ];

  return (
    <Card
      key={id}
      link={url}
      size={'small'}
      title={title}
      categories={[]}
      items={items}
      content={formattedContent}
      actions={
        <>
          {!!url && (
            <a
              href={url}
              className="btn btn-text"
              target="_blank"
              rel="noreferrer noopener"
            >
              Read More <span className="icon midnight">{iconArrowThin}</span>
            </a>
          )}
        </>
      }
      singleMetaRow
    />
  );
}

FeedCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  publicationName: PropTypes.string,
  publishDate: PropTypes.string,
  author: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.node
};

export default FeedCard;
