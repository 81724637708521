import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';

const MediaReleaseYearFilter = ({ handleSelectYear, selectedYear }) => {
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = React.useState(false);

  const yearOptions = (() => {
    let years = [];

    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 2;

    for (let i = currentYear; i >= minYear; i--) {
      years.push(i);
    }

    years.unshift('All');

    return years;
  })();

  React.useEffect(() => {
    setIsMobile(windowWidth <= 768);
  }, [windowWidth]);

  return (
    <div className="years">
      <p className="label">VIEW BY YEAR</p>
      {!isMobile && (
        <ul>
          {yearOptions.map((yearOption, index) => {
            return (
              <li key={index}>
                <button
                  onClick={handleSelectYear(yearOption, index)}
                  className={`${yearOption == selectedYear && ' active-year'}`}
                >
                  {yearOption}
                </button>
              </li>
            );
          })}
        </ul>
      )}

      {isMobile && (
        <select>
          {yearOptions.map((yearOption, index) => {
            return (
              <option
                key={index}
                onClick={handleSelectYear(yearOption, index)}
                className={`${yearOption == selectedYear && ' active-year'}`}
              >
                {yearOption}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export default MediaReleaseYearFilter;
