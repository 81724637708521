import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';
import { isEmpty } from 'lodash';
import {
  clearFilters,
  requestNewsArticlesFirstPage,
  requestNewsTopicsFilters,
  selectNewsTopics
} from '@/store/actions';
import { getNewsTopics, getSelectedNewsTopicIds } from '@/store/selectors';
import { Collapsible } from '@/components';
import { useState } from 'react';

export default function NewsTopicsFilter() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [filterId, setFilterId] = useState(undefined);
  const newsTopics = useSelector(getNewsTopics, shallowEqual);

  const selectedNewsTopicIds = useSelector(getSelectedNewsTopicIds);

  const onToggleNewsTopics = (id) => (ev) => {
    ev.preventDefault();
    dispatch(selectNewsTopics(id));
  };

  const newsTopicFilters = newsTopics.map((topic) => {
    topic.onClickAccordionItem = onToggleNewsTopics(topic.id);
    return topic;
  });

  React.useEffect(() => {
    newsTopics.length <= 1 && dispatch(requestNewsTopicsFilters());
  }, []);

  React.useEffect(() => {
    if (location.search !== '') {
      const query = qs.parse(location.search.substring(1));
      if (!!query?.filter) {
        setFilterId(parseInt(query.filter));
      }
    } else {
      setFilterId(undefined);
    }
  }, [location.search]);

  React.useEffect(() => {
    if (!isEmpty(filterId)) {
      dispatch(selectNewsTopics(filterId));
    } else {
      dispatch(clearFilters());
    }
  }, [filterId]);

  React.useEffect(() => {
    dispatch(
      requestNewsArticlesFirstPage({
        topics: selectedNewsTopicIds
      })
    );
  }, [selectedNewsTopicIds]);

  return (
    <Collapsible
      variant="side-menu"
      items={newsTopicFilters}
      openCollapsible={true}
      childItemToggleIcon="icon-chevron-down-up"
    />
  );
}

export function NewsTopicsFilterChildren({ items }) {
  const dispatch = useDispatch();

  const onSelectNewsTopics = (id) => (ev) => {
    ev.preventDefault();
    dispatch(selectNewsTopics(id));
  };

  return (
    <div className="content">
      <ul>
        {items &&
          items.length &&
          items.map((item, i) => {
            return (
              <li className="content-link" key={`content-item-${item.text}-${i}`}>
                <Link to="#" onClick={onSelectNewsTopics(item.id)}>
                  {item.title}
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
