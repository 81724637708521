import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { validationRegex } from '@/utils';
import { requestContactSubjects, submitContactForm } from '@/store/actions';
import { getUser } from '@/store/selectors';
import { iconArrowThin, iconTick } from '@/images/icons';
import { Button, TextField, Textarea } from '@/components';

export function ContactForm() {
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const form = useForm({
        mode: 'all',
        reValidateMode: 'all',
        defaultValues: {
            firstName: user.first_name || '',
            lastName: user.last_name || '',
            email: user.email || '',
            contactSubjectId: '',
            message: ''
        }
    });

    const { isValid, isSubmitting, isSubmitted } = form.formState;
    const submitDisabled = isSubmitting || !isValid;

    useEffect(() => {
        dispatch(requestContactSubjects());
    }, []);


    const onSubmitForm = (data) => {
        dispatch(
            submitContactForm({
                first_name: data.firstName,
                last_name: data.lastName,
                email: data.email,
                contact_subject_id: data?.contactSubjectId?.id || 1,
                message: data.message
            })
        );
    };

    const onErrorForm = (error) => {
        console.error('An error has occurred!', error);
    };

    return (
        <div className="form support contact contact-form">
            <FormProvider {...form}>
                <form id="contact-form" name="contact-form" noValidate>
                    <legend className="legend H3">Get in touch</legend>
                    <div className="contact-form__row">
                        <TextField
                            id="member-first-name"
                            name="firstName"
                            control={form.control}
                            labelFor="first_name"
                            labelText="First Name"
                            placeholder="First Name"
                            validation={{
                                required: {
                                    value: true,
                                    message: 'This field is required.'
                                }
                            }}
                        />

                        <TextField
                            id="member-last-name"
                            name="lastName"
                            control={form.control}
                            labelFor="last_name"
                            labelText="Last Name"
                            placeholder="Last Name"
                            validation={{
                                required: {
                                    value: true,
                                    message: 'This field is required.'
                                }
                            }}
                        />
                    </div>

                    <TextField
                        id="member-email"
                        control={form.control}
                        type="email"
                        name="email"
                        labelFor="Email"
                        labelText="Email"
                        placeholder="Email"
                        validation={{
                            required: {
                                value: true,
                                message: 'This field is required.'
                            },
                            pattern: {
                                value: validationRegex.email,
                                message: 'Please enter a valid e-mail address.'
                            }
                        }}
                        defaultValue={user.email}
                    />

                    <Textarea
                        id="contact-message"
                        control={form.control}
                        name="message"
                        labelFor="message"
                        labelText="Message"
                        placeholder="Leave message here"
                        rows={4}
                        validation={{
                            required: {
                                value: true,
                                message: 'Please include your message in this field.'
                            }
                        }}
                    />

                    <div className="form-field">
                        <Button
                            variant={'btn btn-fill'}
                            icon={isSubmitting ? null : iconArrowThin}
                            type="submit"
                            isDisabled={submitDisabled}
                            onClick={form.handleSubmit(onSubmitForm, onErrorForm)}
                        >
                            {isSubmitting ? 'Submitting' : 'Submit'}
                        </Button>
                    </div>
                    {isSubmitted && (
                        <div className="form-field">
                            <div className={`form-success ${isSubmitted ? 'display' : 'hidden'}`}>
                                <span className="icon">{iconTick}</span>
                                Your message has been sent
                            </div>
                        </div>
                    )}
                </form>
            </FormProvider>
        </div>
    );
}

export default React.memo(ContactForm);
