import { TRAINING_CART } from '../constants';
import { uniqBy } from 'lodash';

const initialState = {
  items: [],
  isOrdering: false, 
  errors: {},
};

const trainingCartReducer = (state = initialState, action = {}) => {
	const payload = action.payload?.data

  switch (action.type) {
		case TRAINING_CART.ITEM_ADD:
			return {
				...state,
        items: uniqBy(
          [...state.items,  action.payload],
          'id'
        ),
			}

		case TRAINING_CART.ITEM_REMOVE:
			return {
				...state,
				items: state.items.filter(item => item.id !== action.payload?.id)
			}
		
		case TRAINING_CART.SUBMIT_PURCHASE:
			return {
				...state,
				isOrdering: true			
			}

		case TRAINING_CART.SUBMIT_PURCHASE_SUCCESS:
			return {
				...initialState
			}

		case TRAINING_CART.SUBMIT_PURCHASE_FAILED:
			return {
				...state,
        isOrdering: false,
        errors: payload || {},
			}
  }

  return state;
};

export default trainingCartReducer;
