import { PAGES } from '../constants';

const initialState = {
  errors: [],
  isLoading: true,
  menu: '',
  staticPageData: [],
  list: []
}

const pageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PAGES.REQUEST_LIST:
      return {
        ...state,
      }

    case PAGES.REQUEST_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload?.data?.data
      }

    case PAGES.REQUEST_LIST_FAILED:
      return {
        ...state
      }

    case PAGES.REQUEST_BY_ID:
    case PAGES.REQUEST_BY_SLUG:
      return {
        ...state,
        errors: [],
        isLoading: true,
        staticPageData: []
      };

    case PAGES.REQUEST_BY_ID_SUCCESS:
    case PAGES.REQUEST_BY_SLUG_SUCCESS:
      return {
        ...state,
        isLoading: false,
				menu: action.payload?.data?.data?.data?.menu,
        staticPageData: action.payload?.data?.data
      };


    case PAGES.REQUEST_BY_ID_FAILED:
    case PAGES.REQUEST_BY_SLUG_FAILED: {
      return {
        ...state,
        errors: action?.payload?.response?.data,
        isLoading: false,
      };
    }
  
  }

  return state;
};

export default pageReducer;
