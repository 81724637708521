import { CEO } from '../constants';

const initialState = {
	isLoading: false,
	articles: [],
	latestArticle: {},
	errors: undefined
};

const ceoReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		// CEO
		case CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST:
		case CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG:
			return {
				...state,
				isLoading: true,
			};

		case CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST_FAILED:
		case CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload.data
			}

		case CEO.REQUEST_CEO_NEWS_ARTICLE_LATEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				latestArticle: action.payload.data.data
			}

		case CEO.REQUEST_CEO_NEWS_ARTICLE_BY_SLUG_SUCCESS:
			return {
				...state,
				isLoading: false,
				articles: [
					...state.articles,
					action.payload.data.data
				]
			}
	}

	return state;
};

export default ceoReducer;
