import { PORTAL_ANNOUNCEMENT } from '../constants';

export const requestPortalAnnouncement = (payload) => {
    return {
        type: PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT,
        payload
    };
};

export const requestPortalAnnouncementSuccess = (payload) => {
    return {
        type: PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT_SUCCESS,
        payload
    };
};

export const requestPortalAnnouncementFailed = (payload) => {
    return {
        type: PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT_FAILED,
        payload
    };
};
