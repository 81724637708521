import React from 'react';
import Pagination from 'react-js-pagination';
import { iconArrowThinLongLeft, iconArrowThinLongRight } from '@/images/icons';

const ArticleListingPagination = ({
  totalArticles,
  currentPage,
  itemsPerPage,
  onChange,
  pageRangeDisplayed
}) => {
  return (
    <div className="pagination-wrapper">
      <Pagination
        totalItemsCount={totalArticles}
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        onChange={onChange}
        nextPageText={iconArrowThinLongRight}
        prevPageText={iconArrowThinLongLeft}
        hideFirstLastPages={true}
        pageRangeDisplayed={pageRangeDisplayed}
      />
    </div>
  );
};

export default ArticleListingPagination;
