import axios from 'axios';
import { BASE_URL, API } from '@/config';
import { fork, call, put, takeLatest } from 'redux-saga/effects';
import {PAGES} from '../constants';
import {
    requestPagesListSuccess,
    requestPagesListFailed,
    requestPageByIdSuccess,
    requestPageByIdFailed,
    requestPageBySlugFailed,
    requestPageBySlugSuccess
} from '@/store/actions';

export function* getPageList(action) {
    const request = () =>
        axios({
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'get',
            url: `${BASE_URL}${API[action.type]}`
        });

    try {
        const response = yield call(request);
        yield put(requestPagesListSuccess(response));
    } catch (err) {
        yield put(requestPagesListFailed(err));
    }
}

export function* watchGetPageList() {
    yield takeLatest(PAGES.REQUEST_LIST, getPageList);
}

export function* getPageByID(action) {
    const request = () =>
        axios({
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'get',
            url: `${BASE_URL}${API[action.type]}/${action.payload.id}`
        });

    try {
        const response = yield call(request);
        yield put(requestPageByIdSuccess(response));
    } catch (err) {
        yield put(requestPageByIdFailed(err));
    }
}

export function* watchGetPageByID() {
    yield takeLatest(PAGES.REQUEST_BY_ID, getPageByID);
}

export function* getPageBySlug(action) {

    const request = () =>
        axios({
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'get',
            url: `${BASE_URL}${API[action.type]}/${action.payload.slug}`
        });

    try {
        const response = yield call(request);
        yield put(requestPageBySlugSuccess(response));
    } catch (err) {
        yield put(requestPageBySlugFailed(err));
    }
}

export function* watchGetPageBySlug() {
    yield takeLatest(PAGES.REQUEST_BY_SLUG, getPageBySlug);
}

const sagas = [
    fork(watchGetPageList),
    fork(watchGetPageByID),
    fork(watchGetPageBySlug)
];

export default sagas;
