import { RATE_EXPERIENCE } from '../constants';

const rateExperience = (payload) => ({
  type: RATE_EXPERIENCE.RATE_EXPERIENCE,
  payload
});

const successfulRateExperience = (payload) => ({
  type: RATE_EXPERIENCE.SUCCESSFUL_RATE_EXPERIENCE,
  payload
});

const failedRateExperience = (payload) => ({
  type: RATE_EXPERIENCE.FAILED_RATE_EXPERIENCE,
  payload
});

export { rateExperience, successfulRateExperience, failedRateExperience };
