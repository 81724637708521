import { MANAGE_USERS } from '../constants';
import { uniqBy } from 'lodash';

const initialState = {
  isLoading: false,
  users: [],
  hasMore: false,
  currentPage: 1,
  total: 0,
  errors: undefined
};

const manageUsersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // User List
    case MANAGE_USERS.REQUEST_USERS:
      return {
        ...state,
        isLoading: true,
        pageIndex: action.payload.page
      };

    case MANAGE_USERS.REQUEST_USERS_SUCCESS: {
      const payload = action?.payload?.data;
      const data = payload?.data?.data || [];

      return {
        ...state,
        users: uniqBy([...state.users, ...data], 'id'),
        isLoading: false,
        currentPage: payload?.current_page,
        total: payload?.total,
        hasMore: !!payload?.data?.next_page_url
      };
    }

    case MANAGE_USERS.REQUEST_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.data
      };
    // Approve
    case MANAGE_USERS.REQUEST_USER_APPROVE:
      return {
        ...state,
        isLoading: true
      };

    case MANAGE_USERS.REQUEST_USER_APPROVE_SUCCESS:
      // TODO: update state - update user in list
      return {
        ...state,
        isLoading: false
      };

    case MANAGE_USERS.REQUEST_USER_APPROVE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.data
      };
    // Delete
    case MANAGE_USERS.REQUEST_USER_DELETE:
      return {
        ...state,
        isLoading: true
      };

    case MANAGE_USERS.REQUEST_USER_DELETE_SUCCESS:
      // TODO: update state - remove user from list
      return {
        ...state,
        isLoading: false
      };

    case MANAGE_USERS.REQUEST_USER_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.data
      };
  }

  return state;
};

export default manageUsersReducer;
