import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { isUndefined, isEmpty } from 'lodash';
import useMedia from 'use-media';
import { useLocation, Link } from 'react-router-dom';
import { Motion } from '@/components/common';
import CollapsibleMobileToggle from './CollapsibleMobileToggle';

Collapsible.propTypes = {
  variant: PropTypes.string,
  mobileToggle: PropTypes.bool,
  childItemToggleIcon: PropTypes.string,
  items: PropTypes.array,
  mobileToggleName: PropTypes.string,
  openCollapsible: PropTypes.bool
};

export default function Collapsible({
  variant = '',
  mobileToggle,
  childItemToggleIcon = 'icon-chevron-down-up',
  items,
  mobileToggleName,
  openCollapsible
}) {
  const isMobile = useMedia('(max-width: 1023px)');
  const { pathname } = useLocation();
  const CollapsibleItemRef = useRef(null);
  const [collapsibleLabel, setCollapsibleLabel] = useState(mobileToggleName);
  const [currentActive, setCurrentActive] = useState(undefined);
  const [isOpenCollapsible, setIsOpenCollapsible] = useState(mobileToggle);

  const onClickCloseCollapsible = () => {
    setIsOpenCollapsible(!isOpenCollapsible);
  };

  if (isEmpty(items)) {
    <CollapsibleMobileToggle
      name={collapsibleLabel}
      onClick={onClickCloseCollapsible}
      isOpenCollapsible={isOpenCollapsible}
    />;
  }
  return (
    <>
      {isMobile && mobileToggle && (
        <CollapsibleMobileToggle
          name={collapsibleLabel}
          onClick={onClickCloseCollapsible}
          isOpenCollapsible={isOpenCollapsible}
        />
      )}

      <Motion
        animate={!isUndefined(openCollapsible) ? openCollapsible : !isOpenCollapsible}
      >
        <div className={`collapsible ${variant}`}>
          {items?.map((item, index) => {
            if (item?.isActive) {
              currentActive !== index && setCurrentActive(index);
              collapsibleLabel !== item.title && setCollapsibleLabel(item.title);
            } else if (item?.url && pathname.includes(item?.url)) {
              collapsibleLabel !== item.title && setCollapsibleLabel(item.title);
            }

            const isItemActive =
              currentActive === index || (item?.url && pathname.includes(item?.url));
            const accordionItemNoContentClass = !item.Content ? 'no-content' : '';
            const accordionItemActiveClass = isItemActive ? 'active' : '';

            return (
              <div
                className={`collapsible-item ${accordionItemActiveClass} ${accordionItemNoContentClass}`}
                key={index}
                ref={CollapsibleItemRef}
              >
                {item && (
                  <React.Fragment>
                    <div className="collapsible-item-title">
                      <button>
                        {item?.isExternal ? (
                          <a href={item?.url} target="_blank" rel="noreferrer">
                            {item.title}
                          </a>
                        ) : (
                          <Link
                            to={item?.url || '#'}
                            onClick={(ev) => {
                              if (!!item?.onClickAccordionItem) {
                                ev.preventDefault();
                                item.onClickAccordionItem(ev);
                              } else if (!!item?.Content) {
                                currentActive !== index
                                  ? setCurrentActive(index)
                                  : setCurrentActive(undefined);
                              }
                            }}
                          >
                            {item.title}
                          </Link>
                        )}

                        {!!item?.Content && (
                          <span
                            className={`icon ${
                              item.icon ? childItemToggleIcon : ''
                            } ${accordionItemActiveClass}`}
                            onClick={() => {
                              currentActive !== index
                                ? setCurrentActive(index)
                                : setCurrentActive(undefined);
                            }}
                          />
                        )}
                      </button>
                    </div>

                    {isItemActive && !!item?.Content && (
                      <div className="collapsible-item-content">
                        <item.Content
                          isParentActive={isItemActive}
                          items={item.children}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            );
          })}
        </div>
      </Motion>
    </>
  );
}
