import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const ArticleCategoriesLink = ({ category, categoryName, totalArticles }) => {
  const location = useLocation();
  const articleCategoryLink = `/news-and-resources/${category}`;

  return (
    <Link
      to={articleCategoryLink}
      className={`pill${location === articleCategoryLink ? ' active' : ''}`}
    >
      {!!categoryName && categoryName} {!!totalArticles && `(${totalArticles})`}
    </Link>
  );
};

export default ArticleCategoriesLink;
