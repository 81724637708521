import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { requestPageBySlug } from '@/store/actions';
import { selectStaticPage } from '@/store/selectors';
import { Loading, CMSPageContent } from '@/components/Portal';
import { PORTAL_PATHS } from '@/config';

export default function CMSPage() {
  const history = useHistory();
	const dispatch = useDispatch();
  const { slug } = useParams();
  const { loading, errors, content } = useSelector(selectStaticPage);
	const [pageContent, setPageContent] = useState([]);
	const [pageLoading, setPageLoading] = useState(true);

  React.useEffect(() => {
		dispatch(requestPageBySlug({ slug: slug }));
  }, [slug]);

  React.useEffect(() => {
    setPageLoading(loading)
  }, [loading]);

	React.useEffect(() => {
    setPageContent(content)
  }, [content]);

	React.useEffect(() => {
    if (!isEmpty(errors)) {
      history.replace(PORTAL_PATHS.notFound);
    }
  }, [errors]);

  return (
		<div className="content-layout static-page">
			{pageLoading == true ? <Loading /> : <CMSPageContent content={pageContent}/> }
		</div>
  );
}
