import React from 'react';
import { Link } from 'react-router-dom';

export default function PortalNavSubMenuMobile({ items }) {
  return (
    <div className="content">
      <ul>
        {items.map((item, i) => {
          return (
            <li key={`sub-menu-${i}`}>
              {item.attributes.is_external ? (
                <a href={item?.attributes.page} target="_blank" rel="noreferrer">
                  {item.attributes.heading}
                </a>
              ) : (
                <Link to={item.attributes.page}>{item.attributes.heading}</Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
