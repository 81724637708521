import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash'
import { format } from 'date-fns'
import { utcToLocal } from '@/utils';

EventScheduleCard.propTypes = {
	title: PropTypes.string,
	company_name: PropTypes.string,
	name: PropTypes.string,
	startDateTime: PropTypes.string,
	endDateTime: PropTypes.string,
	location: PropTypes.string
}

function formatTime(date) {
	return !!date ? format(utcToLocal(date), 'p') : date;
}

export default function EventScheduleCard({
	title,
	company_name,
	name,
	startDateTime,
	endDateTime,
	location
}) {
	const type = isEmpty(name) && isEmpty(company_name)
		? 'break'
		: 'event'

	return <div
		className={`event-schedule-card ${type}`}
	>
		<div className='time text-meta'>
				{formatTime(startDateTime)} <br/> To <br/> {formatTime(endDateTime)}
		</div>

		<div className='content'>
			{type === 'event' &&
				<div className='heading text-meta'> {company_name} </div>
			}
			<p> {type === 'event' ? name : title}  </p>
		</div>

		<span className='location'>
			{location}
		</span>

	</div>
}
