import React from 'react';
import { iconArrowThinLongRight } from '@/images/icons';

const DivisionsAndBranchesFilterForm = ({ handleSubmitLocation, handleSearchQuery }) => {
  return (
    <div className="location">
      <form className="form-location" onSubmit={handleSubmitLocation}>
        <label>BRANCHES NEAR ME</label>
        <input
          placeholder="Suburb, state or postcode"
          onChange={handleSearchQuery}
          type="text"
        />
      </form>
      <button
        title="Search for locations near you"
        className="btn-primary geo-locate"
        onClick={handleSubmitLocation}
      >
        <span className="icon">{iconArrowThinLongRight}</span>
      </button>
    </div>
  );
};

export default DivisionsAndBranchesFilterForm;
