import React from 'react';
import useMedia from 'use-media';
import { PortalLayout, PortalPageTitle } from '@/components/Portal';
import {
  NewsroomMenu,
  RegulatorFilter,
  NewsListing,
  Search,
  SelectedFilters
} from '@/components/Portal/Newsroom';

export default function Newsroom() {
  const isDesktop = useMedia('(min-width: 1024px)');

  return (
    <PortalLayout>
      <PortalPageTitle
        heading={'Newsroom'}
        description="Browse COBA's bulletins and stay up to date"
      />
        <NewsroomMenu mobile/>
        <Search mobile/>

      <div className="container">
        <div className="row">
          <NewsroomMenu desktop/>

          <div className="news-listing col-xs-12 col-sm-8">
            <Search desktop/>
            {isDesktop && <RegulatorFilter/> }

            <SelectedFilters />
            <NewsListing />
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
