import React from 'react';
import { iconMap, iconPhone, iconLocationAlt } from '@/images/icons';

const DivisionListCardContact = ({
  phone,
  address,
  address2,
  city,
  distance,
  latitude,
  longitude
}) => {
  return (
    <div className="contact-details">
      <div className="row">
        <span className="icon">{iconPhone}</span>
        <span>{phone}</span>
      </div>

      <div className="row">
        <span className="icon">{iconLocationAlt}</span>
        <span>{address}</span>
      </div>

      {address2 && (
        <div className="row">
          <span className="icon"></span>
          <span>{address2}</span>
        </div>
      )}

      {city && (
        <div className="row">
          <span className="icon"></span>
          <span>{city}</span>
        </div>
      )}

      <div className="row distance">
        <span className="icon"></span>
        {`(${parseFloat(distance).toFixed(2)} km away)`}
      </div>

      <div className="row">
        <span className="icon">{iconMap}</span>
        <a
          className="standard"
          href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Directions via Google maps
        </a>
      </div>
    </div>
  );
};

export default DivisionListCardContact;
