import React from 'react';
import { QuickLinks } from '@/components';

export default function NoArticlesFound() {
  return (
    <div className="no-articles-found">
      <div className="search-tips">
        <h3>Search tips</h3>
        <ul>
          <li>Check the spelling of your search term</li>
          <li>Try alternate words</li>
          <li>Try entering a more generic keyword</li>
        </ul>
      </div>

      <div className="quicklinks-section">
        <h3>Try our quick links</h3>
        <QuickLinks />
      </div>
    </div>
  );
}
