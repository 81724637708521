import { pluralize } from 'humanize-plus';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
// Store
import { requestFeedsByPage } from '@/store/actions';
import { selectFeeds, selectIsFeedsLoading } from '@/store/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
// Components
import { LoadMore } from '@/components/Portal';
import { FeedCard } from '../Card';
import FeedsListingSidebar from './FeedsListingSidebar';

function Listing({ results }) {
  if (!results?.length > 0) {
    return null;
  }

  return results.map((item) => <FeedCard key={item.id} {...item} />);
}

export default function FeedsListing() {
  const actions = bindActionCreators(
    {
      requestFeedsByPage
    },
    useDispatch()
  );

  const isLoading = useSelector(selectIsFeedsLoading);
  const { items, hasMore, total, currentPage } = useSelector(selectFeeds, shallowEqual);
  const resultsDisplaying = items.length;
  const resultsTotal = total;
  const articleListing = items;

  const onClickLoadMore = (perPage) => () => {
    actions.requestFeedsByPage({
      page: currentPage + 1,
      per_page: perPage
    });
  };

  useEffect(() => {
    actions.requestFeedsByPage();
  }, []);

  return (
    <div className="newsroom listing">
      <div className="listing-counter">
        {isLoading
          ? 'Loading...'
          : `Showing ${resultsDisplaying} of ${total} ${pluralize(total, 'result')}`}
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <div className="card-listing full">
              {!isLoading && <Listing results={articleListing} />}

              {!isLoading && isEmpty(articleListing) && <div>No feed articles found</div>}

              <LoadMore
                isLoading={isLoading}
                hasMore={hasMore}
                onClickLoadMore={onClickLoadMore}
                resultsDisplaying={resultsDisplaying}
                resultsTotal={resultsTotal}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <FeedsListingSidebar />
          </div>
        </div>
      </div>
    </div>
  );
}
