import { createSelector } from 'reselect';

// State Selectors
export const getFeedsState = (state) => state.feedsReducer;

export const getFeeds = createSelector(
  getFeedsState,
  (state) => state.articles.items
);

export const selectFeeds = createSelector(
  getFeedsState,
  (state) => state.articles
);

export const selectFeedsQueries = createSelector(
  getFeedsState,
  (state) => state.queries
);

export const selectIsFeedsLoading = createSelector(
  getFeedsState,
  (state) => state.isArticlesLoading
);

