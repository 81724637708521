import React from 'react';
import { isEmpty } from 'lodash';

const MediaReleaseNoFilteredResults = ({ searchQuery }) => {
  return (
    <div className="no-results">
      <h3>No results</h3>
      <p>
        No results found for{' '}
        <strong>
          {!isEmpty(searchQuery) ? `'${searchQuery}'` : 'the year'}
        </strong>
        . Please check spelling or try a new search term.
      </p>
    </div>
  );
};

export default MediaReleaseNoFilteredResults;
