import React from 'react';
import NewsArticleImageModal from './NewsArticleImageModal';
import parse from 'html-react-parser';

export default function NewsArticleParsedContent({ markup = '' }) {
  const parsedContent = parse(markup, {
    replace: (domNode) => {
      if (domNode?.name === 'img') {
        const imgSrc = domNode?.attribs?.src;
        const imgAlt = domNode?.attribs?.alt;

        return <NewsArticleImageModal src={imgSrc} alt={imgAlt} />;
      }

      return domNode;
    }
  });

  return !!markup ? <article className="article-content">{parsedContent}</article> : null;
}
