import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import RatingStar from './RatingStar';
import RatingLikeDislike from './RatingLikeDislike';
import { RatingModalContent } from '.';
import RatingSatisfaction from './RatingSatisfaction';
import RatingRadio from './RatingRadio';

/**
 * @typedef {'0' | '1' | '2' | '3'} Variant
 */

/**
 * @typedef {Object} Option
 * @property {string} label - The label for the option.
 * @property {string} value - The value for the option.
 */

/**
 * @typedef {Object} Props
 * @property {Boolean} isOpen - Indicates whether the modal is open.
 * @property {Function} onClose - Function to close the modal.
 * @property {Variant} [variant='radio'] - The variant of the rating component to render.
 * @property {String} title - The title of the modal.
 * @property {String} description - The description of the modal.
 * @property {Option[]} [options] - The options for the radio modal.
 * @property {function} onRate - The function to be called when a rating is selected.
 */

/**
 * RatingModal component.
 * @param {Props} props - The props for the component.
 */
function RatingModal(props) {
  const {
    isOpen,
    onClose,
    variant = 'radio',
    title,
    description,
    options,
    onRate
  } = props;

  /**
   * Renders the appropriate rating component based on the variant.
   * @type {React.FC}
   */
  const RenderRatingModalPage = useCallback(() => {
    let ratingComponent;

    switch (variant) {
      //   case 'like-dislike':
      case '0':
        ratingComponent = (
          <RatingLikeDislike
            onRate={(/** @type {string} */ rate) => onRate(variant, rate)}
          />
        );
        break;
      //   case 'star':
      case '1':
        ratingComponent = (
          <RatingStar onRate={(/** @type {string} */ rate) => onRate(variant, rate)} />
        );
        break;
      //   case 'satisfaction':
      case '2':
        ratingComponent = (
          <RatingSatisfaction
            onRate={(/** @type {string} */ rate) => onRate(variant, rate)}
          />
        );
        break;
      //   case 'radio':
      case '3':
        ratingComponent = (
          <RatingRadio
            options={options || []} // Use the passed options, or an empty array as default
            onRate={(/** @type {string} */ rate) => onRate(variant, rate)}
          />
        );
        break;
      default:
        ratingComponent = <>unknown component</>;
        break;
    }

    return ratingComponent;
  }, [variant, options]);

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: 'transparent'
        }
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Filter"
      className="bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl overflow-hidden px-7 py-16"
      overlayClassName="portal-modal__overlay--filter bg-opacity-50 backdrop-filter backdrop-blur-sm flex justify-center items-center"
      // @ts-ignore
      appElement={document.getElementById('app')}
    >
      <div className="max-w-lg w-full">
        <div className="flex flex-col gap-3">
          <RatingModalContent title={title} description={description} />
          <RenderRatingModalPage />
        </div>
      </div>
    </ReactModal>
  );
}

export default RatingModal;
