import { fork, call, put, takeEvery } from 'redux-saga/effects';
import { HOME_PAGE } from '../constants';
import { 
  requestHomepageSuccess, 
  requestHomepageFailed,
} from '../actions';
import rootDomain from '../rootDomain';

const api = (url) => fetch(url).then((res) => res.json());

export function* requestHomepageAsync() {
  const ROOT = rootDomain();
  const homepageUrl = `${ROOT}/api/v1/pages/slug/home`;

  try {
    const response = yield call(api, homepageUrl);
    yield put(requestHomepageSuccess(response));
  } catch (err) {
    yield put(requestHomepageFailed(err));
  }
}

export function* watchRequestHomepage() {
  yield takeEvery(HOME_PAGE.REQUEST_HOME_PAGE, requestHomepageAsync);
}

const sagas = [
  fork(watchRequestHomepage)
];

export default sagas;