import PropTypes from 'prop-types';
import React from 'react';
// Components
import { Loading } from '@/components/Portal';
import CardListing from './CardListing';
// Utils
import { pluralize } from 'humanize-plus';
import { isEmpty } from 'lodash';

Results.propTypes = {
  loading: PropTypes.bool,
  count: PropTypes.number,
  eventResults: PropTypes.object
};

export default function Results({ loading, count, eventResults }) {
  return (
    <div className="listing">
      <div className="listing-counter">{`${count} ${pluralize(count, 'result')}`}</div>
      <div className="card-grouping">
        {loading && <Loading />}
        {!loading && isEmpty(eventResults) && <div key={-1}> No results were returned. Please revise your search. </div>}
        {!loading && !isEmpty(eventResults) && <CardListing results={eventResults} /> }
      </div>
    </div>
  );
}
