import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash'
import {
  getSelectedTopicsAndPolicy,
  getSelectedFilterDates,
  selectNewsArticlesQueries
} from '@/store/selectors';
import { requestNewsArticlesFirstPage, selectNewsTopics } from '@/store/actions';
import { iconCloseSmall } from '@/images/icons';
import { Badge } from '@/components';
import useAnalyticEventsTracking from '@/hooks/useAnalyticEventsTracking';

export default function SelectedFilters() {
  const dispatch = useDispatch();
  const filterQueries = useSelector(selectNewsArticlesQueries);
  const prevFilterQueries = useRef(null);
  const selectedTopicsAndPolicy = useSelector(getSelectedTopicsAndPolicy);
  const filterDates = useSelector(getSelectedFilterDates);

  const trackEvent = useAnalyticEventsTracking()
  useEffect(() => {
    if (!filterQueries.page) return;
    if (JSON.stringify(prevFilterQueries.current) != JSON.stringify(filterQueries)) {
      prevFilterQueries.current = filterQueries;

      trackEvent({
        type: 'search',
        properties: {
          'search_keyword': filterQueries?.search,
          'topic_ids': filterQueries?.topics,
          'regulator_ids': filterQueries?.regulators,
        }
      })
    }
  }, [filterQueries])

  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [filterToRemove, setFilterToRemove] = React.useState(undefined);

  const onRemoveFilter = (item) => (ev) => {
    ev.preventDefault();
    setFilterToRemove(item);
  };

  React.useEffect(() => {
    setSelectedFilters([...selectedTopicsAndPolicy, ...filterDates]);
  }, [selectedTopicsAndPolicy, filterDates]);

  React.useEffect(() => {
    let payload;

    if (filterToRemove) {
      const removedFilters = selectedFilters.filter(
        (filter) => filter.type === filterToRemove.type && filter.id !== filterToRemove.id
      );

      setSelectedFilters(removedFilters);

      if (filterToRemove.type === 'dateStart' || filterToRemove.type === 'dateEnd') {
        payload = undefined;
      }

      if (filterToRemove.type === 'policy') {
        payload =
          filterToRemove.id === filterQueries.policy ? undefined : filterQueries.policy;
      }

      if (filterToRemove.type === 'topics') {
        payload = filterQueries.topics.filter((topic) => {
          return topic !== filterToRemove.id;
        });
        dispatch(selectNewsTopics(filterToRemove.id));
      }

      dispatch(
        requestNewsArticlesFirstPage({
          [filterToRemove.type]: payload
        })
      );
    }
  }, [filterToRemove]);

  if (isEmpty(selectedFilters)) {
    return null
  }

  return (
    <div className="filter-selected-topics">
      <div className="filter-label">Selected</div>
      <div className="filter-list">
        {selectedFilters &&
          selectedFilters.map((filter) => {
            const name =
              filter.type === 'dateStart' || filter.type === 'dateEnd'
                ? `${filter.id} ${filter.name}`
                : filter.name;

            return (
              <Badge
                key={`selected-topic-${filter.name.replaceAll(' ', '-')}-${filter.id}`}
                type="selected-topics"
                state={'is-active'}
                handleOnClick={onRemoveFilter(filter)}
              >
                <span className="name">{name}</span>
                <span className="icon">{iconCloseSmall}</span>
              </Badge>
            );
          })}
      </div>
    </div>
  );
}
