import React from 'react';

export default function SubpageHeader({ breadcrumbs, title }) {
  return (
    <section className="subpage-header">
      <div className="content-wrapper">
        {breadcrumbs && (
          <div className="breadcrumbs">
            <p>{breadcrumbs}</p>
          </div>
        )}
        {title && <h1>{title}</h1>}
      </div>
    </section>
  );
}
