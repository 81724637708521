import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { parseDate, formatDate } from '@/utils';
import 'react-day-picker/lib/style.css';
import Label from './Label';

const DatePickerInputField = React.forwardRef((props, ref) => {
  return (
    <div className="DayPickerInput input">
      <Label labelFor={props.labelFor} labelText={props.labelText} />
      <input
        className="text-input"
        type="text"
        {...props}
        placeholder={'DD-MM-YYYY'}
        ref={ref}
      />
      <span className="icon icon-chevron" />
    </div>
  );
});

DatePickerInputField.displayName = 'DatePickerInputField';

export default function DatePicker(props) {
  const FORMAT = 'dd MMM yyyy';
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const onClickDayPickerInput = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const dayPickerInputClasNames = isDatePickerOpen
    ? 'DayPickerInput active'
    : 'DayPickerInput';

  return (
    <DayPickerInput
      classNames={{
        container: dayPickerInputClasNames
      }}
      placeholder={props.placeholder || 'Select'}
      formatDate={formatDate}
      format={FORMAT}
      parseDate={parseDate}
      onDayChange={props.onChange}
      onDayPickerShow={onClickDayPickerInput}
      onDayPickerHide={onClickDayPickerInput}
      component={DatePickerInputField}
      value={props.value}
    />
  );
}
