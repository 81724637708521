import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { forgotPasswordRequest } from '@/store/actions';
import { iconArrowThin, iconTick } from '@/images/icons';
import { Button, EmailField } from '@/components';
import { FormBackButton } from '@/components/Portal/Onboarding';
import OnboardingFormTransition from './OnboardingFormTransition';
import { PORTAL_PATHS } from '@/config';

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const [passwordRequestSent, setPasswordRequestSent] = React.useState(false);
  const [email, setEmail] = React.useState('')

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onSubmitForm = (data) => {
    dispatch(forgotPasswordRequest(data));
    setPasswordRequestSent(true);
  };

  const btnNextClass = passwordRequestSent
    ? 'btn-next col-xs-12 no-pad'
    : 'btn-next col-xs-6';

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

  return (
    <OnboardingFormTransition formPath={PORTAL_PATHS.onboarding.forgotPassword}>
      <FormProvider {...form}>
        <form
          className="form form-forgot-password"
          onSubmit={form.handleSubmit(onSubmitForm)}
          noValidate
        >
          <h4 className="form-title">Forgot your password?</h4>
          <p className="form-text">
            Enter your email address below and we will send you an update link.
          </p>

          <br />

          <EmailField control={form.control} onChangeField={onEmailChange}/>

          <br />

          <div className="row">
            {!passwordRequestSent && (
              <div className="btn-back col-xs-6">
                <FormBackButton path={PORTAL_PATHS.onboarding.signIn} />
              </div>
            )}

            <div className={btnNextClass}>
              {!passwordRequestSent && (
                <Button variant={'btn-fill btn-full-width'} icon={iconArrowThin}>
                  Send
                </Button>
              )}

              {passwordRequestSent && (
                <div className="form-success">
                  <span className="icon">{iconTick}</span>
                  <span className="text">If a matching account was found, an email was sent to {email} to allow you to reset your password.</span>
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </OnboardingFormTransition>
  );
}
