import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { PORTAL_PATHS } from '@/config';
//Components
import { PortalLayout, PortalPageTitle, CMSPage } from '@/components/Portal';
import { VacanciesMenu, View, List, Submitted } from '@/components/Portal/Vacancies';

export default function Vacancies() {
  return (
    <PortalLayout>
      <PortalPageTitle
        heading={'Vacancies'}
        description="Explore out vacancies to see which may be right for you"
      />
      <VacanciesMenu mobile />

      <div className="container v-pad">
        <div className="row">
          <VacanciesMenu desktop />

          <div className="col-xs-12 col-md-8">
            <Switch>
              <Route
                component={View}
                exact
                path={`${PORTAL_PATHS.VACANCIES}/view-vacancies`}
              />
              <Route
                component={List}
                exact
                path={`${PORTAL_PATHS.VACANCIES}/list-vacancy`}
              />
              <Route
                component={Submitted}
                exact
                path={`${PORTAL_PATHS.VACANCIES}/submitted-vacancy`}
              />
              <Route component={CMSPage} exact path={`${PORTAL_PATHS.VACANCIES}/:slug`} />
            </Switch>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
