import React from 'react';

export default function CollapsibleMobileToggle({
  name,
  iconName = 'icon-chevron-down-up',
  icon,
  onClick,
  isOpenCollapsible,
  variant=''
}) {
  const isActive = !!isOpenCollapsible ? 'active' : '';

  return (
    <div className={`collapsible-mobile-toggle ${isActive} ${variant}`} onClick={onClick}>
      <p>{name}</p>
      <span className={`icon ${icon ? '' : iconName} ${isActive}`}> 
        {icon}
      </span>
    </div>
  );
}
