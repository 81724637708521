import React from 'react';
import { Link } from 'react-router-dom';
import { iconArrowThinLeft } from '@/images/icons';

export default function FormBackButton({
  path,
  text = 'Back',
  icon = iconArrowThinLeft
}) {
  return (
    <Link className="btn btn-text btn-form-back" to={path}>
      <span className="icon">{icon}</span>
      {text}
    </Link>
  );
}
