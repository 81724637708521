import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestBankingTips,
  requestCampaigns,
  requestCustomerStories,
  requestSectorNews
} from '@/store/actions';
import { getTotalCustomerStories } from '@/store/selectors/customerStories';
import { getTotalBankingTips } from '@/store/selectors/bankingTips';
import { getTotalSectorNews } from '@/store/selectors/sectorNews';
import { getTotalCampaigns } from '@/store/selectors/campaigns';
import ArticleCategoriesLink from './ArticleCategoriesLink';

const ArticleCategories = () => {
  const totalBankingTips = useSelector(getTotalBankingTips);
  const totalSectorNews = useSelector(getTotalSectorNews);
  const totalCampaigns = useSelector(getTotalCampaigns);
  const totalCustomerStories = useSelector(getTotalCustomerStories);

  const actions = bindActionCreators(
    {
      requestBankingTips,
      requestCampaigns,
      requestCustomerStories,
      requestSectorNews
    },
    useDispatch()
  );

  React.useEffect(() => {
    actions.requestBankingTips();
    actions.requestCampaigns();
    actions.requestCustomerStories();
    actions.requestSectorNews();
  }, []);

  return (
    <div className="content-wrapper">
      <p className="show-me">SHOW ME</p>
      <ul>
        <li>
          <ArticleCategoriesLink
            category={'sector-news'}
            categoryName="Sector News"
            totalArticles={totalSectorNews}
          />
        </li>
        <li>
          <ArticleCategoriesLink
            category={'customer-stories'}
            categoryName="Customer stories"
            totalArticles={totalCustomerStories}
          />
        </li>
        <li>
          <ArticleCategoriesLink
            category={'banking-tips'}
            categoryName="Banking tips"
            totalArticles={totalBankingTips}
          />
        </li>
        <li>
          <ArticleCategoriesLink
            category={'campaigns'}
            categoryName="Campaigns"
            totalArticles={totalCampaigns}
          />
        </li>
      </ul>
    </div>
  );
};

export default ArticleCategories;
