import { isEmpty, chunk } from 'lodash';
import { createSelector } from 'reselect';
import { selectDivisionBanksDesc } from './membersInstitutions';

export const selectInstitutionDivisionsInChunks = (chunkSize = 4) =>
  createSelector(selectDivisionBanksDesc, (divisions) =>
    !isEmpty(divisions) ? chunk(divisions, chunkSize) : []
  );

export const selectTotalDivisionsChunks = createSelector(
  selectInstitutionDivisionsInChunks(),
  (groups) => (Array.isArray(groups) && groups.length ? groups.length : 0)
);
