import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, TextField } from '@/components';
import { iconArrowThin, iconArrowThinLeft, iconTick } from '@/images/icons';
import { updateUserBranches } from '@/store/actions';
import { validationRegex } from '@/utils';

export default function EditExsitingBranch({ onClickCloseEditBranch, currentBranch }) {
  const dispatch = useDispatch();

  const form = useForm({
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: {
      branch_address_line1: currentBranch?.address,
      branch_address_suite: currentBranch?.suite,
      branch_address_city: currentBranch?.city,
      branch_address_state: currentBranch?.state,
      branch_address_postcode: currentBranch?.postcode,
      branch_phone: currentBranch?.phone
    }
  });

  const { isSubmitted, isDirty, dirtyFields, isValid } = form.formState;

  const isSubmitDisabled = !isDirty || !isValid;

  const onSubmitEditedBranch = (data) => {
    const payload = Object.keys(dirtyFields).reduce(
      (a, c) => (data?.[c] ? { ...a, [c]: data?.[c] } : a),
      {}
    );

    dispatch(
      updateUserBranches({
        branch_id: currentBranch?.id,
        ...payload
      })
    );
  };

  return (
    <>
      <Button
        variant={'btn btn-text btn-go-back'}
        iconLeft={iconArrowThinLeft}
        onClick={onClickCloseEditBranch}
      >
        Go Back
      </Button>

      <br />
      <br />

      <FormProvider {...form}>
        <form noValidate>
          <fieldset className="fieldset">
            <legend className="legend H3">Update details below</legend>

            <TextField
              id="branch_address_line1"
              control={form.control}
              name="branch_address_line1"
              labelFor="branch_address_line1"
              labelText="Address Line 1"
              placeholder="Address Line 1"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
            />

            <TextField
              id="branch_address_suite"
              control={form.control}
              name="branch_address_suite"
              labelFor="branch_address_suite"
              labelText="Suite/Unit"
              placeholder="Suite/Unit"
            />

            <TextField
              id="branch_address_city"
              control={form.control}
              name="branch_address_city"
              labelFor="branch_address_city"
              labelText="City"
              placeholder="City"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
            />

            <TextField
              id="branch_address_state"
              control={form.control}
              name="branch_address_state"
              labelFor="branch_address_state"
              labelText="State"
              placeholder="State"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                }
              }}
            />

            <TextField
              id="branch_address_postcode"
              control={form.control}
              name="branch_address_postcode"
              labelFor="branch_address_postcode"
              labelText="Postcode"
              placeholder="Postcode"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.ausPostcode,
                  message: 'Please enter a valid postcode.'
                }
              }}
            />

            <TextField
              id="branch_phone"
              control={form.control}
              name="branch_phone"
              labelFor="branch_phone"
              labelText="Branch Phone Number"
              placeholder="Branch Phone Number"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.ausPhoneNumber,
                  message: 'Please enter a valid phone number.'
                }
              }}
              type="tel"
            />

            <div className={`form-success ${isSubmitted ? 'display' : 'hidden'}`}>
              <span className="icon">{iconTick}</span>
              Thanks - your update has been submitted and is awaiting approval.
            </div>

            {!isSubmitted && (
              <div className="btn-group">
                <Button
                  variant={'btn btn-fill'}
                  icon={iconArrowThin}
                  onClick={form.handleSubmit(onSubmitEditedBranch)}
                  isDisabled={isSubmitDisabled}
                >
                  Save Branch Details
                </Button>

                <Link
                  to="#"
                  className={'btn btn-cancel btn-text btn-text-underline'}
                  onClick={onClickCloseEditBranch}
                >
                  Cancel
                </Link>
              </div>
            )}
          </fieldset>
        </form>
      </FormProvider>
    </>
  );
}
