import React from 'react';

/**
 * @param {{ title: string; description: string; }} props
 */
function ModalContent(props) {
  const { title, description } = props;
  return (
    <div className="flex flex-col items-start justify-start gap-3">
      <h6 className="text-black text-3xl font-bold">{title}</h6>
      <p className="text-zinc-500 text-xl font-normal">{description}</p>
      <div className="text-black text-base font-bold">Rate your experience</div>
    </div>
  );
}

export default ModalContent;
