import React from 'react';
import { logoCobaBig } from '@/images/logos';
import banner_img from '@/images/brooke-cagle.jpg';
import transparent_half_logo from '@/images/transparent_half_logo.png';

const DivisionHero = () => {
  return (
    <section className="institutions-hero-section">
      <div className="content-wrapper">
        <div className="heading">
          <div className="breadcrumbs">
            <p>Home / Find a bank</p>
          </div>
          <div>
            <h1>
              Find a <u>customer owned</u> banking institution
            </h1>
            <p>Join more than 4 million Australians who own their own banking.</p>
          </div>
        </div>

        <div className="parallax-image">
          <div className="image parallax-object" data-rate="-0.1">
            <img className="bg-img" src={banner_img} alt="business meeting" />
            <img
              className="transparent-logo"
              src={transparent_half_logo}
              alt="transparent Coba half logo"
            />
          </div>

          <div className="logo parallax-object" data-rate="0.25">
            {logoCobaBig}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DivisionHero;
