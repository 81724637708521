import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import parseJSON from 'date-fns/parseJSON';
import {utcToZonedTime} from 'date-fns-tz';

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

export function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

export function utcToLocal(date) {
  return utcToZonedTime(parseJSON(date), 'Australia/Sydney')
}
