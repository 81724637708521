import React from 'react';
import zxcvbn from 'zxcvbn';

export default function PasswordStrength({ password }) {
  const passwordEval = zxcvbn(password);

  const passwordStrength = {
    1: 'weak',
    2: 'average',
    3: 'strong',
    4: 'strong'
  };

  const passwordResultClasses = !!passwordEval.score
    ? `password-strength-result ${passwordStrength[passwordEval.score]}`
    : 'password-strength-result';

  return (
    <div className="password-strength">
      <span className="password-strength-label">Password Strength</span>

      <span className={passwordResultClasses}>
        {passwordStrength[passwordEval.score] || 'Pending'}
      </span>
    </div>
  );
}
