import { createSelector } from 'reselect';

export const getVacancies = (state) => state.jobsReducer.vacancies;
export const postVacancy = (state) => state.jobsReducer.listVacancy;
export const getJobQueries = (state) => state.jobsReducer.queries;

export const selectVacancies = createSelector(getVacancies, (vacancies) => ({
  hasMore: vacancies.hasMore,
  page: vacancies.currentPage,
  total: vacancies.total,
  isLoading: vacancies.isLoading,
  items: vacancies.items
}));

export const selectSearchQuery = createSelector(
  getJobQueries,
  (queries) => queries.search
);

export const selectVacancy = (id) =>
  createSelector(getVacancies, (vacancies) =>
    vacancies.items.find((item) => item?.id === Number(id))
  );

export const selectListing = createSelector(postVacancy, (listVacancy) => ({
  isListing: listVacancy.isListing,
  hasListed: listVacancy.hasListed,
  errors: listVacancy.errors
}));

export const selectJobQueries = createSelector(getJobQueries, (queries) => {
  const filteredQueries = Object.keys(queries).reduce((result, param) => {
    if (!queries?.[param]) {
      result[param] = queries[param];
    }
    return result;
  }, {});
  return filteredQueries;
});
