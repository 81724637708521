import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import useScrollPosition from '@react-hook/window-scroll';
import { logoCobaText } from '@/images/logos';
import navMenuManifest from '@/data/navmenu-manifest.json';
import { isEmpty } from 'lodash';

import { NavMenuContext } from '@/context';
import AnnouncementBar from './AnnouncementBar';
import { NavMenu, NavSubMenu, NavMenuMobileToggle } from '@/components/NavMenu';
import usePrevious from '@react-hook/previous';

const Header = () => {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  const windowWidth = useWindowWidth();
  const scrollPositionY = useScrollPosition();
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [selectedMenuData, setSelectedMenuData] = React.useState([]);
  const [selectedMenuKey, setSelectedMenuKey] = React.useState('');
  const [selectedMenuTitle, setSelectedMenuTitle] = React.useState('');
  const [isHeaderFixed, setIsHeaderFixed] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    setIsMobile(windowWidth <= 1090);
  }, [windowWidth]);

  const focusedMenuItem = !!document && document.getElementsByClassName('focused');

  const removeMenuFocus = () => {
    focusedMenuItem &&
      focusedMenuItem.length > 0 &&
      focusedMenuItem[0].classList.remove('focused');
  };

  const onClickToggleMobileNavMenu = (ev) => {
    ev.preventDefault();

    if (!isMenuOpen && !isSubMenuOpen) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
      setIsSubMenuOpen(false);
    }
  };

  const onClickOpenSubMenu = (ev) => {
    const target = ev.currentTarget;
    const targetName = target.dataset.title;
    removeMenuFocus();
    target.classList.add('focused');
    setSelectedMenuKey(targetName);
  };

  React.useEffect(() => {
    if (!!selectedMenuKey && navMenuManifest[selectedMenuKey]) {
      setSelectedMenuData(navMenuManifest[selectedMenuKey].subMenus);
      setSelectedMenuTitle(navMenuManifest[selectedMenuKey].name);
    }
  }, [selectedMenuKey]);

  React.useEffect(() => {
    !isEmpty(selectedMenuData) && setIsSubMenuOpen(true);
  }, [selectedMenuData]);

  const onClickCloseSubMenu = (ev) => {
    !!ev && ev.preventDefault();

    if (isMenuOpen && !!prevPathname && prevPathname !== pathname) {
      setIsMenuOpen(false);
    }

    setIsSubMenuOpen(false);
    setSelectedMenuData([]);
    setSelectedMenuKey('');
    removeMenuFocus();
  };

  React.useEffect(() => {
    setIsHeaderFixed(scrollPositionY >= 20);
  }, [scrollPositionY]);

  return (
    <>
      <NavMenuContext.Provider
        value={{
          isMobile,
          isMenuOpen,
          setIsMenuOpen,
          isSubMenuOpen,
          onClickOpenSubMenu,
          onClickCloseSubMenu,
          onClickToggleMobileNavMenu,
          selectedMenuKey,
          selectedMenuTitle,
          selectedMenuData
        }}
      >
        <section id="main-header" className="main-header" data-testid="main-header">
          <header
            className={`${
              isHeaderFixed || isSubMenuOpen ? 'opac-white-background fixed' : ''
            }`}
          >
            <AnnouncementBar />

            <div className="content-wrapper">
              <div className="header">
                <div className="logo">
                  <Link to="/">{logoCobaText}</Link>
                </div>

                {!isMobile && <NavMenu />}

                {isMobile && <NavMenuMobileToggle />}
              </div>
            </div>
          </header>

          {isMobile && isMenuOpen && <NavMenu />}
          {isSubMenuOpen && <NavSubMenu />}
        </section>
      </NavMenuContext.Provider>
    </>
  );
};

export default Header;
