import { ANNOUNCEMENT } from '../constants';

const initialState = {
  announcement: undefined,
  isActive: false,
  isLoading: false
};

const announcementReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ANNOUNCEMENT.REQUEST_ANNOUNCEMENT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ANNOUNCEMENT.REQUEST_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        announcement: action.payload.data[1].hello_bar_heading,
        isActive: Boolean(Number(action.payload.data[0].hello_bar_active))
      };
    }

    case ANNOUNCEMENT.REQUEST_ANNOUNCEMENT_FAILED: {
      return {
        ...state,
        isLoading: false
      };
    }
  }

  return state;
};

export default announcementReducer;
