import { call, put, takeLatest } from 'redux-saga/effects';
import { ARTICLES } from '../constants';
import { receivedCustomerStories, failedCustomerStories } from '../actions';
import rootDomain from '../rootDomain';
const api = url => fetch(url, {}).then(res => res.json());

export function* requestCustomerStoriesAsync(action) {
  const ROOT = rootDomain();
  const customerStoriesUrl = `${ROOT}/api/v1/articles/type?per_page=12&type_id=2&page=${action.payload}`;

  try {
    const response = yield call(api, customerStoriesUrl);
    yield put(receivedCustomerStories(response));
  } catch (err) {
    yield put(failedCustomerStories(err));
  }
}

export default function* watchRequestCustomerStories() {
  yield takeLatest(
    ARTICLES.REQUEST_CUSTOMER_STORIES,
    requestCustomerStoriesAsync
  );
}
