import { NAV } from '../constants';

const initialState = {
    quicklinks: {
			isLoading: false,
			links: [],
			error: undefined
		},
};

const navReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		// Quicklinks
		case NAV.REQUEST_QUICKLINKS:
			return { 
				...state, 
				quicklinks: {
						isLoading: true,
				}
			};

		case NAV.REQUEST_QUICKLINKS_SUCCESS:
			return { 
				...state, 
				quicklinks: {
						...state.quicklinks,
						isLoading: false,
						links: action.payload.data.data.data
				} 
			}

		case NAV.REQUEST_QUICKLINKS_FAILED:
			return { 
				...state, 
				quicklinks: {
						...state.quicklinks,
						isLoading: false,
						errors: action.payload.data
				} 
			}
	
	}
	
	return state;
};

export default navReducer;
