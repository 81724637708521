import React from 'react';
import MiniLogo from '@/components/MiniLogo';

const HeroSection = ({ currentArticlesCategory, caption }) => {
  return (
    <section className="hero-section">
      <div className="content-wrapper">
        <div>
          <div className="breadcrumbs">
            <p>Home / News and resources / {currentArticlesCategory}</p>
          </div>

          <h1>News and resources</h1>

          <div className="heading">
            <span className="page-description">
              <MiniLogo />

              <div>
                <h4>{currentArticlesCategory}</h4>
                <p className="caption">{caption}</p>
              </div>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
