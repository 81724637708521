import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Recaptcha({ onVerifyCaptcha, errorMsg }) {
  const RECAPTCHA_API_KEY = process.env.GOOGLE_CHECKBOX_RECAPTCHA_API_KEY;
  return (
    <>
      <ReCAPTCHA sitekey={RECAPTCHA_API_KEY} onChange={onVerifyCaptcha} />
      {!!errorMsg && <p className="text-field-error">{errorMsg}</p>}
    </>
  );
}
