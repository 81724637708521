import React from 'react';
import { useLocation } from 'react-router-dom';

export default function PortalPageTitle({ heading, description, secondary, id }) {
  const { pathname } = useLocation();
  const path = pathname.replace('/member-portal/', '');
  const breadcrumbPath = path
    .split('/')
    .map((text) => text.replace(/-+/g, ' '))
    .join(' / ');

  return (
    <div className={'portal-page-title' + `${secondary ? ' secondary' : ''}`}>
      <div className="container v-pad">
        <p className="breadcrumbs">{breadcrumbPath}</p>
        {heading && <h1 className="heading">{heading}</h1>}
          {id && <h2 className="sub-heading">Bulletin ID: {id}</h2>}
        {description && <p className="description">{description}</p>}
      </div>
    </div>
  );
}
