import React from 'react';
import { Link } from 'react-router-dom';
import { iconArrowThin } from '@/images/icons';
import MiniLogo from '@/components/MiniLogo';

export default function CarouselHeader({ heading, link }) {
  return (
    <header className="carousel-header">
      <div className="heading">
        <MiniLogo />
        <h2>{heading}</h2>
      </div>

      <Link className="btn btn-fill" to={link}>
        View all <span className="icon">{iconArrowThin}</span>
      </Link>
    </header>
  );
}
