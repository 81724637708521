import React from 'react';
import { isEmpty } from 'lodash';
import { Checkbox } from '@/components';

const EmailList = ({ control, id, display_name, subscribed, tags }) => {
  return (
    <div className="campaign">
      <Checkbox
        control={control}
        name={`campaign.${id}`}
        defaultChecked={subscribed}
        indeterminate={!!tags}
      >
        {display_name}
      </Checkbox>

      {!isEmpty(tags) && (
        <div className="campaign-tags">
          {tags.map((tag) => {
            return (
              <Checkbox
                key={tag.id}
                control={control}
                name={`tags.${tag.id}`}
                defaultChecked={tag.subscribed}
              >
                {tag.AC_name}
              </Checkbox>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EmailList;
