import axios from 'axios';
import {fork, call, put, takeLatest, select} from 'redux-saga/effects';
import { PORTAL_ANNOUNCEMENT } from '../constants';
import {
    requestPortalAnnouncementSuccess,
    requestPortalAnnouncementFailed
} from '../actions';
import { BASE_URL, API } from '@/config';

import { getUserToken } from '@/store/selectors';


export function* portalAnnouncementSaga(action) {
    const token = yield select(getUserToken);
    const request = () =>
        axios({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            method: 'get',
            url: `${BASE_URL}${API[action.type]}`
        });

    try {
        const response = yield call(request);
        yield put(requestPortalAnnouncementSuccess(response));
    } catch (err) {
        yield put(requestPortalAnnouncementFailed(err));
    }
}

export function* watchRequestPortalAnnouncement() {
    yield takeLatest(
        PORTAL_ANNOUNCEMENT.REQUEST_PORTAL_ANNOUNCEMENT,
        portalAnnouncementSaga
    );
}

const sagas = [fork(watchRequestPortalAnnouncement)];

export default sagas;
