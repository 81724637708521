import { TRAINING } from '../constants';

// Training Categories
export const requestTrainingCategories = () => ({
	type: TRAINING.REQUEST_TRAINING_CATEGORIES
})

export const requestTrainingCategoriesSuccess  = (payload) => ({
	type: TRAINING.REQUEST_TRAINING_CATEGORIES_SUCCESS,
	payload
})

export const requestTrainingCategoriesFailed = (payload) => ({
	type: TRAINING.REQUEST_TRAINING_CATEGORIES_FAILED,
	payload
})

// Training Modules
export const clearTrainingModules = () => ({
	type: TRAINING.CLEAR_TRAINING_MODULES,
})

export const requestTrainingModuleById = (payload) => ({
	type: TRAINING.REQUEST_TRAINING_MODULE_BY_ID,
	payload
})

export const requestTrainingModuleByIdSuccess = (payload) => ({
	type: TRAINING.REQUEST_TRAINING_MODULE_BY_ID_SUCCESS,
	payload
})

export const requestTrainingModuleByIdFailed = (payload) => ({
	type: TRAINING.REQUEST_TRAINING_MODULE_BY_ID_FAILED,
	payload
})


export const requestTrainingModules = (payload) => ({
  type: TRAINING.REQUEST_TRAINING_MODULES,
  payload
});

export const requestTrainingModulesSuccess = (payload) => ({
  type: TRAINING.REQUEST_TRAINING_MODULES_SUCCESS,
  payload
});

export const requestTrainingModulesFailed = (payload) => ({
  type: TRAINING.REQUEST_TRAINING_MODULES_FAILED,
  payload
});
