import PropTypes from 'prop-types';
import React from 'react';
import { iconPortalUser } from '@/images/icons';
import { Tag } from '@/components/Portal';

WhoShouldAttendCard.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	who: PropTypes.arrayOf(PropTypes.string),
	size: PropTypes.oneOf(['small', 'large']),
}

function rowItems(items) {
	return items.map((who, index) => {
		return (
			<div className='row' key={index}>
				<span className='icon voyage small'>{iconPortalUser}</span>
				<div className='text-meta'>{who}</div>
			</div>
		)
	})
}

export default function WhoShouldAttendCard({ id, size='large', who}) {
  return (
    <div key={id} className={`card no-shaddow ${size}`}>
			<div className='card__layout'>
				<div className='card__header'>
					<Tag type='event-who-should-attend' label='who should attend'/>
				</div>
				{who && <div className='card__details columns'>{rowItems(who)}</div>}

			</div>
		</div>
  );
}