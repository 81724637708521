import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
// Utils
import { format } from 'date-fns';
import { PORTAL_PATHS } from '@/config';
import { utcToLocal } from '@/utils';
// Components
import {
  iconArrowThin,
  iconPortalJob,
  iconPortalClock,
  iconPortalLocation,
  iconPortalCalendar,
  iconPortalUser,
  iconPortalMail
} from '@/images/icons';
import { Tag } from '@/components/Portal'
import { Card } from '@/components/Portal/Card';


VacanciesCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bank: PropTypes.string,
  closes: PropTypes.string,
  contact: PropTypes.string,
  email: PropTypes.string,
  employment: PropTypes.string,
  location: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  title: PropTypes.string,
  type: PropTypes.oneOf(['read-more', 'apply-link', 'apply-email']),
  url: PropTypes.string
};

function action(type, cardLink, applyUrl, mailTo) {
  if (type == 'read-more') {
    return (
      <Link
        className="btn btn-text"
        to={cardLink}
      >
        Read More <span className="icon midnight">{iconArrowThin}</span>
      </Link>
    );
  } else if (type == 'apply-link') {
    return (
      <a className="btn btn-fill" target="_blank" rel="noopener noreferrer" href={applyUrl}>
        Apply Now <span className="icon">{iconArrowThin}</span>
      </a>
    );
  } else if (type == 'apply-email') {
    return (
      <a target="_blank" rel="noopener noreferrer" href={mailTo} >
      	<Tag type='apply-now' label='Apply Now' />
      </a>
    );
  }
}

export default function VacanciesCard({
  id,
  bank,
  closes,
  contact,
  email,
  employment,
  location,
  size = 'large',
  title,
  type = 'read-more',
  url
}) {
  const cardLink = `${PORTAL_PATHS.VACANCIES}/job/${id}`;
  const applyUrl = url;
	const mailTo = `mailto:${email}?subject=${title}`

  const formattedDate = !!closes ? format(utcToLocal(closes), 'dd/MM/yyyy') : closes;

  const items = [
    { icon: iconPortalClock, details: employment },
    { icon: iconPortalLocation, details: location },
    { icon: iconPortalCalendar, details: `Closes ${formattedDate}` }
  ];

  const contactItems = [
    { icon: iconPortalUser, details: contact },
    { icon: iconPortalMail, details: email }
  ];

  return (
    <Card
      key={id}
      link={cardLink}
      size={size}
      type={bank}
      icon={iconPortalJob}
      title={title}
      items={items}
      actions={action(type, cardLink, applyUrl, mailTo)}
      secondaryItems={type === 'apply-email' ? contactItems : null}
    />
  );
}
