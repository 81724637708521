import axios from 'axios'
import { fork, call, put, takeLatest, select} from 'redux-saga/effects'
import { MANAGE_USERS } from '../constants'
import { 
	requestUsersSuccess, 
	requestUsersFailed,
	requestUserApproveSuccess,
	requestUserApproveFailed,
	requestUserDeleteSuccess,
	requestUserDeleteFailed
} from '../actions'
import { BASE_URL, API, PER_PAGE } from '@/config'
import { getUserToken } from '@/store/selectors'

export function* getRequestUsers(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: PER_PAGE,
        ...action.payload
      }
    });

    try {
      const response = yield call(request);
      yield put(requestUsersSuccess(response));
    } catch (err) {
      yield put(requestUsersFailed(err));
    }
}

export function* watchRequestUsers() {
  yield takeLatest(MANAGE_USERS.REQUEST_USERS, getRequestUsers);
}

export function* postRequestUserApprove(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

    try {
      const response = yield call(request);
      yield put(requestUserApproveSuccess(response));
    } catch (err) {
      yield put(requestUserApproveFailed(err));
    }
}

export function* watchRequestUserApprove() {
  yield takeLatest(MANAGE_USERS.REQUEST_USER_APPROVE, postRequestUserApprove);
}

export function* postRequestUserDelete(action) {
  const token = yield select(getUserToken);
  
  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'post',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        ...action.payload
      }
    });

    try {
      const response = yield call(request);
      yield put(requestUserDeleteSuccess(response));
    } catch (err) {
      yield put(requestUserDeleteFailed(err));
    }
}

export function* watchPostRequestUserDelete() {
  yield takeLatest(MANAGE_USERS.REQUEST_USER_DELETE, postRequestUserDelete);
}

const sagas = [
  fork(watchRequestUsers),
	fork(watchRequestUserApprove),
	fork(watchPostRequestUserDelete)
];

export default sagas;