import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import mini_logo from '@/images/mini_logo.png';
import { getHomePageKeyMetrics } from '@/selectors/homePage';

function KeyMetrics() {
  const store = useSelector((store) => store);
  const contentStore = store.contentReducer.contentData;
  const keyMetrics = useSelector(getHomePageKeyMetrics);

  return (
    <section id="key-metrics" data-testid="key-metrics">
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <div className="logo">
            <img src={mini_logo} alt="Coba logo" />
          </div>
        </div>

        <div className="metrics-wrapper">
          <div className="metric">
            <div className="heading">
              <h2>{keyMetrics.keyMetric1_name}</h2>

              {!isEmpty(contentStore) &&
                contentStore.data.map((item, index) => {
                  if (item.id == keyMetrics.keyMetric1_image) {
                    return (
                      <span key={index} className="icon">
                        <img src={item.url} alt={item.title} />
                      </span>
                    );
                  }
                })}
            </div>

            <div className="caption">{keyMetrics.keyMetric1_sub_heading}</div>
          </div>

          <div className="metric">
            <div className="heading">
              <h2>{keyMetrics.keyMetric2_name}</h2>

              {!isEmpty(contentStore) &&
                contentStore.data.map((item, index) => {
                  if (item.id == keyMetrics.keyMetric2_image) {
                    return (
                      <span key={index} className="icon">
                        <img src={item.url} alt={item.title} />
                      </span>
                    );
                  }
                })}
            </div>
            <div className="caption">{keyMetrics.keyMetric2_sub_heading}</div>
          </div>

          <div className="metric">
            <div className="heading">
              <h3>{keyMetrics.keyMetric3_name}</h3>
              {!isEmpty(contentStore) &&
                contentStore.data.map((item, index) => {
                  if (item.id == keyMetrics.keyMetric3_image) {
                    return (
                      <span key={index} className="icon">
                        <img src={item.url} alt={item.title} />
                      </span>
                    );
                  }
                })}
            </div>

            <div className="caption">{keyMetrics.keyMetric3_sub_heading}</div>
          </div>

          <div className="metric">
            <div className="heading">
              <h2>{keyMetrics.keyMetric4_name}</h2>
              {!isEmpty(contentStore) &&
                contentStore.data.map((item, index) => {
                  if (item.id == keyMetrics.keyMetric4_image) {
                    return (
                      <span key={index} className="icon">
                        <img src={item.url} alt={item.title} />
                      </span>
                    );
                  }
                })}
            </div>
            <div className="caption">{keyMetrics.keyMetric4_sub_heading}</div>
          </div>

          <div className="metric">
            <div className="heading">
              <h3>{keyMetrics.keyMetric5_name}</h3>
              {!isEmpty(contentStore) &&
                contentStore.data.map((item, index) => {
                  if (item.id == keyMetrics.keyMetric5_image) {
                    return (
                      <span key={index} className="icon">
                        <img src={item.url} alt={item.title} />
                      </span>
                    );
                  }
                })}
            </div>
            <div className="caption">{keyMetrics.keyMetric5_sub_heading}</div>
          </div>

          <div className="metric">
            <div className="heading">
              <h3>{keyMetrics.keyMetric6_name}</h3>
              {!isEmpty(contentStore) &&
                contentStore.data.map((item, index) => {
                  if (item.id == keyMetrics.keyMetric6_image) {
                    return (
                      <span key={index} className="icon">
                        <img src={item.url} alt={item.title} />
                      </span>
                    );
                  }
                })}
            </div>
            <div className="caption">{keyMetrics.keyMetric6_sub_heading}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeyMetrics;
