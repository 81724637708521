import { NEWS_ARTICLES } from '../constants';

export const requestNewsArticlesFirstPage = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE,
    payload: {
      ...payload,
      page: 1
    }
  };
};

export const requestNewsArticlesFirstPageSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE_SUCCESS,
    payload
  };
};

export const requestNewsArticlesFirstPageFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_FIRST_PAGE_FAILED,
    payload
  };
};

export const requestNewsArticlesNextPage = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE,
    payload
  };
};

export const requestNewsArticlesNextPageSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE_SUCCESS,
    payload
  };
};

export const requestNewsArticlesNextPageFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLES_NEXT_PAGE_FAILED,
    payload
  };
};

export const requestNewsArticlesBySlug = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG,
    payload
  };
};

export const requestNewsArticlesBySlugSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG_SUCCESS,
    payload
  };
};

export const requestNewsArticlesBySlugFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_SLUG_FAILED,
    payload
  };
};

// article by bulletin
export const requestNewsArticleByBulletin = (payload) => {
    return {
        type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID,
        payload
    };
};
export const requestNewsArticleByBulletinSuccess = payload => ({
    type:  NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_SUCCESS,
    payload
});

export const requestNewsArticleByBulletinFailed = payload => ({
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_FAILED,
    payload
});

export const requestNewsArticlesByBulletinId = (payload) => {
    return {
        type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID,
        payload
    };
};


export const requestNewsArticlesByBulletinIdSuccess = (payload) => {
    return {
        type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_SUCCESS,
        payload
    };
};


export const requestNewsArticlesByBulletinIdFailed = (payload) => {
    return {
        type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_BULLETIN_ID_FAILED,
        payload
    };
};


export const requestNewsArticlesById = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID,
    payload
  };
};

export const requestNewsArticlesByIdSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID_SUCCESS,
    payload
  };
};

export const requestNewsArticlesByIdFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_ARTICLE_BY_ID_FAILED,
    payload
  };
};

export const requestNewsTopicsFilters = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_TOPICS,
    payload
  };
};

export const requestNewsTopicsFiltersSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_TOPICS_SUCCESS,
    payload
  };
};

export const requestNewsTopicsFiltersFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_NEWS_TOPICS_FAILED,
    payload
  };
};

export const requestRegulatorFilters = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_REGULATORS,
    payload
  };
};

export const requestRegulatorFiltersSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_REGULATORS_SUCCESS,
    payload
  };
};

export const requestRegulatorFiltersFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_REGULATORS_FAILED,
    payload
  };
};

export const requestPolicyStageFilters = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_POLICY_STAGE,
    payload
  };
};

export const requestPolicyStageFiltersSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_POLICY_STAGE_SUCCESS,
    payload
  };
};

export const requestPolicyStageFiltersFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.REQUEST_POLICY_STAGE_FAILED,
    payload
  };
};

export const selectNewsTopics = (payload) => {
  return {
    type: NEWS_ARTICLES.SELECT_NEWS_TOPICS,
    payload
  };
};

export const clearFilters = () => {
  return {
    type: NEWS_ARTICLES.CLEAR_FILTERS
  };
};

export const submitFeedback = (payload) => {
  return {
    type: NEWS_ARTICLES.SUBMIT_FEEDBACK,
    payload
  };
};

export const submitFeedbackSuccess = (payload) => {
  return {
    type: NEWS_ARTICLES.SUBMIT_FEEDBACK_SUCCESS,
    payload
  };
};

export const submitFeedbackFailed = (payload) => {
  return {
    type: NEWS_ARTICLES.SUBMIT_FEEDBACK_FAILED,
    payload
  };
};
