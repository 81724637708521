import { CONTENT } from '../constants'

const requestContent = () => ({
    type: CONTENT.REQUEST_CONTENT
})

const receivedContent = (payload) => ({
    type: CONTENT.RECEIVED_CONTENT,
    payload
})

const failedContent = (payload) => ({
    type: CONTENT.FAILED_CONTENT,
    payload
})

export {
    requestContent, receivedContent, failedContent
}