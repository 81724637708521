import React from 'react';
import {useParams} from 'react-router';
import {iconPortalCopyFile} from '@/images/icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function ExportCard({articleRef}) {
    const params = useParams();

    const options = {
        orientation: 'p',
        unit: 'pt',
        format: [595.28, 841.89],
        putOnlyUsedFonts: true,
        floatPrecision: 1,
    };

    const toPdf = () => {
        const targetComponent = articleRef.current;
        if (!targetComponent) {
            throw new Error('Target ref must be used or informed');
        }
        html2canvas(targetComponent, {
            logging: false,
            useCORS: true,
            scale: 1
        }).then((canvas) => {
            const imgWidth = 660;
            const pageHeight = 1300;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF(options);
            let position = 0;

            const img = canvas.toDataURL('image/jpeg');

            doc.addImage(img, 'JPEG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(img, 'JPEG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            doc.save(params.slug);
        });
    };

    return (
        <div className="action-card">
            <div className="layout">
                <span className="icon gradient outline">{iconPortalCopyFile}</span>

                <div className="content">
                    <div className="wrapper">
                        <span className="title">Export page as PDF</span>
                    </div>
                    <a className="link" onClick={toPdf} download>
                        Export
                    </a>
                </div>
            </div>
        </div>
    );
}
