import React, { Fragment } from 'react';

const getVideoId = (url, param) => {
  var query = url.split('?')[1];
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == param) {
      return decodeURIComponent(pair[1]);
    }
  }
};

const VideoContainer = ({ children }) => {
  return (
    <div className="article-video embed-responsive embed-responsive-16by9">
      {children}
    </div>
  );
};

const YoutubeEmbed = ({ video_id }) => {
  return (
    <Fragment>
      {video_id && (
        <iframe
          className="embed-responsive-item"
          src={`https://www.youtube.com/embed/${video_id}?rel=0`}
        ></iframe>
      )}
    </Fragment>
  );
};

const VideoEmbed = ({ video_url }) => {
  const isYoutube = video_url.indexOf('youtube');
  const videoSrc = isYoutube > -1 ? getVideoId(video_url, 'v') : null;

  return (
    <Fragment>
      {videoSrc && (
        <VideoContainer>
          <YoutubeEmbed video_id={videoSrc} />
        </VideoContainer>
      )}
    </Fragment>
  );
};

export default VideoEmbed;
