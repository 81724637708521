import React from 'react';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { PORTAL_PATHS } from '@/config';
import { iconSearch } from '@/images/icons';
import { TextField } from '@/components';

export default function GlobalSearchMobile() {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = React.useState('');

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit'
  });

  const onClickSearch = (ev) => {
    ev.preventDefault();
    history.push({
      pathname: PORTAL_PATHS.GLOABL_SEARCH,
      search: `?query=${searchQuery}`
    });
  };

  const onChangeSearchInput = (ev) => {
    ev.preventDefault();
    setSearchQuery(ev.target.value);
  };

  return (
    <div className="global-search-bar">
      <div className="searchbar portal-search mobile">

          <FormProvider {...form}>
            <form className="searchbar-container" noValidate>
              <TextField
                id="search"
                name="globalSearchMobile"
                control={form.control}
                onChangeField={onChangeSearchInput}
                placeholder="Search"
              />
              <button className="btn-round" onClick={onClickSearch}>
                <span className="icon">{iconSearch}</span>
              </button>

            </form>
          </FormProvider>
        </div>
    </div>
  );
}
