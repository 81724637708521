import { RATE_INFO } from '../constants';

export const requestRateInfo = (payload) => {
  return {
    type: RATE_INFO.REQUEST_RATE_INFO,
    payload
  };
};

export const requestRateInfoSuccess = (payload) => {
  return {
    type: RATE_INFO.REQUEST_RATE_INFO_SUCCESS,
    payload
  };
};

export const requestRateInfoFailed = (payload) => {
  return {
    type: RATE_INFO.REQUEST_RATE_INFO_FAILED,
    payload
  };
};
