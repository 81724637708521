import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PortalPageTitle, PortalLayout } from '@/components/Portal';
import {
  AccountDetails,
  Branch,
  LoginDetails,
  ManageUsers,
  MyAccountMenu,
  Subscriptions,
  UpdateDivisionDetails
} from '@/components/Portal/MyAccount';
import { PORTAL_PATHS } from '@/config';

export default function MyAccount() {
  return (
    <PortalLayout>
      <PortalPageTitle
        heading={'My Account'}
        description="Update your account details here"
      />
      <MyAccountMenu mobile />

      <div className="container v-pad">
        <div className="row">
          <MyAccountMenu desktop />

          <div className="col-xs-12 col-md-8">
            <Switch>
              <Route exact path={PORTAL_PATHS.myAccount.index}>
                <AccountDetails />
              </Route>

              <Route exact path={PORTAL_PATHS.myAccount.settings.index}>
                <AccountDetails />
              </Route>

              <Route exact path={PORTAL_PATHS.myAccount.settings.details}>
                <AccountDetails />
              </Route>

              <Route exact path={PORTAL_PATHS.myAccount.settings.loginDetails}>
                <LoginDetails />
              </Route>

              <Route exact path={PORTAL_PATHS.myAccount.institutionBranchDetails.index}>
                <UpdateDivisionDetails />
              </Route>

              <Route
                exact
                path={PORTAL_PATHS.myAccount.institutionBranchDetails.institutionDetails}
              >
                <UpdateDivisionDetails />
              </Route>

              <Route
                exact
                path={PORTAL_PATHS.myAccount.institutionBranchDetails.branchDetails}
              >
                <Branch />
              </Route>

              <Route exact path={PORTAL_PATHS.myAccount.manageUsers}>
                <ManageUsers />
              </Route>

              <Route exact path={PORTAL_PATHS.myAccount.emailSubscription}>
                <Subscriptions />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </PortalLayout>
  );
}
