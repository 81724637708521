import React from 'react';
import { iconQuote } from '@/images/icons';

export default function CarouselQuoteHeader({ heading }) {
  return (
    <div className="heading">
      <span className="quote-icon">{iconQuote}</span>
      <div
        className="h2"
        dangerouslySetInnerHTML={{
          __html: heading
        }}
      />
    </div>
  );
}
