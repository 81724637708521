import React from 'react';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import usePrevious from '@react-hook/previous';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import { validationRegex } from '@/utils';
// Store
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { registerUser, submitStep, clearForm } from '@/store/actions';
import {
  getCreateAccountForm,
  getUser,
  getUserError,
  getUserIsRegistered
} from '@/store/selectors';
// Components
import { iconArrowThin, iconTick } from '@/images/icons';
import {
  Button,
  Checkbox,
  PasswordField,
  Recaptcha,
  TextField
} from '@/components';
import { ErrorTags } from '@/components/Portal';
import { FormBackButton } from '@/components/Portal/Onboarding';
import OnboardingFormTransition from './OnboardingFormTransition';
import CreateAccountFormHeader from './CreateAccountFormHeader';
import { PORTAL_PATHS } from '@/config';

export default function CreateAccountFormStep3() {
  const history = useHistory();
  const formData = useSelector(getCreateAccountForm);
  const user = useSelector(getUser, shallowEqual);
  const isUserRegistered = useSelector(getUserIsRegistered);
  const error = useSelector(getUserError);

  const [recaptchaToken, setRecaptchaToken] = React.useState(undefined);
  const previousRecaptchaToken = usePrevious(recaptchaToken);
  const [minLettersHintOK, setMinLettersHintOK] = React.useState(false);
  const [capitalLetterHintOK, setCapitalLetterHintOK] = React.useState(false);
  const [specialCharHintOK, setSpecialCharHintOK] = React.useState(false);

  const actions = bindActionCreators(
    {
      submitStep,
      clearForm,
      registerUser
    },
    useDispatch()
  );

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  const watchNewPassword = form.watch('newPassword');
  const watchConfirmPassword = form.watch('confirmPassword');

  const onSubmitForm = (data) => {
    const registrationFormData = {
      ...formData,
      ...data
    };

    const registerUserPayload = {
      email: registrationFormData.email,
      password: registrationFormData.newPassword,
      password_confirmation: registrationFormData.confirmPassword,
      terms_conditions_agree: Number(Boolean(registrationFormData.agreeToTerms)),
      first_name: registrationFormData.firstName,
      last_name: registrationFormData.lastName,
      contact_number: registrationFormData.tel,
      institution_id: registrationFormData.companyId.id,
      institution_title: registrationFormData.positionTitle,
      institution_department: registrationFormData.department
    };

    actions.submitStep(
      {
        email: data.email,
        agreeToTerms: data.agreeToTerms
      },
      3
    );
    actions.registerUser(registerUserPayload);
  };

  const onVerifyCaptcha = (token) => {
    form.setValue('recaptcha', token);
    setRecaptchaToken(token);
  };

  const onChangePassword = () => {
    const newPasswordError = form?.errors?.newPassword;

    if (!!newPasswordError) {
      form.trigger('newPassword');
    }
  };

  const onChangeConfirmPassword = () => {
    const confirmPasswordError = form?.errors?.confirmPassword;
    const newPasswordError = form?.errors?.newPassword;
    const newPasswordMatchError =
      newPasswordError?.message === 'Password fields do not match.';

    if (!!confirmPasswordError) {
      form.trigger('confirmPassword');
    }

    if (
      newPasswordMatchError &&
      !confirmPasswordError &&
      watchNewPassword === watchConfirmPassword
    ) {
      form.clearErrors('newPassword');
      form.clearErrors('confirmPassword');
    }
  };

  const passwordValidation = (value) => {
    const SPECIAL_CHAR_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const hasUppercase = value !== value.toLowerCase();
    const hasSpecialChar = SPECIAL_CHAR_REGEX.test(value);

    if (!value) {
      return 'This field is required.';
    }

    if (value.length < 6) {
      setMinLettersHintOK(false);
      return 'This field must have at least 6 characters.';
    }

    if (value.length >= 6) {
      setMinLettersHintOK(true);
    }

    if (!hasUppercase) {
      return 'This field must have at least 1 capital letter.';
    }

    if (hasUppercase) {
      setCapitalLetterHintOK(true);
    }

    if (!hasSpecialChar) {
      return 'This field must have at least 1 special character.';
    }

    if (hasSpecialChar) {
      setSpecialCharHintOK(true);
    }

    if (
      !!watchNewPassword &&
      !!watchConfirmPassword &&
      watchNewPassword !== watchConfirmPassword
    ) {
      return 'Password fields do not match.';
    }

    return true;
  };

  React.useEffect(() => {
    form.register('recaptcha', {
      required: {
        value: true,
        message: 'Please verify that you are not a robot.'
      }
    });
  }, []);

  React.useEffect(() => {
    if (!!previousRecaptchaToken && !recaptchaToken) {
      form.trigger('recaptcha');
    }
  }, [recaptchaToken]);

  React.useEffect(() => {
    if (!isEmpty(user) && isUserRegistered) {
      actions.clearForm();
      history.push(PORTAL_PATHS.onboarding.createAccount.accountCreated);
    }
  }, [isUserRegistered]);

  const minLettersHintClasses = minLettersHintOK
    ? 'password-hint min-length valid'
    : 'password-hint min-length';

  const capitalLetterHintClasses = capitalLetterHintOK
    ? 'password-hint capital-letter valid'
    : 'password-hint capital-letter';

  const specialCharHintClasses = specialCharHintOK
    ? 'password-hint special-char valid'
    : 'password-hint special-char';

  return (
    <OnboardingFormTransition formPath={PORTAL_PATHS.onboarding.createAccount.step(3)}>
      <CreateAccountFormHeader stepIndex={3} formName="Password" />

      <div className="create-account-form step-3">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmitForm)} noValidate>
            <TextField
              id="member-email"
              type="email"
              name="email"
              labelFor="Email"
              labelText="Email"
              placeholder="Email"
              validation={{
                required: {
                  value: true,
                  message: 'This field is required.'
                },
                pattern: {
                  value: validationRegex.email,
                  message: 'Please enter a valid e-mail address.'
                }
              }}
              defaultValue={formData.email}
              control={form.control}
            />

            <PasswordField
              id="new-password"
              labelFor="new-password"
              labelText="Password"
              placeholder="Password"
              name="newPassword"
              onChange={onChangePassword}
              validation={{
                validate: passwordValidation
              }}
              control={form.control}
            />

            <PasswordField
              labelFor="confirm-password"
              labelText="Verify Password"
              placeholder="Verify Password"
              name="confirmPassword"
              onChange={onChangeConfirmPassword}
              validation={{
                validate: passwordValidation
              }}
              control={form.control}
            />

            <div className="password-hints">
              <p>Your password needs to include</p>

              <div className={minLettersHintClasses}>
                <span className="icon icon-tick">{iconTick}</span>
                <p>At least 6 letters</p>
              </div>

              <div className={capitalLetterHintClasses}>
                <span className="icon icon-tick">{iconTick}</span>
                <p>At least 1 capital letter</p>
              </div>

              <div className={specialCharHintClasses}>
                <span className="icon icon-tick">{iconTick}</span>
                <p>At least 1 special character</p>
              </div>
            </div>

            <br />

            <Recaptcha
              onVerifyCaptcha={onVerifyCaptcha}
              errorMsg={form?.errors?.recaptcha?.message}
            />

            <br />

            <Checkbox
              id="checkbox-terms-and-conditions"
              name="agreeToTerms"
              value={formData.agreeToTerms}
              validation={{
                required: {
                  message: 'You must agree to terms and conditions to create an account.'
                }
              }}
            >
              <span className="tnc-permission">
                I agree to COBA's{' '}
                <Link className="tnc-link" to="/">
                  Terms and Conditions
                </Link>
              </span>
            </Checkbox>

            <br />

            {error && error.message == 'The given data was invalid.' && (
              <>
                <div className="row errors">
                  <ErrorTags errors={error.errors}/>
                </div>
                <br />
              </>
            )}

            <div className="row">
              <div className="btn-back col-xs-6">
                <FormBackButton path={PORTAL_PATHS.onboarding.createAccount.step(2)} />
              </div>

              <div className="btn-next col-xs-6">
                <Button
                  variant={'btn-fill btn-full-width'}
                  icon={iconArrowThin}
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </OnboardingFormTransition>
  );
}
