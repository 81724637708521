import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';


const AnchorLink = ({href, children}) => {
	const isExternalLink = href.charAt(0) !== '/';

	return (
	<>
		{
			isExternalLink ? <a href={href} target='_blank' rel='noreferrer noopener'>
				{children}
			</a> : <Link to={href}>{children}</Link>
		}
	</>
	)
}

const Heading = ({ title, link, children }) => {

	const heading =
		children
			? <div className="with-children-container">
				<div className="heading h4">{link ? <AnchorLink href={link}>{title}</AnchorLink> : title}</div>
				{children}
			</div>
			: <div className="heading h4">{link ? <AnchorLink href={link}>{title}</AnchorLink> : title}</div>

	return heading;
}

const Title = ({
	icon,
	subHeading,
	title,
	link,
	children
}) => {
	return (
		<div className="title__container">
			{icon && <span className="icon gradient outline">{icon}</span>}
			<div className="title">
				{subHeading && <div className="heading h5">{subHeading}</div>}
				<Heading title={title} link={link}>{children}</Heading>
			</div>
		</div>
	)
}

Title.propTypes = {
	icon: PropTypes.any,
	subHeading: PropTypes.string,
	title: PropTypes.string,
	link: PropTypes.string
};

export default Title