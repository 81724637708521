import React from 'react';
import { useDispatch } from 'react-redux';
// Store
import { trainingCartItemRemove } from '@/store/actions';
// Utils
import { PORTAL_PATHS } from '@/config';
// Components
import { iconPortalLayers } from '@/images/icons';
import { Title } from '@/components';

const CartItem = ({ id, heading, title, price }) => {
  const dispatch = useDispatch();
  price = !price || isNaN(price) ? price : '$' + price.toLocaleString();

  const onClickRemove = (ev) => {
    ev.preventDefault();
    dispatch(trainingCartItemRemove({ id: id }));
  };

  return (
    <div className="cart-item">
      <Title 
        icon={iconPortalLayers} 
        subHeading={heading} 
        title={title} 
        link={`${PORTAL_PATHS.TRAINING}/module/${id}`}
      />
      <a className="link action" onClick={onClickRemove}>
        Remove
      </a>
      <div className="text-regular price">{price}</div>
    </div>
  );
};

export default CartItem;
