import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import { Title, ActionCard } from '@/components/';
import { iconCloseSmall, iconPortalDownload } from '@/images/icons';

const DownloadModal = ({
  showModal,
  onClose,
  icon,
  subHeading,
  title,
  installation,
  downloads
}) => {
  DownloadModal.propTypes = {
    showModal: PropTypes.bool,
    icon: PropTypes.node,
    subHeading: PropTypes.string,
    title: PropTypes.string,
    installation: PropTypes.array,
    downloads: PropTypes.array
  };

  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={onClose}
      contentLabel="Download Training Resources"
      className="portal-modal"
      overlayClassName="portal-overlay"
    >
      <div className="close">
        <div className="icon" onClick={onClose}>
          {iconCloseSmall}
        </div>
      </div>

      <div className="modal-card training-download min-h-max h-full overflow-auto">
        <Title icon={icon} subHeading={subHeading} title={title} />

        {installation.map(({ installation_instructions }, index) => (
          <div className="modal-section" key={index}>
            {!!installation_instructions && (
              <>
                <p className="small bold">
                  1. Download and read the instructions on how to use your training
                  modules
                </p>
                <div className="card-collection">
                  <ActionCard
                    key={index}
                    action="download"
                    title={installation_instructions.title}
                    value={installation_instructions?.size || ''}
                    link={installation_instructions.url}
                  />
                </div>
              </>
            )}
          </div>
        ))}

        <div className="modal-section w-full">
          <p className="small bold">2. Download your module</p>
          <div className="card-collection">
            {downloads.map((download, index) => (
              <ActionCard
                key={index}
                action="download"
                title={`${index + 1}. ${download.download_title} | ${
                  download?.lms_version ? 'LMS version' : 'WEB version'
                }`}
                value={download?.download_file?.size || ''}
                link={download?.download_file?.url || ''}
                icon={iconPortalDownload}
              />
            ))}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default DownloadModal;
