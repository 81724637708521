import axios from 'axios';
import { fork, call, put, takeLatest, select} from 'redux-saga/effects';
import { NAV } from '../constants';
import { 
  requestQuicklinksSuccess,
  requestQuicklinksFailed,
} from '../actions';
import { BASE_URL, API } from '@/config';
import { getUserToken } from '@/store/selectors';

export function* getQuickLinks(action) {
  const token = yield select(getUserToken);

  const request = () =>
    axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'get',
      url: `${BASE_URL}${API[action.type]}`,
      params: {
        per_page: 6,
        ...action.payload
      }
    });

    try {
      const response = yield call(request);
      yield put(requestQuicklinksSuccess(response));
    } catch (err) {
      yield put(requestQuicklinksFailed(err));
    }
}

export function* watchGetQuickLinks() {
  yield takeLatest(NAV.REQUEST_QUICKLINKS, getQuickLinks);
}

const sagas = [
  fork(watchGetQuickLinks),
];

export default sagas;