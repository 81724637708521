import PropTypes from 'prop-types';
import React from 'react';
import{ PortalPageMenuNavigation } from '@/components/Portal';
import { PORTAL_PATHS } from '@/config';

VacanciesMenu.propTypes = {
	mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default function VacanciesMenu ({mobile, desktop}) {

  const menuItems = [
    {
      title: 'View Vacancies',
      url: PORTAL_PATHS.resources.vacancies.view,
    },
    {
      title: 'List Vacancy',
      url: PORTAL_PATHS.resources.vacancies.list,
    },
    {
      title: 'Support',
      url: PORTAL_PATHS.resources.vacancies.support,
    }
  ];

	return (
    <PortalPageMenuNavigation
      mobile={mobile}
      desktop={desktop}
      items={menuItems}
      title="Recruitment"
    />
	);
};