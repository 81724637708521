import PropTypes from 'prop-types';
import React from 'react'
import useMedia from 'use-media';
import { Tag } from '@/components/Portal'

PortalPageMenuItem.propTypes = {
	displayItemMobile: PropTypes.bool,
	displayItemDesktop: PropTypes.bool,
	title: PropTypes.any,
	mobileToggleName: PropTypes.string,
	children: PropTypes.node
}

export default function PortalPageMenuItem({
	displayItemMobile=false, 
	displayItemDesktop=false, 
	title,
	children
}) {
	const isMobile = useMedia('(max-width: 1023px)');
  const isDesktop = useMedia('(min-width: 1024px)');

	// Render if Mobile or Desktop
	if ( (isDesktop && displayItemMobile) || (isMobile && displayItemDesktop)) {
		return null;
	}

  return (
		<div className="portal-page-menu__item">	
			{title && <Tag type='title voyage' label={title} /> }
			<div className="portal-page-menu__item-content">
				{children}
			</div>
		</div>
	)
}