import {
  CreateAccount,
  FormTypeSelector,
  MemberWelcome,
  MemberWelcomeFooter,
  SignIn
} from '@/components/Portal/Onboarding';
import { PORTAL_PATHS } from '@/config';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useMedia from 'use-media';

export default function Onboarding() {
  const isMobile = useMedia('(max-width: 1023px)');

  return (
    <div className="portal row no-margin">
      <MemberWelcome />

      <div className="onboarding-form col-xs-12 col-md-5 col-md-offset-7">
        <div className="container">
          <FormTypeSelector />
        </div>

        <div className="onboarding-form-wrapper">
          <div className="container">
            <Switch>
              <Route component={CreateAccount} path={PORTAL_PATHS.onboarding.createAccount.index} />
              <Route component={SignIn} path={PORTAL_PATHS.onboarding.index} />
            </Switch>
          </div>
        </div>

        {isMobile && <MemberWelcomeFooter />}
      </div>
    </div>
  );
}
