import PropTypes from 'prop-types';
import React from 'react';
import MiniLogo from '@/components/MiniLogo';
import Accordion from './Accordion';

BoardPageSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  accordionData: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
      small: PropTypes.string,
      blurb: PropTypes.string
    })
  ),
  initialIndex: PropTypes.number
};

export default function BoardPageSection({
  title,
  description,
  accordionData,
  initialIndex
}) {
  return (
    <div className="content-wrapper two-col">
      <div className="col board-section-info">
        {title && (
          <h2>
            <MiniLogo />
            {title}
          </h2>
        )}
        {description && <p className="description">{description}</p>}
      </div>
      <div className="col">
        <div className="accordion">
          {accordionData &&
            accordionData.map((item, index) => {
              const newIndex = index + (initialIndex || 0);
              return <Accordion item={item} key={newIndex} />;
            })}
        </div>
      </div>
    </div>
  );
}
