import { ARTICLES } from '../constants'

//initial state
const initialState = {
    isLoading: false,
    generalArticlesData: {},
    currentPage: 1
}

//Reducer
const generalArticlesReducer = (state = initialState, action = {}) => {

    switch(action.type) {
        case ARTICLES.REQUEST_GENERAL_ARTICLES:
            return {...state, isLoading:true, currentPage: action.payload}

        case ARTICLES.RECEIVED_GENERAL_ARTICLES:
            return {...state, isLoading:false, generalArticlesData: action.payload}

        case ARTICLES.FAILED_GENERAL_ARTICLES:
            return {...state, isLoading:false, generalArticlesData: action.payload}
    }

    return state
}

export default generalArticlesReducer
