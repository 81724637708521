import { createSelector } from 'reselect';
import { toTitleCase } from '@/utils';

export const getStaticPageState = (state) => state.pageReducer;
export const getStaticPageErrors = (state) => state.pageReducer.errors;
export const getStaticPageLoading = (state) => state.pageReducer.isLoading;
export const getStaticPageMenu = (state) => state.pageReducer.menu;
const getPagesList = (state) => state.pageReducer.list;

const PATHMEMBERPORTAL = '/member-portal'

export const selectPagesPublic = createSelector(getPagesList, (pages) => {
  const locale = 'en';
  return pages.filter(page => page.locales.includes(locale) && page.path.en !== PATHMEMBERPORTAL);
});

export const selectPagesMemeberPortal = createSelector(getPagesList, (pages) => {
  const locale = 'en';

  for (let index = 0; index < pages.length; index++) {
    const page = pages[index];
    if (page.locales.includes(locale) && page.path[locale] === PATHMEMBERPORTAL) {
      return page.children;
    }    
  }

  return [];
});


export const selectMemberPortalMenuNav = createSelector (selectPagesMemeberPortal, (pages) => {
  const locale = 'en';
  const menuSlugs = ['newsroom', 'about', 'resources-and-services', 'events'];

  return menuSlugs.reduce((nav, slug, index) => {
    const pageItem = pages.find(page => page.locales.includes(locale) && page.path[locale] === `${PATHMEMBERPORTAL}/${slug}`);
    
    let menutItem = {
      id: pageItem?.id[locale] || -index,
      title:  pageItem?.name[locale] || toTitleCase(slug),
      slug: pageItem?.slug[locale] || slug
    }

    if (pageItem?.children) {
      menutItem.subMenus = pageItem.children.map((subpage) => ({
        id: subpage?.id[locale],
        title:  subpage?.name[locale],
        path: subpage?.path[locale],
        slug: subpage?.slug[locale] || slug
      }));
    }else{
      menutItem.path = pageItem?.path[locale] || `${PATHMEMBERPORTAL}/${slug}`;
    }

    nav[slug] = menutItem;
    return nav;
  }, {});
  
});


export const selectStaticPage = createSelector(getStaticPageState, (state) => ({
  loading: state.isLoading,
  errors: state.errors,
  menu: state.menu,
  title: state.staticPageData?.data?.menu || '',
  description: state.staticPageData?.data?.description || '',
  content: state.staticPageData.data?.page || []
}));

