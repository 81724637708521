import React from 'react';
import { useLocation } from 'react-router-dom';
import { Motion } from '@/components';
import { PortalBannerText } from '@/components/Portal';
import { PORTAL_PATHS } from '@/config';

export default function NewMemberWelcomeMessage() {
  const { pathname } = useLocation();

  return (
    <Motion
      animate={
        pathname.includes(PORTAL_PATHS.onboarding.createAccount.index) ? 'in' : 'out'
      }
    >
      <PortalBannerText
        subheading="Are you a "
        heading={'New Member?'}
        text="<p>
            Access and use of this member portal is for board members, management and
            staff of the members and customers of the Customer Owned Banking Association
            only.
          </p>
          <p>
            Approval for all new user sign-up requests will be sought from the nominated
            organisation.
          </p>"
      />
    </Motion>
  );
}
