import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { requestCEONewsArticleLatest } from '@/store/actions'
import { selectCEONewsArticleLatest } from '@/store/selectors'
import { format } from 'date-fns';
import { utcToLocal } from '@/utils';
import { iconArrowThin } from '@/images/icons';
import { MiniLogo, Tag } from '@/components';

export default function CEOdesk() {

  const dispatch = useDispatch();
	const { 
		key_contact_name,
		profile_image_data,
		date_published,
		extract,
		slug
	} = useSelector(selectCEONewsArticleLatest)

  useEffect(() => { dispatch(requestCEONewsArticleLatest()) }, [])

  return (
    <div className='portal-home ceo-desk'>
        <div className='container'>
          <div className='row start-md'>
            <div className='ceo-info col-xs-12 col-sm-4'>
              <MiniLogo width='45px' />

              <div className='attribution'>
                <div className='h-wrapper'>
                  <h5 className='H5'>From the CEO’s Desk</h5>
                  <h3 className='H3'>{key_contact_name}</h3>
                </div>
                <div className="staff-image">
									{	!!profile_image_data
										?	<img src={profile_image_data.url} 
												alt={`CEO ${key_contact_name}`}
											/>
										: <div className='placeholder'> <MiniLogo /> </div>
									}
                </div>
              </div>
            </div>

          <div className='article-excerpt col-xs-12 col-sm-7'>
						{ !!date_published && 
							<Tag label={format(utcToLocal(date_published), 'd LLLL yyyy')}/>
						}
						{ !!extract &&
							<div className='excerpt'
								dangerouslySetInnerHTML={{
									__html: extract && extract
								}}
							/>
						}
						{ !!slug &&
							<Link className='btn btn-fill' to={`/ceos-desk/${slug}`}>
								Read More <span className='icon midnight'>{iconArrowThin}</span>
							</Link>
						}
					 </div>

        </div>
      </div>
    </div>
  );
}
