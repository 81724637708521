import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { iconCrossThin } from '@/images/icons';
import { requestPortalAnnouncement } from '@/store/actions';
import {
    selectAnnouncementContent,
    selectAnnouncementActive
} from '@/store/selectors/portalAnnouncement';
import useMedia from 'use-media';

function getSessionStorageItem(key, defaultValue) {
    const sessionItem = sessionStorage.getItem(key);
    return !sessionItem ? defaultValue : JSON.parse(sessionItem);
}

export default function AnnouncementBar() {
    const dispatch = useDispatch();
    const isDesktop = useMedia('(min-width: 1024px)');

    const isActive = useSelector(selectAnnouncementActive);
    const announcementContent = useSelector(selectAnnouncementContent);
    const [showAnnouncement, setShowAnnouncement] = React.useState(
        getSessionStorageItem('showPortalAnnouncement', true)
    );
    const onClickHideAnnouncement = () => {
        setShowAnnouncement(false);
    };
    React.useEffect(() => {
        dispatch(requestPortalAnnouncement());
    }, []);

    React.useEffect(() => {
        sessionStorage.setItem('showPortalAnnouncement', JSON.stringify(showAnnouncement));
        let element = document.getElementsByTagName('main')[0];

        if (showAnnouncement && isActive && !!announcementContent) {
            element.style.marginTop = isDesktop ? '143px' : '136px';
        } else {
            element.style.marginTop = isDesktop ? '80px' : '75px';
        }
    }, [showAnnouncement, isActive, announcementContent, isDesktop]);

    if (showAnnouncement && isActive && !!announcementContent) {
        return (
            <div className="announcement-bar">
                <div className="content-wrapper">
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{
                            __html: announcementContent
                        }}
                    />

                    <span className="icon menu-icon" onClick={onClickHideAnnouncement}>
            {iconCrossThin}
          </span>
                </div>
            </div>
        );
    }

    return null;
}
