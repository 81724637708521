import { call, put, takeLatest } from 'redux-saga/effects';
import { ARTICLES } from '../constants';
import { receivedCampaigns, failedCampaigns } from '../actions';
import rootDomain from '../rootDomain';
const api = url => fetch(url, {}).then(res => res.json());

export function* requestCampaignsAsync(action) {
  const ROOT = rootDomain();
  const campaignsUrl = `${ROOT}/api/v1/articles/type?per_page=12&type_id=4&page=${action.payload}`;

  try {
    const response = yield call(api, campaignsUrl);
    yield put(receivedCampaigns(response));
  } catch (err) {
    yield put(failedCampaigns(err));
  }
}

export default function* watchRequestCampaigns() {
  yield takeLatest(ARTICLES.REQUEST_CAMPAIGNS, requestCampaignsAsync);
}
