import { HOME_PAGE } from '../constants'
// Home Page
const requestHomepage = () => ({
    type: HOME_PAGE.REQUEST_HOME_PAGE
})

const requestHomepageSuccess = (payload) => ({
    type: HOME_PAGE.REQUEST_HOME_PAGE_SUCCESS,
    payload
})

const requestHomepageFailed = (payload) => ({
    type: HOME_PAGE.REQUEST_HOME_PAGE_FAILED,
    payload
})

export {
    requestHomepage, 
    requestHomepageSuccess, 
    requestHomepageFailed
}