import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar({ variant, attribution, links }) {
  return (
    <div className={`navbar ${variant}`}>
      <div className="content-wrapper">
        <div className="attribution">{attribution}</div>

        {!!links && links.length && (
          <nav className="navbar-nav">
            <ul>
              {links.map(({ url, text, isExternal, onClickItem }, i) => (
                <li key={`text-${i}`}>
                  {isExternal ? <a href={url} target='_blank' rel='noreferrer' >{text}</a> :
                    <Link to={url} onClick={onClickItem}>
                      {text}
                    </Link>
                  }
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
}
