import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
// Store
import { useDispatch } from 'react-redux';
import { requestPagesList } from '@/store/actions';

import PortalRoutes from './PortalRoutes';

export default function AppRoutes() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestPagesList());
  }, []);

  return (
    <Switch>
      <PortalRoutes />
    </Switch>
  );
}
