import PropTypes from 'prop-types';
import React from 'react';
import { useDebouncedFn } from '@artifak/usedebouncedfn';
import { useForm, FormProvider } from 'react-hook-form';
import { iconSearch, iconCloseSmall } from '@/images/icons';
import { Motion, TextField } from '@/components';

Searchbar.propTypes = {
  defaultValue: PropTypes.string,
  actions: PropTypes.object,
  placeholder: PropTypes.string,
  totalSearchResults: PropTypes.number,
  isSearching: PropTypes.bool,
  searchFieldName: PropTypes.string,
  searchFieldLabel: PropTypes.string
};

export default function Searchbar({
  defaultValue = '',
  actions,
  placeholder = 'Search',
  totalSearchResults,
  isSearching = false,
  searchFieldName = 'search',
  searchFieldLabel = 'Search'
}) {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searching, setSearching] = React.useState(false);

  const debouncedGetSearchResults = useDebouncedFn(
    (payload) => actions.request.dispatch({ ...payload }),
    500
  );

  const form = useForm({
    mode: 'onChange'
  });

  const onSubmitSearch = () => {
    return false;
  };

  const onChangeSearchInput = (ev) => {
    ev.preventDefault();
    setSearchQuery(ev.target.value);
  };

  const onClickClearSearch = () => {
    setSearchQuery('');
    form.setValue(searchFieldName, '');

    if (!!actions?.clear?.dispatch) {
      actions.clear.dispatch(actions.clear.payload);
    }
  };

  React.useEffect(() => {
    if (!!defaultValue) {
      setSearchQuery(defaultValue);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    if (searchQuery) {
      setSearching(true);

      debouncedGetSearchResults({
        ...actions.request.payload,
        [actions.request.searchParamKey]: searchQuery
      });
    }
  }, [searchQuery]);

  React.useEffect(() => {
    setSearching(isSearching);
  }, [isSearching]);

  const totalResults = totalSearchResults > 100 ? '100+' : totalSearchResults;

  return (
    <div className="searchbar">
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmitSearch)}>
          <div className="searchbar-container">
            <TextField
              id={searchFieldName}
              name={searchFieldName}
              type="search"
              labelFor={searchFieldName}
              labelText={searchFieldLabel}
              placeholder={placeholder}
              onChangeField={onChangeSearchInput}
              defaultValue={defaultValue}
            />

            <button className="btn-round" onClick={onClickClearSearch} type="button">
              <Motion animate={!searchQuery}>
                <span className="icon">{iconSearch}</span>
              </Motion>
              <Motion animate={!!searchQuery}>
                <span className="icon">{iconCloseSmall}</span>
              </Motion>
            </button>
          </div>
        </form>
      </FormProvider>

      {searching && !!searchQuery && <p className="results small">Searching...</p>}

      {!searching && !!searchQuery && (
        <p className="results small">
          <span className="hightlight text-small">Showing {totalResults}</span> results
          for <span className="hightlight text-small">&quot;{searchQuery}&quot;</span>
        </p>
      )}
    </div>
  );
}
