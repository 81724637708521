import React from 'react';
import { iconMenu, iconCrossThin } from '@/images/icons';

export default function PortalNavMobileToggle({ onClick, isOpenMobileNav }) {
  return (
    <a href="#" className="portal-nav-mobile-toggle">
      <span className="icon-mobile-menu-toggle" onClick={onClick}>
        {isOpenMobileNav ? iconCrossThin : iconMenu}
      </span>
    </a>
  );
}
