import PropTypes from 'prop-types';
import React from 'react';
import { iconPortalPhone, iconPortalMail } from '@/images/icons';

ContactCard.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	name: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
	phone: PropTypes.node
}


export default function ContactCard({ id, name, title, email, phone}) {
  return (
    <div key={id} className="contact-card">
      <div className="card-wrapper">
        <div className="content">
          <div className="details">
            <h4>{name}</h4>
            <h5 className="position">{title}</h5>
          </div>
          <div className="contact">
            { email && 
              <a href={`mailto:${email}`}>
                <span className="icon gradient">{iconPortalMail}</span>
                <span className="truncate"><abbr title={email}>{email}</abbr></span>
              </a>
            }
            { phone && 
              <a href={`tel:${phone}`}>
                <span className="icon gradient">{iconPortalPhone}</span>
                {phone}
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  );
}