import PropTypes from 'prop-types';
import React from 'react'
import useMedia from 'use-media';
import { Collapsible } from '@/components';
import { PortalPageMenuItem } from '@/components/Portal';

PortalPageMenuNavigation.propTypes = {
	mobile: PropTypes.bool,
	desktop: PropTypes.bool,
	title: PropTypes.string,
	items: PropTypes.array
}

export default function PortalPageMenuNavigation({
	mobile, 
	desktop,
	title,
	items
}) {
	const isMobile = useMedia('(max-width: 1023px)');
  const isDesktop = useMedia('(min-width: 1024px)');
	const openCollapsible = desktop;

	// Render if Mobile or Desktop
	if ( (isDesktop && mobile) || (isMobile && desktop)) {
		return null;
	}

  return (
		<div className="portal-page-menu navigation col-xs-12 col-md-4">
			<aside className="portal-page-menu__content">
				<PortalPageMenuItem title={isDesktop ? title: null}>
					<Collapsible
						variant="side-menu"
						items={items}
						openCollapsible={openCollapsible}
						mobileToggle={mobile}
					/>
				</PortalPageMenuItem>
			</aside>
		</div>
		
	);
}
