import React, { useState } from 'react';
import { iconStarFilled, iconStarOutlined } from '@/images/icons';

/**
 * @typedef {Object} Props
 * @property {function} onRate - The function to be called when a rating is selected.
 */

/**
 * RatingStar component.
 * @param {Props} props - The props for the component.
 */
function RatingStar(props) {
  const { onRate } = props;
  const [rating, setRating] = useState(0);

  const handleRate = (/** @type {number} */ index) => {
    const newRating = index + 1;
    setRating(newRating);
    onRate(newRating);
  };

  return (
    <>
      <div className="rounded-2xl justify-start items-start gap-3 inline-flex">
        {Array.from({ length: 5 }, (_, index) => (
          <div
            key={index}
            className="p-2.5 bg-zinc-300 rounded-lg justify-start items-start gap-2.5 flex cursor-pointer"
            onClick={() => handleRate(index)}
          >
            <div className="w-6 h-6 justify-center items-center flex">
              <div className="w-6 h-6 relative">
                {index < rating ? iconStarFilled : iconStarOutlined}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default RatingStar;
