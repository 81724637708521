import { createSelector } from 'reselect';
import { selectDivisionsBanks } from './membersInstitutions';

export const getUser = (state) => state.userReducer.user;

export const getUserDivisionOptions = createSelector(
  getUser,
  selectDivisionsBanks,
  (user, divisions) =>
    !!user?.divisions?.length
      ? user?.divisions?.map((division) => ({ id: division.id, name: division.name }))
      : divisions.reduce(
          (a, c) =>
            c.institution_id === user.institution_id
              ? [...a, { id: c.id, name: c.name }]
              : a,
          []
        )
);

export const getExistingUserDivisionById = (divisionId) =>
  createSelector(getUser, (user) => {
    return user?.divisions?.find((division) => division.id === Number(divisionId));
  });

export const getUserBranches = createSelector(getUser, (user) => user?.branches || []);
export const getUserIsRegistered = (state) => state.userReducer.isUserRegistered;
export const getUserError = (state) => state.userReducer.error;
export const isResetPasswordRequestSubmitting = (state) => state.userReducer.isSubmitting;
export const getUserToken = (state) => state.userReducer.token;
export const getUserDeviceName = (state) => state.userReducer.deviceName;
