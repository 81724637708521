import React from 'react';
import { Link } from 'react-router-dom';
import { iconArrowThin } from '@/images/icons';
import { PORTAL_PATHS } from '@/config';

export default function Preferences({ variant = '', title, text, btnText }) {

	return (
    <div className={`preferences ${variant}`}>
      <h4 className="title">{title}</h4>

      <p>{text}</p>

			<Link
				to={PORTAL_PATHS.myAccount.emailSubscription}
				className="btn btn-fill btn-full-width"
			>
        {btnText} <span className="icon">{iconArrowThin}</span>
			</Link>

    </div>
  );
}
